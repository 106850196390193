import * as yup from "yup";
import { userSchema } from "./user_api";
import { identitySchema, permissionSchema, } from "../auth";
export var getMeResponseSchema = yup
    .object({
    user: userSchema.defined(),
    identity: identitySchema.defined(),
    permissions: yup.array(permissionSchema.defined()).defined(),
})
    .camelCase()
    .required();
export var loginResponseSchema = yup
    .object({
    accessToken: yup.string().defined(),
})
    .camelCase()
    .defined();
export var loginRequestSchema = yup
    .object({
    username: yup.string().defined(),
    password: yup.string().defined(),
})
    .defined();
export var loginBySSORequestSchema = yup
    .object({
    sso_token: yup.string().defined(),
})
    .defined();

import { __assign, __read } from "tslib";
import React, { useCallback, useMemo, useState } from "react";
import { DocumentType, } from "@shared/models/document";
import { Persona, PersonaSize } from "@fluentui/react";
import { extractInitials } from "@utils/avatar";
import DOMPurify from "dompurify";
import { DotsHorizontalIcon, DownloadIcon } from "@heroicons/react/outline";
import { getFileTypeBgColor, getFileTypeExtension, humanizeFileSize, } from "@utils/file";
import { FormattedMessage } from "react-intl";
import DocumentDetailContextMenu from "./DocumentDetailContextMenu";
import cn from "classnames";
import "./DocumentDetailPanel.scss";
import { format } from "date-fns-tz";
var DATE_FORMAT = "EEE MM/dd/y HH:mm";
function extractEmailOrName(address) {
    var _a, _b;
    var name = (_b = (_a = address.contact) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : address.name;
    if (!name || name === address.address) {
        return address.address;
    }
    return name + " <" + address.address + ">";
}
var MessageHTMLContent = React.memo(function (props) {
    var className = props.className, document = props.document;
    var _a = __read(useState(false), 2), showFullBody = _a[0], setShowFullBody = _a[1];
    var _b = __read(useMemo(function () {
        var body_ = DOMPurify.sanitize(document.body);
        var fullBody_ = DOMPurify.sanitize(document.fullBody);
        return [body_, fullBody_];
    }, [document.body, document.fullBody]), 2), htmlBody = _b[0], htmlFullBody = _b[1];
    var onToggleFullBody = useCallback(function () {
        setShowFullBody(function (v) { return !v; });
    }, [setShowFullBody]);
    return (React.createElement("div", { className: cn("flex flex-col min-w-0 flex-none max-w-full overflow-x-hidden", className) },
        React.createElement("div", { className: "overflow-x-auto" },
            React.createElement("div", { className: "DocumentDetailPanel__htmlContainer", dangerouslySetInnerHTML: {
                    __html: showFullBody ? htmlFullBody : htmlBody,
                } })),
        document.type === DocumentType.Message && (React.createElement("button", { type: "button", onClick: onToggleFullBody, className: "bg-gray-200 w-min rounded-full px-2 mt-6" },
            React.createElement(DotsHorizontalIcon, { className: "w-4 h-4 text-gray-900" })))));
});
var FileItem = React.memo(function (props) {
    var _a, _b;
    var file = props.file;
    var _c = __read(useMemo(function () { var _a; return getFileTypeExtension((_a = file.fileName) !== null && _a !== void 0 ? _a : "" + file.id + file.fileExtension); }, [file.fileName, file.id, file.fileExtension]), 2), type = _c[0], extension = _c[1];
    var typeColor = useMemo(function () { return getFileTypeBgColor(type); }, [type]);
    return (React.createElement("a", { className: cn("inline-flex flex-row shadow-sm rounded-md w-min max-w-full overflow-hidden", !!file.url && "cursor-pointer"), href: (_a = file.url) !== null && _a !== void 0 ? _a : undefined, download: (_b = file.fileName) !== null && _b !== void 0 ? _b : "" + file.id + file.fileExtension },
        React.createElement("div", { className: "flex flex-none items-center justify-center rounded-l-md " + typeColor },
            React.createElement("span", { className: "w-[2.625rem] font-bold text-sm text-white text-center" }, type !== null && type !== void 0 ? type : extension === null || extension === void 0 ? void 0 : extension.toUpperCase())),
        React.createElement("div", { className: "inline-flex flex-initial flex-row py-2 px-4 items-center rounded-r-md border overflow-hidden" },
            React.createElement("div", { className: "truncate flex-initial" },
                React.createElement("span", { className: "font-bold text-sm text-gray-900 whitespace-nowrap" }, file.fileName)),
            React.createElement("div", { className: "flex-none px-2" },
                React.createElement("span", { className: "text-sm text-gray-500 whitespace-nowrap" }, humanizeFileSize(file.fileSize))),
            !!file.url && (React.createElement("div", { className: "flex-none" },
                React.createElement(DownloadIcon, { className: "text-gray-600 w-4 h-4" }))))));
});
export var DocumentDetailPanelSkeleton = React.memo(function () {
    return (React.createElement("div", { className: "flex flex-col items-start bg-white rounded-[0.625rem] p-4" },
        React.createElement("div", { className: "flex flex-row w-full" },
            React.createElement("div", { className: "w-8 h-8 rounded-full animate-pulse bg-gray-200" }),
            React.createElement("div", { className: "ml-4 flex-1 flex flex-col min-w-0" },
                React.createElement("div", { className: "flex flex-row justify-between" },
                    React.createElement("h1", { className: "font-bold text-sm leading-5 text-gray-900" },
                        React.createElement("div", { className: "h-8 w-16 animate-pulse bg-gray-200 rounded-full" }))),
                React.createElement("div", { className: "mt-2 space-y-1 flex flex-col" },
                    React.createElement("h3", { className: "font-bold text-sm leading-5 text-gray-900" },
                        React.createElement("div", { className: "h-5 w-20 animate-pulse bg-gray-200 rounded-full" })),
                    React.createElement("h3", { className: "font-bold text-sm leading-5 text-gray-900" },
                        React.createElement("div", { className: "h-5 w-20 animate-pulse bg-gray-200 rounded-full" }))))),
        React.createElement("div", { className: "w-full my-6 border-b border-gray-300" }),
        React.createElement("div", { className: "flex-1 max-w-full space-y-2" }, new Array(3).fill(null).map(function (_, index) { return (React.createElement("div", { key: index, className: "h-5 w-80 animate-pulse bg-gray-200 rounded-full" })); }))));
});
var MessageDetailPanel = React.memo(function (props) {
    var _a, _b, _c, _d;
    var document = props.document, onRemove = props.onRemove;
    var _e = __read(useState(false), 2), showFullToList = _e[0], setShowFullToList = _e[1];
    var _f = __read(useState(false), 2), showFullCcList = _f[0], setShowFullCcList = _f[1];
    var _g = __read(useMemo(function () {
        var addressList = document.to.map(extractEmailOrName).filter(function (a) { return !!a; });
        var slicedList = addressList.slice(0, 3);
        return [
            slicedList.join(", "),
            addressList.join(", "),
            addressList.length - slicedList.length,
        ];
    }, [document.to]), 3), toAddresses = _g[0], fullToAddresses = _g[1], extraToAddressesCount = _g[2];
    var _h = __read(useMemo(function () {
        var addressList = document.cc.map(extractEmailOrName).filter(function (a) { return !!a; });
        var slicedList = addressList.slice(0, 3);
        return [
            slicedList.join(", "),
            addressList.join(", "),
            addressList.length - slicedList.length,
        ];
    }, [document.cc]), 3), ccAddresses = _h[0], fullCcAddresses = _h[1], extraCcAddressesCount = _h[2];
    var senderIdentifier = useMemo(function () {
        var _a;
        if (!document.from)
            return "";
        if (!!((_a = document.from.contact) === null || _a === void 0 ? void 0 : _a.name)) {
            return document.from.contact.name;
        }
        return document.from.name;
    }, [document.from]);
    var fromAddress = useMemo(function () {
        if (!document.from)
            return "";
        return extractEmailOrName(document.from);
    }, [document.from]);
    var sentAt = useMemo(function () {
        return format(document.createdAt, DATE_FORMAT);
    }, [document]);
    var onClickDisplayFullToAddresses = useCallback(function () {
        setShowFullToList(true);
    }, [setShowFullToList]);
    var onClickDisplayFullCcAddresses = useCallback(function () {
        setShowFullCcList(true);
    }, [setShowFullCcList]);
    return (React.createElement("div", { className: "flex flex-col items-start bg-white rounded-[0.625rem] p-4" },
        React.createElement("div", { className: "flex flex-row w-full" },
            React.createElement(Persona, { imageUrl: (_d = (_c = (_b = (_a = document.from) === null || _a === void 0 ? void 0 : _a.contact) === null || _b === void 0 ? void 0 : _b.avatar) === null || _c === void 0 ? void 0 : _c.url) !== null && _d !== void 0 ? _d : undefined, imageInitials: extractInitials(senderIdentifier, 2), hidePersonaDetails: true, size: PersonaSize.size32 }),
            React.createElement("div", { className: "ml-4 flex-1 flex flex-col min-w-0" },
                React.createElement("div", { className: "flex flex-row justify-between" },
                    React.createElement("h1", { className: "font-bold text-sm leading-5 text-gray-900" }, fromAddress),
                    React.createElement("div", { className: "flex flex-row space-x-2 items-center" },
                        React.createElement("span", { className: "font-normal text-[0.625rem] leading-4 text-gray-600" }, sentAt),
                        React.createElement(DocumentDetailContextMenu, { document: document, onRemove: onRemove, isRemovable: document.isModifiable, isExportable: true }))),
                React.createElement("div", { className: "mt-2 space-y-1 flex flex-col" },
                    React.createElement("h3", { className: "font-bold text-sm leading-5 text-gray-900" },
                        React.createElement(FormattedMessage, { id: "uploads.detail.to" }),
                        React.createElement("span", { className: "font-normal text-sm leading-5 text-gray-900" }, !showFullToList ? toAddresses : fullToAddresses),
                        !!extraToAddressesCount && !showFullToList && (React.createElement("button", { className: "ml-2 font-normal text-xs leading-4 text-indigo-900", type: "button", onClick: onClickDisplayFullToAddresses },
                            React.createElement(FormattedMessage, { id: "uploads.detail.extra_address", values: { count: extraToAddressesCount } })))),
                    React.createElement("h3", { className: "font-bold text-sm leading-5 text-gray-900" },
                        React.createElement(FormattedMessage, { id: "uploads.detail.cc" }),
                        React.createElement("span", { className: "font-normal text-sm leading-5 text-gray-900" }, !showFullCcList ? ccAddresses : fullCcAddresses),
                        !!extraCcAddressesCount && !showFullCcList && (React.createElement("button", { className: "ml-2 font-normal text-xs leading-4 text-indigo-900", type: "button", onClick: onClickDisplayFullCcAddresses },
                            React.createElement(FormattedMessage, { id: "uploads.detail.extra_address", values: { count: extraCcAddressesCount } }))))),
                document.attachments.length > 0 && (React.createElement("div", { className: "flex flex-col mt-4 space-y-2" }, document.attachments.map(function (file) { return (React.createElement(FileItem, { key: file.id, file: file })); }))))),
        React.createElement("div", { className: "w-full my-6 border-b border-gray-300" }),
        React.createElement(MessageHTMLContent, { document: document })));
});
var FileDetailPanel = React.memo(function (props) {
    var _a, _b, _c;
    var document = props.document, onRemove = props.onRemove;
    var attachmentCreatorIdentifier = useMemo(function () {
        if (!document.creator)
            return "";
        return (
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        document.creator.fullName || document.creator.emails[0]);
    }, [document.creator]);
    var sentAt = useMemo(function () {
        return format(document.createdAt, DATE_FORMAT);
    }, [document]);
    return (React.createElement("div", { className: "flex flex-col items-start bg-white rounded-[0.625rem] p-4" },
        React.createElement("div", { className: "flex flex-row items-center w-full" },
            React.createElement(Persona, { imageUrl: (_c = (_b = (_a = document.creator) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.url) !== null && _c !== void 0 ? _c : undefined, imageInitials: extractInitials(attachmentCreatorIdentifier, 2), hidePersonaDetails: true, size: PersonaSize.size32 }),
            React.createElement("div", { className: "ml-4 flex-1 flex flex-row justify-between" },
                React.createElement("h1", { className: "font-bold text-sm leading-5 text-gray-900" }, attachmentCreatorIdentifier),
                React.createElement("div", { className: "flex flex-row space-x-2 items-center" },
                    React.createElement("span", { className: "font-normal text-[0.625rem] leading-4 text-gray-600" }, sentAt),
                    React.createElement(DocumentDetailContextMenu, { document: document, onRemove: onRemove, isRemovable: document.isModifiable, isExportable: false })))),
        React.createElement(MessageHTMLContent, { className: "py-6", document: document }),
        React.createElement("div", { className: "flex-1" },
            React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900" },
                React.createElement(FormattedMessage, { id: "uploads.detail.documents" }),
                React.createElement("span", { className: "ml-2 text-base leading-6 font-normal text-gray-600" }, document.attachments.length)),
            React.createElement("div", { className: "flex flex-col mt-3 space-y-2" }, document.attachments.map(function (file) { return (React.createElement(FileItem, { key: file.id, file: file })); })))));
});
export var DocumentDetailPanel = React.memo(function (props) {
    var document = props.document;
    if (document.type === DocumentType.Message) {
        return React.createElement(MessageDetailPanel, __assign({}, props));
    }
    return React.createElement(FileDetailPanel, __assign({}, props));
});

import { __read, __spreadArray } from "tslib";
import * as yup from "yup";
import { generatePaginationSchema, paginatedSearchRequest, } from "../pagination";
import { DefaultStartPageTypeValues, } from "../user";
import { fileSchema } from "./file_api";
import { prioritySchema } from "./priority_api";
import { departmentRoleSchema, departmentSchema } from "./department_api";
import { toggleableParamSchema } from "./params";
export var userSchema = yup
    .object({
    id: yup.number().defined(),
    username: yup.string().defined(),
    fullName: yup.string().defined().nullable(),
    isEnabled: yup.bool().defined(),
    emails: yup.array(yup.string().defined()).defined(),
    avatar: fileSchema.defined().nullable(),
})
    .camelCase()
    .defined();
export var userWithDepartmentsSchema = userSchema.concat(yup
    .object({
    departments: yup.array(departmentSchema).defined(),
    departmentRoles: yup.array(departmentRoleSchema).defined(),
})
    .camelCase()
    .defined());
export var userWithDepartmentsAndManagersSchema = userWithDepartmentsSchema.concat(yup
    .object({
    managers: yup.array(userSchema).defined(),
})
    .camelCase()
    .defined());
export var getUserResponseSchema = yup
    .object({
    user: userWithDepartmentsAndManagersSchema.defined(),
})
    .camelCase()
    .defined();
export var userPreferenceSchema = yup
    .object({
    user: userSchema.defined(),
    defaultStartPage: yup
        .string()
        .defined()
        .nullable()
        .oneOf(__spreadArray(__spreadArray([], __read(DefaultStartPageTypeValues)), [null])),
    priorities: yup.array(prioritySchema.defined()).defined(),
    defaultShareUsers: yup.array(userSchema.defined()).defined(),
    defaultShareDepartments: yup.array(departmentSchema.defined()).defined(),
})
    .camelCase()
    .defined();
export var listUserRequestSchema = paginatedSearchRequest.concat(yup
    .object({
    userIds: yup.array(yup.number().defined().integer()).notRequired(),
    departmentIds: yup.array(yup.number().defined().integer()).notRequired(),
    excludeSystem: yup.boolean().notRequired(),
})
    .concat(toggleableParamSchema)
    .snakeCase()
    .defined());
export var listUserResponseSchema = generatePaginationSchema(userWithDepartmentsSchema);
export var editUserPreferenceRequestSchema = yup
    .object()
    .shape({
    full_name: yup.string().trim().defined(),
    avatar: yup.string().defined().nullable(),
    default_start_page: yup.string().defined().nullable(),
    priorities: yup.array(yup.number().defined()).defined(),
    default_share_users: yup.array(yup.number().defined()).defined(),
    default_share_departments: yup.array(yup.number().defined()).defined(),
})
    .snakeCase()
    .defined();
export var updateUserRequestSchema = yup
    .object()
    .shape({
    full_name: yup.string().defined(),
    emails: yup.array(yup.string().defined()).defined(),
    avatar_id: yup.string().defined().nullable(),
    department_id: yup.number().defined().nullable(),
    role: yup.string().defined().nullable(),
    manager_ids: yup.array(yup.number().defined()).defined(),
    is_enabled: yup.boolean().defined(),
})
    .snakeCase()
    .defined();
export var updateUserResponseSchema = yup
    .object()
    .shape({
    user: userWithDepartmentsAndManagersSchema.defined(),
})
    .snakeCase()
    .defined();
export var updateUserIsEnabledRequestSchema = yup
    .object()
    .shape({
    is_enabled: yup.boolean().defined(),
})
    .snakeCase()
    .defined();
export var updateUserIsEnabledResponseSchema = yup
    .object()
    .shape({
    user: userSchema.defined(),
})
    .snakeCase()
    .defined();

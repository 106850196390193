import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { PriorityAPIContext } from "@shared/contexts/api/PriorityAPIContext";
import { ROUTES } from "@router/routes";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { useAppToast } from "../common/Toast";
import { TagDetailHeader } from "../settings/TagDetailHeader";
import { TagDetailInfo } from "../settings/TagDetailInfo";
import { useNavigation } from "@hooks/useNavigation";
import { ColouredTag } from "@components/common/ColouredTag";
import { PriorityExtractors } from "@shared/models/priority";
import { getTagGroupCustomFieldsEntries, } from "@shared/models/tag";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { useMergeTagDialog, MergeTagDialog } from "../common/MergeTagDialog";
export var PriorityTagDetailScreen = React.memo(function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var tagId = useParams().tagId;
    var priorityId = useMemo(function () { return parseInt(tagId, 10); }, [tagId]);
    var navigation = useNavigation();
    var location = useLocation();
    var showErrorToast = useAppToast().showErrorToast;
    var priorityAPI = useContext(PriorityAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _h = __read(useState(null), 2), priority = _h[0], setPriority = _h[1];
    var _j = __read(useState(false), 2), isLoading = _j[0], setIsLoading = _j[1];
    var _k = __read(useState([]), 2), availableCustomProperties = _k[0], setAvailableCustomProperties = _k[1];
    var _l = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _l[0], setTagName = _l[1];
    var goGroupDetail = useCallback(function () {
        navigation.replace(ROUTES.settings.tags.priority.group_detail);
    }, [navigation]);
    var fetchPriority = useCallback(function () {
        setIsLoading(true);
        Promise.all([
            priorityAPI.getPriority(priorityId),
            tagGroupAPI.getTagGroupBySlug("priority"),
        ])
            .then(function (_a) {
            var _b = __read(_a, 2), priority = _b[0], tagGroup = _b[1];
            setPriority(priority);
            setTagName(priority.name);
            setAvailableCustomProperties(getTagGroupCustomFieldsEntries(tagGroup.definition).map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return ({
                    name: value.name,
                    value: priority.additional[key],
                });
            }));
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            goGroupDetail();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [priorityAPI, priorityId, tagGroupAPI, showErrorToast, goGroupDetail]);
    var onEditTag = useCallback(function () {
        navigation.push(ROUTES.settings.tags.priority.group_detail + "/" + priorityId + "/edit");
    }, [navigation, priorityId]);
    var onEnableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, priorityAPI.enablePriority(priorityId)];
        });
    }); }, [priorityAPI, priorityId]);
    var onDisableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, priorityAPI.disablePriority(priorityId)];
        });
    }); }, [priorityAPI, priorityId]);
    var onDeleteTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, priorityAPI.deletePriority(priorityId).then(function () {
                    goGroupDetail();
                })];
        });
    }); }, [priorityAPI, priorityId, goGroupDetail]);
    var _m = useMergeTagDialog(), mergeDialogProps = _m.mergeDialogProps, openMergeDialog = _m.openMergeDialog, closeMergeDialog = _m.closeMergeDialog;
    var _o = __read(useState(false), 2), isMerging = _o[0], setIsMerging = _o[1];
    var onMergeTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var source, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openMergeDialog()];
                case 1:
                    source = _a.sent();
                    if (!source) {
                        return [2 /*return*/];
                    }
                    setIsMerging(true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, priorityAPI.mergePriority(priorityId, source.id)];
                case 3:
                    _a.sent();
                    closeMergeDialog();
                    fetchPriority();
                    return [3 /*break*/, 6];
                case 4:
                    err_1 = _a.sent();
                    console.error("Failed to merge tag", err_1);
                    showErrorToast({
                        titleId: "merge_tag.error.title",
                        descriptionId: "merge_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 6];
                case 5:
                    setIsMerging(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [
        openMergeDialog,
        priorityAPI,
        priorityId,
        closeMergeDialog,
        showErrorToast,
        fetchPriority,
    ]);
    useEffect(function () {
        fetchPriority();
    }, [fetchPriority]);
    return (React.createElement("div", { className: "flex flex-col min-w-0 flex-1" },
        React.createElement(MergeTagDialog, __assign({}, mergeDialogProps, { destinationId: priorityId, tagName: tagName, fetch: priorityAPI.listPriorities, isMerging: isMerging, keyExtractor: PriorityExtractors.id, labelExtractor: PriorityExtractors.name })),
        React.createElement(TagDetailHeader, { typeId: "settings.tags.title.priority", tagName: tagName, isTagEnabled: (_c = priority === null || priority === void 0 ? void 0 : priority.isEnabled) !== null && _c !== void 0 ? _c : true, onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onMergeTag, onGoBack: goGroupDetail }),
        React.createElement("div", { className: "flex-1 mt-2 overflow-y-auto md:mt-0 md:p-4" },
            React.createElement(TagDetailInfo.Panel, { isLoading: isLoading },
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.priority.form.display_name" }), value: (_d = priority === null || priority === void 0 ? void 0 : priority.fullName) !== null && _d !== void 0 ? _d : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.priority.form.color" }), value: React.createElement(ColouredTag, { tag: (_f = (_e = priority === null || priority === void 0 ? void 0 : priority.color) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : "", colour: priority === null || priority === void 0 ? void 0 : priority.color }) }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.priority.form.remarks" }), value: (_g = priority === null || priority === void 0 ? void 0 : priority.remarks) !== null && _g !== void 0 ? _g : "" }),
                availableCustomProperties.map(function (value, index) { return (React.createElement(TagDetailInfo.Item, { key: index, title: value.name, value: value.value })); })))));
});

import { __read } from "tslib";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { useAppToast } from "../common/Toast";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
import { ROUTES } from "@router/routes";
import { RegionForm } from "../settings/forms/RegionForm";
import { TagSettingRouterContext } from "@router/TagSettingRouter";
import { RegionAPIContext } from "@shared/contexts/api/RegionAPIContext";
import { parseTagCreationErrorMessage } from "@shared/models/error";
import { useNavigation } from "@hooks/useNavigation";
export var RegionTagCreationScreen = React.memo(function () {
    var regionAPI = useContext(RegionAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var refreshSidebar = useContext(TagSettingRouterContext).refreshSidebar;
    var intl = useIntl();
    var navigation = useNavigation();
    var showErrorToast = useAppToast().showErrorToast;
    var _a = __read(useState({}), 2), additionalFields = _a[0], setAdditionalFields = _a[1];
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(false), 2), isSubmitting = _c[0], setIsSubmitting = _c[1];
    var handleSubmit = useCallback(function (formValues) {
        setIsSubmitting(true);
        regionAPI
            .createRegion({
            name: formValues.tagName.trim(),
            fullName: !!formValues.fullName ? formValues.fullName.trim() : null,
            additional: formValues.additional,
        })
            .then(function (region) {
            refreshSidebar();
            navigation.push(ROUTES.settings.tags.region.group_detail + "/" + region.id);
        })
            .catch(function (err) {
            var _a = __read(parseTagCreationErrorMessage(err), 2), titleId = _a[0], descriptionId = _a[1];
            showErrorToast({
                titleId: titleId,
                descriptionId: descriptionId,
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    }, [regionAPI, navigation, setIsSubmitting, refreshSidebar, showErrorToast]);
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.region.group_detail);
        }
    }, [navigation]);
    useEffect(function () {
        setIsLoading(true);
        tagGroupAPI
            .getTagGroupBySlug("region")
            .then(function (resp) {
            setAdditionalFields(resp.definition);
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        tagGroupAPI,
        navigation,
        showErrorToast,
        setAdditionalFields,
        handleGoBack,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tags.form.create.title", values: {
                        type: intl.formatMessage({
                            id: "settings.tags.title.region",
                        }),
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(RegionForm, { additionalFields: additionalFields, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleGoBack })))));
});

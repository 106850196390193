import { __assign, __read, __spreadArray } from "tslib";
import * as yup from "yup";
import { TagGroupSlug } from "../tag";
import { toggleableParamSchema } from "./params";
export var customFieldDefinitionSchema = yup
    .object({
    name: yup.string().defined(),
    mandatory: yup.boolean().defined(),
    createdAt: yup.date().defined().nullable(),
})
    .camelCase()
    .from("isMandatory", "mandatory")
    .defined();
export var tagGroupSchema = yup
    .object({
    id: yup.number().defined(),
    slug: yup
        .mixed()
        .oneOf(__spreadArray([null], __read(Object.values(TagGroupSlug)))),
    name: yup.string().defined(),
    enabled: yup.boolean().defined(),
    mandatory: yup.boolean().defined(),
    public: yup.boolean().defined(),
    visible: yup.boolean().defined(),
    tagCount: yup.number().defined(),
    definition: yup.lazy(function (o) {
        if (typeof o !== "object") {
            // To guard check input value is an object
            return yup
                .object()
                .shape({})
                .defined();
        }
        return yup
            .object(Object.keys(o).reduce(function (acc, curr) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[curr] = customFieldDefinitionSchema, _a)));
        }, {}))
            .defined();
    }),
})
    .camelCase()
    .from("isEnabled", "enabled")
    .from("isMandatory", "mandatory")
    .from("isPublic", "public")
    .from("isVisible", "visible")
    .defined();
export var tagGroupCustomFieldDefinitionRequestSchema = yup
    .object({
    name: yup.string().defined(),
    is_mandatory: yup.boolean().defined(),
})
    .snakeCase()
    .from("mandatory", "is_mandatory")
    .defined();
export var createTagGroupRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    is_mandatory: yup.boolean().defined(),
    is_public: yup.boolean().defined(),
    is_visible: yup.boolean().defined(),
    definition: yup.lazy(function (o) {
        if (typeof o !== "object") {
            return yup.object();
        }
        return yup
            .object(Object.keys(o).reduce(function (acc, curr) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[curr] = tagGroupCustomFieldDefinitionRequestSchema, _a)));
        }, {}))
            .defined();
    }),
})
    .snakeCase()
    .from("mandatory", "is_mandatory")
    .from("public", "is_public")
    .from("visible", "is_visible")
    .defined();
export var updateTagGroupRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    is_mandatory: yup.boolean().defined(),
    is_public: yup.boolean().defined(),
    is_visible: yup.boolean().defined(),
    definition: yup.lazy(function (o) {
        if (typeof o !== "object") {
            return yup.object();
        }
        return yup
            .object(Object.keys(o).reduce(function (acc, curr) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[curr] = tagGroupCustomFieldDefinitionRequestSchema, _a)));
        }, {}))
            .defined();
    }),
})
    .snakeCase()
    .from("mandatory", "is_mandatory")
    .from("public", "is_public")
    .from("visible", "is_visible")
    .defined();
export var updatePredefinedTagGroupRequestSchema = yup
    .object()
    .shape({
    is_mandatory: yup.boolean().defined(),
    is_public: yup.boolean().defined(),
    is_visible: yup.boolean().defined(),
    definition: yup.lazy(function (o) {
        if (typeof o !== "object") {
            return yup.object();
        }
        return yup
            .object(Object.keys(o).reduce(function (acc, curr) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[curr] = tagGroupCustomFieldDefinitionRequestSchema, _a)));
        }, {}))
            .defined();
    }),
})
    .snakeCase()
    .defined();
export var getAllTagGroupsQueryParamsSchema = yup
    .object()
    .shape({
    isCustom: yup.boolean(),
    isMandatory: yup.boolean(),
    isVisible: yup.boolean(),
})
    .concat(toggleableParamSchema)
    .snakeCase()
    .defined();
export var getAllTagGroupsResponseSchema = yup
    .object()
    .shape({
    data: yup.array(tagGroupSchema.defined()).defined(),
})
    .defined();

import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { tagGroupSchema, createTagGroupRequestSchema, updateTagGroupRequestSchema, updatePredefinedTagGroupRequestSchema, getAllTagGroupsResponseSchema, getAllTagGroupsQueryParamsSchema, } from "@shared/models/api/tag_group_api";
export var TagGroupAPIContext = React.createContext(undefined);
export var TagGroupAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getAllTagGroups = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var validatedParams, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    validatedParams = params
                        ? getAllTagGroupsQueryParamsSchema.validateSync(params)
                        : {};
                    return [4 /*yield*/, apiContext.get("tag_groups/all", validatedParams)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, getAllTagGroupsResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var getTagGroupBySlug = useCallback(function (slug) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("tag_groups/slug/" + slug)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, tagGroupSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var getTagGroupById = useCallback(function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("tag_groups/" + id)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, tagGroupSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var createTagGroup = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = createTagGroupRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("tag_groups", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, tagGroupSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updateTagGroup = useCallback(function (groupId, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = updateTagGroupRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.put("tag_groups/" + groupId, body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, tagGroupSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updatePredefinedTagGroup = useCallback(function (slug, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = updatePredefinedTagGroupRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.put("tag_groups/slug/" + slug, body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, tagGroupSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var enableTagGroup = useCallback(function (groupId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.post("tag_groups/" + groupId + "/enable", {})];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, tagGroupSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var disableTagGroup = useCallback(function (groupId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.post("tag_groups/" + groupId + "/disable", {})];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, tagGroupSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var deleteTagGroup = useCallback(function (groupId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.delete("tag_groups/" + groupId)];
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            getAllTagGroups: getAllTagGroups,
            getTagGroupBySlug: getTagGroupBySlug,
            getTagGroupById: getTagGroupById,
            createTagGroup: createTagGroup,
            updateTagGroup: updateTagGroup,
            updatePredefinedTagGroup: updatePredefinedTagGroup,
            enableTagGroup: enableTagGroup,
            disableTagGroup: disableTagGroup,
            deleteTagGroup: deleteTagGroup,
        };
    }, [
        getAllTagGroups,
        getTagGroupBySlug,
        getTagGroupById,
        createTagGroup,
        updateTagGroup,
        updatePredefinedTagGroup,
        enableTagGroup,
        disableTagGroup,
        deleteTagGroup,
    ]);
    return (React.createElement(TagGroupAPIContext.Provider, { value: value }, children));
});

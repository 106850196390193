import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Dialog as HeadlessDialog } from "@headlessui/react";
import { FormattedMessage } from "react-intl";
import { BasicDialog } from "./BasicDialog";
export var Dialog = React.memo(function (props) {
    var isOpened = props.isOpened, title = props.title, description = props.description, cancelLabelId = props.cancelLabelId, onClose = props.onClose, _a = props.confirmLabelId, confirmLabelId = _a === void 0 ? "common.confirm" : _a, icon = props.icon;
    var cancelButtonRef = useRef(null);
    var onConfirm = useCallback(function () {
        onClose(true);
    }, [onClose]);
    var onCancel = useCallback(function () {
        onClose(false);
    }, [onClose]);
    return (React.createElement(BasicDialog, { isOpened: isOpened, onClose: onClose },
        React.createElement("div", { className: "px-4 pt-5 pb-4 sm:p-6" },
            React.createElement("div", { className: "sm:flex sm:items-start" },
                icon && (React.createElement("div", { className: "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10" }, icon)),
                React.createElement("div", { className: "mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" },
                    title && (React.createElement(HeadlessDialog.Title, { as: "h3", className: "text-lg leading-6 font-medium text-gray-900" }, title)),
                    description && (React.createElement("div", { className: "mt-2" },
                        React.createElement("p", { className: "text-sm text-gray-500" }, description))))),
            React.createElement("div", { className: "mt-5 flex flex-col-reverse sm:mt-4 sm:justify-end sm:flex-row" },
                cancelLabelId && (React.createElement("button", { type: "button", className: "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm", onClick: onCancel, ref: cancelButtonRef },
                    React.createElement(FormattedMessage, { id: cancelLabelId }))),
                React.createElement("button", { type: "button", className: "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm", onClick: onConfirm },
                    React.createElement(FormattedMessage, { id: confirmLabelId }))))));
});
export function useDialog() {
    var _this = this;
    var _a = __read(useState(false), 2), isOpened = _a[0], setIsOpened = _a[1];
    var _b = __read(useState(null), 2), dialogProps = _b[0], setDialogProps = _b[1];
    var callbackRef = useRef(null);
    var openDialog = useCallback(function (props) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (callbackRef.current != null) {
                return [2 /*return*/, Promise.resolve(false)];
            }
            return [2 /*return*/, new Promise(function (resolve) {
                    callbackRef.current = resolve;
                    setDialogProps(props !== null && props !== void 0 ? props : null);
                    setIsOpened(true);
                })];
        });
    }); }, [setIsOpened]);
    var onClose = useCallback(function (result) {
        var _a;
        setIsOpened(false);
        (_a = callbackRef.current) === null || _a === void 0 ? void 0 : _a.call(callbackRef, result);
        callbackRef.current = null;
    }, [setIsOpened]);
    var props = useMemo(function () { return (__assign(__assign({}, (dialogProps !== null && dialogProps !== void 0 ? dialogProps : {})), { isOpened: isOpened, onClose: onClose })); }, [isOpened, dialogProps, onClose]);
    return {
        dialogProps: props,
        openDialog: openDialog,
    };
}

import { __assign, __read } from "tslib";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { IndustryExtractor } from "@shared/models/industry";
import { CountryExtractor } from "@shared/models/country";
import { companyFormSchema } from "./models";
import { useIntl } from "react-intl";
import { FormField } from "@components/form/FormField";
import { SelectMenu } from "@components/form/SelectMenu";
import { ColorRandomizor } from "@components/form/ColorRandomizor";
import { Formik } from "formik";
import cn from "classnames";
import { TextField } from "@components/form/TextField";
import { getTagGroupCustomFieldsEntries, } from "@shared/models/tag";
import { aggregateFormSchema } from "./utils";
import { FormShell } from "./FormShell";
export var CompanyForm = React.memo(function (props) {
    var colors = props.colors, countries = props.countries, industries = props.industries, additionalFields = props.additionalFields, initialFormValues = props.initialFormValues, isSubmitting = props.isSubmitting, onSubmit = props.onSubmit, onCancel = props.onCancel;
    var intl = useIntl();
    var _a = __read(useState(countries), 2), countriesOptions = _a[0], setCountriesOptions = _a[1];
    var _b = __read(useState(industries), 2), industriesOptions = _b[0], setIndustriesOptions = _b[1];
    var formikRef = useRef(null);
    var isEditing = useMemo(function () { return !!initialFormValues; }, [initialFormValues]);
    var initialValues = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        return ({
            fullName: (_a = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.fullName) !== null && _a !== void 0 ? _a : "",
            tagName: (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.tagName) !== null && _b !== void 0 ? _b : "",
            countries: {
                data: (_c = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.countries.data) !== null && _c !== void 0 ? _c : [],
            },
            industry: (_d = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.industry) !== null && _d !== void 0 ? _d : null,
            domain: (_e = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.domain) !== null && _e !== void 0 ? _e : "",
            color: (_f = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.color) !== null && _f !== void 0 ? _f : null,
            address: (_g = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.address) !== null && _g !== void 0 ? _g : "",
            additional: Object.keys(additionalFields).reduce(function (acc, curr) {
                var _a;
                var _b;
                return (__assign(__assign({}, acc), (_a = {}, _a[curr] = (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.additional[curr]) !== null && _b !== void 0 ? _b : "", _a)));
            }, {}),
        });
    }, [initialFormValues, additionalFields]);
    var aggregatedSchema = useMemo(function () { return aggregateFormSchema(companyFormSchema, additionalFields); }, [additionalFields]);
    var onCountryChange = useCallback(function (countries) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("countries", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("countries", { data: countries }, true);
    }, []);
    var onIndustryChange = useCallback(function (industry) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("industry", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("industry", industry);
    }, []);
    var onColorChange = useCallback(function (color) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("color", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("color", color);
    }, []);
    var onCountrySearch = useCallback(function (term) {
        if (term) {
            setCountriesOptions(countries.filter(function (i) {
                return CountryExtractor.name(i).toLowerCase().includes(term.toLowerCase());
            }));
        }
        else {
            setCountriesOptions(countries);
        }
    }, [countries]);
    var onIndustrySearch = useCallback(function (term) {
        if (term) {
            setIndustriesOptions(industries.filter(function (i) {
                return IndustryExtractor.name(i)
                    .toLowerCase()
                    .includes(term.toLowerCase());
            }));
        }
        else {
            setIndustriesOptions(industries);
        }
    }, [industries]);
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: aggregatedSchema, validateOnChange: true, onSubmit: onSubmit, innerRef: formikRef }, function (_a) {
        var _b, _c;
        var values = _a.values, errors = _a.errors, touched = _a.touched, handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur;
        return (React.createElement(FormShell, { isSubmitting: isSubmitting, onCancel: onCancel, onSubmit: handleSubmit, cancelLabel: intl.formatMessage({ id: "common.cancel" }), submitLabel: intl.formatMessage({
                id: isEditing
                    ? "settings.tags.form.edit.submit"
                    : "settings.tags.form.create.submit",
            }, {
                type: intl.formatMessage({
                    id: "settings.tags.title.company",
                }),
            }) },
            React.createElement(FormField, { titleId: "settings.tags.company.form.full_name", errorId: touched.fullName ? errors.fullName : undefined, isOptional: true },
                React.createElement(TextField, { name: "fullName", onChange: handleChange, onBlur: handleBlur, value: values.fullName, isError: touched.fullName && !!errors.fullName, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
            React.createElement(FormField, { titleId: "settings.tags.company.form.tag_name", errorId: touched.tagName ? errors.tagName : undefined, isOptional: false },
                React.createElement(TextField, { name: "tagName", onChange: handleChange, onBlur: handleBlur, value: values.tagName, placeholder: intl.formatMessage({
                        id: "settings.tags.company.form.tag_name.placeholder",
                    }), isError: touched.tagName && !!errors.tagName, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
            React.createElement(FormField, { titleId: "settings.tags.company.form.country", errorId: touched.countries
                    ? (_b = errors.countries) === null || _b === void 0 ? void 0 : _b.data
                    : undefined, isOptional: false },
                React.createElement(SelectMenu, { className: "h-[2.375rem] w-full md:max-w-xs", selected: values.countries.data, onSelect: onCountryChange, multiple: true, items: countriesOptions, placeholder: intl.formatMessage({
                        id: "settings.tags.company.form.country.placeholder",
                    }), isError: touched.countries && !!((_c = errors.countries) === null || _c === void 0 ? void 0 : _c.data), keyExtractor: CountryExtractor.id, labelExtractor: CountryExtractor.name, onSearch: onCountrySearch })),
            React.createElement(FormField, { titleId: "settings.tags.company.form.industry", errorId: touched.industry ? errors.industry : undefined, isOptional: false },
                React.createElement(SelectMenu, { className: "h-[2.375rem] w-full md:max-w-xs", selected: values.industry, onSelect: onIndustryChange, multiple: false, items: industriesOptions, keyExtractor: IndustryExtractor.id, labelExtractor: IndustryExtractor.name, onSearch: onIndustrySearch, isError: touched.industry && !!errors.industry })),
            React.createElement(FormField, { titleId: "settings.tags.company.form.domain", errorId: touched.domain ? errors.domain : undefined, isOptional: false },
                React.createElement(TextField, { name: "domain", onChange: handleChange, onBlur: handleBlur, value: values.domain, className: "py-2 px-3 border border-gray-300 shadow-sm h-[2.375rem] w-full", placeholder: intl.formatMessage({
                        id: "settings.tags.company.form.domain.hint",
                    }), isError: touched.domain && !!errors.domain })),
            React.createElement(FormField, { titleId: "settings.tags.company.form.color", isOptional: true },
                React.createElement(ColorRandomizor, { className: "h-[2.375rem]", colors: colors, currentColor: values.color, onColorChange: onColorChange })),
            React.createElement(FormField, { titleId: "settings.tags.company.form.address", isOptional: true },
                React.createElement(TextField, { name: "address", onChange: handleChange, onBlur: handleBlur, value: values.address, className: "py-2 px-3 border border-gray-300 shadow-sm h-[2.375rem] w-full" })),
            getTagGroupCustomFieldsEntries(additionalFields).map(function (_a) {
                var _b, _c, _d, _e;
                var _f = __read(_a, 2), key = _f[0], _g = _f[1], name = _g.name, mandatory = _g.mandatory;
                return (React.createElement(FormField, { key: key, title: name, errorId: ((_b = touched.additional) === null || _b === void 0 ? void 0 : _b[key])
                        ? (_c = errors.additional) === null || _c === void 0 ? void 0 : _c[key]
                        : undefined, isOptional: !mandatory },
                    React.createElement(TextField, { name: "additional." + key, onChange: handleChange, onBlur: handleBlur, isError: ((_d = touched.additional) === null || _d === void 0 ? void 0 : _d[key]) && !!((_e = errors.additional) === null || _e === void 0 ? void 0 : _e[key]), value: values.additional[key], className: "py-2 px-3 border border-gray-300 shadow-sm h-[2.375rem] w-full" })));
            })));
    }));
});

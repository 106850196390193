import { __assign, __rest } from "tslib";
import cn from "classnames";
import React from "react";
import { SearchIcon } from "@heroicons/react/outline";
export var SearchBar = React.forwardRef(function (props, ref) {
    var containerClassName = props.containerClassName, inputClassName = props.inputClassName, searchIconClassName = props.searchIconClassName, hideSearchIcon = props.hideSearchIcon, restProps = __rest(props, ["containerClassName", "inputClassName", "searchIconClassName", "hideSearchIcon"]);
    return (React.createElement("div", { className: cn("w-full flex text-gray-100 md:ml-0 ", containerClassName) },
        React.createElement("div", { className: "relative w-full " },
            !hideSearchIcon && (React.createElement("div", { className: cn("absolute inset-y-0 left-3 md:left-4 flex items-center pointer-events-none bg-transparent", searchIconClassName) },
                React.createElement(SearchIcon, { className: "h-5 w-5", "aria-hidden": "true" }))),
            React.createElement("input", __assign({}, restProps, { ref: ref, className: cn("flex h-full w-full pr-4 md:pr-3 py-2 bg-transparent border-transparent focus:outline-none focus:ring-0 focus:border-transparent", !hideSearchIcon ? "pl-11" : "pl-4 md:pl-3", inputClassName) })))));
});

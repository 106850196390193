import { __awaiter, __generator } from "tslib";
import React, { Suspense, useContext } from "react";
import cn from "classnames";
import { Switch, Route, BrowserRouter as Router, Redirect, } from "react-router-dom";
import { AuthContextProvider, AuthContext } from "@contexts/AuthContext";
import { I18nContextProvider } from "@contexts/I18nContext";
import { APIContextProvider } from "@shared/contexts/api";
import { AzureOAuthContextProvider } from "@contexts/AzureOAuthContext";
import { AppContextProvider } from "@contexts/AppContext";
import { AuthRoute, AuthState } from "./router/AuthRoute";
import { NotFoundScreen } from "./components/screens/NotFoundScreen";
import { ROUTES } from "./router/routes";
import "./App.scss";
import { MainRoute } from "@router/MainRoute";
import { TagSettingRouter } from "@router/TagSettingRouter";
import { Toaster } from "react-hot-toast";
import { DocumentListRouter } from "./router/DocumentListRouter";
import OrganizationSettingRouter from "./router/OrganizationSettingRouter";
import StaffSettingRouter from "./router/StaffSettingRouter";
import { Permission } from "@shared/models/auth";
var LoginScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/LoginScreen")];
}); }); });
var OAuthRedirectScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/OAuthRedirectScreen")];
}); }); });
var AppLoading = React.memo(function () {
    return React.createElement("div", null);
});
var MyPreferenceScreen = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, import("@components/screens/MyPreferenceScreen")];
}); }); });
var Routes = React.memo(function () {
    var _a;
    var authContext = useContext(AuthContext);
    return (React.createElement("main", { className: cn("flex", "flex-col", "flex-1") },
        React.createElement(Suspense, { fallback: React.createElement(AppLoading, null) },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: "/" }, ((_a = authContext.userPreference) === null || _a === void 0 ? void 0 : _a.defaultStartPage) ===
                    "all-emails-and-files" ? (React.createElement(Redirect, { to: ROUTES.uploads.allUploads })) : (React.createElement(Redirect, { to: ROUTES.uploads.unreadUploads }))),
                React.createElement(MainRoute, { exact: true, path: ROUTES.uploads.root },
                    React.createElement(Redirect, { to: ROUTES.uploads.allUploads })),
                React.createElement(MainRoute, { path: ROUTES.uploads.root, requiredPermission: Permission.DocumentsRead },
                    React.createElement(DocumentListRouter, null)),
                React.createElement(MainRoute, { exact: true, path: ROUTES.settings.root },
                    React.createElement(Redirect, { to: ROUTES.settings.tags.root })),
                React.createElement(MainRoute, { path: ROUTES.settings.tags.root, requiredPermission: Permission.TagsRead },
                    React.createElement(TagSettingRouter, null)),
                React.createElement(MainRoute, { path: ROUTES.settings.organization.root, requiredPermission: Permission.DepartmentsRead },
                    React.createElement(OrganizationSettingRouter, null)),
                React.createElement(MainRoute, { path: ROUTES.settings.staff.root, requiredPermission: Permission.UsersRead },
                    React.createElement(StaffSettingRouter, null)),
                React.createElement(MainRoute, { exact: true, path: ROUTES.myPreference, requiredPermission: Permission.MeUpdate },
                    React.createElement(MyPreferenceScreen, null)),
                React.createElement(AuthRoute, { requiredAuthState: AuthState.notAuthenticated, exact: true, path: ROUTES.login, authFallbackPath: ROUTES.root },
                    React.createElement(LoginScreen, null)),
                React.createElement(Route, { exact: true, path: ROUTES.oauthRedirect },
                    React.createElement(OAuthRedirectScreen, null)),
                React.createElement(MainRoute, { path: "*" },
                    React.createElement(NotFoundScreen, null))))));
});
export var App = React.memo(function () {
    return (React.createElement(I18nContextProvider, null,
        React.createElement(AzureOAuthContextProvider, null,
            React.createElement(AuthContextProvider, null,
                React.createElement(APIContextProvider, { apiEndpoint: window.CONFIGS.SERVER_ENDPOINT + "/api" },
                    React.createElement(Router, null,
                        React.createElement(AppContextProvider, null,
                            React.createElement(Routes, null),
                            React.createElement(Toaster, null))))))));
});

import { __assign, __read, __spreadArray } from "tslib";
import React, { useCallback, useMemo, useRef, useState, } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import cn from "classnames";
import { SearchBar } from "@components/common/SearchBar";
import { InfiniteScroll } from "@components/common/InfiniteScroll";
import { Spinner } from "@components/common/Spinner";
import { usePagination } from "@hooks/usePagination";
import { useDebounce } from "@hooks/useDebounce";
import { useWindowEvent } from "@hooks/useWindowEvent";
import { sameWidthModifier } from "@constants/modifiers";
import { usePopper } from "react-popper";
import { Portal } from "@components/common/Portal";
var MultiSelectDropdownFilterImpl = function (props) {
    var selected = props.selected, onSelect = props.onSelect, selectedMessage = props.selectedMessage, getOptions = props.getOptions, placeholder = props.placeholder, labelExtractor = props.labelExtractor, keyExtractor = props.keyExtractor;
    var _a = __read(useState(""), 2), searchText = _a[0], setSearchText = _a[1];
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var _c = __read(useState(null), 2), buttonEl = _c[0], setButtonEl = _c[1];
    var _d = __read(useState(null), 2), optionsContainerEl = _d[0], setOptionsContainerEl = _d[1];
    var searchBarRef = useRef(null);
    var _e = usePopper(buttonEl, optionsContainerEl, {
        placement: "bottom-start",
        strategy: "fixed",
        modifiers: [sameWidthModifier],
    }), styles = _e.styles, attributes = _e.attributes;
    var isActive = useMemo(function () { return selected.length > 0; }, [selected]);
    var debouncedSearchText = useDebounce(searchText, 100);
    var _f = usePagination({
        fetch: getOptions,
        initialPage: 1,
        perPage: 10,
        extraOptions: { search: debouncedSearchText },
    }), loadedOptions = _f.data, isFetchingMore = _f.isFetchingMore, isInitializing = _f.isInitializing, nextPage = _f.nextPage;
    var selectedItemByKey = useMemo(function () {
        return new Map(selected.map(function (i) { return [keyExtractor(i), i]; }));
    }, [selected, keyExtractor]);
    var onListboxChange = useCallback(function (option) {
        var optionKey = keyExtractor(option);
        if (selectedItemByKey.has(optionKey)) {
            selectedItemByKey.delete(optionKey);
        }
        else {
            selectedItemByKey.set(optionKey, option);
        }
        onSelect(__spreadArray([], __read(selectedItemByKey.values())));
    }, [keyExtractor, selectedItemByKey, onSelect]);
    var listboxButtonClick = useCallback(function (e) {
        e.preventDefault();
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);
    var onSearchChange = useCallback(function (e) {
        setSearchText(e.currentTarget.value);
    }, []);
    var onSearchBarKeyDown = useCallback(function (e) {
        e.stopPropagation();
    }, []);
    // Replicate close menu on outside click logic from ListBox
    useWindowEvent("mousedown", function (event) {
        var _a;
        var target = event.target;
        if (!isOpen) {
            return;
        }
        if ((buttonEl === null || buttonEl === void 0 ? void 0 : buttonEl.contains(target)) ||
            (optionsContainerEl === null || optionsContainerEl === void 0 ? void 0 : optionsContainerEl.contains(target)) ||
            ((_a = searchBarRef.current) === null || _a === void 0 ? void 0 : _a.contains(target))) {
            return;
        }
        setIsOpen(false);
    });
    var optionItems = useMemo(function () {
        var loadedOptionKeySet = new Set(loadedOptions.map(function (option) { return keyExtractor(option); }));
        return selected
            .filter(function (item) { return !loadedOptionKeySet.has(keyExtractor(item)); })
            .concat(loadedOptions);
    }, [keyExtractor, loadedOptions, selected]);
    return (React.createElement(Listbox, { value: null, onChange: onListboxChange },
        React.createElement("div", { className: "relative" },
            React.createElement("button", { ref: setButtonEl, onClick: listboxButtonClick, type: "button", className: cn("relative", "w-full", "border", "rounded-md", "shadow-sm", "pl-3", "pr-10", "py-2", "text-left", "cursor-default", "sm:text-sm", isActive
                    ? ["bg-orange-600", "border-orange-600"]
                    : ["bg-white", "border-gray-300"]) },
                React.createElement("span", { className: cn("block truncate min-h-[1.25rem]", isActive
                        ? ["text-white", "font-bold"]
                        : ["text-gray-900", "font-normal"]) }, selected.length > 0 ? selectedMessage(selected) : placeholder),
                React.createElement("span", { className: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" },
                    React.createElement(SelectorIcon, { className: cn("h-5 w-5", isActive ? "text-white" : "text-gray-400"), "aria-hidden": "true" }))),
            React.createElement(Portal, null,
                React.createElement(Transition, { as: "div", show: isOpen, leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                    React.createElement(Listbox.Options, __assign({ className: cn("min-w-[20rem] z-40 origin-top-right absolute right-0 mt-1 bg-white shadow-lg max-h-60 rounded-md py-2 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"), ref: setOptionsContainerEl, style: styles.popper }, attributes.popper),
                        React.createElement("div", { className: "mx-3" },
                            React.createElement(SearchBar, { ref: searchBarRef, hideSearchIcon: true, containerClassName: "border border-gray-300 rounded shadow-sm", inputClassName: "text-sm leading-5 text-gray-500", value: searchText, onKeyDown: onSearchBarKeyDown, onChange: onSearchChange })),
                        optionItems.map(function (item, index) {
                            var isSelected = selectedItemByKey.has(keyExtractor(item));
                            return (React.createElement(Listbox.Option, { key: index, className: cn("cursor-default select-none flex flex-row py-2 px-3 bg-white hover:bg-gray-50"), value: item },
                                React.createElement("div", { className: "inset-y-0 flex items-center h-5" },
                                    React.createElement("input", { type: "checkbox", checked: isSelected, readOnly: true, className: "h-4 w-4 text-indigo-600 border-gray-300 rounded" })),
                                React.createElement("span", { className: cn("block truncate font-bold leading-5 text-gray-700 ml-2") }, labelExtractor(item))));
                        }),
                        React.createElement(InfiniteScroll, { onFetchMore: nextPage, containerElement: optionsContainerEl }),
                        (isFetchingMore || isInitializing) && (React.createElement("div", null,
                            React.createElement(Spinner, { size: "large" })))))))));
};
export var MultiSelectDropdownFilter = React.memo(MultiSelectDropdownFilterImpl);

import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { DealAPIContext } from "@shared/contexts/api/DealAPIContext";
import { DealExtractors } from "@shared/models/deal";
import { ROUTES } from "@router/routes";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { useAppToast } from "../common/Toast";
import { TagDetailHeader } from "../settings/TagDetailHeader";
import { TagDetailInfo } from "../settings/TagDetailInfo";
import { useNavigation } from "@hooks/useNavigation";
import { Persona, PersonaSize } from "@fluentui/react";
import { extractInitials } from "@utils/avatar";
import { ColouredTag } from "@components/common/ColouredTag";
import { getTagGroupCustomFieldsEntries, } from "@shared/models/tag";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { useUserLabel } from "@hooks/useUserLabel";
import { useMergeTagDialog, MergeTagDialog } from "../common/MergeTagDialog";
export var DealTagDetailScreen = React.memo(function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var tagId = useParams().tagId;
    var dealId = useMemo(function () { return parseInt(tagId, 10); }, [tagId]);
    var navigation = useNavigation();
    var location = useLocation();
    var showErrorToast = useAppToast().showErrorToast;
    var dealAPI = useContext(DealAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _h = __read(useState(null), 2), deal = _h[0], setDeal = _h[1];
    var _j = __read(useState(false), 2), isLoading = _j[0], setIsLoading = _j[1];
    var _k = __read(useState([]), 2), availableCustomProperties = _k[0], setAvailableCustomProperties = _k[1];
    var _l = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _l[0], setTagName = _l[1];
    var goGroupDetail = useCallback(function () {
        navigation.replace(ROUTES.settings.tags.deal.group_detail);
    }, [navigation]);
    var fetchDeal = useCallback(function () {
        setIsLoading(true);
        Promise.all([
            dealAPI.getDeal(dealId),
            tagGroupAPI.getTagGroupBySlug("deal"),
        ])
            .then(function (_a) {
            var _b = __read(_a, 2), deal = _b[0], tagGroup = _b[1];
            setDeal(deal);
            setTagName(deal.name);
            setAvailableCustomProperties(getTagGroupCustomFieldsEntries(tagGroup.definition).map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return ({
                    name: value.name,
                    value: deal.additional[key],
                });
            }));
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            goGroupDetail();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [dealAPI, dealId, tagGroupAPI, showErrorToast, goGroupDetail]);
    var onEditTag = useCallback(function () {
        navigation.push(ROUTES.settings.tags.deal.group_detail + "/" + dealId + "/edit");
    }, [navigation, dealId]);
    var onEnableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, dealAPI.enableDeal(dealId)];
        });
    }); }, [dealId, dealAPI]);
    var onDisableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, dealAPI.disableDeal(dealId)];
        });
    }); }, [dealId, dealAPI]);
    var onDeleteTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, dealAPI.deleteDeal(dealId).then(function () {
                    goGroupDetail();
                })];
        });
    }); }, [dealAPI, dealId, goGroupDetail]);
    var _m = useMergeTagDialog(), mergeDialogProps = _m.mergeDialogProps, openMergeDialog = _m.openMergeDialog, closeMergeDialog = _m.closeMergeDialog;
    var _o = __read(useState(false), 2), isMerging = _o[0], setIsMerging = _o[1];
    var onMergeTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var source, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openMergeDialog()];
                case 1:
                    source = _a.sent();
                    if (!source) {
                        return [2 /*return*/];
                    }
                    setIsMerging(true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, dealAPI.mergeDeal(dealId, source.id)];
                case 3:
                    _a.sent();
                    closeMergeDialog();
                    fetchDeal();
                    return [3 /*break*/, 6];
                case 4:
                    err_1 = _a.sent();
                    console.error("Failed to merge tag", err_1);
                    showErrorToast({
                        titleId: "merge_tag.error.title",
                        descriptionId: "merge_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 6];
                case 5:
                    setIsMerging(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [
        openMergeDialog,
        dealAPI,
        dealId,
        closeMergeDialog,
        showErrorToast,
        fetchDeal,
    ]);
    useEffect(function () {
        fetchDeal();
    }, [fetchDeal]);
    var renderPersonaText = useCallback(function (props) {
        return (React.createElement("span", { className: "px-1 text-sm leading-5 font-bold text-gray-800" }, props === null || props === void 0 ? void 0 : props.text));
    }, []);
    var userLabel = useUserLabel();
    return (React.createElement("div", { className: "flex flex-col min-w-0 flex-1" },
        React.createElement(MergeTagDialog, __assign({}, mergeDialogProps, { destinationId: dealId, tagName: tagName, fetch: dealAPI.listDeals, isMerging: isMerging, keyExtractor: DealExtractors.id, labelExtractor: DealExtractors.name })),
        React.createElement(TagDetailHeader, { typeId: "settings.tags.title.deal", tagName: tagName, isTagEnabled: (_c = deal === null || deal === void 0 ? void 0 : deal.isEnabled) !== null && _c !== void 0 ? _c : true, onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onMergeTag, onGoBack: goGroupDetail }),
        React.createElement("div", { className: "flex-1 mt-2 overflow-y-auto md:mt-0 md:p-4" },
            React.createElement(TagDetailInfo.Panel, { isLoading: isLoading },
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.deal.form.display_name" }), value: (_d = deal === null || deal === void 0 ? void 0 : deal.fullName) !== null && _d !== void 0 ? _d : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.deal.form.teammates" }), value: React.createElement("div", { className: "flex flex-row flex-wrap space-x-1 space-y-1 -ml-1 -mt-1 items-center" }, deal === null || deal === void 0 ? void 0 : deal.teammates.map(function (t) {
                        var _a, _b, _c;
                        return (React.createElement(Persona, { key: t.id, size: PersonaSize.size24, text: userLabel(t), imageInitials: extractInitials((_a = t.fullName) !== null && _a !== void 0 ? _a : t.username, 2), imageUrl: (_c = (_b = t.avatar) === null || _b === void 0 ? void 0 : _b.url) !== null && _c !== void 0 ? _c : undefined, onRenderPrimaryText: renderPersonaText, className: "bg-gray-100 rounded-full w-min first:ml-1 first:mt-1" }));
                    })) }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.deal.form.color" }), value: React.createElement(ColouredTag, { tag: (_f = (_e = deal === null || deal === void 0 ? void 0 : deal.color) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : "", colour: deal === null || deal === void 0 ? void 0 : deal.color }) }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.deal.form.remarks" }), value: (_g = deal === null || deal === void 0 ? void 0 : deal.remarks) !== null && _g !== void 0 ? _g : "" }),
                availableCustomProperties.map(function (value, index) { return (React.createElement(TagDetailInfo.Item, { key: index, title: value.name, value: value.value })); })))));
});

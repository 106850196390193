import React, { useContext, useCallback, useMemo } from "react";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import { TagIndexContext } from "@contexts/TagIndexContext";
export var TabButton = React.memo(function (_a) {
    var tabKey = _a.tabKey, classNames = _a.classNames;
    var tagIndexContext = useContext(TagIndexContext);
    var isActive = useMemo(function () { return tagIndexContext.selectedTab === tabKey; }, [tabKey, tagIndexContext.selectedTab]);
    var handleClick = useCallback(function () {
        tagIndexContext.setSelectedTab(tabKey);
    }, [tabKey, tagIndexContext]);
    var messageId = useMemo(function () {
        switch (tabKey) {
            case "external":
                return "tag_index.header.tabs.external";
            case "internal":
                return "tag_index.header.tabs.internal";
            default:
                throw new Error("Unknown tab " + tabKey);
        }
    }, [tabKey]);
    return (React.createElement("button", { type: "button", className: cn("text-sm", "leading-5", "font-bold", "py-4", "relative", isActive ? "text-gray-900" : "text-gray-500", classNames), onClick: handleClick },
        React.createElement(FormattedMessage, { id: messageId }),
        React.createElement("div", { className: cn("absolute bottom-0 left-0 right-0 h-0.5", isActive ? "bg-indigo-500" : "opacity-0") })));
});
export var TagIndexPanelHeader = React.memo(function () {
    return (React.createElement("header", { className: "block rounded-lg shadow-sm" },
        React.createElement("div", { className: "pl-4 pb-3 pt-6" },
            React.createElement("h1", { className: "text-gray-900 text-base font-bold leading-6 block" },
                React.createElement(FormattedMessage, { id: "tag_index.header.title" }))),
        React.createElement("div", { className: "flex" },
            React.createElement(TabButton, { tabKey: "external", classNames: "flex-1" }),
            React.createElement(TabButton, { tabKey: "internal", classNames: "flex-1" }))));
});

import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DropDown } from "../common/DropDown";
import { WhiteButton } from "../common/WhiteButton";
import cn from "classnames";
import { PencilIcon } from "@heroicons/react/outline";
import { Dialog, useDialog } from "../common/Dialog";
import { ExclamationIcon } from "@heroicons/react/solid";
import RequirePermission from "../common/RequirePermission";
import { Permission } from "@shared/models/auth";
export var TagGroupDetailHeader = React.memo(function (props) {
    var className = props.className, count = props.count, onEdit = props.onEdit, isDefault = props.isDefault, isGroupEnabled = props.isGroupEnabled, onDisable = props.onDisable, onEnable = props.onEnable;
    var _a = __read(useState(false), 2), isDeleting = _a[0], setIsDeleting = _a[1];
    var _b = useDialog(), deleteGroupDialogProps = _b.dialogProps, openDeleteGroupDialog = _b.openDialog;
    var intl = useIntl();
    var onToggleGroup = useMemo(function () {
        if (isDefault)
            return undefined;
        return isGroupEnabled ? onDisable : onEnable;
    }, [isDefault, isGroupEnabled, onDisable, onEnable]);
    var onDeleteGroup = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var confirm;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (props.isDefault)
                        return [2 /*return*/];
                    return [4 /*yield*/, openDeleteGroupDialog()];
                case 1:
                    confirm = _a.sent();
                    if (!confirm) {
                        return [2 /*return*/];
                    }
                    setIsDeleting(true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, , 4, 5]);
                    return [4 /*yield*/, props.onDelete()];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    setIsDeleting(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [props, setIsDeleting, openDeleteGroupDialog]);
    return (React.createElement("div", { className: cn("flex flex-row py-4 items-center justify-between bg-white", className) },
        !props.isDefault && (React.createElement(Dialog, __assign({}, deleteGroupDialogProps, { title: intl.formatMessage({
                id: "settings.tags.delete_group.confirmation.title",
            }, {
                groupName: props.name,
            }), description: intl.formatMessage({
                id: "settings.tags.delete_group.confirmation.description",
            }, {
                groupName: props.name,
            }), confirmLabelId: "common.delete", cancelLabelId: "common.cancel", icon: React.createElement(ExclamationIcon, { className: "h-6 w-6 text-red-600", "aria-hidden": "true" }) }))),
        React.createElement("div", { className: "flex flex-col h-10 justify-center" },
            React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900" }, props.isDefault ? (React.createElement(FormattedMessage, { id: props.nameId })) : (props.name)),
            React.createElement("span", { className: "font-normal text-sm leading-5 text-gray-600" },
                React.createElement(FormattedMessage, { id: props.isDefault
                        ? props.tagCountMessageId
                        : "settings.tags.tag_counts.custom", values: { count: count } }))),
        React.createElement(RequirePermission, { permission: Permission.TagGroupsUpdate },
            React.createElement("div", { className: "flex flex-row space-x-2" },
                React.createElement(DropDown.Button, { labelId: "settings.tags.detail.header.actions", hideLabelOnMobile: true, className: cn(props.isDefault && "hidden") },
                    React.createElement(DropDown.Item, { disabled: props.isDefault || isDeleting, onClick: onToggleGroup },
                        React.createElement(FormattedMessage, { id: isGroupEnabled
                                ? "settings.tags.detail.header.actions.disable_group"
                                : "settings.tags.detail.header.actions.enable_group" })),
                    React.createElement(RequirePermission, { permission: Permission.TagGroupsDelete },
                        React.createElement(DropDown.Item, { disabled: props.isDefault || isDeleting, onClick: onDeleteGroup },
                            React.createElement(FormattedMessage, { id: "settings.tags.detail.header.actions.delete_group" })))),
                React.createElement(WhiteButton, { size: "base", className: "hidden md:flex", onClick: onEdit },
                    React.createElement(FormattedMessage, { id: "settings.tags.detail.header.edit_group" })),
                React.createElement(WhiteButton, { size: "sm", className: "md:hidden", onClick: onEdit },
                    React.createElement(PencilIcon, { className: "h-4 w-4 text-gray-500" }))))));
});

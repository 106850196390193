import { __extends } from "tslib";
var APIResponseError = /** @class */ (function (_super) {
    __extends(APIResponseError, _super);
    function APIResponseError(response) {
        var _this = _super.call(this, String(response.status)) || this;
        _this.response = response;
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(_this, APIResponseError.prototype);
        return _this;
    }
    return APIResponseError;
}(Error));
export { APIResponseError };
var APIAuthenticationError = /** @class */ (function (_super) {
    __extends(APIAuthenticationError, _super);
    function APIAuthenticationError(response) {
        var _this = _super.call(this, response) || this;
        _this.response = response;
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(_this, APIAuthenticationError.prototype);
        return _this;
    }
    return APIAuthenticationError;
}(APIResponseError));
export { APIAuthenticationError };

import * as yup from "yup";
import { paginatedSearchRequest, generatePaginationSchema, } from "../pagination";
export var industrySchema = yup
    .object({
    id: yup.number().defined(),
    fullName: yup.string().defined().nullable(),
    isEnabled: yup.boolean().defined(),
    name: yup.string().defined(),
    remarks: yup.string().defined().nullable(),
    additional: yup.object(),
})
    .camelCase()
    .defined();
export var listIndustryRequestSchema = paginatedSearchRequest;
export var listIndustryResponseSchema = generatePaginationSchema(industrySchema);
export var getAllIndustriesResponseSchema = yup
    .object({
    data: yup.array().of(industrySchema).required(),
})
    .defined();
export var createIndustryRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    full_name: yup.string().defined().nullable(),
    remarks: yup.string().defined().nullable(),
    additional: yup.object().defined().nullable(),
})
    .snakeCase()
    .defined();
export var editIndustryRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    full_name: yup.string().defined().nullable(),
    remarks: yup.string().defined().nullable(),
    additional: yup.object().defined().nullable(),
})
    .snakeCase()
    .defined();
export var mergeIndustryRequestSchema = yup
    .object()
    .shape({
    source_id: yup.number().defined(),
})
    .snakeCase()
    .defined();

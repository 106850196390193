import React from "react";
import { IntlProvider } from "react-intl";
import en from "../i18n/en.json";
export var I18nContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var messages = React.useMemo(function () {
        return en;
    }, []);
    return (React.createElement(IntlProvider, { messages: messages, locale: "en", defaultLocale: "en" }, children));
});

import { __assign, __read } from "tslib";
import React, { useCallback, useMemo, useRef } from "react";
import { priorityFormSchema } from "./models";
import { useIntl } from "react-intl";
import { FormField } from "@components/form/FormField";
import { Formik } from "formik";
import cn from "classnames";
import { TextField } from "@components/form/TextField";
import { getTagGroupCustomFieldsEntries, } from "@shared/models/tag";
import { aggregateFormSchema } from "./utils";
import { ColorRandomizor } from "@components/form/ColorRandomizor";
import { FormShell } from "./FormShell";
export var PriorityForm = React.memo(function (props) {
    var colors = props.colors, additionalFields = props.additionalFields, initialFormValues = props.initialFormValues, isSubmitting = props.isSubmitting, onSubmit = props.onSubmit, onCancel = props.onCancel;
    var intl = useIntl();
    var formikRef = useRef(null);
    var isEditing = useMemo(function () { return !!initialFormValues; }, [initialFormValues]);
    var initialValues = useMemo(function () {
        var _a, _b, _c, _d;
        return ({
            fullName: (_a = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.fullName) !== null && _a !== void 0 ? _a : "",
            tagName: (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.tagName) !== null && _b !== void 0 ? _b : "",
            remarks: (_c = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.remarks) !== null && _c !== void 0 ? _c : "",
            color: (_d = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.color) !== null && _d !== void 0 ? _d : null,
            additional: Object.keys(additionalFields).reduce(function (acc, curr) {
                var _a;
                var _b;
                return (__assign(__assign({}, acc), (_a = {}, _a[curr] = (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.additional[curr]) !== null && _b !== void 0 ? _b : "", _a)));
            }, {}),
        });
    }, [initialFormValues, additionalFields]);
    var aggregatedSchema = useMemo(function () { return aggregateFormSchema(priorityFormSchema, additionalFields); }, [additionalFields]);
    var onColorChange = useCallback(function (color) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("color", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("color", color);
    }, []);
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: aggregatedSchema, validateOnChange: true, onSubmit: onSubmit, innerRef: formikRef }, function (_a) {
        var values = _a.values, errors = _a.errors, touched = _a.touched, handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur;
        return (React.createElement(FormShell, { isSubmitting: isSubmitting, onCancel: onCancel, onSubmit: handleSubmit, cancelLabel: intl.formatMessage({ id: "common.cancel" }), submitLabel: intl.formatMessage({
                id: isEditing
                    ? "settings.tags.form.edit.submit"
                    : "settings.tags.form.create.submit",
            }, {
                type: intl.formatMessage({
                    id: "settings.tags.title.priority",
                }),
            }) },
            React.createElement(FormField, { titleId: "settings.tags.priority.form.full_name", errorId: touched.fullName ? errors.fullName : undefined, isOptional: true },
                React.createElement(TextField, { name: "fullName", onChange: handleChange, onBlur: handleBlur, value: values.fullName, isError: touched.fullName && !!errors.fullName, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
            React.createElement(FormField, { titleId: "settings.tags.priority.form.tag_name", errorId: touched.tagName ? errors.tagName : undefined, isOptional: false },
                React.createElement(TextField, { name: "tagName", onChange: handleChange, onBlur: handleBlur, value: values.tagName, placeholder: intl.formatMessage({
                        id: "settings.tags.priority.form.tag_name.placeholder",
                    }), isError: touched.tagName && !!errors.tagName, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
            React.createElement(FormField, { titleId: "settings.tags.priority.form.color", isOptional: true },
                React.createElement(ColorRandomizor, { className: "h-[2.375rem]", colors: colors, currentColor: values.color, onColorChange: onColorChange })),
            React.createElement(FormField, { titleId: "settings.tags.priority.form.remarks", alignment: "start", isOptional: true },
                React.createElement(TextField, { name: "remarks", onChange: handleChange, onBlur: handleBlur, value: values.remarks, isTextArea: true, rows: 4, cols: 4, placeholder: intl.formatMessage({
                        id: "settings.tags.priority.form.remarks.placeholder",
                    }), className: "py-2 px-3 border border-gray-300 shadow-sm w-full" })),
            getTagGroupCustomFieldsEntries(additionalFields).map(function (_a) {
                var _b, _c, _d, _e;
                var _f = __read(_a, 2), key = _f[0], _g = _f[1], name = _g.name, mandatory = _g.mandatory;
                return (React.createElement(FormField, { key: key, title: name, errorId: ((_b = touched.additional) === null || _b === void 0 ? void 0 : _b[key])
                        ? (_c = errors.additional) === null || _c === void 0 ? void 0 : _c[key]
                        : undefined, isOptional: !mandatory },
                    React.createElement(TextField, { name: "additional." + key, onChange: handleChange, onBlur: handleBlur, isError: ((_d = touched.additional) === null || _d === void 0 ? void 0 : _d[key]) && !!((_e = errors.additional) === null || _e === void 0 ? void 0 : _e[key]), value: values.additional[key], className: "py-2 px-3 border border-gray-300 shadow-sm h-[2.375rem] w-full" })));
            })));
    }));
});

import { __assign, __awaiter, __generator, __read } from "tslib";
import { Persona, PersonaSize } from "@fluentui/react";
import { ChevronLeftIcon, PencilIcon } from "@heroicons/react/outline";
import { useNavigation } from "@hooks/useNavigation";
import { ROUTES } from "@router/routes";
import { extractInitials } from "@utils/avatar";
import { UserAPIContext } from "@shared/contexts/api/UserAPIContext";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { useAppToast } from "../common/Toast";
import { WhiteButton } from "../common/WhiteButton";
import { TagDetailInfo } from "../settings/TagDetailInfo";
import { useUserLabel } from "@hooks/useUserLabel";
import { APIResponseError } from "@shared/contexts/api";
import { parseErrorMessage } from "@shared/models/error";
import StaffDetailDropDownMenu from "../common/StaffDetailDropDownMenu";
import { StaffSettingRouterContext } from "@router/StaffSettingRouter";
import RequirePermission from "../common/RequirePermission";
import { Permission } from "@shared/models/auth";
export var StaffDetailScreen = React.memo(function () {
    var _a, _b, _c, _d, _e, _f;
    var userId = useParams().userId;
    var navigation = useNavigation();
    var onGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.staff.root);
        }
    }, [navigation]);
    var _g = __read(useState(null), 2), user = _g[0], setUser = _g[1];
    var _h = __read(useState(false), 2), isLoading = _h[0], setIsLoading = _h[1];
    var getUser = useContext(UserAPIContext).getUser;
    var showErrorToast = useAppToast().showErrorToast;
    useEffect(function () {
        setUser(null);
        setIsLoading(true);
        if (userId == null) {
            return;
        }
        var _userID = parseInt(userId, 10);
        getUser(_userID)
            .then(function (user) {
            setUser(user);
        })
            .catch(function (e) {
            console.error(e);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [getUser, showErrorToast, userId]);
    var intl = useIntl();
    var mapRoleToMessage = useCallback(function (role) {
        switch (role) {
            case "head":
                return intl.formatMessage({ id: "settings.staffDetail.roles.head" });
            case "admin":
                return intl.formatMessage({ id: "settings.staffDetail.roles.admin" });
            default:
                return role;
        }
    }, [intl]);
    var combinedDepartmentAndRoleLabels = useMemo(function () {
        var _a;
        return ((_a = user === null || user === void 0 ? void 0 : user.departments.map(function (d) {
            var role = user.departmentRoles.find(function (r) { return r.departmentId === d.id; });
            return role != null
                ? d.name + " - " + mapRoleToMessage(role.role)
                : d.name;
        })) !== null && _a !== void 0 ? _a : []);
    }, [mapRoleToMessage, user === null || user === void 0 ? void 0 : user.departmentRoles, user === null || user === void 0 ? void 0 : user.departments]);
    var onEdit = useCallback(function (ev) {
        ev.preventDefault();
        ev.stopPropagation();
        if ((user === null || user === void 0 ? void 0 : user.id) == null) {
            return;
        }
        navigation.push(ROUTES.settings.staff.staff_edit.format(user.id));
    }, [navigation, user === null || user === void 0 ? void 0 : user.id]);
    var userLabel = useUserLabel();
    var updateUserIsEnabled = useContext(UserAPIContext).updateUserIsEnabled;
    var refreshSidebar = useContext(StaffSettingRouterContext).refreshSidebar;
    var onToggleUserEnabled = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var updatedUser_1, e_1, _a, titleId, descriptionId;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (user == null) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updateUserIsEnabled(user.id, {
                            isEnabled: !user.isEnabled,
                        })];
                case 2:
                    updatedUser_1 = _b.sent();
                    setUser(function (prev) {
                        return __assign(__assign({}, prev), updatedUser_1);
                    });
                    refreshSidebar();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    console.error(e_1);
                    if (e_1 instanceof APIResponseError) {
                        _a = __read(parseErrorMessage(e_1), 2), titleId = _a[0], descriptionId = _a[1];
                        showErrorToast({
                            titleId: titleId,
                            descriptionId: descriptionId,
                        }, {
                            position: "bottom-center",
                        });
                        return [2 /*return*/];
                    }
                    showErrorToast({
                        titleId: "common.errors.data.submit_failed.title",
                        descriptionId: "common.errors.data.submit_failed.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [refreshSidebar, showErrorToast, updateUserIsEnabled, user]);
    return (React.createElement("div", { className: "flex flex-1 min-w-0 flex-col" },
        React.createElement("div", { className: "flex flex-row bg-white px-1 justify-between items-center" },
            React.createElement("div", { className: "flex flex-row items-center" },
                React.createElement("button", { type: "button", className: "md:hidden", onClick: onGoBack },
                    React.createElement(ChevronLeftIcon, { className: "h-9" })),
                React.createElement("div", { className: "px-4 py-6 bg-white" },
                    React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900 line-clamp-1 whitespace-no-warp overflow-ellipsis break-all h-6" }, user != null ? userLabel(user) : ""))),
            React.createElement(RequirePermission, { permission: Permission.UsersUpdate },
                React.createElement("div", { className: "flex flex-row items-center px-4" },
                    user != null && (React.createElement(StaffDetailDropDownMenu, { className: "mr-2", user: user, onToggleUserEnabled: onToggleUserEnabled })),
                    React.createElement(WhiteButton, { size: "base", className: "hidden md:flex", onClick: onEdit },
                        React.createElement(FormattedMessage, { id: "settings.staffDetail.editStaff" })),
                    React.createElement(WhiteButton, { size: "sm", className: "md:hidden", onClick: onEdit },
                        React.createElement(PencilIcon, { className: "h-4 w-4 text-gray-500" }))))),
        React.createElement("div", { className: "flex-1 mt-2 overflow-y-auto md:mt-0 md:p-4" },
            React.createElement(TagDetailInfo.Panel, { isLoading: isLoading },
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.staffDetail.fullName" }), value: (_a = user === null || user === void 0 ? void 0 : user.fullName) !== null && _a !== void 0 ? _a : "" }),
                user != null && user.emails.length > 0 ? (React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.staffDetail.email" }), value: React.createElement("p", { className: "whitespace-pre-wrap break-all" }, user.emails.join(",\n")) })) : (
                // not AD user, show username instead
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.staffDetail.username" }), value: React.createElement("span", { className: "mt-1 text-sm leading-5 font-normal text-gray-900 md:mt-0 break-all" }, (_b = user === null || user === void 0 ? void 0 : user.username) !== null && _b !== void 0 ? _b : "") })),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.staffDetail.profilePicture" }), value: React.createElement(Persona, { imageUrl: (_d = (_c = user === null || user === void 0 ? void 0 : user.avatar) === null || _c === void 0 ? void 0 : _c.url) !== null && _d !== void 0 ? _d : undefined, imageInitials: extractInitials((_f = (_e = user === null || user === void 0 ? void 0 : user.fullName) !== null && _e !== void 0 ? _e : user === null || user === void 0 ? void 0 : user.username) !== null && _f !== void 0 ? _f : "", 2), hidePersonaDetails: true, size: PersonaSize.size56 }) }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.staffDetail.department" }), value: React.createElement("div", { className: "flex flex-row flex-wrap -m-1" }, combinedDepartmentAndRoleLabels.map(function (label, i) { return (React.createElement("div", { key: i, className: cn("px-3 py-1 rounded text-sm leading-5 font-bold", "bg-gray-100 text-gray-800 truncate") }, label)); })) }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.staffDetail.lineManager" }), value: React.createElement("div", { className: "flex flex-row flex-wrap gap-2" }, user === null || user === void 0 ? void 0 : user.managers.map(function (manager) {
                        var _a, _b, _c;
                        return (React.createElement(Persona, { key: manager.id, imageUrl: (_b = (_a = manager.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined, imageInitials: extractInitials((_c = manager.fullName) !== null && _c !== void 0 ? _c : manager.username, 2), text: userLabel(manager), hidePersonaDetails: false, size: PersonaSize.size24, className: "bg-gray-100 rounded-full" }));
                    })) })))));
});

import React, { useContext } from "react";
import { SearchContext } from "@contexts/SearchContext";
import { MainSearchBarSuggestionsEmpty } from "./MainSearchBarSuggestionsEmpty";
import { MainSearchBarSuggestionsLoading } from "./MainSearchBarSuggestionsLoading";
import { MainSearchBarContactSuggestions } from "./MainSearchBarContactSuggestions";
import { MainSearchBarTagSuggestions } from "./MainSearchBarTagSuggestions";
import { MainSearchBarGenericSuggestions } from "./MainSearchBarGenericSuggestions";
export var MainSearchBarSuggestions = React.memo(function () {
    var searchContext = useContext(SearchContext);
    if (searchContext.isSuggestionLoading) {
        return React.createElement(MainSearchBarSuggestionsLoading, null);
    }
    if (!searchContext.searchText) {
        return React.createElement(MainSearchBarSuggestionsEmpty, null);
    }
    if (!searchContext.suggestion) {
        return null;
    }
    if (searchContext.suggestion.type === "contacts") {
        return (React.createElement(MainSearchBarContactSuggestions, { suggestions: searchContext.suggestion }));
    }
    if (searchContext.suggestion.type === "tags") {
        return (React.createElement(MainSearchBarTagSuggestions, { suggestions: searchContext.suggestion }));
    }
    return (React.createElement(MainSearchBarGenericSuggestions, { suggestions: searchContext.suggestion }));
});

import * as yup from "yup";
export var colorSchema = yup
    .object({
    id: yup.number().defined(),
    name: yup.string().defined(),
    textColor: yup.string().defined(),
    backgroundColor: yup.string().defined(),
})
    .camelCase()
    .from("textColorCode", "textColor")
    .from("backgroundColorCode", "backgroundColor")
    .defined();
export var getAllColorsResponseSchema = yup
    .object({
    data: yup.array().of(colorSchema).required(),
})
    .defined();

import { __read } from "tslib";
import React, { useCallback, useContext, useMemo } from "react";
import { MainSearchBarSuggestionsContainer } from "./MainSearchBarSuggestionsContainer";
import { ContactSuggestionItem } from "./ContactSuggestionItem";
import { TagSuggestionItem } from "./TagSuggestionItem";
import { DocumentGroupSuggestionItem } from "./DocumentGroupSuggestionItem";
import { MainSearchBarContext } from "./MainSearchBar";
import { useSelectedIndex } from "./hooks";
export var MainSearchBarGenericSuggestions = React.memo(function (props) {
    var suggestions = props.suggestions;
    var _a = useContext(MainSearchBarContext), onTagSelected = _a.onTagSelected, onDocumentGroupSelected = _a.onDocumentGroupSelected, eventEmitter = _a.eventEmitter;
    var items = useMemo(function () {
        return suggestions.contacts
            .map(function (item) { return ({
            type: "Contact",
            item: item,
        }); })
            .concat(suggestions.companies.map(function (item) { return ({
            type: "Company",
            item: item,
        }); }))
            .concat(suggestions.deals.map(function (item) { return ({
            type: "Deal",
            item: item,
        }); }))
            .concat(suggestions.priorities.map(function (item) { return ({
            type: "Priority",
            item: item,
        }); }))
            .concat(suggestions.otherTags.map(function (item) { return ({
            type: "OtherTag",
            item: item,
        }); }))
            .concat(suggestions.customTags.map(function (item) { return ({
            type: "Tag",
            item: item,
        }); }))
            .concat(suggestions.documentGroups.map(function (item) { return ({
            type: "DocumentGroup",
            item: item,
        }); }));
    }, [suggestions]);
    var onSelectedItem = useCallback(function (item) {
        switch (item.type) {
            case "DocumentGroup":
                onDocumentGroupSelected(item.item);
                break;
            default:
                onTagSelected(item.item.name);
        }
    }, [onDocumentGroupSelected, onTagSelected]);
    var onSelectedTag = useCallback(function (item) {
        onTagSelected(item.name);
    }, [onTagSelected]);
    var _b = __read(useSelectedIndex(eventEmitter, items, onSelectedItem), 1), selectedItemIndex = _b[0];
    if (items.length === 0) {
        return null;
    }
    return (React.createElement(MainSearchBarSuggestionsContainer, null, items.map(
    // eslint-disable-next-line complexity
    function (item, idx) {
        var _a, _b, _c, _d, _e;
        switch (item.type) {
            case "Contact":
                return (React.createElement(ContactSuggestionItem, { contact: item.item, key: idx, onSelect: onSelectedTag, isSelected: selectedItemIndex === idx }));
            case "Company":
                return (React.createElement(TagSuggestionItem, { key: idx, tag: item.item, name: item.item.name, description: (_a = item.item.fullName) !== null && _a !== void 0 ? _a : "", color: item.item.color, onSelect: onSelectedTag, isSelected: selectedItemIndex === idx }));
            case "Deal":
                return (React.createElement(TagSuggestionItem, { key: idx, tag: item.item, name: item.item.name, description: (_b = item.item.fullName) !== null && _b !== void 0 ? _b : "", color: item.item.color, onSelect: onSelectedTag, isSelected: selectedItemIndex === idx }));
            case "Priority":
                return (React.createElement(TagSuggestionItem, { key: idx, tag: item.item, name: item.item.name, description: (_c = item.item.fullName) !== null && _c !== void 0 ? _c : "", color: item.item.color, onSelect: onSelectedTag, isSelected: selectedItemIndex === idx }));
            case "OtherTag":
                return (React.createElement(TagSuggestionItem, { key: idx, tag: item.item, name: item.item.name, description: (_d = item.item.fullName) !== null && _d !== void 0 ? _d : "", color: item.item.color, onSelect: onSelectedTag, isSelected: selectedItemIndex === idx }));
            case "Tag":
                return (React.createElement(TagSuggestionItem, { key: idx, tag: item.item, name: item.item.name, description: (_e = item.item.fullName) !== null && _e !== void 0 ? _e : "", color: item.item.color, onSelect: onSelectedTag, isSelected: selectedItemIndex === idx }));
            case "DocumentGroup":
                return (React.createElement(DocumentGroupSuggestionItem, { key: idx, item: item.item, onSelect: onDocumentGroupSelected, isSelected: selectedItemIndex === idx }));
            default:
                return null;
        }
    })));
});

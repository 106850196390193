import { useMediaQueries } from "@react-hook/media-query";
export var ScreenSize;
(function (ScreenSize) {
    ScreenSize[ScreenSize["sm"] = 0] = "sm";
    ScreenSize[ScreenSize["md"] = 1] = "md";
    ScreenSize[ScreenSize["lg"] = 2] = "lg";
    ScreenSize[ScreenSize["xl"] = 3] = "xl";
    ScreenSize[ScreenSize["2xl"] = 4] = "2xl";
})(ScreenSize || (ScreenSize = {}));
export var useMediaQuery = function () {
    var _a;
    var matches = useMediaQueries((_a = {},
        _a[ScreenSize.sm] = "(min-width: 640px)",
        _a[ScreenSize.md] = "(min-width: 768px)",
        _a[ScreenSize.lg] = "(min-width: 1024px)",
        _a[ScreenSize.xl] = "(min-width: 1280px)",
        _a[ScreenSize["2xl"]] = "(min-width: 1536px)",
        _a)).matches;
    if (matches[ScreenSize["2xl"]]) {
        return ScreenSize["2xl"];
    }
    else if (matches[ScreenSize.xl]) {
        return ScreenSize.xl;
    }
    else if (matches[ScreenSize.lg]) {
        return ScreenSize.lg;
    }
    else if (matches[ScreenSize.md]) {
        return ScreenSize.md;
    }
    return ScreenSize.sm;
};

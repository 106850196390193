import { __awaiter, __generator } from "tslib";
import React, { useCallback, useMemo, useContext } from "react";
import { searchDocumentRequestSchema, searchDocumentResponseSchema, uploadDocumentFilesRequestSchema, uploadDocumentFilesResponseSchema, updateDocumentFilesRequestSchema, updateDocumentFilesResponseSchema, removeDocumentRequestSchema, exportDocumentResponseSchema, getDefaultDocumentFilesResponseSchema, } from "@shared/models/api/document_api";
import { APIContext } from "./APIContext";
export var DocumentAPIContext = React.createContext(undefined);
export var DocumentAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var searchDocuments = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = searchDocumentRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("documents/search", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, searchDocumentResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var uploadDocumentFiles = useCallback(function (body) { return __awaiter(void 0, void 0, void 0, function () {
        var validatedBody, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    validatedBody = uploadDocumentFilesRequestSchema.validateSync(body);
                    return [4 /*yield*/, apiContext.post("documents/files/upload", validatedBody)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, uploadDocumentFilesResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updateDocumentFiles = useCallback(function (documentId, body) { return __awaiter(void 0, void 0, void 0, function () {
        var validatedBody, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    validatedBody = updateDocumentFilesRequestSchema.validateSync(body, { stripUnknown: true });
                    return [4 /*yield*/, apiContext.put("documents/files/" + encodeURIComponent(encodeURIComponent(documentId)), validatedBody)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, updateDocumentFilesResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var getDefaultDocumentFiles = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("documents/files/default")];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, getDefaultDocumentFilesResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var exportDocument = useCallback(function (documentId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("documents/" + encodeURIComponent(encodeURIComponent(documentId)) + "/export")];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, exportDocumentResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var removeDocument = useCallback(function (documentId, body) { return __awaiter(void 0, void 0, void 0, function () {
        var validatedBody;
        return __generator(this, function (_a) {
            validatedBody = removeDocumentRequestSchema.validateSync(body);
            // Encode twice to avoid flask decode %2F to "/"
            return [2 /*return*/, apiContext.delete("documents/" + encodeURIComponent(encodeURIComponent(documentId)), validatedBody)];
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            searchDocuments: searchDocuments,
            uploadDocumentFiles: uploadDocumentFiles,
            updateDocumentFiles: updateDocumentFiles,
            getDefaultDocumentFiles: getDefaultDocumentFiles,
            exportDocument: exportDocument,
            removeDocument: removeDocument,
        };
    }, [
        searchDocuments,
        uploadDocumentFiles,
        updateDocumentFiles,
        getDefaultDocumentFiles,
        exportDocument,
        removeDocument,
    ]);
    return (React.createElement(DocumentAPIContext.Provider, { value: value }, children));
});

import { __assign, __awaiter, __generator, __read } from "tslib";
import { IndustryAPIContext } from "@shared/contexts/api/IndustryAPIContext";
import { ROUTES } from "@router/routes";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { DefaultTagGroupDetail } from "../settings/DefaultTagGroupDetail";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { TagContextMenu } from "../settings/TagContextMenu";
import { useNavigation } from "@hooks/useNavigation";
import cn from "classnames";
import { useAppToast } from "../common/Toast";
import { MergeIndustryDialog } from "@components/mergeTag/MergeIndustryDialog";
import { TagGroupSlug } from "@shared/models/tag";
export var IndustryTagGroupDetailScreen = React.memo(function () {
    var navigation = useNavigation();
    var industryAPI = useContext(IndustryAPIContext);
    var _a = useAppToast(), showErrorToast = _a.showErrorToast, showSuccessToast = _a.showSuccessToast;
    var _b = __read(useState({}), 2), enableStates = _b[0], setEnableStates = _b[1];
    var onGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.root);
        }
    }, [navigation]);
    var onFetchListItemError = useCallback(function () {
        showErrorToast({
            titleId: "common.errors.data.fetch_failed.title",
            descriptionId: "common.errors.data.fetch_failed.description",
        }, {
            position: "bottom-center",
        });
        onGoBack();
    }, [onGoBack, showErrorToast]);
    var _c = useSearchPagination(industryAPI.listIndustries, {
        page: 1,
        perPage: 100,
    }, onFetchListItemError), data = _c.data, totalCount = _c.totalCount, isInitializing = _c.isInitializing, isFetchingMore = _c.isFetchingMore, hasMore = _c.hasMore, nextPage = _c.nextPage, search = _c.search, refresh = _c.refresh;
    var onAddNewIndustry = useCallback(function () {
        navigation.push(ROUTES.settings.tags.industry.add);
    }, [navigation]);
    var onEditGroup = useCallback(function () {
        navigation.push(ROUTES.settings.tags.industry.edit_group);
    }, [navigation]);
    var onEditTag = useCallback(function (industry) {
        navigation.push(ROUTES.settings.tags.industry.group_detail + "/" + industry.id + "/edit");
    }, [navigation]);
    var onEnableTag = useCallback(function (industry) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setEnableStates(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[industry.id] = true, _a)));
            });
            industryAPI
                .enableIndustry(industry.id)
                .then(function () {
                showSuccessToast({
                    titleId: "settings.tags.enable_tag.success.title",
                }, {
                    position: "bottom-center",
                });
            })
                .catch(function (err) {
                console.error("Failed to enable tag", err);
                showErrorToast({
                    titleId: "settings.tags.enable_tag.error.title",
                    descriptionId: "settings.tags.enable_tag.error.description",
                }, {
                    position: "bottom-center",
                });
                setEnableStates(function (prevState) {
                    var _a;
                    return (__assign(__assign({}, prevState), (_a = {}, _a[industry.id] = industry.isEnabled, _a)));
                });
            });
            return [2 /*return*/];
        });
    }); }, [industryAPI, showErrorToast, showSuccessToast]);
    var onDisableTag = useCallback(function (industry) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setEnableStates(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[industry.id] = false, _a)));
            });
            industryAPI
                .disableIndustry(industry.id)
                .then(function () {
                showSuccessToast({
                    titleId: "settings.tags.disable_tag.success.title",
                }, {
                    position: "bottom-center",
                });
            })
                .catch(function (err) {
                console.error("Failed to disable tag", err);
                showErrorToast({
                    titleId: "settings.tags.disable_tag.error.title",
                    descriptionId: "settings.tags.disable_tag.error.description",
                }, {
                    position: "bottom-center",
                });
                setEnableStates(function (prevState) {
                    var _a;
                    return (__assign(__assign({}, prevState), (_a = {}, _a[industry.id] = industry.isEnabled, _a)));
                });
            });
            return [2 /*return*/];
        });
    }); }, [industryAPI, showErrorToast, showSuccessToast]);
    var onDeleteTag = useCallback(function (industry) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, industryAPI.deleteIndustry(industry.id).then(function () {
                    refresh();
                })];
        });
    }); }, [industryAPI, refresh]);
    var _d = __read(useState(null), 2), mergeItem = _d[0], setMergeItem = _d[1];
    var onCloseMergeDialog = useCallback(function () { return setMergeItem(null); }, []);
    var onStartMerge = useCallback(function (industry) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, setMergeItem(industry)];
    }); }); }, []);
    var onGoToTagDetail = useCallback(function (industry) {
        navigation.push(ROUTES.settings.tags.industry.group_detail + "/" + industry.id, {
            tagName: industry.name,
        });
    }, [navigation]);
    var renderContextMenu = useCallback(function (i) {
        var _a;
        return (React.createElement(TagContextMenu, { typeId: "settings.tags.title.industry", item: i, itemEnableState: (_a = enableStates[i.id]) !== null && _a !== void 0 ? _a : i.isEnabled, onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onStartMerge }));
    }, [
        enableStates,
        onEditTag,
        onEnableTag,
        onDisableTag,
        onDeleteTag,
        onStartMerge,
    ]);
    var tableColumns = useMemo(function () { return [
        {
            titleId: "settings.tags.industry.detail.table.company_type",
            accessor: function (c) { return c.name; },
            render: function (name, c) {
                var _a;
                return (React.createElement("span", { className: cn("font-bold text-sm leading-5 text-indigo-600", !((_a = enableStates[c.id]) !== null && _a !== void 0 ? _a : c.isEnabled) && "opacity-50") }, name));
            },
        },
    ]; }, [enableStates]);
    var tableActions = useMemo(function () { return [
        {
            className: "flex items-center justify-end",
            render: renderContextMenu,
        },
    ]; }, [renderContextMenu]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DefaultTagGroupDetail, { headerTitleId: "settings.tags.title.industry", headerTagCountId: "settings.tags.tag_counts.industry", searchPlaceholderId: "settings.tags.industry.detail.search_placeholder", addNewTagId: "settings.tags.industry.detail.add_new_industry", tableData: data, tableColumns: tableColumns, tableActions: tableActions, tagCount: totalCount, isInitializing: isInitializing, isFetchingMore: isFetchingMore, hasMoreData: hasMore, onTableItemClick: onGoToTagDetail, onFetchMore: nextPage, onGoBack: onGoBack, onAddNewClick: onAddNewIndustry, onEditGroup: onEditGroup, onSearch: search, tagGroupSlug: TagGroupSlug.Industry }),
        mergeItem && (React.createElement(MergeIndustryDialog, { destination: mergeItem, onClose: onCloseMergeDialog, onDone: refresh }))));
});

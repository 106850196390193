import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { loginRequestSchema, loginBySSORequestSchema, loginResponseSchema, getMeResponseSchema, } from "@shared/models/api/auth_api";
export var AuthAPIContext = React.createContext(undefined);
export var AuthAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var login = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = loginRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("auth/login", body, {
                            requireAuthentication: false,
                        })];
                case 1:
                    respRaw = _a.sent();
                    resp = loginResponseSchema.validateSync(respRaw);
                    return [2 /*return*/, resp];
            }
        });
    }); }, [apiContext]);
    var loginBySSO = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = loginBySSORequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("auth/login/sso", body, {
                            requireAuthentication: false,
                        })];
                case 1:
                    respRaw = _a.sent();
                    resp = loginResponseSchema.validateSync(respRaw);
                    return [2 /*return*/, resp];
            }
        });
    }); }, [apiContext]);
    var me = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("auth/me")];
                case 1:
                    respRaw = _a.sent();
                    resp = getMeResponseSchema.validateSync(respRaw);
                    return [2 /*return*/, resp];
            }
        });
    }); }, [apiContext]);
    var revoke = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.post("auth/revoke", {})];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            login: login,
            loginBySSO: loginBySSO,
            me: me,
            revoke: revoke,
        };
    }, [login, loginBySSO, me, revoke]);
    return (React.createElement(AuthAPIContext.Provider, { value: value }, children));
});

import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { ROUTES } from "@router/routes";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { useAppToast } from "../common/Toast";
import { TagDetailHeader } from "../settings/TagDetailHeader";
import { TagDetailInfo } from "../settings/TagDetailInfo";
import { useNavigation } from "@hooks/useNavigation";
import { ColouredTag } from "@components/common/ColouredTag";
import { TagExtractors, getTagGroupCustomFieldsEntries, } from "@shared/models/tag";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { TagAPIContext } from "@shared/contexts/api/TagAPIContext";
import { useMergeTagDialog, MergeTagDialog } from "../common/MergeTagDialog";
export var CustomTagDetailScreen = React.memo(function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useParams(), groupId = _h.groupId, tagId_ = _h.tagId;
    var tagId = useMemo(function () { return parseInt(tagId_, 10); }, [tagId_]);
    var tagGroupId = useMemo(function () { return parseInt(groupId, 10); }, [groupId]);
    var navigation = useNavigation();
    var location = useLocation();
    var showErrorToast = useAppToast().showErrorToast;
    var tagAPI = useContext(TagAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _j = __read(useState(null), 2), tag = _j[0], setTag = _j[1];
    var _k = __read(useState(false), 2), isLoading = _k[0], setIsLoading = _k[1];
    var _l = __read(useState([]), 2), availableCustomProperties = _l[0], setAvailableCustomProperties = _l[1];
    var _m = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _m[0], setTagName = _m[1];
    var goGroupDetail = useCallback(function () {
        navigation.replace(ROUTES.settings.tags.root + "/" + tagGroupId);
    }, [navigation, tagGroupId]);
    var fetchTag = useCallback(function () {
        setIsLoading(true);
        Promise.all([
            tagAPI.getTag(tagGroupId, tagId),
            tagGroupAPI.getTagGroupById(tagGroupId),
        ])
            .then(function (_a) {
            var _b = __read(_a, 2), tag = _b[0], tagGroup = _b[1];
            setTag(tag);
            setTagName(tag.name);
            setAvailableCustomProperties(getTagGroupCustomFieldsEntries(tagGroup.definition).map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return ({
                    name: value.name,
                    value: tag.additional[key],
                });
            }));
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            goGroupDetail();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [tagAPI, tagGroupId, tagId, tagGroupAPI, showErrorToast, goGroupDetail]);
    var onEditTag = useCallback(function () {
        navigation.push(ROUTES.settings.tags.root + "/" + tagGroupId + "/" + tagId + "/edit");
    }, [navigation, tagGroupId, tagId]);
    var onEnableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, tagAPI.enableTag(tagGroupId, tagId)];
        });
    }); }, [tagAPI, tagGroupId, tagId]);
    var onDisableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, tagAPI.disableTag(tagGroupId, tagId)];
        });
    }); }, [tagAPI, tagGroupId, tagId]);
    var onDeleteTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, tagAPI.deleteTag(tagGroupId, tagId).then(function () {
                    goGroupDetail();
                })];
        });
    }); }, [tagAPI, tagGroupId, tagId, goGroupDetail]);
    var _o = useMergeTagDialog(), mergeDialogProps = _o.mergeDialogProps, openMergeDialog = _o.openMergeDialog, closeMergeDialog = _o.closeMergeDialog;
    var _p = __read(useState(false), 2), isMerging = _p[0], setIsMerging = _p[1];
    var onMergeTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var source, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openMergeDialog()];
                case 1:
                    source = _a.sent();
                    if (!source) {
                        return [2 /*return*/];
                    }
                    setIsMerging(true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, tagAPI.mergeTag(tagGroupId, tagId, source.id)];
                case 3:
                    _a.sent();
                    closeMergeDialog();
                    fetchTag();
                    return [3 /*break*/, 6];
                case 4:
                    err_1 = _a.sent();
                    console.error("Failed to merge tag", err_1);
                    showErrorToast({
                        titleId: "merge_tag.error.title",
                        descriptionId: "merge_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 6];
                case 5:
                    setIsMerging(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [
        openMergeDialog,
        tagAPI,
        tagGroupId,
        tagId,
        closeMergeDialog,
        fetchTag,
        showErrorToast,
    ]);
    useEffect(function () {
        fetchTag();
    }, [fetchTag]);
    var listTags = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, tagAPI.listTags(tagGroupId, request)];
        });
    }); }, [tagGroupId, tagAPI]);
    return (React.createElement("div", { className: "flex flex-col min-w-0 flex-1" },
        React.createElement(MergeTagDialog, __assign({}, mergeDialogProps, { destinationId: tagId, tagName: tagName, fetch: listTags, isMerging: isMerging, keyExtractor: TagExtractors.id, labelExtractor: TagExtractors.name })),
        React.createElement(TagDetailHeader, { typeId: "settings.tags.title.custom", tagName: tagName, isTagEnabled: (_c = tag === null || tag === void 0 ? void 0 : tag.isEnabled) !== null && _c !== void 0 ? _c : true, onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onMergeTag, onGoBack: goGroupDetail }),
        React.createElement("div", { className: "flex-1 mt-2 overflow-y-auto md:mt-0 md:p-4" },
            React.createElement(TagDetailInfo.Panel, { isLoading: isLoading },
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.custom.form.display_name" }), value: (_d = tag === null || tag === void 0 ? void 0 : tag.name) !== null && _d !== void 0 ? _d : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.custom.form.full_name" }), value: (_e = tag === null || tag === void 0 ? void 0 : tag.fullName) !== null && _e !== void 0 ? _e : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.custom.form.color" }), value: React.createElement(ColouredTag, { tag: (_g = (_f = tag === null || tag === void 0 ? void 0 : tag.color) === null || _f === void 0 ? void 0 : _f.name) !== null && _g !== void 0 ? _g : "", colour: tag === null || tag === void 0 ? void 0 : tag.color }) }),
                availableCustomProperties.map(function (value, index) { return (React.createElement(TagDetailInfo.Item, { key: index, title: value.name, value: value.value })); })))));
});

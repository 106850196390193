import { __assign, __awaiter, __generator, __read, __values } from "tslib";
import React, { useCallback, useEffect, useMemo, useRef, useState, useContext, } from "react";
import { contactFormSchema } from "./models";
import { useIntl } from "react-intl";
import { FormField } from "@components/form/FormField";
import { Formik } from "formik";
import cn from "classnames";
import { TextField } from "@components/form/TextField";
import { getTagGroupCustomFieldsEntries, } from "@shared/models/tag";
import { extractInitials } from "@utils/avatar";
import { aggregateFormSchema, notEmpty } from "./utils";
import { FilePickerButton } from "@components/common/FilePickerButton";
import { CompanyExtractors } from "@shared/models/company";
import { SelectMenu } from "@components/form/SelectMenu";
import EmailParser from "email-addresses";
import { Persona, PersonaSize } from "@fluentui/react";
import { FormShell } from "./FormShell";
import { CompanyAPIContext } from "@shared/contexts/api";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { useAppToast } from "@components/common/Toast";
export var ContactForm = React.memo(function (props) {
    var _a, _b;
    var additionalFields = props.additionalFields, initialAvatarUrl = props.initialAvatarUrl, initialFormValues = props.initialFormValues, isSubmitting = props.isSubmitting, onSubmit = props.onSubmit, onCancel = props.onCancel;
    var intl = useIntl();
    var companyAPI = useContext(CompanyAPIContext);
    var showErrorToast = useAppToast().showErrorToast;
    var onFetchListItemError = useCallback(function () {
        showErrorToast({
            titleId: "common.errors.data.fetch_failed.title",
            descriptionId: "common.errors.data.fetch_failed.description",
        }, {
            position: "bottom-center",
        });
    }, [showErrorToast]);
    var _c = useSearchPagination(companyAPI.listCompanies, {
        page: 1,
        perPage: 20,
    }, onFetchListItemError), companyOptions = _c.data, isFetchingMore = _c.isFetchingMore, nextPage = _c.nextPage, search = _c.search;
    var formikRef = useRef(null);
    var _d = __read(useState(initialAvatarUrl), 2), avatarUrl = _d[0], setAvatarUrl = _d[1];
    var _e = __read(useState((_b = (_a = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.name) !== null && _a !== void 0 ? _a : initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.email) !== null && _b !== void 0 ? _b : ""), 2), avatarIdentifier = _e[0], setAvatarIdentifier = _e[1];
    var isEditing = useMemo(function () { return !!initialFormValues; }, [initialFormValues]);
    var initialValues = useMemo(function () {
        var _a, _b, _c, _d;
        return ({
            name: (_a = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.name) !== null && _a !== void 0 ? _a : "",
            email: (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.email) !== null && _b !== void 0 ? _b : "",
            phoneNumber: (_c = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.phoneNumber) !== null && _c !== void 0 ? _c : "",
            company: (_d = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.company) !== null && _d !== void 0 ? _d : null,
            avatar: null,
            additional: Object.keys(additionalFields).reduce(function (acc, curr) {
                var _a;
                var _b;
                return (__assign(__assign({}, acc), (_a = {}, _a[curr] = (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.additional[curr]) !== null && _b !== void 0 ? _b : "", _a)));
            }, {}),
        });
    }, [initialFormValues, additionalFields]);
    var aggregatedSchema = useMemo(function () { return aggregateFormSchema(contactFormSchema, additionalFields); }, [additionalFields]);
    var onCompanyChange = useCallback(function (company) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("company", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("company", company);
    }, []);
    var onAvatarFileChange = useCallback(function (file) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("avatar", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("avatar", file);
        if (file) {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                setAvatarUrl(reader_1.result);
            };
            reader_1.readAsDataURL(file);
        }
    }, []);
    var onFileBadMimeType = useCallback(function (file) {
        showErrorToast({
            titleId: "common.errors.file.bad_mime_type.title",
            titleValues: {
                type: file.type,
            },
            descriptionId: "common.errors.file.bad_mime_type.description",
        }, {
            position: "bottom-center",
        });
    }, [showErrorToast]);
    var onNameFieldBlur = useCallback(function (e) {
        var _a;
        setAvatarIdentifier(e.currentTarget.value);
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.handleBlur(e);
    }, [setAvatarIdentifier]);
    var onEmailFieldBlur = useCallback(function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var value, emails, _loop_1, emails_1, emails_1_1, email, domains, companies;
        var e_1, _a;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    value = e.currentTarget.value;
                    emails = value
                        .split(",")
                        .map(function (v) { return EmailParser.parseOneAddress(v); });
                    _loop_1 = function (email) {
                        // Set avatar identifier if there is no previous value
                        setAvatarIdentifier(function (prevValue) {
                            return !prevValue && email && email.type === "mailbox"
                                ? email.address
                                : prevValue;
                        });
                    };
                    try {
                        for (emails_1 = __values(emails), emails_1_1 = emails_1.next(); !emails_1_1.done; emails_1_1 = emails_1.next()) {
                            email = emails_1_1.value;
                            _loop_1(email);
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (emails_1_1 && !emails_1_1.done && (_a = emails_1.return)) _a.call(emails_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    domains = emails
                        .map(function (email) {
                        return email && email.type === "mailbox" ? email.domain : null;
                    })
                        .filter(notEmpty);
                    if (!(domains.length > 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, companyAPI.getAllCompaniesByDomains(domains)];
                case 1:
                    companies = _d.sent();
                    if (companies.data.length > 0) {
                        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("company", companies.data[0]);
                    }
                    _d.label = 2;
                case 2:
                    (_c = formikRef.current) === null || _c === void 0 ? void 0 : _c.handleBlur(e);
                    return [2 /*return*/];
            }
        });
    }); }, [companyAPI, setAvatarIdentifier]);
    useEffect(function () {
        setAvatarUrl(initialAvatarUrl);
    }, [initialAvatarUrl, setAvatarUrl]);
    var onCompanySearch = useCallback(function (term) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            search(term);
            return [2 /*return*/];
        });
    }); }, [search]);
    var onCompanyFetchMore = useCallback(function () {
        nextPage();
    }, [nextPage]);
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: aggregatedSchema, validateOnChange: true, onSubmit: onSubmit, innerRef: formikRef }, function (_a) {
        var _b;
        var values = _a.values, errors = _a.errors, touched = _a.touched, handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur;
        return (React.createElement(FormShell, { isSubmitting: isSubmitting, onCancel: onCancel, onSubmit: handleSubmit, cancelLabel: intl.formatMessage({ id: "common.cancel" }), submitLabel: intl.formatMessage({
                id: isEditing
                    ? "settings.tags.form.edit.submit"
                    : "settings.tags.form.create.submit",
            }, {
                type: intl.formatMessage({
                    id: "settings.tags.title.contact",
                }),
            }) },
            React.createElement(FormField, { titleId: "settings.tags.contact.form.name", errorId: touched.name ? errors.name : undefined, isOptional: false },
                React.createElement(TextField, { name: "name", onChange: handleChange, onBlur: onNameFieldBlur, value: values.name, placeholder: intl.formatMessage({
                        id: "settings.tags.contact.form.name.placeholder",
                    }), isError: touched.name && !!errors.name, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
            React.createElement(FormField, { titleId: "settings.tags.contact.form.email", errorId: touched.email ? errors.email : undefined, isOptional: false },
                React.createElement(TextField, { name: "email", onChange: handleChange, onBlur: onEmailFieldBlur, value: values.email, placeholder: intl.formatMessage({
                        id: "settings.tags.contact.form.email.placeholder",
                    }), isError: touched.email && !!errors.email, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
            React.createElement(FormField, { titleId: "settings.tags.contact.form.phone_number", errorId: touched.phoneNumber ? errors.phoneNumber : undefined, isOptional: true },
                React.createElement(TextField, { name: "phoneNumber", onChange: handleChange, onBlur: handleBlur, value: values.phoneNumber, isError: touched.phoneNumber && !!errors.phoneNumber, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
            React.createElement(FormField, { titleId: "settings.tags.contact.form.profile_picture", errorId: touched.avatar ? errors.avatar : undefined, isOptional: true },
                React.createElement("div", { className: "flex flex-row items-end" },
                    React.createElement(Persona, { className: "mr-4", imageUrl: avatarUrl, imageInitials: extractInitials(avatarIdentifier, 2), hidePersonaDetails: true, size: PersonaSize.size56 }),
                    React.createElement(FilePickerButton, { multiple: false, acceptedMimeTypes: ["image/jpeg", "image/png"], onFileSelect: onAvatarFileChange, onFileBadMimeType: onFileBadMimeType, uploadButtonLabelId: "settings.tags.contact.form.upload_image" }))),
            React.createElement(FormField, { titleId: "settings.tags.contact.form.company", errorId: touched.company ? errors.company : undefined, isOptional: false },
                React.createElement(SelectMenu, { className: "h-[2.375rem] w-full md:max-w-xs", selected: (_b = values.company) !== null && _b !== void 0 ? _b : null, onSelect: onCompanyChange, multiple: false, items: companyOptions, placeholder: intl.formatMessage({
                        id: "settings.tags.contact.form.company.placeholder",
                    }), isError: touched.company && !!errors.company, keyExtractor: CompanyExtractors.id, labelExtractor: CompanyExtractors.name, onSearch: onCompanySearch, onFetchMore: onCompanyFetchMore, isFetchingMore: isFetchingMore })),
            getTagGroupCustomFieldsEntries(additionalFields).map(function (_a) {
                var _b, _c, _d, _e;
                var _f = __read(_a, 2), key = _f[0], _g = _f[1], name = _g.name, mandatory = _g.mandatory;
                return (React.createElement(FormField, { key: key, title: name, errorId: ((_b = touched.additional) === null || _b === void 0 ? void 0 : _b[key])
                        ? (_c = errors.additional) === null || _c === void 0 ? void 0 : _c[key]
                        : undefined, isOptional: !mandatory },
                    React.createElement(TextField, { name: "additional." + key, onChange: handleChange, onBlur: handleBlur, isError: ((_d = touched.additional) === null || _d === void 0 ? void 0 : _d[key]) && !!((_e = errors.additional) === null || _e === void 0 ? void 0 : _e[key]), value: values.additional[key], className: "py-2 px-3 border border-gray-300 shadow-sm h-[2.375rem] w-full" })));
            })));
    }));
});

import { CheckIcon, PencilIcon } from "@heroicons/react/outline";
import cn from "classnames";
import React from "react";
export var ThreadDetailEditButton = React.memo(function (props) {
    var isEditing = props.isEditing, onStartEditing = props.onStartEditing, onSubmitChanges = props.onSubmitChanges, canSubmit = props.canSubmit;
    if (isEditing) {
        return (React.createElement("button", { type: "button", onClick: onSubmitChanges, disabled: !canSubmit, className: cn("w-6 h-6 rounded-full bg-indigo-900 flex items-center justify-center", "disabled:cursor-not-allowed disabled:opacity-30") },
            React.createElement(CheckIcon, { className: "w-3.5 h-3.5 text-white" })));
    }
    return (React.createElement("button", { type: "button", className: "w-6 h-6 rounded-full bg-indigo-900 flex items-center justify-center", onClick: onStartEditing },
        React.createElement(PencilIcon, { className: "w-3.5 h-3.5 text-white" })));
});

import React from "react";
import { FormattedMessage } from "react-intl";
import cn from "classnames";
export var Checkbox = React.memo(function (props) {
    var name = props.name, titleId = props.titleId, value = props.value, disabled = props.disabled, onChange = props.onChange, className = props.className, descriptionId = props.descriptionId, titleClassName = props.titleClassName, descriptionClassName = props.descriptionClassName;
    return (React.createElement("div", { className: cn("relative flex items-start", className) },
        React.createElement("div", { className: "flex items-center h-5" },
            React.createElement("input", { name: name, type: "checkbox", defaultChecked: value, disabled: disabled, onClick: onChange, className: "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded disabled:bg-gray-300" })),
        React.createElement("div", { className: "ml-3 text-sm font-normal leading-5" },
            React.createElement("label", { className: cn("text-gray-700", titleClassName) },
                React.createElement(FormattedMessage, { id: titleId })),
            descriptionId && (React.createElement("p", { className: cn("text-gray-500", descriptionClassName) },
                React.createElement(FormattedMessage, { id: descriptionId }))))));
});

import React from "react";
import { FormattedMessage } from "react-intl";
import cn from "classnames";
var RowFormField = function (props) {
    var className = props.className, style = props.style, errorId = props.errorId, children = props.children;
    return (React.createElement("td", { className: cn("flex-1", className), style: style },
        React.createElement("fieldset", null, children),
        errorId && (React.createElement("span", { className: "text-error text-sm leading-5 font-normal" },
            React.createElement(FormattedMessage, { id: errorId })))));
};
export default RowFormField;

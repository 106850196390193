import React, { useContext } from "react";
import { InboxIcon, CogIcon, AdjustmentsIcon } from "@heroicons/react/outline";
import { ROUTES } from "@router/routes";
import { ThreadReadStatusContext } from "@contexts/ThreadReadStatusContext";
import useHasPermission from "@hooks/useHasPermission";
import { Permission } from "@shared/models/auth";
export var MainRouteContext = React.createContext(undefined);
export var MainRouteContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var unreadCount = useContext(ThreadReadStatusContext).unreadCount;
    var hasPermission = useHasPermission();
    var value = React.useMemo(function () {
        return {
            sideBarItems: [
                hasPermission(Permission.DocumentsRead)
                    ? {
                        titleId: "main_route.sidebar.uploads.all_uploads",
                        icon: InboxIcon,
                        children: [
                            {
                                titleId: "main_route.sidebar.uploads.unread_uploads",
                                count: unreadCount !== null && unreadCount !== void 0 ? unreadCount : "",
                                path: ROUTES.uploads.unreadUploads,
                            },
                            {
                                titleId: "main_route.sidebar.uploads.all_emails_files",
                                path: ROUTES.uploads.allUploads,
                            },
                        ],
                    }
                    : null,
                {
                    titleId: "main_route.sidebar.settings",
                    icon: CogIcon,
                    children: [
                        hasPermission(Permission.TagsRead)
                            ? {
                                titleId: "main_route.sidebar.settings.tags",
                                path: ROUTES.settings.tags.root,
                                alwaysRemountOnClick: true,
                            }
                            : null,
                        hasPermission(Permission.DepartmentsRead)
                            ? {
                                titleId: "main_route.sidebar.settings.organization",
                                path: ROUTES.settings.organization.root,
                                alwaysRemountOnClick: true,
                            }
                            : null,
                        hasPermission(Permission.UsersRead)
                            ? {
                                titleId: "main_route.sidebar.settings.staff",
                                path: ROUTES.settings.staff.root,
                                alwaysRemountOnClick: true,
                            }
                            : null,
                    ].filter(function (i) { return i != null; }),
                },
                hasPermission(Permission.MeUpdate)
                    ? {
                        titleId: "main_route.sidebar.my_preference",
                        icon: AdjustmentsIcon,
                        path: ROUTES.myPreference,
                        alwaysRemountOnClick: true,
                    }
                    : null,
            ].filter(function (i) { return i != null; }),
        };
    }, [hasPermission, unreadCount]);
    return (React.createElement(MainRouteContext.Provider, { value: value }, children));
});

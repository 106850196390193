import { __read } from "tslib";
import { useEffect, useState } from "react";
export function useSelectedIndex(eventEmitter, items, onSelectItem) {
    var _a = __read(useState(null), 2), selectedItemIndex = _a[0], setSelectedItemIndex = _a[1];
    useEffect(function () {
        function onSelectionIndexChange(diff) {
            setSelectedItemIndex(function (prevIndex) {
                var maxSuggestionIndex = items.length - 1;
                if (prevIndex === null) {
                    if (diff > 0) {
                        return 0;
                    }
                    return maxSuggestionIndex;
                }
                var newIndex = prevIndex + diff;
                if (newIndex < 0 || newIndex > maxSuggestionIndex) {
                    return null;
                }
                return newIndex;
            });
        }
        eventEmitter.addListener("suggestionIndexChange", onSelectionIndexChange);
        return function () {
            eventEmitter.removeListener("suggestionIndexChange", onSelectionIndexChange);
        };
    }, [eventEmitter, items]);
    useEffect(function () {
        function _onSelectItem() {
            if (selectedItemIndex === null || selectedItemIndex > items.length - 1) {
                return;
            }
            var item = items[selectedItemIndex];
            onSelectItem(item);
            setSelectedItemIndex(null);
        }
        eventEmitter.addListener("suggestionSelected", _onSelectItem);
        return function () {
            eventEmitter.removeListener("suggestionSelected", _onSelectItem);
        };
    }, [
        eventEmitter,
        items,
        onSelectItem,
        selectedItemIndex,
        setSelectedItemIndex,
    ]);
    useEffect(function () {
        // Reset selected index on items changed
        setSelectedItemIndex(null);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]);
    return [selectedItemIndex, setSelectedItemIndex];
}

import { __values } from "tslib";
import { ALL_SCOPE } from "./models";
function isScope(value) {
    return ALL_SCOPE.includes(value);
}
function removeQuotes(value) {
    return value.replace(/"/g, "");
}
function parseClause(value) {
    var colonIndex = value.indexOf(":");
    if (colonIndex === -1) {
        return { scope: null, valueRaw: value, value: removeQuotes(value) };
    }
    var scopePart = value.substring(0, colonIndex);
    if (isScope(scopePart)) {
        var valueRaw = value.substring(colonIndex + 1);
        return { scope: scopePart, value: removeQuotes(valueRaw), valueRaw: valueRaw };
    }
    return { scope: null, valueRaw: value, value: removeQuotes(value) };
}
export function parseSearchText(text) {
    var e_1, _a;
    var values = [];
    var currentValue = "";
    var quoted = false;
    try {
        for (var text_1 = __values(text), text_1_1 = text_1.next(); !text_1_1.done; text_1_1 = text_1.next()) {
            var char = text_1_1.value;
            switch (char) {
                case '"':
                    quoted = !quoted;
                    currentValue += char;
                    break;
                case " ":
                    if (quoted) {
                        currentValue += char;
                    }
                    else {
                        var value = currentValue.trim();
                        if (value) {
                            values.push(value);
                        }
                        currentValue = "";
                    }
                    break;
                default:
                    currentValue += char;
                    break;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (text_1_1 && !text_1_1.done && (_a = text_1.return)) _a.call(text_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (currentValue) {
        values.push(currentValue);
    }
    var results = values.map(function (value) {
        return parseClause(value);
    });
    return results;
}

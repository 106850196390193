import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useMemo, useState, useEffect, useRef, useCallback, useContext, } from "react";
import { ContactAPIContext, AllTagAPIContext, DocumentAPIContext, } from "@shared/contexts/api";
import { parseSearchText } from "./parser";
import { useDebounce } from "@hooks/useDebounce";
export var SearchContext = React.createContext(undefined);
function findContinuousGenericClausesTailStart(clauses) {
    if (clauses.length === 0) {
        return clauses.length;
    }
    var reversedClauses = Array.from(clauses).reverse();
    var firstNonNullScopeIndex = reversedClauses.findIndex(function (clause) { return clause.scope !== null; });
    if (firstNonNullScopeIndex === 0) {
        // The last one is non null, therefore no clauses found
        return clauses.length;
    }
    if (firstNonNullScopeIndex === -1) {
        // All are null
        return 0;
    }
    return clauses.length - firstNonNullScopeIndex;
}
function getSuggestionQuery(clauses) {
    var lastClause = clauses.at(-1);
    var contactScopes = ["from", "to", "cc", "to,cc"];
    var tagScopes = ["tags"];
    var threadScopes = [
        "remarks",
        "subject",
        "body",
        "file",
        "remarks",
    ];
    if (!lastClause || lastClause.scope === "thread") {
        return null;
    }
    if (!lastClause.scope) {
        var tailGenericClauses = clauses.slice(findContinuousGenericClausesTailStart(clauses));
        var value = tailGenericClauses.map(function (c) { return c.value; }).join(" ");
        return ["generic", value, lastClause.scope];
    }
    if (contactScopes.indexOf(lastClause.scope) !== -1) {
        return ["contact", lastClause.value, lastClause.scope];
    }
    if (tagScopes.indexOf(lastClause.scope) !== -1) {
        return ["tags", lastClause.value, lastClause.scope];
    }
    if (threadScopes.indexOf(lastClause.scope) !== -1) {
        return ["threads", lastClause.value, lastClause.scope];
    }
    return ["generic", lastClause.value, lastClause.scope];
}
function getContactSuggestions(contactAPI, search) {
    return __awaiter(this, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, contactAPI.listContactEmails({
                        search: search,
                        page: 1,
                        perPage: 3,
                    })];
                case 1:
                    resp = _a.sent();
                    return [2 /*return*/, {
                            type: "contacts",
                            contacts: resp.data,
                        }];
            }
        });
    });
}
function getTagsSuggestions(allTagAPI, search) {
    return __awaiter(this, void 0, void 0, function () {
        var tags;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, allTagAPI.listAllTags({
                        search: search,
                        limitPerGroup: 2,
                    })];
                case 1:
                    tags = _a.sent();
                    return [2 /*return*/, __assign({ type: "tags" }, tags)];
            }
        });
    });
}
function getGenericSuggestions(allTagAPI, documentAPI, searchScope, searchText) {
    return __awaiter(this, void 0, void 0, function () {
        var tags, searchDocumentParam, documentGroupsResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(searchScope === null)) return [3 /*break*/, 2];
                    return [4 /*yield*/, allTagAPI.listAllTags({
                            search: searchText,
                            limitPerGroup: 2,
                        })];
                case 1:
                    tags = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    tags = {
                        contacts: [],
                        companies: [],
                        deals: [],
                        priorities: [],
                        otherTags: [],
                        customTags: [],
                    };
                    _a.label = 3;
                case 3:
                    searchDocumentParam = searchScope === "body"
                        ? {
                            body: [searchText],
                        }
                        : searchScope === "subject"
                            ? {
                                subject: [searchText],
                            }
                            : searchScope === "remarks"
                                ? {
                                    remark: [searchText],
                                }
                                : searchScope === "file"
                                    ? {
                                        file: [searchText],
                                    }
                                    : {
                                        text: [searchText],
                                    };
                    return [4 /*yield*/, documentAPI.searchDocuments({
                            page: 1,
                            perPage: 3,
                            search: searchDocumentParam,
                        })];
                case 4:
                    documentGroupsResp = _a.sent();
                    return [2 /*return*/, __assign(__assign({ type: "generic" }, tags), { documentGroups: documentGroupsResp.data })];
            }
        });
    });
}
export var SearchContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var contactAPI = useContext(ContactAPIContext);
    var allTagAPI = useContext(AllTagAPIContext);
    var documentAPI = useContext(DocumentAPIContext);
    var _b = __read(useState(""), 2), searchText = _b[0], setSearchText = _b[1];
    var _c = __read(useState({
        fromText: "",
        clauses: [],
    }), 2), commitedSearchClausesState = _c[0], setCommitedSearchClausesState = _c[1];
    var _d = __read(useState(null), 2), suggestion = _d[0], setSuggestion = _d[1];
    var _e = __read(useState(false), 2), isSuggestionLoading = _e[0], setIsSuggestionLoading = _e[1];
    var promiseRefs = useRef({});
    var parsedSearchClauses = useMemo(function () {
        return parseSearchText(searchText);
    }, [searchText]);
    var setSearchClauses = useCallback(function (clauses) {
        var newText = clauses
            .map(function (clause) {
            return clause.scope
                ? clause.scope + ":" + clause.valueRaw
                : clause.valueRaw;
        })
            .join(" ");
        setSearchText(newText);
    }, []);
    var replaceGenericTail = useCallback(function (clause) {
        var newClauses = Array.from(parsedSearchClauses);
        var startIndex = findContinuousGenericClausesTailStart(parsedSearchClauses);
        newClauses.splice(startIndex);
        newClauses.push(clause);
        setSearchClauses(newClauses);
    }, [parsedSearchClauses, setSearchClauses]);
    var commitSearchClauses = useCallback(function () {
        setSearchText(function (latestText) {
            setCommitedSearchClausesState(function (original) {
                if (original.fromText.trim() === latestText.trim()) {
                    // No change
                    return original;
                }
                var clauses = parseSearchText(latestText);
                return {
                    fromText: latestText,
                    clauses: clauses,
                };
            });
            return latestText;
        });
    }, []);
    var debouncedSearchClauses = useDebounce(parsedSearchClauses, 100);
    useEffect(function () {
        function getSuggestions() {
            return __awaiter(this, void 0, void 0, function () {
                var suggestionQuery, _a, suggestionType, queryValue, scope, suggestionsPromise, suggestion_1, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            suggestionQuery = getSuggestionQuery(debouncedSearchClauses);
                            if (suggestionQuery === null) {
                                setSuggestion(null);
                                setIsSuggestionLoading(false);
                                promiseRefs.current.getSuggestions = Promise.resolve(null);
                                return [2 /*return*/];
                            }
                            setIsSuggestionLoading(true);
                            _a = __read(suggestionQuery, 3), suggestionType = _a[0], queryValue = _a[1], scope = _a[2];
                            suggestionsPromise = suggestionType === "contact"
                                ? getContactSuggestions(contactAPI, queryValue)
                                : suggestionType === "tags"
                                    ? getTagsSuggestions(allTagAPI, queryValue)
                                    : getGenericSuggestions(allTagAPI, documentAPI, scope, queryValue);
                            promiseRefs.current.getSuggestions = suggestionsPromise;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, suggestionsPromise];
                        case 2:
                            suggestion_1 = _b.sent();
                            if (promiseRefs.current.getSuggestions === suggestionsPromise) {
                                setSuggestion(suggestion_1);
                                setIsSuggestionLoading(false);
                            }
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _b.sent();
                            console.error("Error in getting suggestedTags", e_1);
                            if (promiseRefs.current.getSuggestions === suggestionsPromise) {
                                setSuggestion(null);
                                setIsSuggestionLoading(false);
                            }
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
        void getSuggestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchClauses]);
    var relatedTagsSearchText = useMemo(function () {
        var noScopeValues = commitedSearchClausesState.clauses
            .filter(function (clause) { return clause.scope === null; })
            .map(function (clause) { return clause.value; });
        if (noScopeValues.length === 0) {
            return null;
        }
        return noScopeValues.join(" ");
    }, [commitedSearchClausesState.clauses]);
    var value = useMemo(function () {
        return {
            searchText: searchText,
            parsedSearchClauses: parsedSearchClauses,
            commitedSearchClauses: commitedSearchClausesState.clauses,
            setSearchText: setSearchText,
            setSearchClauses: setSearchClauses,
            replaceGenericTail: replaceGenericTail,
            commitSearchClauses: commitSearchClauses,
            isSuggestionLoading: isSuggestionLoading,
            suggestion: suggestion,
            relatedTagsSearchText: relatedTagsSearchText,
        };
    }, [
        commitSearchClauses,
        commitedSearchClausesState.clauses,
        isSuggestionLoading,
        parsedSearchClauses,
        searchText,
        setSearchClauses,
        replaceGenericTail,
        suggestion,
        relatedTagsSearchText,
    ]);
    return (React.createElement(SearchContext.Provider, { value: value }, children));
});

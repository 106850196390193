import { __assign, __awaiter, __generator } from "tslib";
import React, { useContext, useCallback } from "react";
import { DropDown } from "./DropDown";
import { FormattedMessage, useIntl } from "react-intl";
import { DocumentType } from "@shared/models/document";
import { DocumentAPIContext } from "@shared/contexts/api/DocumentAPIContext";
import { Dialog, useDialog } from "../common/Dialog";
import { ExclamationIcon } from "@heroicons/react/solid";
import RequirePermission from "../common/RequirePermission";
import { Permission } from "@shared/models/auth";
var DocumentDetailContextMenu = React.memo(function (props) {
    var document = props.document, onRemove = props.onRemove, isRemovable = props.isRemovable, isExportable = props.isExportable;
    var documentAPI = useContext(DocumentAPIContext);
    var intl = useIntl();
    var _a = useDialog(), deleteDialogProps = _a.dialogProps, openDeleteDialog = _a.openDialog;
    var handleExportClick = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, link;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, documentAPI.exportDocument(document.id)];
                case 1:
                    resp = _a.sent();
                    if (resp.url) {
                        link = window.document.createElement("a");
                        link.href = resp.url;
                        window.document.body.appendChild(link);
                        link.click();
                        window.document.body.removeChild(link);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [document, documentAPI]);
    var handleRemoveClick = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var confirm;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openDeleteDialog()];
                case 1:
                    confirm = _a.sent();
                    if (!confirm) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, onRemove(document.id, "this_only")];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [document, openDeleteDialog, onRemove]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DropDown.Button, null,
            isExportable && (React.createElement(DropDown.Item, { disabled: document.type !== DocumentType.Message, onClick: handleExportClick },
                React.createElement(FormattedMessage, { id: "uploads.detail.context.export" }))),
            React.createElement(RequirePermission, { permission: Permission.DocumentsDelete },
                React.createElement(DropDown.Item, { onClick: handleRemoveClick, disabled: !isRemovable },
                    React.createElement(FormattedMessage, { id: "uploads.detail.context.remove_message" })))),
        React.createElement(Dialog, __assign({}, deleteDialogProps, { title: intl.formatMessage({
                id: "uploads.delete.confirmation.title",
            }), description: intl.formatMessage({
                id: "uploads.delete.confirmation.description",
            }, { subject: document.subject }), confirmLabelId: "common.remove", cancelLabelId: "common.cancel", icon: React.createElement(ExclamationIcon, { className: "h-6 w-6 text-red-600", "aria-hidden": "true" }) }))));
});
export default DocumentDetailContextMenu;

import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { BasicDialog } from "./BasicDialog";
import { PrimaryButton } from "./PrimaryButton";
import { WhiteButton } from "./WhiteButton";
import { SelectMenu } from "../form/SelectMenu";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { useAppToast } from "../common/Toast";
import { Spinner } from "@components/common/Spinner";
var MergeTagDialogImpl = function (props) {
    var isOpened = props.isOpened, tagName = props.tagName, destinationId = props.destinationId, onClose = props.onClose, fetch = props.fetch, onMergeClick = props.onMergeClick, isMerging = props.isMerging, labelExtractor = props.labelExtractor, keyExtractor = props.keyExtractor;
    var intl = useIntl();
    var showErrorToast = useAppToast().showErrorToast;
    var _a = __read(useState(null), 2), selected = _a[0], setSelected = _a[1];
    var onConfirm = useCallback(function () {
        onMergeClick(selected);
    }, [onMergeClick, selected]);
    var onCancel = useCallback(function () {
        onClose();
    }, [onClose]);
    var onSelect = useCallback(function (selection) {
        setSelected(selection);
    }, [setSelected]);
    var onFetchListItemError = useCallback(function () {
        showErrorToast({
            titleId: "common.errors.data.fetch_failed.title",
            descriptionId: "common.errors.data.fetch_failed.description",
        }, {
            position: "bottom-center",
        });
    }, [showErrorToast]);
    var _b = useSearchPagination(fetch, {
        page: 1,
        perPage: 20,
    }, onFetchListItemError), data = _b.data, isInitializing = _b.isInitializing, isFetchingMore = _b.isFetchingMore, nextPage = _b.nextPage, search = _b.search;
    var onFetchMore = useCallback(function () {
        nextPage();
    }, [nextPage]);
    var onDialogClose = useCallback(function () {
        return null;
    }, []);
    var onSearch = useCallback(function (term) {
        search(term);
    }, [search]);
    var options = useMemo(function () {
        return data.filter(function (d) { return d.id !== destinationId; });
    }, [data, destinationId]);
    return (React.createElement(BasicDialog, { isOpened: isOpened, onClose: onDialogClose, panelClassName: "sm:max-w-sm" },
        React.createElement("div", { className: "px-4 py-5 text-base leading-6 font-medium text-gray-900" },
            React.createElement(FormattedMessage, { id: "merge_tag.title" })),
        React.createElement("hr", null),
        React.createElement("div", { className: "p-4" },
            React.createElement("div", { className: "text-sm leading-5 font-normal text-gray-500 mb-4" },
                React.createElement(FormattedMessage, { id: "merge_tag.into_info" }),
                React.createElement("span", { className: "font-bold" },
                    " ",
                    tagName)),
            React.createElement("div", { className: "text-sm leading-5 font-medium text-gray-700 font-bold pb-1" },
                React.createElement(FormattedMessage, { id: "merge_tag.select.label" })),
            isInitializing ? (React.createElement(Spinner, { size: "small" })) : (React.createElement(SelectMenu, { className: "h-[2.375rem] w-full md:max-w-sm", selected: selected, onSelect: onSelect, multiple: false, items: options, keyExtractor: keyExtractor, labelExtractor: labelExtractor, placeholder: intl.formatMessage({
                    id: "merge_tag.select.placeholder",
                }), icon: "chevron-down", isFetchingMore: isFetchingMore, onFetchMore: onFetchMore, onSearch: onSearch })),
            React.createElement("div", { className: "text-sm leading-5 font-normal text-[#EB5757] py-4" },
                React.createElement(FormattedMessage, { id: "merge_tag.warning" }),
                React.createElement("span", { className: "font-bold" },
                    " ",
                    tagName,
                    "."),
                React.createElement("div", { className: "pt-5" },
                    React.createElement(FormattedMessage, { id: "merge_tag.warning_take_time" }))),
            React.createElement("hr", null),
            React.createElement("div", { className: "pt-8 mx-2 flex flex-col-reverse sm:mt-4 sm:justify-end sm:flex-row space-x-2" },
                React.createElement(WhiteButton, { type: "button", size: "xs", onClick: onCancel },
                    React.createElement(FormattedMessage, { id: "common.cancel" })),
                React.createElement(PrimaryButton, { type: "submit", size: "xs", disabled: !selected, onClick: onConfirm }, isMerging ? (React.createElement(Spinner, { size: "small" })) : (React.createElement(FormattedMessage, { id: "common.merge" })))))));
};
export function useMergeTagDialog() {
    var _this = this;
    var _a = __read(useState(false), 2), isOpened = _a[0], setIsOpened = _a[1];
    var _b = __read(useState(null), 2), dialogProps = _b[0], setDialogProps = _b[1];
    var callbackRef = useRef(null);
    var openDialog = useCallback(function (props) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (callbackRef.current != null) {
                console.log("Found existing dialog");
                return [2 /*return*/, Promise.resolve(null)];
            }
            return [2 /*return*/, new Promise(function (resolve) {
                    callbackRef.current = resolve;
                    setDialogProps(props !== null && props !== void 0 ? props : null);
                    setIsOpened(true);
                })];
        });
    }); }, [setIsOpened]);
    var onMergeClick = useCallback(function (result) {
        var _a;
        (_a = callbackRef.current) === null || _a === void 0 ? void 0 : _a.call(callbackRef, result);
    }, []);
    var onClose = useCallback(function () {
        setIsOpened(false);
        callbackRef.current = null;
    }, []);
    var props = useMemo(function () { return (__assign(__assign({}, (dialogProps !== null && dialogProps !== void 0 ? dialogProps : {})), { isOpened: isOpened, onMergeClick: onMergeClick, onClose: onClose })); }, [isOpened, dialogProps, onMergeClick, onClose]);
    var closeDialog = useCallback(function () {
        onClose();
    }, [onClose]);
    return {
        mergeDialogProps: props,
        openMergeDialog: openDialog,
        closeMergeDialog: closeDialog,
    };
}
export var MergeTagDialog = React.memo(MergeTagDialogImpl);

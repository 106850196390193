import { __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ContactForm } from "../settings/forms/ContactForm";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
import { ROUTES } from "@router/routes";
import { useAppToast } from "../common/Toast";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { FileAPIContext } from "@shared/contexts/api/FileAPIContext";
import { ContactAPIContext } from "@shared/contexts/api/ContactAPIContext";
import { TagSettingRouterContext } from "@router/TagSettingRouter";
import { parseTagCreationErrorMessage } from "@shared/models/error";
import { useImageResizer } from "@hooks/useImageResizer";
import { useNavigation } from "@hooks/useNavigation";
export var ContactTagCreationScreen = React.memo(function () {
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var fileAPI = useContext(FileAPIContext);
    var contactAPI = useContext(ContactAPIContext);
    var refreshSidebar = useContext(TagSettingRouterContext).refreshSidebar;
    var intl = useIntl();
    var navigation = useNavigation();
    var showErrorToast = useAppToast().showErrorToast;
    var _a = __read(useState({}), 2), additionalFields = _a[0], setAdditionalFields = _a[1];
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(false), 2), isSubmitting = _c[0], setIsSubmitting = _c[1];
    var resizeImageFile = useImageResizer().resizeImageFile;
    var handleSubmit = useCallback(function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
        var avatarId, resizedImage, contact, err_1, _a, titleId, descriptionId;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsSubmitting(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, 7, 8]);
                    avatarId = null;
                    if (!formValues.avatar) return [3 /*break*/, 4];
                    return [4 /*yield*/, resizeImageFile(formValues.avatar, {
                            maxWidth: 64,
                            maxHeight: 64,
                        })];
                case 2:
                    resizedImage = _b.sent();
                    return [4 /*yield*/, fileAPI.uploadImage(resizedImage)];
                case 3:
                    avatarId = _b.sent();
                    _b.label = 4;
                case 4: return [4 /*yield*/, contactAPI.createContact({
                        name: formValues.name.trim(),
                        emails: formValues.email.split(",").map(function (d) { return d.trim(); }),
                        companyId: !!formValues.company ? formValues.company.id : null,
                        phoneNumber: !!formValues.phoneNumber
                            ? formValues.phoneNumber.trim()
                            : null,
                        avatar: avatarId,
                        additional: formValues.additional,
                    })];
                case 5:
                    contact = _b.sent();
                    refreshSidebar();
                    navigation.push(ROUTES.settings.tags.contact.group_detail + "/" + contact.id);
                    return [3 /*break*/, 8];
                case 6:
                    err_1 = _b.sent();
                    _a = __read(parseTagCreationErrorMessage(err_1, "contact"), 2), titleId = _a[0], descriptionId = _a[1];
                    showErrorToast({
                        titleId: titleId,
                        descriptionId: descriptionId,
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 8];
                case 7:
                    setIsSubmitting(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); }, [
        fileAPI,
        contactAPI,
        navigation,
        resizeImageFile,
        setIsSubmitting,
        showErrorToast,
        refreshSidebar,
    ]);
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.contact.group_detail);
        }
    }, [navigation]);
    useEffect(function () {
        setIsLoading(true);
        Promise.all([tagGroupAPI.getTagGroupBySlug("contact")])
            .then(function (_a) {
            var _b = __read(_a, 1), tagGroupResp = _b[0];
            setAdditionalFields(tagGroupResp.definition);
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        tagGroupAPI,
        navigation,
        showErrorToast,
        setAdditionalFields,
        handleGoBack,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tags.form.create.title", values: {
                        type: intl.formatMessage({
                            id: "settings.tags.title.contact",
                        }),
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(ContactForm, { additionalFields: additionalFields, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleGoBack })))));
});

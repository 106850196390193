import { __assign, __rest } from "tslib";
import { NotFoundScreen } from "@components/screens/NotFoundScreen";
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
export var AuthState;
(function (AuthState) {
    AuthState[AuthState["authenticated"] = 0] = "authenticated";
    AuthState[AuthState["notAuthenticated"] = 1] = "notAuthenticated";
})(AuthState || (AuthState = {}));
var AuthRouteImpl = function (props) {
    var requiredAuthState = props.requiredAuthState, requiredPermission = props.requiredPermission, children = props.children, authFallbackPath = props.authFallbackPath, permissionFallbackPath = props.permissionFallbackPath, permissionFallbackScreen = props.permissionFallbackScreen, restProps = __rest(props, ["requiredAuthState", "requiredPermission", "children", "authFallbackPath", "permissionFallbackPath", "permissionFallbackScreen"]);
    var location = useLocation();
    var me = React.useContext(AuthContext).me;
    var currentAuthState = (me === null || me === void 0 ? void 0 : me.user) == null ? AuthState.notAuthenticated : AuthState.authenticated;
    var shouldRedirectForAuth = React.useMemo(function () {
        return currentAuthState !== requiredAuthState;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, requiredAuthState]);
    var shouldRedirectForPermission = React.useMemo(function () {
        var _a;
        var permissions = (_a = me === null || me === void 0 ? void 0 : me.permissions) !== null && _a !== void 0 ? _a : [];
        return (requiredPermission != null && !permissions.includes(requiredPermission));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, requiredPermission]);
    if (shouldRedirectForAuth) {
        var redirectState = { from: location.pathname };
        return (React.createElement(Redirect, { to: {
                pathname: authFallbackPath,
                state: redirectState,
            } }));
    }
    if (shouldRedirectForPermission) {
        if (permissionFallbackPath != null) {
            var redirectState = { from: location.pathname };
            return (React.createElement(Redirect, { to: { pathname: permissionFallbackPath, state: redirectState } }));
        }
        return (React.createElement(Route, __assign({}, restProps), permissionFallbackScreen !== null && permissionFallbackScreen !== void 0 ? permissionFallbackScreen : React.createElement(NotFoundScreen, null)));
    }
    return React.createElement(Route, __assign({}, restProps), children);
};
var AuthRoute = React.memo(AuthRouteImpl);
export { AuthRoute };

import { __read } from "tslib";
import React, { useCallback, useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ROUTES } from "@router/routes";
import { TagGroupForm } from "../settings/forms/TagGroupForm";
import { TagGroupStoreContext } from "@contexts/TagGroupStoreContext";
import { useNavigation } from "@hooks/useNavigation";
import { TagSettingRouterContext } from "@router/TagSettingRouter";
import { useAppToast } from "../common/Toast";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { parseTagGroupCreationErrorMessage } from "@shared/models/error";
export var TagGroupCreationScreen = React.memo(function () {
    var refreshSidebar = useContext(TagSettingRouterContext).refreshSidebar;
    var tagGroupStoreContext = useContext(TagGroupStoreContext);
    var intl = useIntl();
    var navigation = useNavigation();
    var showErrorToast = useAppToast().showErrorToast;
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _a = __read(useState(false), 2), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var handleSubmit = useCallback(function (formValues) {
        setIsSubmitting(true);
        tagGroupAPI
            .createTagGroup({
            name: formValues.name.trim(),
            mandatory: formValues.mandatory,
            public: formValues.public,
            visible: formValues.visible,
            definition: formValues.definitions,
        })
            .then(function (group) {
            tagGroupStoreContext.upsert([group]);
            refreshSidebar();
            navigation.push(ROUTES.settings.tags.root + "/" + group.id);
        })
            .catch(function (err) {
            var _a = __read(parseTagGroupCreationErrorMessage(err), 2), titleId = _a[0], descriptionId = _a[1];
            showErrorToast({
                titleId: titleId,
                descriptionId: descriptionId,
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    }, [
        tagGroupAPI,
        tagGroupStoreContext,
        refreshSidebar,
        navigation,
        showErrorToast,
    ]);
    var handleCancel = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.root);
        }
    }, [navigation]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tags.form.create.title", values: {
                        type: intl.formatMessage({
                            id: "settings.tags.title.tag_group",
                        }),
                    } }))),
        React.createElement("div", { className: "flex-1" },
            React.createElement(TagGroupForm, { isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleCancel }))));
});

import { __awaiter, __extends, __generator } from "tslib";
import React from "react";
import memoize from "memoize-one";
import { AuthContext } from "@shared/contexts/AuthContext";
import { STORAGE_KEYS } from "@constants/consts";
export { AuthContext };
var AuthContextProvider = /** @class */ (function (_super) {
    __extends(AuthContextProvider, _super);
    function AuthContextProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            accessToken: null,
            me: null,
            userPreference: null,
        };
        _this.setAccessToken = function (token) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (token === null) {
                    localStorage.removeItem(STORAGE_KEYS.AUTH_ACCESS_TOKEN);
                }
                else {
                    localStorage.setItem(STORAGE_KEYS.AUTH_ACCESS_TOKEN, token);
                }
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.setState({
                            accessToken: token,
                        }, function () {
                            resolve();
                        });
                    })];
            });
        }); };
        _this.readStoredAccessToken = function () { return __awaiter(_this, void 0, void 0, function () {
            var token;
            var _this = this;
            return __generator(this, function (_a) {
                token = localStorage.getItem(STORAGE_KEYS.AUTH_ACCESS_TOKEN);
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.setState({
                            accessToken: token,
                        }, function () {
                            resolve(token);
                        });
                    })];
            });
        }); };
        _this.setMe = function (me) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.setState({
                            me: me,
                        }, function () {
                            resolve();
                        });
                    })];
            });
        }); };
        _this.setUserPreference = function (userPreference) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.setState({
                            userPreference: userPreference,
                        }, function () {
                            resolve();
                        });
                    })];
            });
        }); };
        _this.contextValue = memoize(function (accessToken, me, userPreference, setAccessToken, readStoredAccessToken, setMe, setUserPreference) {
            return {
                accessToken: accessToken,
                me: me,
                userPreference: userPreference,
                setAccessToken: setAccessToken,
                readStoredAccessToken: readStoredAccessToken,
                setMe: setMe,
                setUserPreference: setUserPreference,
            };
        });
        return _this;
    }
    AuthContextProvider.prototype.render = function () {
        var _a = this.state, accessToken = _a.accessToken, me = _a.me, userPreference = _a.userPreference;
        var children = this.props.children;
        return (React.createElement(AuthContext.Provider, { value: this.contextValue(accessToken, me, userPreference, this.setAccessToken, this.readStoredAccessToken, this.setMe, this.setUserPreference) }, children));
    };
    return AuthContextProvider;
}(React.PureComponent));
export { AuthContextProvider };

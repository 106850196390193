import { __assign, __awaiter, __generator, __read } from "tslib";
import { ExclamationIcon } from "@heroicons/react/solid";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Dialog, useDialog } from "../common/Dialog";
import { DropDown } from "../common/DropDown";
import { useAppToast } from "../common/Toast";
import RequirePermission from "../common/RequirePermission";
import { Permission } from "@shared/models/auth";
function TagContextMenuImpl(props) {
    var _this = this;
    var typeId = props.typeId, item = props.item, itemEnableState = props.itemEnableState, onEdit_ = props.onEdit, onDelete_ = props.onDelete, onEnable_ = props.onEnable, onDisable_ = props.onDisable, onMerge_ = props.onMerge;
    var intl = useIntl();
    var showErrorToast = useAppToast().showErrorToast;
    var _a = __read(useState(false), 2), isDeleting = _a[0], setIsDeleting = _a[1];
    var _b = useDialog(), deleteTagDialogProps = _b.dialogProps, openDeleteTagDialog = _b.openDialog;
    var onEdit = useCallback(function () {
        onEdit_ === null || onEdit_ === void 0 ? void 0 : onEdit_(item);
    }, [item, onEdit_]);
    var onDelete = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var confirm, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!onDelete_) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, openDeleteTagDialog()];
                case 1:
                    confirm = _a.sent();
                    if (!confirm) {
                        return [2 /*return*/];
                    }
                    setIsDeleting(true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, onDelete_(item)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 4:
                    err_1 = _a.sent();
                    console.error("Failed to disable tag", err_1);
                    showErrorToast({
                        titleId: "settings.tags.delete_tag.error.title",
                        descriptionId: "settings.tags.delete_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 6];
                case 5:
                    setIsDeleting(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [item, onDelete_, setIsDeleting, showErrorToast, openDeleteTagDialog]);
    var onDisable = useCallback(function () {
        onDisable_ === null || onDisable_ === void 0 ? void 0 : onDisable_(item);
    }, [item, onDisable_]);
    var onEnable = useCallback(function () {
        onEnable_ === null || onEnable_ === void 0 ? void 0 : onEnable_(item);
    }, [item, onEnable_]);
    var onMerge = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            onMerge_ === null || onMerge_ === void 0 ? void 0 : onMerge_(item);
            return [2 /*return*/];
        });
    }); }, [item, onMerge_]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, __assign({}, deleteTagDialogProps, { title: intl.formatMessage({
                id: "settings.tags.delete_tag.confirmation.title",
            }, {
                tagName: item.name,
            }), description: intl.formatMessage({
                id: "settings.tags.delete_tag.confirmation.description",
            }, {
                tagName: item.name,
            }), confirmLabelId: "common.delete", cancelLabelId: "common.cancel", icon: React.createElement(ExclamationIcon, { className: "h-6 w-6 text-red-600", "aria-hidden": "true" }) })),
        React.createElement(DropDown.Button, { isMenuOpened: isDeleting ? isDeleting : undefined },
            React.createElement(RequirePermission, { permission: Permission.TagsUpdate },
                React.createElement(DropDown.Item, { onClick: onEdit, disabled: !onEdit_ || isDeleting },
                    React.createElement(FormattedMessage, { id: "settings.tags.edit_tag", values: {
                            type: intl.formatMessage({
                                id: typeId,
                            }),
                        } })),
                React.createElement(DropDown.Item, { onClick: itemEnableState ? onDisable : onEnable, disabled: !onDisable_ || !onEnable_ || isDeleting },
                    React.createElement(FormattedMessage, { id: itemEnableState
                            ? "settings.tags.disable_tag"
                            : "settings.tags.enable_tag", values: {
                            type: intl.formatMessage({
                                id: typeId,
                            }),
                        } })),
                React.createElement(DropDown.Item, { onClick: onMerge, disabled: !onMerge_ || isDeleting },
                    React.createElement(FormattedMessage, { id: "settings.tags.merge_tag", values: {
                            type: intl.formatMessage({
                                id: typeId,
                            }),
                        } }))),
            React.createElement(RequirePermission, { permission: Permission.TagsDelete },
                React.createElement(DropDown.Separator, null),
                React.createElement(DropDown.Item, { onClick: onDelete, disabled: !onDelete_ || isDeleting },
                    React.createElement(FormattedMessage, { id: "settings.tags.delete_tag", values: {
                            type: intl.formatMessage({
                                id: typeId,
                            }),
                        } }))))));
}
export var TagContextMenu = React.memo(TagContextMenuImpl);

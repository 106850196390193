import { __read } from "tslib";
import { useEffect, useState } from "react";
export function useDebounce(value, delay) {
    var _a = __read(useState(value), 2), debouncedValue = _a[0], setDebouncedValue = _a[1];
    useEffect(function () {
        var timeout = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        return function () {
            clearTimeout(timeout);
        };
    }, [value, delay]);
    return debouncedValue;
}

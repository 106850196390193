import { __awaiter, __generator, __read } from "tslib";
import React, { useMemo, useContext, useEffect, useState, useCallback, } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@router/routes";
import { AuthAPIContext, UserAPIContext, APIContext, APIAuthenticationError, } from "@shared/contexts/api";
import { AuthContext } from "@contexts/AuthContext";
import { useAppToast } from "@components/common/Toast";
export var AppContext = React.createContext(undefined);
export var AppContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var history = useHistory();
    var apiContext = useContext(APIContext);
    var authContext = useContext(AuthContext);
    var authAPI = useContext(AuthAPIContext);
    var userAPI = useContext(UserAPIContext);
    var showErrorToast = useAppToast().showErrorToast;
    var apiEventEmitter = apiContext.eventEmitter;
    var _b = __read(useState(false), 2), initialized = _b[0], setInitialzied = _b[1];
    var logout = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, authAPI.revoke().catch(function () { })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, authContext.setAccessToken(null)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, authContext.setMe(null)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, authContext.setUserPreference(null)];
                case 4:
                    _a.sent();
                    history.push(ROUTES.login);
                    return [2 /*return*/];
            }
        });
    }); }, [authAPI, authContext, history]);
    var value = useMemo(function () { return ({
        logout: logout,
    }); }, [logout]);
    var restoreSession = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, meResp, preference, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 7, , 9]);
                    return [4 /*yield*/, authContext.readStoredAccessToken()];
                case 1:
                    token = _b.sent();
                    if (!token) return [3 /*break*/, 6];
                    return [4 /*yield*/, authAPI.me()];
                case 2:
                    meResp = _b.sent();
                    return [4 /*yield*/, userAPI.getUserPreference(meResp.user.id)];
                case 3:
                    preference = _b.sent();
                    return [4 /*yield*/, authContext.setMe({
                            user: meResp.user,
                            permissions: meResp.permissions,
                            identity: meResp.identity,
                        })];
                case 4:
                    _b.sent();
                    return [4 /*yield*/, authContext.setUserPreference(preference)];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6: return [3 /*break*/, 9];
                case 7:
                    _a = _b.sent();
                    return [4 /*yield*/, authContext.setAccessToken(null)];
                case 8:
                    _b.sent();
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }); }, [authContext, authAPI, userAPI]);
    useEffect(function () {
        void (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 2, 3]);
                        return [4 /*yield*/, restoreSession()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        setInitialzied(true);
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        function onAPIError(e) {
            if (e instanceof APIAuthenticationError) {
                showErrorToast({ titleId: "common.errors.auth.session_expired" });
                logout().catch(function (e) { return console.warn("Logout failed:", e); });
            }
        }
        apiEventEmitter.addListener("error", onAPIError);
        return function () {
            apiEventEmitter.removeListener("error", onAPIError);
        };
    }, [apiEventEmitter, logout, showErrorToast]);
    return (React.createElement(AppContext.Provider, { value: value }, initialized ? children : null));
});

import { __assign } from "tslib";
import * as yup from "yup";
export var threadDetailFormSchema = yup.object().shape({
    companies: yup.object().shape({
        data: yup.array(yup.object().defined()).defined(),
    }),
    contacts: yup.object().shape({
        data: yup.array(yup.object().defined()).defined(),
    }),
    deals: yup.object().shape({
        data: yup.array(yup.object().defined()).defined(),
    }),
    priorities: yup.object().shape({
        data: yup.array(yup.object().defined()).defined(),
    }),
    otherTags: yup.object().shape({
        data: yup.array(yup.object().defined()).defined(),
    }),
    sharedWiths: yup.object().shape({
        data: yup.array(yup.object().defined()).defined(),
    }),
    departments: yup.object().shape({
        data: yup.array(yup.object().defined()).defined(),
    }),
    customTags: yup.lazy(function (o) {
        return yup
            .object()
            .shape(Object.keys(o).reduce(function (prev, key) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[key] = yup.array(yup.object().defined()).defined(), _a)));
        }, {}))
            .defined();
    }),
});
export var threadRemarksFormSchema = yup.object().shape({
    remark: yup.string().defined(),
});

import * as yup from "yup";
import { paginatedSearchRequest, generatePaginationSchema, } from "../pagination";
import { colorSchema } from "./color_api";
import { toggleableParamSchema } from "./params";
export var otherTagSchema = yup
    .object({
    id: yup.number().defined(),
    name: yup.string().defined(),
    isEnabled: yup.boolean().defined(),
    fullName: yup.string().defined().nullable(),
    color: colorSchema.defined().nullable(),
    remarks: yup.string().defined().nullable(),
    additional: yup.object(),
})
    .camelCase()
    .defined();
export var listOtherTagRequestSchema = yup
    .object()
    .concat(paginatedSearchRequest)
    .concat(toggleableParamSchema);
export var listOtherTagResponseSchema = generatePaginationSchema(otherTagSchema);
export var createOtherTagRequestSchema = yup
    .object()
    .shape({
    name: yup.string().required(),
    full_name: yup.string().defined().nullable(),
    remarks: yup.string().defined().nullable(),
    color_id: yup.number().defined().nullable(),
    additional: yup.object().defined().nullable(),
})
    .snakeCase()
    .defined();
export var editOtherTagRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    full_name: yup.string().defined().nullable(),
    remarks: yup.string().defined().nullable(),
    color_id: yup.number().defined().nullable(),
    additional: yup.object().defined().nullable(),
})
    .snakeCase()
    .defined();

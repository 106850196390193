import { __assign, __read } from "tslib";
import React, { useMemo, useState, useCallback, useContext, useEffect, } from "react";
import { AuthContext } from "@contexts/AuthContext";
export var FilterContext = React.createContext(undefined);
export var FilterContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var userPreference = useContext(AuthContext).userPreference;
    var _b = __read(useState(false), 2), isFilterPanelOpened = _b[0], setFilterPanelOpened = _b[1];
    var _c = __read(useState(false), 2), isMoreFilterPanelOpened = _c[0], setMoreFilterPanelOpened = _c[1];
    var _d = __read(useState(function () { return ({
        dateRange: { start: null, end: null },
        from: [],
        toOrCc: [],
        uploaders: [],
        contacts: [],
        companies: [],
        deals: [],
        priorities: [],
        otherTags: [],
        customTags: [],
        industries: [],
        countries: [],
        regions: [],
    }); }), 2), appliedFilters = _d[0], setAppliedFilters = _d[1];
    var openFilterPanel = useCallback(function () { return setFilterPanelOpened(true); }, []);
    var closeFilterPanel = useCallback(function () {
        setFilterPanelOpened(false);
        setMoreFilterPanelOpened(false);
    }, []);
    var openMoreFilterPanel = useCallback(function () {
        setFilterPanelOpened(true);
        setMoreFilterPanelOpened(true);
    }, []);
    var closeMoreFilterPanel = useCallback(function () { return setMoreFilterPanelOpened(false); }, []);
    var clearAllFilters = useCallback(function () {
        setAppliedFilters({
            dateRange: { start: null, end: null },
            from: [],
            toOrCc: [],
            uploaders: [],
            contacts: [],
            companies: [],
            deals: [],
            priorities: [],
            otherTags: [],
            customTags: [],
            industries: [],
            countries: [],
            regions: [],
        });
    }, []);
    var setAppliedFilter = useCallback(function (partialFilters) {
        setAppliedFilters(function (prev) { return (__assign(__assign({}, prev), partialFilters)); });
    }, []);
    var addContact = useCallback(function (contact) {
        setAppliedFilters(function (prev) { return (__assign(__assign({}, prev), { contacts: prev.contacts
                .filter(function (c) { return c.id !== contact.id; })
                .concat([contact]) })); });
    }, []);
    var addCompany = useCallback(function (company) {
        setAppliedFilters(function (prev) { return (__assign(__assign({}, prev), { companies: prev.companies
                .filter(function (c) { return c.id !== company.id; })
                .concat([company]) })); });
    }, []);
    var addDeal = useCallback(function (deal) {
        setAppliedFilters(function (prev) { return (__assign(__assign({}, prev), { deals: prev.deals.filter(function (d) { return d.id !== deal.id; }).concat([deal]) })); });
    }, []);
    var addPriority = useCallback(function (priority) {
        setAppliedFilters(function (prev) { return (__assign(__assign({}, prev), { priorities: prev.priorities
                .filter(function (p) { return p.id !== priority.id; })
                .concat([priority]) })); });
    }, []);
    var addOtherTag = useCallback(function (otherTag) {
        setAppliedFilters(function (prev) { return (__assign(__assign({}, prev), { otherTags: prev.otherTags
                .filter(function (o) { return o.id !== otherTag.id; })
                .concat([otherTag]) })); });
    }, []);
    var addCustomTag = useCallback(function (tag) {
        setAppliedFilters(function (prev) { return (__assign(__assign({}, prev), { customTags: prev.customTags.filter(function (t) { return t.id !== tag.id; }).concat([tag]) })); });
    }, []);
    useEffect(function () {
        if (!userPreference)
            return;
        setAppliedFilters(function (prev) { return (__assign(__assign({}, prev), { priorities: userPreference.priorities })); });
    }, [userPreference]);
    var values = useMemo(function () {
        return {
            isFilterPanelOpened: isFilterPanelOpened,
            isMoreFilterPanelOpened: isMoreFilterPanelOpened,
            openFilterPanel: openFilterPanel,
            closeFilterPanel: closeFilterPanel,
            openMoreFilterPanel: openMoreFilterPanel,
            closeMoreFilterPanel: closeMoreFilterPanel,
            clearAllFilters: clearAllFilters,
            appliedFilters: appliedFilters,
            setAppliedFilter: setAppliedFilter,
            addCompany: addCompany,
            addContact: addContact,
            addDeal: addDeal,
            addPriority: addPriority,
            addOtherTag: addOtherTag,
            addCustomTag: addCustomTag,
        };
    }, [
        addCompany,
        addContact,
        addCustomTag,
        addDeal,
        addOtherTag,
        addPriority,
        appliedFilters,
        clearAllFilters,
        closeFilterPanel,
        closeMoreFilterPanel,
        isFilterPanelOpened,
        isMoreFilterPanelOpened,
        openFilterPanel,
        openMoreFilterPanel,
        setAppliedFilter,
    ]);
    return (React.createElement(FilterContext.Provider, { value: values }, children));
});

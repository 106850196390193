import { __read } from "tslib";
import React, { useMemo, useState, useCallback, useEffect, useReducer, } from "react";
import cn from "classnames";
import { useCollapsibleListContext } from "./CollapsibleListContext";
import listCollapsedIcon from "@assets/ic-list-collapsed.svg";
import listExpandedIcon from "@assets/ic-list-expanded.svg";
import { Waypoint } from "react-waypoint";
import { Spinner } from "@components/common/Spinner";
// eslint-disable-next-line prefer-const
var CollapsibleListItem;
var CollapsibleListItemImpl = function (_a) {
    var className = _a.className, itemIndex = _a.itemIndex, listState = _a.listState, isExpanded = _a.isExpanded, onClick = _a.onClick, depth = _a.depth;
    var _b = useCollapsibleListContext(), labelExtractor = _b.labelExtractor, badgeTextExtractor = _b.badgeTextExtractor, onItemSelected = _b.onItemSelected, onFetchData = _b.onFetchData, childrenMap = _b.childrenMap, selectedItemKey = _b.selectedItemKey, keyExtractor = _b.keyExtractor, onItemShow = _b.onItemShow, onItemHide = _b.onItemHide;
    var _c = __read(useState(null), 2), expandedItemIdx = _c[0], setExpandedItemIdx = _c[1];
    var children = useMemo(function () { return childrenMap.get(listState.item); }, [childrenMap, listState.item]);
    var onClickChild = useCallback(function (item, idx) {
        if (item.isToggleable) {
            setExpandedItemIdx(function (prevIdx) {
                if (prevIdx === idx)
                    return null;
                return idx;
            });
        }
        if (item.item !== null) {
            onItemSelected === null || onItemSelected === void 0 ? void 0 : onItemSelected(item.item);
        }
    }, [onItemSelected]);
    var onClickThis = useCallback(function (e) {
        e.stopPropagation();
        e.preventDefault();
        onClick === null || onClick === void 0 ? void 0 : onClick(listState, itemIndex);
    }, [itemIndex, listState, onClick]);
    var onLoadMore = useCallback(function () {
        if (listState.fetchNextParams === undefined)
            return;
        onFetchData(listState);
    }, [listState, onFetchData]);
    var label = useMemo(function () {
        if (listState.item === null)
            return "";
        return labelExtractor(listState.item);
    }, [listState, labelExtractor]);
    var hasSubList = useMemo(function () {
        if (listState.isToggleable)
            return true;
        if (children !== undefined)
            return true;
        return false;
    }, [children, listState.isToggleable]);
    var _d = __read(useReducer(function (prev) { return prev + 1; }, 0), 2), endReachedKey = _d[0], checkEndReached = _d[1];
    useEffect(function () {
        checkEndReached();
    }, [listState]);
    useEffect(function () {
        var item = listState.item;
        if (!item)
            return undefined;
        onItemShow(item);
        return function () {
            onItemHide(item);
        };
    }, [listState.item, onItemShow, onItemHide]);
    var itemStyle = useMemo(function () {
        return {
            paddingLeft: depth * 16,
        };
    }, [depth]);
    var isSelected = useMemo(function () {
        if (listState.item === null)
            return false;
        return keyExtractor(listState.item) === selectedItemKey;
    }, [keyExtractor, listState.item, selectedItemKey]);
    var badgeText = useMemo(function () {
        var _a;
        if (!listState.item)
            return null;
        return (_a = badgeTextExtractor === null || badgeTextExtractor === void 0 ? void 0 : badgeTextExtractor(listState.item)) !== null && _a !== void 0 ? _a : null;
    }, [listState.item, badgeTextExtractor]);
    return (React.createElement("li", { className: cn("block relative cursor-pointer", className) },
        React.createElement("button", { type: "button", className: cn("block w-full h-9 flex items-center text-gray-600 pr-2", "hover:bg-gray-50 hover:text-gray-900", isSelected && "bg-gray-100"), style: itemStyle, onClick: onClickThis },
            React.createElement("img", { className: cn("object-contain mr-2 h-2 w-2 mx-1.5", !hasSubList && "opacity-0"), src: isExpanded ? listExpandedIcon : listCollapsedIcon }),
            React.createElement("p", { className: "text-left text-sm font-medium flex-1 truncate" }, label),
            badgeText && (React.createElement("div", { className: "flex-none flex items-center justify-center min-w-[2rem] bg-gray-300 px-3 rounded-full text-gray-700 text-xs leading-none h-5" }, badgeText))),
        hasSubList && isExpanded && (React.createElement("ul", null, children === null || children === void 0 ? void 0 :
            children.map(function (item, idx) {
                return (React.createElement(CollapsibleListItem, { isExpanded: expandedItemIdx === idx, listState: item, itemIndex: idx, key: idx, onClick: onClickChild, depth: depth + 1 }));
            }),
            listState.isFetchingMore && (React.createElement("li", { className: "block p-2" },
                React.createElement(Spinner, { size: "small" }))),
            listState.hasMore && (React.createElement(Waypoint, { key: endReachedKey, onEnter: onLoadMore },
                React.createElement("li", null)))))));
};
CollapsibleListItem = React.memo(CollapsibleListItemImpl);
export { CollapsibleListItem };

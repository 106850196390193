import * as yup from "yup";
import { UploadTypeValues } from "../upload_type";
import { contactSchema } from "./contact_api";
import { companySchema } from "./company_api";
import { prioritySchema } from "./priority_api";
import { tagSchema } from "./tag_api";
import { dealSchema } from "./deal_api";
import { userSchema } from "./user_api";
import { departmentSchema } from "./department_api";
import { otherTagSchema } from "./other_tag_api";
export var unknownContactSchema = yup
    .object({
    name: yup.string().defined(),
    email: yup.string().defined(),
    company: companySchema.defined().nullable(),
})
    .defined();
export var getMessageByIdQuerySchema = yup
    .object({
    tracking_id: yup.string().optional(),
})
    .snakeCase()
    .defined();
export var getMessageByIdResponseSchema = yup
    .object({
    contacts: yup.array(contactSchema).defined(),
    unknownContacts: yup.array(unknownContactSchema).defined(),
    companies: yup.array(companySchema).defined(),
    deals: yup.array(dealSchema).defined(),
    priorities: yup.array(prioritySchema).defined(),
    otherTags: yup.array(otherTagSchema).defined(),
    tags: yup.array(tagSchema).defined(),
    shareWiths: yup.array(userSchema).defined(),
    departments: yup.array(departmentSchema).defined(),
    isVisible: yup.boolean().defined(),
    isDraft: yup.boolean().defined(),
    isThreadTracked: yup.boolean().defined(),
})
    .camelCase()
    .defined();
export var setMessageTagsResponseSchema = yup
    .object({
    contacts: yup.array(contactSchema).defined(),
    companies: yup.array(companySchema).defined(),
    deals: yup.array(dealSchema).defined(),
    priorities: yup.array(prioritySchema).defined(),
    otherTags: yup.array(otherTagSchema).defined(),
    tags: yup.array(tagSchema).defined(),
    shareWiths: yup.array(userSchema).defined(),
    departments: yup.array(departmentSchema).defined(),
})
    .camelCase()
    .defined();
export var setMessageTagsRequestSchema = yup
    .object({
    contact_ids: yup.array(yup.number().defined()).defined(),
    company_ids: yup.array(yup.number().defined()).defined(),
    deal_ids: yup.array(yup.number().defined()).defined(),
    priority_ids: yup.array(yup.number().defined()).defined(),
    other_tag_ids: yup.array(yup.number().defined()).defined(),
    tag_ids: yup.array(yup.number().defined()).defined(),
    share_with_ids: yup.array(yup.number().defined()).defined(),
    department_ids: yup.array(yup.number().defined()).defined(),
    tracking_id: yup.string().optional().nullable(),
})
    .snakeCase()
    .defined();
export var uploadMessageRequestSchema = yup
    .object({
    type: yup.string().defined().oneOf(UploadTypeValues),
    remark: yup.string().defined().nullable(),
    tracking_id: yup.string().optional().nullable(),
})
    .snakeCase()
    .defined();
export var removeMessageRequestSchema = yup
    .object({
    type: yup.string().defined().oneOf(UploadTypeValues),
    tracking_id: yup.string().optional().nullable(),
})
    .snakeCase()
    .defined();

import { __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { IndustryAPIContext } from "@shared/contexts/api/IndustryAPIContext";
import { ROUTES } from "@router/routes";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { useAppToast } from "../common/Toast";
import { TagDetailHeader } from "../settings/TagDetailHeader";
import { TagDetailInfo } from "../settings/TagDetailInfo";
import { useNavigation } from "@hooks/useNavigation";
import { getTagGroupCustomFieldsEntries, } from "@shared/models/tag";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { MergeIndustryDialog } from "@components/mergeTag/MergeIndustryDialog";
export var IndustryTagDetailScreen = React.memo(function () {
    var _a, _b, _c, _d, _e;
    var tagId = useParams().tagId;
    var industryId = useMemo(function () { return parseInt(tagId, 10); }, [tagId]);
    var navigation = useNavigation();
    var location = useLocation();
    var showErrorToast = useAppToast().showErrorToast;
    var industryAPI = useContext(IndustryAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _f = __read(useState(null), 2), industry = _f[0], setIndustry = _f[1];
    var _g = __read(useState(false), 2), isLoading = _g[0], setIsLoading = _g[1];
    var _h = __read(useState([]), 2), availableCustomProperties = _h[0], setAvailableCustomProperties = _h[1];
    var _j = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _j[0], setTagName = _j[1];
    var goGroupDetail = useCallback(function () {
        navigation.replace(ROUTES.settings.tags.industry.group_detail);
    }, [navigation]);
    var refreshPageData = useCallback(function () {
        setIsLoading(true);
        Promise.all([
            industryAPI.getIndustry(industryId),
            tagGroupAPI.getTagGroupBySlug("industry"),
        ])
            .then(function (_a) {
            var _b = __read(_a, 2), industry = _b[0], tagGroup = _b[1];
            setIndustry(industry);
            setTagName(industry.name);
            setAvailableCustomProperties(getTagGroupCustomFieldsEntries(tagGroup.definition).map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return ({
                    name: value.name,
                    value: industry.additional[key],
                });
            }));
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            goGroupDetail();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [industryAPI, industryId, goGroupDetail, showErrorToast, tagGroupAPI]);
    var onEditTag = useCallback(function () {
        navigation.push(ROUTES.settings.tags.industry.group_detail + "/" + industryId + "/edit");
    }, [navigation, industryId]);
    var onEnableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, industryAPI.enableIndustry(industryId)];
        });
    }); }, [industryId, industryAPI]);
    var onDisableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, industryAPI.disableIndustry(industryId)];
        });
    }); }, [industryId, industryAPI]);
    var onDeleteTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, industryAPI.deleteIndustry(industryId).then(function () {
                    goGroupDetail();
                })];
        });
    }); }, [industryId, industryAPI, goGroupDetail]);
    var _k = __read(useState(null), 2), mergeItem = _k[0], setMergeItem = _k[1];
    var onCloseMergeDialog = useCallback(function () { return setMergeItem(null); }, []);
    var onStartMerge = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, setMergeItem(industry)];
    }); }); }, [industry]);
    useEffect(function () {
        refreshPageData();
    }, [
        industryId,
        industryAPI,
        tagGroupAPI,
        navigation,
        setIsLoading,
        showErrorToast,
        setAvailableCustomProperties,
        refreshPageData,
    ]);
    return (React.createElement("div", { className: "flex flex-col min-w-0 flex-1" },
        mergeItem && (React.createElement(MergeIndustryDialog, { destination: mergeItem, onClose: onCloseMergeDialog, onDone: refreshPageData })),
        React.createElement(TagDetailHeader, { typeId: "settings.tags.title.industry", tagName: tagName, isTagEnabled: (_c = industry === null || industry === void 0 ? void 0 : industry.isEnabled) !== null && _c !== void 0 ? _c : true, onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onStartMerge, onGoBack: goGroupDetail }),
        React.createElement("div", { className: "flex-1 mt-2 overflow-y-auto md:mt-0 md:p-4" },
            React.createElement(TagDetailInfo.Panel, { isLoading: isLoading },
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.industry.form.full_name" }), value: (_d = industry === null || industry === void 0 ? void 0 : industry.fullName) !== null && _d !== void 0 ? _d : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.industry.form.remarks" }), value: (_e = industry === null || industry === void 0 ? void 0 : industry.remarks) !== null && _e !== void 0 ? _e : "" }),
                availableCustomProperties.map(function (value, index) { return (React.createElement(TagDetailInfo.Item, { key: index, title: value.name, value: value.value })); })))));
});

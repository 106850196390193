import { __read } from "tslib";
import React, { useState, useMemo, useEffect } from "react";
export var TagIndexContext = React.createContext(undefined);
export var TagIndexContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var _b = __read(useState(null), 2), item = _b[0], setItem = _b[1];
    var _c = __read(useState(false), 2), isTagIndexPanelOpened = _c[0], setTagIndexPanelOpened = _c[1];
    var _d = __read(useState("external"), 2), selectedTab = _d[0], setSelectedTab = _d[1];
    useEffect(function () {
        setItem(null);
    }, [selectedTab]);
    useEffect(function () {
        if (!isTagIndexPanelOpened) {
            setItem(null);
        }
    }, [isTagIndexPanelOpened]);
    var value = useMemo(function () {
        return {
            selectedItem: item,
            selectItem: setItem,
            selectedTab: selectedTab,
            setSelectedTab: setSelectedTab,
            isTagIndexPanelOpened: isTagIndexPanelOpened,
            setTagIndexPanelOpened: setTagIndexPanelOpened,
        };
    }, [isTagIndexPanelOpened, item, selectedTab]);
    return (React.createElement(TagIndexContext.Provider, { value: value }, children));
});

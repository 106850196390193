import { __awaiter, __generator } from "tslib";
import React, { useContext, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { FilterContext } from "@contexts/FilterContext";
import { OtherTagAPIContext } from "@shared/contexts/api";
import { OtherTagExtractors } from "@shared/models/other_tag";
import { CollapsibleInlineFilter } from "./CollapsibleInlineFilter";
export var OthersFilter = React.memo(function (props) {
    var filterKey = props.filterKey, isCollapsed = props.isCollapsed, onToggle = props.onToggle;
    var intl = useIntl();
    var otherAPI = useContext(OtherTagAPIContext);
    var _a = useContext(FilterContext), appliedFilters = _a.appliedFilters, setAppliedFilter = _a.setAppliedFilter;
    var fetchOptions = useCallback(function (page, perPage, options) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, otherAPI.listOtherTags({
                    page: page,
                    perPage: perPage,
                    search: options.search,
                })];
        });
    }); }, [otherAPI]);
    var onSelect = useCallback(function (items) {
        setAppliedFilter({ otherTags: items });
    }, [setAppliedFilter]);
    var title = useMemo(function () {
        return intl.formatMessage({ id: "common.tags.others" });
    }, [intl]);
    var searchPlaceholder = useMemo(function () {
        return intl.formatMessage({ id: "common.search" });
    }, [intl]);
    var onToggleFilter = useCallback(function () {
        onToggle(filterKey);
    }, [filterKey, onToggle]);
    return (React.createElement(CollapsibleInlineFilter, { isCollapsed: isCollapsed, onToggle: onToggleFilter, selected: appliedFilters.otherTags, onSelect: onSelect, getOptions: fetchOptions, title: title, searchPlaceholder: searchPlaceholder, labelExtractor: OtherTagExtractors.name, descriptionExtractor: OtherTagExtractors.fullName, keyExtractor: OtherTagExtractors.id }));
});

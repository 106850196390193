import * as yup from "yup";
export var Identity;
(function (Identity) {
    Identity["Default"] = "default";
    Identity["DepartmentAdmin"] = "department_admin";
    Identity["DepartmentHead"] = "department_head";
    Identity["SystemAdmin"] = "system_admin";
})(Identity || (Identity = {}));
export var identitySchema = yup
    .mixed()
    .oneOf(Object.values(Identity));
export var Permission;
(function (Permission) {
    Permission["TagsPublicCreate"] = "tags.public.create";
    Permission["TagsPrivateCreate"] = "tags.private.create";
    Permission["TagsRead"] = "tags.read";
    Permission["TagsUpdate"] = "tags.update";
    Permission["TagsDelete"] = "tags.delete";
    Permission["TagGroupsCreate"] = "tagGroups.create";
    Permission["TagGroupsRead"] = "tagGroups.read";
    Permission["TagGroupsUpdate"] = "tagGroups.update";
    Permission["TagGroupsDelete"] = "tagGroups.delete";
    Permission["DepartmentsCreate"] = "departments.create";
    Permission["DepartmentsRead"] = "departments.read";
    Permission["DepartmentsUpdate"] = "departments.update";
    Permission["UsersRead"] = "users.read";
    Permission["UsersUpdate"] = "users.update";
    Permission["MeRead"] = "me.read";
    Permission["MeUpdate"] = "me.update";
    Permission["ColorsRead"] = "colors.read";
    Permission["DocumentsCreate"] = "documents.create";
    Permission["DocumentsRead"] = "documents.read";
    Permission["DocumentsUpdate"] = "documents.update";
    Permission["DocumentsDelete"] = "documents.delete";
    Permission["FilesUpload"] = "files.upload";
    Permission["FilesDownload"] = "files.download";
    Permission["MessagesCreate"] = "messages.create";
    Permission["MessagesRead"] = "messages.read";
    Permission["MessagesUpdate"] = "messages.update";
    Permission["MessagesDelete"] = "messages.delete";
    Permission["ThreadsRead"] = "threads.read";
    Permission["ThreadsUpdate"] = "threads.update";
})(Permission || (Permission = {}));
export var permissionSchema = yup
    .mixed()
    .oneOf(Object.values(Permission));

import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { listUserRequestSchema, listUserResponseSchema, userPreferenceSchema, editUserPreferenceRequestSchema, getUserResponseSchema, updateUserRequestSchema, updateUserResponseSchema, updateUserIsEnabledRequestSchema, updateUserIsEnabledResponseSchema, } from "@shared/models/api/user_api";
export var UserAPIContext = React.createContext(undefined);
export var UserAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getUserPreference = useCallback(function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("users/" + id + "/preference")];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, userPreferenceSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var listUsers = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listUserRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("users", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listUserResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updateUserPreference = useCallback(function (id, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = editUserPreferenceRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.put("users/" + id + "/preference", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, userPreferenceSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var getUser = useCallback(function (userId) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("users/" + userId.toString(10))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, getUserResponseSchema.validateSync(res).user];
            }
        });
    }); }, [apiContext]);
    var updateUser = useCallback(function (userId, req) { return __awaiter(void 0, void 0, void 0, function () {
        var body, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = updateUserRequestSchema.validateSync(req);
                    return [4 /*yield*/, apiContext.put("users/" + userId.toString(10), body)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, updateUserResponseSchema.validateSync(res).user];
            }
        });
    }); }, [apiContext]);
    var updateUserIsEnabled = useCallback(function (userId, req) { return __awaiter(void 0, void 0, void 0, function () {
        var body, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = updateUserIsEnabledRequestSchema.validateSync(req);
                    return [4 /*yield*/, apiContext.put("users/" + userId.toString(10) + "/is_enabled", body)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, updateUserIsEnabledResponseSchema.validateSync(res).user];
            }
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            getUserPreference: getUserPreference,
            listUsers: listUsers,
            updateUserPreference: updateUserPreference,
            getUser: getUser,
            updateUser: updateUser,
            updateUserIsEnabled: updateUserIsEnabled,
        };
    }, [
        getUser,
        getUserPreference,
        listUsers,
        updateUser,
        updateUserIsEnabled,
        updateUserPreference,
    ]);
    return (React.createElement(UserAPIContext.Provider, { value: value }, children));
});

import { __read, __spreadArray } from "tslib";
import { FormField } from "@components/form/FormField";
import { TextField } from "@components/form/TextField";
import { UserExtractors } from "@shared/models/user";
import { Formik, useFormikContext } from "formik";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { FormShell } from "./FormShell";
import { allStaffFormRoleOptions, staffFormSchema, } from "./models";
import cn from "classnames";
import { Persona, PersonaSize } from "@fluentui/react";
import { FilePickerButton } from "@components/common/FilePickerButton";
import { extractInitials } from "@utils/avatar";
import { SelectMenu } from "@components/form/SelectMenu";
import { PeoplePicker } from "@components/common/PeoplePicker";
import { useAppToast } from "@components/common/Toast";
// eslint-disable-next-line complexity
var FormView = React.memo(function (props) {
    var _a;
    var user = props.user, avatarUrl = props.avatarUrl, isSubmitting = props.isSubmitting, onCancel = props.onCancel, onAvatarFileChange = props.onAvatarFileChange, onFileBadMimeType = props.onFileBadMimeType, departmentOptions = props.departmentOptions, onDepartmentChange = props.onDepartmentChange, onRoleChange = props.onRoleChange, fetchUserSuggestion = props.fetchUserSuggestion, onManagersChange = props.onManagersChange;
    var _b = __read(useState(departmentOptions), 2), departmentOptionItems = _b[0], setDepartmentOptionItems = _b[1];
    var _c = useFormikContext(), handleSubmit = _c.handleSubmit, touched = _c.touched, errors = _c.errors, handleBlur = _c.handleBlur, handleChange = _c.handleChange, values = _c.values;
    var intl = useIntl();
    var departmentKeyExtractor = useCallback(function (option) {
        var _a;
        return (_a = option === null || option === void 0 ? void 0 : option.id) !== null && _a !== void 0 ? _a : "null";
    }, []);
    var departmentLabelExtractor = useCallback(function (option) {
        var _a;
        return ((_a = option === null || option === void 0 ? void 0 : option.name) !== null && _a !== void 0 ? _a : intl.formatMessage({ id: "settings.staffEdit.noDepartment" }));
    }, [intl]);
    var roleKeyExtractor = useCallback(function (option) {
        return option;
    }, []);
    var roleLabelExtractor = useCallback(function (option) {
        switch (option) {
            case "head":
                return intl.formatMessage({
                    id: "settings.staffDetail.roles.head",
                });
            case "admin":
                return intl.formatMessage({
                    id: "settings.staffDetail.roles.admin",
                });
            case "staff":
                return intl.formatMessage({
                    id: "settings.staffDetail.roles.staff",
                });
            default:
                return option;
        }
    }, [intl]);
    var onDepartmentSearch = useCallback(function (term) {
        if (term) {
            setDepartmentOptionItems(departmentOptions.filter(function (i) {
                return departmentLabelExtractor(i)
                    .toLowerCase()
                    .includes(term.toLowerCase());
            }));
        }
        else {
            setDepartmentOptionItems(departmentOptions);
        }
    }, [departmentLabelExtractor, departmentOptions]);
    return (React.createElement(FormShell, { isSubmitting: isSubmitting, onCancel: onCancel, onSubmit: handleSubmit, cancelLabel: intl.formatMessage({ id: "common.cancel" }), submitLabel: intl.formatMessage({
            id: "settings.staffEdit.submit",
        }) },
        React.createElement(FormField, { titleId: "settings.staffDetail.fullName", errorId: touched.fullName ? errors.fullName : undefined, isOptional: false },
            React.createElement(TextField, { name: "fullName", onChange: handleChange, onBlur: handleBlur, value: values.fullName, isError: touched.fullName && errors.fullName != null, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
        user.emails.length > 0 ? (React.createElement(FormField, { titleId: "settings.staffDetail.email", isOptional: false },
            React.createElement("p", { className: "mt-1 text-sm leading-5 font-normal text-gray-900 md:mt-0 whitespace-pre-wrap break-all" }, user.emails.join(",\n")))) : (
        // not AD user, show username instead
        React.createElement(FormField, { titleId: "settings.staffDetail.username", isOptional: false },
            React.createElement("p", { className: "mt-1 text-sm leading-5 font-normal text-gray-900 md:mt-0 break-all" }, user.username))),
        React.createElement(FormField, { titleId: "settings.staffDetail.profilePicture", errorId: touched.avatar ? errors.avatar : undefined, isOptional: true },
            React.createElement("div", { className: "flex flex-row items-end" },
                React.createElement(Persona, { className: "mr-4", imageUrl: avatarUrl, imageInitials: extractInitials(values.fullName, 2), hidePersonaDetails: true, size: PersonaSize.size56 }),
                React.createElement(FilePickerButton, { multiple: false, acceptedMimeTypes: ["image/jpeg", "image/png"], onFileSelect: onAvatarFileChange, onFileBadMimeType: onFileBadMimeType, uploadButtonLabelId: "settings.staffEdit.uploadImage" }))),
        React.createElement(FormField, { titleId: "settings.staffDetail.department", errorId: touched.departmentId ? errors.departmentId : undefined, isOptional: true },
            React.createElement(SelectMenu, { className: "h-[2.375rem] w-full md:max-w-xs", selected: (_a = departmentOptions.find(function (d) { return d.id === values.departmentId; })) !== null && _a !== void 0 ? _a : null, onSelect: onDepartmentChange, multiple: false, items: __spreadArray([null], __read(departmentOptionItems)), isError: touched.departmentId && errors.departmentId != null, keyExtractor: departmentKeyExtractor, labelExtractor: departmentLabelExtractor, onSearch: onDepartmentSearch })),
        React.createElement(FormField, { titleId: "settings.staffEdit.role", errorId: touched.role ? errors.role : undefined, isOptional: false, className: cn(values.departmentId == null && "hidden") },
            React.createElement(SelectMenu, { className: "h-[2.375rem] w-full md:max-w-xs", selected: values.role, onSelect: onRoleChange, multiple: false, items: allStaffFormRoleOptions, isError: touched.role && errors.role != null, keyExtractor: roleKeyExtractor, labelExtractor: roleLabelExtractor })),
        React.createElement(FormField, { titleId: "settings.staffDetail.lineManager", errorId: touched.managers
                ? Array.isArray(errors.managers)
                    ? // a typing mess, no time to handle, display generic error for now
                        "settings.staffEdit.invalidUser"
                    : errors.managers
                : undefined, isOptional: true },
            React.createElement(PeoplePicker, { className: "w-full md:max-w-xs", inputClassName: "!h-[2.375rem]", defaultSelectedItems: values.managers, keyExtractor: UserExtractors.id, labelExtractor: UserExtractors.name, avatarExtractor: UserExtractors.avatarUrl, fetchTags: fetchUserSuggestion, onTagChange: onManagersChange }))));
});
var StaffForm = React.memo(function (props) {
    var _a, _b;
    var user = props.user, initialFormValues = props.initialFormValues, onSubmit = props.onSubmit, isSubmitting = props.isSubmitting, onCancel = props.onCancel, departmentOptions = props.departmentOptions, fetchUserSuggestion = props.fetchUserSuggestion;
    var showErrorToast = useAppToast().showErrorToast;
    var formikRef = useRef(null);
    var initialValues = useMemo(function () {
        var _a, _b, _c, _d, _e;
        return ({
            fullName: (_a = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.fullName) !== null && _a !== void 0 ? _a : "",
            avatar: (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.avatar) !== null && _b !== void 0 ? _b : null,
            departmentId: (_c = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.departmentId) !== null && _c !== void 0 ? _c : null,
            role: (_d = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.role) !== null && _d !== void 0 ? _d : null,
            managers: (_e = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.managers) !== null && _e !== void 0 ? _e : [],
        });
    }, [
        initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.avatar,
        initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.departmentId,
        initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.fullName,
        initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.managers,
        initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.role,
    ]);
    var _c = __read(useState((_b = (_a = user.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined), 2), avatarUrl = _c[0], setAvatarUrl = _c[1];
    var onAvatarFileChange = useCallback(function (file) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("avatar", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("avatar", file);
        if (file) {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                setAvatarUrl(reader_1.result);
            };
            reader_1.readAsDataURL(file);
        }
    }, []);
    var onFileBadMimeType = useCallback(function (file) {
        showErrorToast({
            titleId: "common.errors.file.bad_mime_type.title",
            titleValues: {
                type: file.type,
            },
            descriptionId: "common.errors.file.bad_mime_type.description",
        }, {
            position: "bottom-center",
        });
    }, [showErrorToast]);
    var onDepartmentChange = useCallback(function (department) {
        var _a, _b, _c, _d, _e;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("departmentId", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("departmentId", (_c = department === null || department === void 0 ? void 0 : department.id) !== null && _c !== void 0 ? _c : null);
        (_d = formikRef.current) === null || _d === void 0 ? void 0 : _d.setFieldTouched("role", true);
        (_e = formikRef.current) === null || _e === void 0 ? void 0 : _e.setFieldValue("role", department != null ? "staff" : null);
    }, []);
    var onRoleChange = useCallback(function (option) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("role", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("role", option);
    }, []);
    var onManagersChange = useCallback(function (managers) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("managers", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("managers", managers);
    }, []);
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: staffFormSchema, validateOnChange: true, onSubmit: onSubmit, innerRef: formikRef },
        React.createElement(FormView, { user: user, avatarUrl: avatarUrl, isSubmitting: isSubmitting !== null && isSubmitting !== void 0 ? isSubmitting : false, onCancel: onCancel, departmentOptions: departmentOptions, fetchUserSuggestion: fetchUserSuggestion, onAvatarFileChange: onAvatarFileChange, onFileBadMimeType: onFileBadMimeType, onDepartmentChange: onDepartmentChange, onRoleChange: onRoleChange, onManagersChange: onManagersChange })));
});
export default StaffForm;

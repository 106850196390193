import { __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { IndustryAPIContext } from "@shared/contexts/api/IndustryAPIContext";
import { ROUTES } from "@router/routes";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { useAppToast } from "../common/Toast";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
import { IndustryForm } from "../settings/forms/IndustryForm";
import { parseTagUpdateErrorMessage } from "@shared/models/error";
import { useNavigation } from "@hooks/useNavigation";
export var IndustryTagEditScreen = React.memo(function () {
    var _a, _b;
    var tagId = useParams().tagId;
    var showErrorToast = useAppToast().showErrorToast;
    var industryId = useMemo(function () { return parseInt(tagId, 10); }, [tagId]);
    var navigation = useNavigation();
    var location = useLocation();
    var industryAPI = useContext(IndustryAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _c = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _c[0], setTagName = _c[1];
    var _d = __read(useState(undefined), 2), initialFormValues = _d[0], setInitialFormValues = _d[1];
    var _e = __read(useState({}), 2), additionalFields = _e[0], setAdditionalFields = _e[1];
    var _f = __read(useState(true), 2), isLoading = _f[0], setIsLoading = _f[1];
    var _g = __read(useState(false), 2), isSubmitting = _g[0], setIsSubmitting = _g[1];
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.industry.group_detail);
        }
    }, [navigation]);
    var handleSubmit = useCallback(function (formValues) {
        setIsSubmitting(true);
        industryAPI
            .updateIndustry(industryId, {
            name: formValues.tagName.trim(),
            fullName: !!formValues.fullName ? formValues.fullName.trim() : null,
            remarks: !!formValues.remarks ? formValues.remarks.trim() : null,
            additional: formValues.additional,
        })
            .then(function () {
            handleGoBack();
        })
            .catch(function (err) {
            var _a = __read(parseTagUpdateErrorMessage(err), 2), titleId = _a[0], descriptionId = _a[1];
            showErrorToast({
                titleId: titleId,
                descriptionId: descriptionId,
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    }, [industryId, industryAPI, setIsSubmitting, showErrorToast, handleGoBack]);
    useEffect(function () {
        setIsLoading(true);
        Promise.all([
            industryAPI.getIndustry(industryId),
            tagGroupAPI.getTagGroupBySlug("industry"),
        ])
            .then(function (_a) {
            var _b, _c;
            var _d = __read(_a, 2), industry = _d[0], tagGroupResp = _d[1];
            // Handles displaying tag name when we enter the page by typing in the url
            setTagName(industry.name);
            setAdditionalFields(tagGroupResp.definition);
            setInitialFormValues({
                tagName: industry.name,
                fullName: (_b = industry.fullName) !== null && _b !== void 0 ? _b : "",
                remarks: (_c = industry.remarks) !== null && _c !== void 0 ? _c : "",
                additional: industry.additional,
            });
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        industryAPI,
        industryId,
        tagGroupAPI,
        setTagName,
        showErrorToast,
        setAdditionalFields,
        setInitialFormValues,
        handleGoBack,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tags.form.edit.title", values: {
                        tagName: tagName,
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(IndustryForm, { initialFormValues: initialFormValues, additionalFields: additionalFields, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleGoBack })))));
});

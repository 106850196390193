export var ROUTES = {
    root: "/",
    uploads: {
        root: "/uploads",
        unreadUploads: "/uploads/unread",
        unreadUploadsThread: {
            toString: function () { return "/uploads/unread/thread/:threadId"; },
            format: function (threadId, displayDetails) {
                return "/uploads/unread/thread/" + threadId + (displayDetails ? "/detail" : "");
            },
        },
        allUploads: "/uploads/all",
        allUploadsThread: {
            toString: function () { return "/uploads/all/thread/:threadId"; },
            format: function (threadId, displayDetails) {
                return "/uploads/all/thread/" + threadId + (displayDetails ? "/detail" : "");
            },
        },
    },
    report: "/report",
    settings: {
        root: "/settings",
        tags: {
            root: "/settings/tags",
            new_group: "/settings/tags/new_group",
            company: {
                group_detail: "/settings/tags/company",
                add: "/settings/tags/company/add",
                edit_group: "/settings/tags/company/edit",
                tag_detail: "/settings/tags/company/:tagId",
                edit_tag: "/settings/tags/company/:tagId/edit",
            },
            industry: {
                group_detail: "/settings/tags/industry",
                add: "/settings/tags/industry/add",
                edit_group: "/settings/tags/industry/edit",
                tag_detail: "/settings/tags/industry/:tagId",
                edit_tag: "/settings/tags/industry/:tagId/edit",
            },
            contact: {
                group_detail: "/settings/tags/contact",
                add: "/settings/tags/contact/add",
                edit_group: "/settings/tags/contact/edit",
                tag_detail: "/settings/tags/contact/:tagId",
                edit_tag: "/settings/tags/contact/:tagId/edit",
            },
            deal: {
                group_detail: "/settings/tags/deal",
                add: "/settings/tags/deal/add",
                edit_group: "/settings/tags/deal/edit",
                tag_detail: "/settings/tags/deal/:tagId",
                edit_tag: "/settings/tags/deal/:tagId/edit",
            },
            others: {
                group_detail: "/settings/tags/others",
                add: "/settings/tags/others/add",
                edit_group: "/settings/tags/others/edit",
                tag_detail: "/settings/tags/others/:tagId",
                edit_tag: "/settings/tags/others/:tagId/edit",
            },
            priority: {
                group_detail: "/settings/tags/priority",
                add: "/settings/tags/priority/add",
                edit_group: "/settings/tags/priority/edit",
                tag_detail: "/settings/tags/priority/:tagId",
                edit_tag: "/settings/tags/priority/:tagId/edit",
            },
            region: {
                group_detail: "/settings/tags/region",
                add: "/settings/tags/region/add",
                edit_group: "/settings/tags/region/edit",
                tag_detail: "/settings/tags/region/:tagId",
                edit_tag: "/settings/tags/region/:tagId/edit",
            },
            custom: {
                group_detail: "/settings/tags/:groupId",
                edit_group: "/settings/tags/:groupId/edit",
                add: "/settings/tags/:groupId/add",
                tag_detail: "/settings/tags/:groupId/:tagId",
                edit_tag: "/settings/tags/:groupId/:tagId/edit",
            },
        },
        organization: {
            root: "/settings/organization",
            add: "/settings/organization/add",
            department_detail: "/settings/organization/:departmentId",
        },
        staff: {
            root: "/settings/staff",
            staff_detail: {
                toString: function () { return "/settings/staff/:userId"; },
                format: function (userId) {
                    return "/settings/staff/" + userId.toString(10);
                },
            },
            staff_edit: {
                toString: function () { return "/settings/staff/:userId/edit"; },
                format: function (userId) {
                    return "/settings/staff/" + userId.toString(10) + "/edit";
                },
            },
        },
    },
    myPreference: "/my-preference",
    login: "/login",
    oauthRedirect: "/oauth/redirect",
};

import { __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
import { ROUTES } from "@router/routes";
import { useAppToast } from "../common/Toast";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { TagForm } from "../settings/forms/TagForm";
import { ColorAPIContext } from "@shared/contexts/api/ColorAPIContext";
import { TagSettingRouterContext } from "@router/TagSettingRouter";
import { parseTagCreationErrorMessage } from "@shared/models/error";
import { useNavigation } from "@hooks/useNavigation";
import { TagAPIContext } from "@shared/contexts/api/TagAPIContext";
import { useParams } from "react-router-dom";
export var CustomTagCreationScreen = React.memo(function () {
    var groupId = useParams().groupId;
    var tagGroupId = useMemo(function () { return parseInt(groupId, 10); }, [groupId]);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var colorAPI = useContext(ColorAPIContext);
    var tagAPI = useContext(TagAPIContext);
    var refreshSidebar = useContext(TagSettingRouterContext).refreshSidebar;
    var intl = useIntl();
    var navigation = useNavigation();
    var showErrorToast = useAppToast().showErrorToast;
    var _a = __read(useState([]), 2), colors = _a[0], setColors = _a[1];
    var _b = __read(useState({}), 2), additionalFields = _b[0], setAdditionalFields = _b[1];
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var _d = __read(useState(false), 2), isSubmitting = _d[0], setIsSubmitting = _d[1];
    var handleSubmit = useCallback(function (formValues) {
        var _a, _b;
        setIsSubmitting(true);
        tagAPI
            .createTag(tagGroupId, {
            name: formValues.tagName.trim(),
            fullName: !!formValues.fullName ? formValues.fullName.trim() : null,
            colorId: (_b = (_a = formValues.color) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
            additional: formValues.additional,
        })
            .then(function (tag) {
            refreshSidebar();
            navigation.push(ROUTES.settings.tags.root + "/" + tagGroupId + "/" + tag.id);
        })
            .catch(function (err) {
            var _a = __read(parseTagCreationErrorMessage(err), 2), titleId = _a[0], descriptionId = _a[1];
            showErrorToast({
                titleId: titleId,
                descriptionId: descriptionId,
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    }, [
        tagAPI,
        tagGroupId,
        navigation,
        setIsSubmitting,
        showErrorToast,
        refreshSidebar,
    ]);
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.root + "/" + tagGroupId);
        }
    }, [navigation, tagGroupId]);
    useEffect(function () {
        setIsLoading(true);
        Promise.all([
            colorAPI.getAllColors(),
            tagGroupAPI.getTagGroupById(tagGroupId),
        ])
            .then(function (_a) {
            var _b = __read(_a, 2), colorResp = _b[0], groupResp = _b[1];
            setColors(colorResp.data);
            setAdditionalFields(groupResp.definition);
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        tagGroupAPI,
        tagGroupId,
        colorAPI,
        navigation,
        setColors,
        showErrorToast,
        setAdditionalFields,
        handleGoBack,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tags.form.create.title", values: {
                        type: intl.formatMessage({
                            id: "settings.tags.title.custom",
                        }),
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(TagForm, { colors: colors, additionalFields: additionalFields, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleGoBack })))));
});

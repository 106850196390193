import * as yup from "yup";
import { allDepartmentRoleValues } from "../department";
import { userWithDepartmentsSchema } from "./user_api";
export var createDepartmentUserRequestSchema = yup
    .object()
    .shape({
    user_id: yup.number().integer().defined(),
    role: yup.string().oneOf(allDepartmentRoleValues).notRequired(),
})
    .snakeCase()
    .defined();
export var createDepartmentUserResponseSchema = yup
    .object()
    .shape({
    user: userWithDepartmentsSchema.defined(),
})
    .camelCase()
    .defined();

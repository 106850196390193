import { __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { FormattedMessage } from "react-intl";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
import { ROUTES } from "@router/routes";
import { useAppToast } from "../common/Toast";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { TagForm } from "../settings/forms/TagForm";
import { ColorAPIContext } from "@shared/contexts/api/ColorAPIContext";
import { parseTagCreationErrorMessage } from "@shared/models/error";
import { useNavigation } from "@hooks/useNavigation";
import { TagAPIContext } from "@shared/contexts/api/TagAPIContext";
import { useLocation, useParams } from "react-router-dom";
export var CustomTagEditScreen = React.memo(function () {
    var _a, _b;
    var _c = useParams(), groupId = _c.groupId, tagId_ = _c.tagId;
    var tagId = useMemo(function () { return parseInt(tagId_, 10); }, [tagId_]);
    var tagGroupId = useMemo(function () { return parseInt(groupId, 10); }, [groupId]);
    var location = useLocation();
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var colorAPI = useContext(ColorAPIContext);
    var tagAPI = useContext(TagAPIContext);
    var navigation = useNavigation();
    var showErrorToast = useAppToast().showErrorToast;
    var _d = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _d[0], setTagName = _d[1];
    var _e = __read(useState(undefined), 2), initialFormValues = _e[0], setInitialFormValues = _e[1];
    var _f = __read(useState([]), 2), colors = _f[0], setColors = _f[1];
    var _g = __read(useState({}), 2), additionalFields = _g[0], setAdditionalFields = _g[1];
    var _h = __read(useState(false), 2), isLoading = _h[0], setIsLoading = _h[1];
    var _j = __read(useState(false), 2), isSubmitting = _j[0], setIsSubmitting = _j[1];
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.root + "/" + tagGroupId);
        }
    }, [navigation, tagGroupId]);
    var handleSubmit = useCallback(function (formValues) {
        var _a, _b;
        setIsSubmitting(true);
        tagAPI
            .updateTag(tagGroupId, tagId, {
            name: formValues.tagName.trim(),
            fullName: !!formValues.fullName ? formValues.fullName.trim() : null,
            colorId: (_b = (_a = formValues.color) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
            additional: formValues.additional,
        })
            .then(function () {
            handleGoBack();
        })
            .catch(function (err) {
            var _a = __read(parseTagCreationErrorMessage(err), 2), titleId = _a[0], descriptionId = _a[1];
            showErrorToast({
                titleId: titleId,
                descriptionId: descriptionId,
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    }, [tagAPI, tagId, tagGroupId, handleGoBack, setIsSubmitting, showErrorToast]);
    useEffect(function () {
        setIsLoading(true);
        Promise.all([
            tagAPI.getTag(tagGroupId, tagId),
            colorAPI.getAllColors(),
            tagGroupAPI.getTagGroupById(tagGroupId),
        ])
            .then(function (_a) {
            var _b, _c;
            var _d = __read(_a, 3), tag = _d[0], colorResp = _d[1], groupResp = _d[2];
            setTagName(tag.name);
            setColors(colorResp.data);
            setAdditionalFields(groupResp.definition);
            setInitialFormValues({
                tagName: tag.name,
                fullName: (_b = tag.fullName) !== null && _b !== void 0 ? _b : "",
                color: (_c = tag.color) !== null && _c !== void 0 ? _c : colorResp.data[0],
                additional: tag.additional,
            });
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        tagAPI,
        tagId,
        tagGroupAPI,
        tagGroupId,
        colorAPI,
        navigation,
        setTagName,
        setInitialFormValues,
        setColors,
        showErrorToast,
        setAdditionalFields,
        handleGoBack,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tags.form.edit.title", values: {
                        tagName: tagName,
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(TagForm, { colors: colors, initialFormValues: initialFormValues, additionalFields: additionalFields, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleGoBack })))));
});

import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { getMessageByIdResponseSchema, setMessageTagsRequestSchema, setMessageTagsResponseSchema, uploadMessageRequestSchema, removeMessageRequestSchema, getMessageByIdQuerySchema, } from "@shared/models/api/message_api";
export var MessageAPIContext = React.createContext(undefined);
export var MessageAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getMessage = useCallback(function (messageId, params) { return __awaiter(void 0, void 0, void 0, function () {
        var validatedParams, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    validatedParams = getMessageByIdQuerySchema.validateSync(params);
                    return [4 /*yield*/, apiContext.get("messages/" + messageId, validatedParams)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, getMessageByIdResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var setMessageTags = useCallback(function (messageId, body) { return __awaiter(void 0, void 0, void 0, function () {
        var validatedBody, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    validatedBody = setMessageTagsRequestSchema.validateSync(body);
                    return [4 /*yield*/, apiContext.post("messages/" + messageId + "/tags", validatedBody)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, setMessageTagsResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var uploadMessage = useCallback(function (messageId, body) { return __awaiter(void 0, void 0, void 0, function () {
        var validatedBody;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    validatedBody = uploadMessageRequestSchema.validateSync(body);
                    return [4 /*yield*/, apiContext.post("messages/" + messageId + "/upload", validatedBody)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [apiContext]);
    var removeMessage = useCallback(function (messageId, body) { return __awaiter(void 0, void 0, void 0, function () {
        var validatedBody;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    validatedBody = removeMessageRequestSchema.validateSync(body);
                    return [4 /*yield*/, apiContext.delete("messages/" + messageId, validatedBody)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            getMessage: getMessage,
            setMessageTags: setMessageTags,
            uploadMessage: uploadMessage,
            removeMessage: removeMessage,
        };
    }, [getMessage, removeMessage, setMessageTags, uploadMessage]);
    return (React.createElement(MessageAPIContext.Provider, { value: value }, children));
});

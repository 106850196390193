import { __assign, __awaiter, __generator, __read } from "tslib";
import { Persona, PersonaSize } from "@fluentui/react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { TagAPIContext } from "@shared/contexts/api/TagAPIContext";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { useDebounce } from "@hooks/useDebounce";
import { useNavigation } from "@hooks/useNavigation";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { TagExtractors } from "@shared/models/tag";
import { ROUTES } from "@router/routes";
import { extractInitials } from "@utils/avatar";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { PrimaryButton } from "../common/PrimaryButton";
import { SearchBar } from "../common/SearchBar";
import { SkeletonTable, Table, } from "../common/Table";
import { useAppToast } from "../common/Toast";
import { ColouredTag } from "@components/common/ColouredTag";
import { TagContextMenu } from "../settings/TagContextMenu";
import { TagDetailInfo } from "../settings/TagDetailInfo";
import { TagGroupDetailHeader } from "../settings/TagGroupDetailHeader";
import cn from "classnames";
import { MergeTagDialog, useMergeTagDialog } from "../common/MergeTagDialog";
import RequirePermission from "../common/RequirePermission";
import { Permission } from "@shared/models/auth";
import useHasPermission from "@hooks/useHasPermission";
import { TagSettingRouterContext } from "@router/TagSettingRouter";
import { TagGroupStoreContext } from "@contexts/TagGroupStoreContext";
import { UserAPIContext } from "@shared/contexts/api";
// eslint-disable-next-line complexity
export var CustomTagGroupDetailScreen = React.memo(function () {
    var _a, _b, _c;
    var groupIdStr = useParams().groupId;
    var tagGroupId = useMemo(function () { return parseInt(groupIdStr, 10); }, [groupIdStr]);
    var _d = __read(useState(false), 2), isLoading = _d[0], setIsLoading = _d[1];
    var _e = useContext(TagGroupStoreContext), tagGroupMap = _e.tagGroupMap, upsertTagGroupStore = _e.upsert;
    var _f = useAppToast(), showErrorToast = _f.showErrorToast, showSuccessToast = _f.showSuccessToast;
    var _g = __read(useState({}), 2), enableStates = _g[0], setEnableStates = _g[1];
    var tagGroup = tagGroupMap.get(tagGroupId);
    var navigation = useNavigation();
    var tagAPI = useContext(TagAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var userAPI = useContext(UserAPIContext);
    var _h = __read(useState(null), 2), searchValue = _h[0], setSearchValue = _h[1];
    var _j = __read(useState(new Map()), 2), creatorMap = _j[0], setCreatorMap = _j[1];
    var debouncedSearchValue = useDebounce(searchValue, 500);
    var intl = useIntl();
    var onAddNewTag = useCallback(function () {
        navigation.push(ROUTES.settings.tags.root + "/" + tagGroupId + "/add");
    }, [navigation, tagGroupId]);
    var onEditGroup = useCallback(function () {
        navigation.push(ROUTES.settings.tags.root + "/" + tagGroupId + "/edit");
    }, [navigation, tagGroupId]);
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.root);
        }
    }, [navigation]);
    var onSearchValueChange = useCallback(function (e) {
        setSearchValue(e.currentTarget.value);
    }, [setSearchValue]);
    var listTags = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var tagResp, creatorIds, creators, creatorMap;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, tagAPI.listTags(tagGroupId, request)];
                case 1:
                    tagResp = _a.sent();
                    creatorIds = tagResp.data.map(function (t) { return t.createdBy.id; });
                    return [4 /*yield*/, userAPI.listUsers({
                            page: 1,
                            perPage: creatorIds.length,
                            userIds: creatorIds,
                        })];
                case 2:
                    creators = _a.sent();
                    creatorMap = new Map(creators.data.map(function (c) { return [c.id, c]; }));
                    setCreatorMap(creatorMap);
                    return [2 /*return*/, tagResp];
            }
        });
    }); }, [tagGroupId, tagAPI, userAPI]);
    var onGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.root);
        }
    }, [navigation]);
    var onFetchListItemError = useCallback(function () {
        showErrorToast({
            titleId: "common.errors.data.fetch_failed.title",
            descriptionId: "common.errors.data.fetch_failed.description",
        }, {
            position: "bottom-center",
        });
        onGoBack();
    }, [onGoBack, showErrorToast]);
    var _k = useSearchPagination(listTags, {
        page: 1,
        perPage: 100,
        isEnabled: "any",
    }, onFetchListItemError), data = _k.data, initialTotalCount = _k.initialTotalCount, totalCount = _k.totalCount, isInitializing = _k.isInitializing, isFetchingMore = _k.isFetchingMore, hasMore = _k.hasMore, nextPage = _k.nextPage, search = _k.search, refresh = _k.refresh;
    var onEnableGroup = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var newGroup, udpatedGroup, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (tagGroup == null)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    newGroup = __assign(__assign({}, tagGroup), { enabled: true });
                    upsertTagGroupStore([newGroup]);
                    return [4 /*yield*/, tagGroupAPI.enableTagGroup(tagGroupId)];
                case 2:
                    udpatedGroup = _a.sent();
                    upsertTagGroupStore([udpatedGroup]);
                    showSuccessToast({
                        titleId: "settings.tags.enable_group.success.title",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.error(e_1);
                    showErrorToast({
                        titleId: "settings.tags.enable_group.error.title",
                        descriptionId: "settings.tags.enable_group.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    // revert optimistic update
                    upsertTagGroupStore([tagGroup]);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [
        showErrorToast,
        showSuccessToast,
        tagGroup,
        tagGroupAPI,
        tagGroupId,
        upsertTagGroupStore,
    ]);
    var onDisableGroup = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var newGroup, udpatedGroup, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (tagGroup == null)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    newGroup = __assign(__assign({}, tagGroup), { enabled: false });
                    upsertTagGroupStore([newGroup]);
                    return [4 /*yield*/, tagGroupAPI.disableTagGroup(tagGroupId)];
                case 2:
                    udpatedGroup = _a.sent();
                    upsertTagGroupStore([udpatedGroup]);
                    showSuccessToast({
                        titleId: "settings.tags.disable_group.success.title",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    console.error(e_2);
                    showErrorToast({
                        titleId: "settings.tags.disable_group.error.title",
                        descriptionId: "settings.tags.disable_group.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    // revert optimistic update
                    upsertTagGroupStore([tagGroup]);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [
        showErrorToast,
        showSuccessToast,
        tagGroup,
        tagGroupAPI,
        tagGroupId,
        upsertTagGroupStore,
    ]);
    var refreshSidebar = useContext(TagSettingRouterContext).refreshSidebar;
    var onDeleteGroup = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, tagGroupAPI.deleteTagGroup(tagGroupId)];
                case 2:
                    _a.sent();
                    refreshSidebar();
                    navigation.push("" + ROUTES.settings.tags.root);
                    return [3 /*break*/, 5];
                case 3:
                    e_3 = _a.sent();
                    console.error(e_3);
                    showErrorToast({
                        titleId: "settings.tags.delete_group.error.title",
                        descriptionId: "settings.tags.delete_group.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [navigation, refreshSidebar, showErrorToast, tagGroupAPI, tagGroupId]);
    var onGoToTagDetail = useCallback(function (tag) {
        navigation.push(ROUTES.settings.tags.root + "/" + tagGroupId + "/" + tag.id, {
            tagName: tag.name,
        });
    }, [navigation, tagGroupId]);
    var onEditTag = useCallback(function (tag) {
        navigation.push(ROUTES.settings.tags.root + "/" + tagGroupId + "/" + tag.id + "/edit");
    }, [navigation, tagGroupId]);
    var onEnableTag = useCallback(function (tag) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setEnableStates(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[tag.id] = true, _a)));
            });
            tagAPI
                .enableTag(tagGroupId, tag.id)
                .then(function () {
                showSuccessToast({
                    titleId: "settings.tags.enable_tag.success.title",
                }, {
                    position: "bottom-center",
                });
            })
                .catch(function (err) {
                console.error("Failed to enable tag", err);
                showErrorToast({
                    titleId: "settings.tags.enable_tag.error.title",
                    descriptionId: "settings.tags.enable_tag.error.description",
                }, {
                    position: "bottom-center",
                });
                setEnableStates(function (prevState) {
                    var _a;
                    return (__assign(__assign({}, prevState), (_a = {}, _a[tag.id] = tag.isEnabled, _a)));
                });
            });
            return [2 /*return*/];
        });
    }); }, [tagAPI, tagGroupId, showSuccessToast, showErrorToast]);
    var onDisableTag = useCallback(function (tag) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setEnableStates(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[tag.id] = false, _a)));
            });
            tagAPI
                .disableTag(tagGroupId, tag.id)
                .then(function () {
                showSuccessToast({
                    titleId: "settings.tags.disable_tag.success.title",
                }, {
                    position: "bottom-center",
                });
            })
                .catch(function (err) {
                console.error("Failed to enable tag", err);
                showErrorToast({
                    titleId: "settings.tags.disable_tag.error.title",
                    descriptionId: "settings.tags.disable_tag.error.description",
                }, {
                    position: "bottom-center",
                });
                setEnableStates(function (prevState) {
                    var _a;
                    return (__assign(__assign({}, prevState), (_a = {}, _a[tag.id] = tag.isEnabled, _a)));
                });
            });
            return [2 /*return*/];
        });
    }); }, [tagGroupId, tagAPI, showErrorToast, showSuccessToast]);
    var onDeleteTag = useCallback(function (tag) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, tagAPI.deleteTag(tagGroupId, tag.id).then(function () {
                    refresh();
                })];
        });
    }); }, [tagGroupId, tagAPI, refresh]);
    var _l = useMergeTagDialog(), mergeDialogProps = _l.mergeDialogProps, openMergeDialog = _l.openMergeDialog, closeMergeDialog = _l.closeMergeDialog;
    var _m = __read(useState(false), 2), isMerging = _m[0], setIsMerging = _m[1];
    var _o = __read(useState(null), 2), mergeItem = _o[0], setMergeItem = _o[1];
    var onMergeTag = useCallback(function (item) { return __awaiter(void 0, void 0, void 0, function () {
        var source, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setMergeItem(item);
                    return [4 /*yield*/, openMergeDialog()];
                case 1:
                    source = _a.sent();
                    if (!source) {
                        return [2 /*return*/];
                    }
                    setIsMerging(true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, tagAPI.mergeTag(item.tagGroupId, item.id, source.id)];
                case 3:
                    _a.sent();
                    closeMergeDialog();
                    refresh();
                    return [3 /*break*/, 6];
                case 4:
                    err_1 = _a.sent();
                    console.error("Failed to merge tag", err_1);
                    showErrorToast({
                        titleId: "merge_tag.error.title",
                        descriptionId: "merge_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 6];
                case 5:
                    setIsMerging(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [openMergeDialog, tagAPI, closeMergeDialog, refresh, showErrorToast]);
    var renderContextMenu = useCallback(function (t) {
        var _a;
        return (React.createElement(TagContextMenu, { typeId: "settings.tags.title.custom", item: t, itemEnableState: (_a = enableStates[t.id]) !== null && _a !== void 0 ? _a : t.isEnabled, onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onMergeTag }));
    }, [
        enableStates,
        onEditTag,
        onEnableTag,
        onDisableTag,
        onDeleteTag,
        onMergeTag,
    ]);
    var tagTableColumns = useMemo(function () { return [
        {
            titleId: "settings.tags.custom.detail.table.tag",
            accessor: function (t) { return t.name; },
            render: function (_, t) {
                var _a;
                return (React.createElement(ColouredTag, { className: cn(!((_a = enableStates[t.id]) !== null && _a !== void 0 ? _a : t.isEnabled) && "opacity-50"), tag: t.name, colour: t.color }));
            },
        },
        {
            titleId: "settings.tags.custom.detail.table.full_name",
            accessor: function (t) { return t.fullName; },
        },
        {
            titleId: "settings.tags.custom.detail.table.created_by",
            accessor: function (t) { return t.createdBy; },
            render: function (_, t) {
                var _a, _b, _c, _d, _e;
                var user = t.createdBy;
                return (React.createElement("div", { className: "flex flex-row" },
                    React.createElement(Persona, { className: "mr-4", imageUrl: (_b = (_a = user.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined, imageInitials: extractInitials((_c = user.fullName) !== null && _c !== void 0 ? _c : (user.username || user.emails[0]), 2), hidePersonaDetails: true, size: PersonaSize.size40 }),
                    React.createElement("div", { className: "flex flex-col" },
                        React.createElement("h1", { className: "text-sm leading-5 font-bold text-indigo-700" }, (_d = user.fullName) !== null && _d !== void 0 ? _d : (user.username || user.emails[0])),
                        React.createElement("h2", { className: "text-sm-leading-5 font-normal text-gray-500" }, (_e = creatorMap
                            .get(user.id)) === null || _e === void 0 ? void 0 : _e.departments.map(function (d) { return d.name; }).join(", ")))));
            },
        },
    ]; }, [enableStates, creatorMap]);
    var tagTableActions = useMemo(function () { return [
        {
            className: "flex items-center justify-end",
            render: renderContextMenu,
        },
    ]; }, [renderContextMenu]);
    useEffect(function () {
        if (debouncedSearchValue != null) {
            search(debouncedSearchValue);
        }
    }, [debouncedSearchValue, search]);
    useEffect(function () {
        setIsLoading(true);
        tagGroupAPI
            .getTagGroupById(tagGroupId)
            .then(function (group) {
            upsertTagGroupStore([group]);
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        tagGroupId,
        tagGroupAPI,
        handleGoBack,
        setIsLoading,
        showErrorToast,
        upsertTagGroupStore,
    ]);
    var hasPermission = useHasPermission();
    return (React.createElement("div", { className: "flex flex-1 min-w-0 flex-col" },
        React.createElement("div", { className: "flex flex-row bg-white px-4" },
            React.createElement("button", { type: "button", className: "md:hidden", onClick: handleGoBack },
                React.createElement(ChevronLeftIcon, { className: "h-6 text-gray-500 mr-4" })),
            React.createElement(TagGroupDetailHeader, { className: "flex-1", name: (_a = tagGroup === null || tagGroup === void 0 ? void 0 : tagGroup.name) !== null && _a !== void 0 ? _a : "", count: initialTotalCount, isDefault: false, isGroupEnabled: (_b = tagGroup === null || tagGroup === void 0 ? void 0 : tagGroup.enabled) !== null && _b !== void 0 ? _b : false, onEdit: onEditGroup, onEnable: onEnableGroup, onDisable: onDisableGroup, onDelete: onDeleteGroup })),
        React.createElement("div", { className: "flex flex-col h-full min-h-0 py-4 px-3 justify-start" },
            React.createElement(TagDetailInfo.Panel, { isLoading: isLoading },
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.custom.detail.visible" }), value: intl.formatMessage({
                        id: (tagGroup === null || tagGroup === void 0 ? void 0 : tagGroup.visible) ? "common.yes" : "common.no",
                    }) }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.custom.detail.mandatory" }), value: intl.formatMessage({
                        id: (tagGroup === null || tagGroup === void 0 ? void 0 : tagGroup.mandatory) ? "common.yes" : "common.no",
                    }) }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.custom.detail.public" }), value: intl.formatMessage({
                        id: (tagGroup === null || tagGroup === void 0 ? void 0 : tagGroup.public) ? "common.yes" : "common.no",
                    }) })),
            React.createElement("div", { className: "flex flex-col py-[1.125rem] px-4 md:items-center md:flex-row" },
                React.createElement("div", { className: "flex-1 flex-col" },
                    React.createElement("h1", { className: "text-base leading-6 font-bold text-gray-900" },
                        React.createElement(FormattedMessage, { id: "settings.tags.custom.detail.tag_in_group" })),
                    React.createElement("span", { className: "text-sm leading-5 font-normal text-gray-600" },
                        React.createElement(FormattedMessage, { id: "settings.tags.tag_counts.custom", values: { count: totalCount } }))),
                React.createElement("div", { className: " flex flex-col space-y-2 items-start justify-center mt-[0.375rem] md:mt-0 md:space-y-0 md:flex-row md:space-x-2 md:items-center" },
                    React.createElement(SearchBar, { containerClassName: "flex-1 min-w-[20rem] bg-white rounded-md border border-gray-300", searchIconClassName: "text-gray-400", inputClassName: "text-gray-500 placeholder-gray-500 md:text-sm", placeholder: intl.formatMessage({
                            id: "settings.tags.custom.detail.search_placeholder",
                        }), value: searchValue !== null && searchValue !== void 0 ? searchValue : "", onChange: onSearchValueChange }),
                    React.createElement(RequirePermission, { permission: ((_c = tagGroup === null || tagGroup === void 0 ? void 0 : tagGroup.public) !== null && _c !== void 0 ? _c : false)
                            ? Permission.TagsPublicCreate
                            : Permission.TagsPrivateCreate },
                        React.createElement(PrimaryButton, { size: "sm", onClick: onAddNewTag },
                            React.createElement(FormattedMessage, { id: "settings.tags.custom.detail.add_tag" }))))),
            React.createElement("div", { className: "h-full max-h-[25.3125rem] flex flex-col overflow-x-hidden" }, isInitializing || isLoading ? (React.createElement(SkeletonTable, { labelClassName: "text-xs leading-4 font-medium text-gray-500", cellClassName: "text-sm leading-5 font-normal text-gray-500", columns: tagTableColumns })) : (React.createElement(Table, { data: data, labelClassName: "text-xs leading-4 font-medium text-gray-500", cellClassName: "text-sm leading-5 font-normal text-gray-500", columns: tagTableColumns, onItemClick: onGoToTagDetail, actions: hasPermission(Permission.TagsUpdate)
                    ? tagTableActions
                    : undefined, onFetchMore: nextPage, hasMore: hasMore, isFetchingMore: isFetchingMore })))),
        mergeItem && (React.createElement(MergeTagDialog, __assign({}, mergeDialogProps, { destinationId: mergeItem.id, tagName: mergeItem.name, isMerging: isMerging, fetch: listTags, keyExtractor: TagExtractors.id, labelExtractor: TagExtractors.name })))));
});

var getFileExtension = function (filename) {
    var _a;
    return (_a = filename.split(".").pop()) !== null && _a !== void 0 ? _a : null;
};
export var getFileTypeExtension = function (filename) {
    var extension = getFileExtension(filename);
    switch (extension) {
        case "pdf":
            return ["PDF", extension];
        case "xls":
        case "xlsx":
            return ["XLS", extension];
        case "doc":
        case "docx":
            return ["DOC", extension];
        default:
            return [null, extension];
    }
};
export var getFileTypeBgColor = function (type) {
    switch (type) {
        case "DOC":
            return "bg-blue-400";
        case "PDF":
            return "bg-red-400";
        case "XLS":
            return "bg-green-400";
        default:
            return "bg-gray-400";
    }
};
export var humanizeFileSize = function (bytes) {
    var units = ["Bytes", "KB", "MB", "GB"];
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    i = Math.min(Math.max(i, 0), units.length - 1);
    return Math.round(bytes / Math.pow(1024, i)) + " " + units[i];
};

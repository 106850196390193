import { __read } from "tslib";
import { FormField } from "@components/form/FormField";
import { TextField } from "@components/form/TextField";
import { Formik } from "formik";
import React, { useMemo, useRef, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { FormShell } from "./FormShell";
import { departmentFormSchema, } from "./models";
import cn from "classnames";
import { SelectMenu } from "@components/form/SelectMenu";
var DepartmentForm = React.memo(function (props) {
    var parentDepartmentOptions = props.parentDepartmentOptions, initialFormValues = props.initialFormValues, isSubmitting = props.isSubmitting, onSubmit = props.onSubmit, onCancel = props.onCancel;
    var intl = useIntl();
    var _a = __read(useState(parentDepartmentOptions), 2), departmentOptions = _a[0], setDepartmentOptions = _a[1];
    var formikRef = useRef(null);
    var initialValues = useMemo(function () {
        var _a, _b;
        return ({
            name: (_a = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.name) !== null && _a !== void 0 ? _a : "",
            parentID: (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.parentID) !== null && _b !== void 0 ? _b : null,
        });
    }, [initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.name, initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.parentID]);
    var onParentDepartmentChange = useCallback(function (option) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("parentID", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("parentID", option === null || option === void 0 ? void 0 : option.id);
    }, []);
    var parentDepartmentKeyExtractor = useCallback(function (option) {
        return option.id;
    }, []);
    var parentDepartmentLabelExtractor = useCallback(function (option) {
        return option.name;
    }, []);
    var onParentDepartmentSearch = useCallback(function (term) {
        if (term) {
            setDepartmentOptions(parentDepartmentOptions.filter(function (i) {
                return parentDepartmentLabelExtractor(i)
                    .toLowerCase()
                    .includes(term.toLowerCase());
            }));
        }
        else {
            setDepartmentOptions(parentDepartmentOptions);
        }
    }, [parentDepartmentLabelExtractor, parentDepartmentOptions]);
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: departmentFormSchema, validateOnChange: true, onSubmit: onSubmit, innerRef: formikRef }, function (_a) {
        var _b;
        var values = _a.values, errors = _a.errors, touched = _a.touched, handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur;
        return (React.createElement(FormShell, { isSubmitting: isSubmitting, onCancel: onCancel, onSubmit: handleSubmit, cancelLabel: intl.formatMessage({ id: "common.cancel" }), submitLabel: intl.formatMessage({
                id: "settings.organization.createDepartment.form.submit",
            }) },
            React.createElement(FormField, { titleId: "settings.organization.createDepartment.form.departmentName", errorId: touched.name ? errors.name : undefined, isOptional: false },
                React.createElement(TextField, { name: "name", onChange: handleChange, onBlur: handleBlur, value: values.name, isError: touched.name && errors.name != null, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
            React.createElement(FormField, { titleId: "settings.organization.createDepartment.form.parentDepartment", errorId: touched.parentID ? errors.parentID : undefined, isOptional: false },
                React.createElement(SelectMenu, { className: "h-[2.375rem] w-full md:max-w-xs", selected: (_b = parentDepartmentOptions.find(function (x) { return x.id === values.parentID; })) !== null && _b !== void 0 ? _b : null, onSelect: onParentDepartmentChange, multiple: false, items: departmentOptions, keyExtractor: parentDepartmentKeyExtractor, labelExtractor: parentDepartmentLabelExtractor, onSearch: onParentDepartmentSearch }))));
    }));
});
export default DepartmentForm;

import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { listTagRequestSchema, listTagResponseSchema, createTagRequestSchema, editTagRequestSchema, tagSchema, } from "@shared/models/api/tag_api";
export var TagAPIContext = React.createContext(undefined);
export var TagAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var listTags = useCallback(function (groupId, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = listTagRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("tag_groups/" + groupId + "/tags", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listTagResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var createTag = useCallback(function (groupId, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = createTagRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("tag_groups/" + groupId + "/tags", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, tagSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var getTag = useCallback(function (groupId, tagId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("tag_groups/" + groupId + "/tags/" + tagId)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, tagSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updateTag = useCallback(function (groupId, tagId, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = editTagRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.put("tag_groups/" + groupId + "/tags/" + tagId, body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, tagSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var enableTag = useCallback(function (groupId, tagId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("tag_groups/" + groupId + "/tags/" + tagId + "/enable", {})];
        });
    }); }, [apiContext]);
    var disableTag = useCallback(function (groupId, tagId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("tag_groups/" + groupId + "/tags/" + tagId + "/disable", {})];
        });
    }); }, [apiContext]);
    var deleteTag = useCallback(function (groupId, tagId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.delete("tag_groups/" + groupId + "/tags/" + tagId)];
        });
    }); }, [apiContext]);
    var mergeTag = useCallback(function (groupId, tagId, sourceId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("tag_groups/" + groupId + "/tags/" + tagId + "/merge", {
                    source_id: sourceId,
                })];
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            listTags: listTags,
            getTag: getTag,
            createTag: createTag,
            updateTag: updateTag,
            enableTag: enableTag,
            disableTag: disableTag,
            deleteTag: deleteTag,
            mergeTag: mergeTag,
        };
    }, [
        listTags,
        getTag,
        createTag,
        updateTag,
        enableTag,
        disableTag,
        deleteTag,
        mergeTag,
    ]);
    return (React.createElement(TagAPIContext.Provider, { value: value }, children));
});

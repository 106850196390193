import { __read } from "tslib";
import React, { useCallback, useState, useContext, useMemo } from "react";
import cn from "classnames";
import { countFilters } from "@shared/models/filter";
import { DocumentListItem } from "./DocumentListItem";
import { FormattedMessage } from "react-intl";
import { FilterContext } from "@contexts/FilterContext";
import { PrimaryButton } from "./PrimaryButton";
import { WhiteButton } from "./WhiteButton";
import { isSearchTagsEmpty } from "@shared/models/search";
import { RefreshIcon, FilterIcon as OutlineFilterIcon, } from "@heroicons/react/outline";
import { FilterIcon as SolidFilterIcon } from "@heroicons/react/solid";
import { InfiniteScroll } from "@components/common/InfiniteScroll";
import { FilterPanel } from "@components/filter/FilterPanel";
import { MoreFilterPanel } from "@components/filter/MoreFilterPanel";
import { RelatedTagsSearchResult } from "@components/search/RelatedTagsSearchResult";
import { Spinner } from "@components/common/Spinner";
import { ThreadReadStatusContext } from "@contexts/ThreadReadStatusContext";
var DocumentListSkeleton = React.memo(function () {
    return (React.createElement("div", { className: "divide-y divide-gray-200 -mx-3 overflow-y-auto min-h-0 flex-1" }, new Array(50).fill(null).map(function (_, index) { return (React.createElement("div", { key: index, className: "animate-pulse w-full py-2 px-3 items-start justify-center flex flex-col space-y-1" },
        React.createElement("div", { className: "h-5 w-full bg-gray-200 rounded-full" }))); })));
});
var FilterButton = React.memo(function () {
    var _a = useContext(FilterContext), openFilterPanel = _a.openFilterPanel, closeFilterPanel = _a.closeFilterPanel, isFilterPanelOpened = _a.isFilterPanelOpened, appliedFilters = _a.appliedFilters;
    var filterCount = useMemo(function () { return countFilters(appliedFilters); }, [appliedFilters]);
    var Button = useMemo(function () {
        if (filterCount > 0) {
            return PrimaryButton;
        }
        return WhiteButton;
    }, [filterCount]);
    var Icon = useMemo(function () {
        if (filterCount > 0) {
            return SolidFilterIcon;
        }
        return OutlineFilterIcon;
    }, [filterCount]);
    return (React.createElement(Button, { size: "base", className: "ml-2 px-3", onClick: isFilterPanelOpened ? closeFilterPanel : openFilterPanel },
        React.createElement("div", { className: "flex items-center" },
            React.createElement(Icon, { className: cn("w-4 h-4", filterCount > 0 ? ["text-white"] : ["text-gray-500"]) }),
            filterCount > 0 && (React.createElement("span", { className: "ml-2 text-white text-sm font-bold" }, filterCount)))));
});
var DocumentListHeader = React.memo(function (props) {
    var title = props.title, relatedTags = props.relatedTags, count = props.count, itemCountId = props.itemCountId, onRefresh = props.onRefresh;
    return (React.createElement("div", { className: "flex items-center px-4 mb-3 justify-between flex-wrap md:flex-nowrap" },
        React.createElement("div", { className: "mx-1 flex flex-col font-black text-base leading-6 text-gray-900" },
            React.createElement("span", null, title),
            relatedTags && !isSearchTagsEmpty(relatedTags) && (React.createElement("div", { className: "mt-3.5 mb-4" },
                React.createElement(RelatedTagsSearchResult, { tags: relatedTags }))),
            React.createElement("span", { className: cn("font-normal text-sm leading-5 text-gray-600", count === undefined && "opacity-0") },
                React.createElement(FormattedMessage, { id: itemCountId, values: {
                        count: count,
                    } }))),
        React.createElement("div", { className: "flex flex-row justify-between self-start" },
            React.createElement(WhiteButton, { size: "base", className: "px-3", onClick: onRefresh },
                React.createElement(RefreshIcon, { className: "w-4 h-4 text-gray-500" })),
            React.createElement(FilterButton, null))));
});
export var DocumentList = React.memo(function (props) {
    var className = props.className, title = props.title, count = props.count, itemCountId = props.itemCountId, documents = props.documents, isLoading = props.isLoading, isCompact = props.isCompact, onRefresh = props.onRefresh, onItemClick_ = props.onItemClick, onFetchMore = props.onFetchMore, isFetchingMore = props.isFetchingMore, hasMore = props.hasMore, relatedTags = props.relatedTags;
    var _a = useContext(FilterContext), isFilterPanelOpened = _a.isFilterPanelOpened, isMoreFilterPanelOpened = _a.isMoreFilterPanelOpened;
    var onItemClick = useCallback(function (i) {
        onItemClick_(i);
    }, [onItemClick_]);
    var _b = __read(useState(null), 2), scrollEl = _b[0], setScrollEl = _b[1];
    var threadReadStatusMapContext = useContext(ThreadReadStatusContext);
    return (React.createElement("div", { className: cn("flex", className) },
        React.createElement("div", { className: "w-full flex flex-col min-h-0 min-w-0 bg-white pt-3.5" },
            React.createElement(DocumentListHeader, { title: title, relatedTags: relatedTags, count: count, itemCountId: itemCountId, onRefresh: onRefresh }),
            isFilterPanelOpened && (React.createElement("div", { className: "px-4" },
                React.createElement(FilterPanel, null))),
            React.createElement("div", { className: "relative flex-1 overflow-hidden flex flex-col" },
                isLoading ? (React.createElement(DocumentListSkeleton, null)) : (React.createElement("div", { ref: setScrollEl, className: cn("divide-y divide-gray-200 overflow-y-auto min-h-0 flex-1", isFilterPanelOpened && "pt-6") }, documents.length ? (React.createElement(React.Fragment, null,
                    documents.map(function (group) {
                        var _a;
                        return (React.createElement(DocumentListItem, { key: group.threadId, isRead: (_a = threadReadStatusMapContext.threadReadStatusMap.get(group.threadId)) !== null && _a !== void 0 ? _a : false, group: group, isCompact: isCompact, onClick: onItemClick }));
                    }),
                    hasMore && (React.createElement(InfiniteScroll, { onFetchMore: onFetchMore, containerElement: scrollEl }, isFetchingMore ? (React.createElement("div", { className: "py-2 flex items-center justify-center" },
                        React.createElement(Spinner, { size: "large" }))) : (React.createElement("div", null)))))) : (React.createElement("div", { className: "flex-1 flex items-center justify-center py-5" },
                    React.createElement("span", { className: "font-normal text-sm leading-5 text-[#605E5C]" },
                        React.createElement(FormattedMessage, { id: "uploads.empty" })))))),
                isMoreFilterPanelOpened && (React.createElement("div", { className: "absolute top-0 bottom-0 right-0 left-0" },
                    React.createElement(MoreFilterPanel, { className: "h-full w-full" })))))));
});

import { __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { OtherTagAPIContext } from "@shared/contexts/api/OtherTagAPIContext";
import { ROUTES } from "@router/routes";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { useAppToast } from "../common/Toast";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
import { OtherTagForm } from "../settings/forms/OtherTagForm";
import { parseTagUpdateErrorMessage } from "@shared/models/error";
import { ColorAPIContext } from "@shared/contexts/api/ColorAPIContext";
import { useNavigation } from "@hooks/useNavigation";
export var OtherTagEditScreen = React.memo(function () {
    var _a, _b;
    var tagId = useParams().tagId;
    var showErrorToast = useAppToast().showErrorToast;
    var otherTagId = useMemo(function () { return parseInt(tagId, 10); }, [tagId]);
    var navigation = useNavigation();
    var location = useLocation();
    var colorAPI = useContext(ColorAPIContext);
    var otherTagAPI = useContext(OtherTagAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _c = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _c[0], setTagName = _c[1];
    var _d = __read(useState(undefined), 2), initialFormValues = _d[0], setInitialFormValues = _d[1];
    var _e = __read(useState([]), 2), colors = _e[0], setColors = _e[1];
    var _f = __read(useState({}), 2), additionalFields = _f[0], setAdditionalFields = _f[1];
    var _g = __read(useState(true), 2), isLoading = _g[0], setIsLoading = _g[1];
    var _h = __read(useState(false), 2), isSubmitting = _h[0], setIsSubmitting = _h[1];
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.others.group_detail);
        }
    }, [navigation]);
    var handleSubmit = useCallback(function (formValues) {
        var _a, _b;
        setIsSubmitting(true);
        otherTagAPI
            .updateOtherTag(otherTagId, {
            name: formValues.tagName.trim(),
            fullName: !!formValues.fullName ? formValues.fullName.trim() : null,
            colorId: (_b = (_a = formValues.color) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
            remarks: !!formValues.remarks ? formValues.remarks.trim() : null,
            additional: formValues.additional,
        })
            .then(function () {
            handleGoBack();
        })
            .catch(function (err) {
            var _a = __read(parseTagUpdateErrorMessage(err), 2), titleId = _a[0], descriptionId = _a[1];
            showErrorToast({
                titleId: titleId,
                descriptionId: descriptionId,
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    }, [otherTagId, otherTagAPI, setIsSubmitting, showErrorToast, handleGoBack]);
    useEffect(function () {
        setIsLoading(true);
        Promise.all([
            otherTagAPI.getOtherTag(otherTagId),
            colorAPI.getAllColors(),
            tagGroupAPI.getTagGroupBySlug("other_tag"),
        ])
            .then(function (_a) {
            var _b, _c, _d;
            var _e = __read(_a, 3), priority = _e[0], colorResp = _e[1], tagGroupResp = _e[2];
            // Handles displaying tag name when we enter the page by typing in the url
            setTagName(priority.name);
            setColors(colorResp.data);
            setAdditionalFields(tagGroupResp.definition);
            setInitialFormValues({
                tagName: priority.name,
                fullName: (_b = priority.fullName) !== null && _b !== void 0 ? _b : "",
                color: (_c = priority.color) !== null && _c !== void 0 ? _c : colorResp.data[0],
                remarks: (_d = priority.remarks) !== null && _d !== void 0 ? _d : "",
                additional: priority.additional,
            });
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        otherTagAPI,
        otherTagId,
        tagGroupAPI,
        colorAPI,
        setColors,
        setTagName,
        showErrorToast,
        setAdditionalFields,
        setInitialFormValues,
        handleGoBack,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tags.form.edit.title", values: {
                        tagName: tagName,
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(OtherTagForm, { colors: colors, initialFormValues: initialFormValues, additionalFields: additionalFields, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleGoBack })))));
});

import { __read } from "tslib";
import React, { useState, useCallback, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { FilterContext } from "@contexts/FilterContext";
import { ContactTag } from "@components/common/ContactTag";
import { searchTagsCount } from "@shared/models/search";
import { ColouredTag } from "@components/common/ColouredTag";
import { useAppToast } from "@components/common/Toast";
import { RelevantTagsDialog } from "./RelevantTagsDialog";
export var RelatedTagsSearchResult = React.memo(function (props) {
    var filterContext = useContext(FilterContext);
    var tags = props.tags;
    var showSuccessToast = useAppToast().showSuccessToast;
    var _a = __read(useState(false), 2), isRelevantTagsDialogOpened = _a[0], setIsRelevantTagsDialogOpened = _a[1];
    var openRelevantTagsDialog = useCallback(function () {
        setIsRelevantTagsDialogOpened(true);
    }, []);
    var closeRelevantTagsDialog = useCallback(function () {
        setIsRelevantTagsDialogOpened(false);
    }, []);
    var onContactSelected = useCallback(function (contact) {
        filterContext.addContact(contact);
        showSuccessToast({
            titleId: "related_tags.added_to_filter",
            titleValues: { name: contact.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    var onCompanySelected = useCallback(function (company) {
        filterContext.addCompany(company);
        showSuccessToast({
            titleId: "related_tags.added_to_filter",
            titleValues: { name: company.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    var onDealSelected = useCallback(function (deal) {
        filterContext.addDeal(deal);
        showSuccessToast({
            titleId: "related_tags.added_to_filter",
            titleValues: { name: deal.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    var onPrioritySelected = useCallback(function (priority) {
        filterContext.addPriority(priority);
        showSuccessToast({
            titleId: "related_tags.added_to_filter",
            titleValues: { name: priority.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    var onOtherTagSelected = useCallback(function (otherTag) {
        filterContext.addOtherTag(otherTag);
        showSuccessToast({
            titleId: "related_tags.added_to_filter",
            titleValues: { name: otherTag.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    var onCustomTagSelected = useCallback(function (tag) {
        filterContext.addCustomTag(tag);
        showSuccessToast({
            titleId: "related_tags.added_to_filter",
            titleValues: { name: tag.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    return (React.createElement(React.Fragment, null,
        React.createElement("section", { className: "block" },
            React.createElement("h3", { className: "block truncate font-normal text-gray-600 text-sm leading-5" },
                React.createElement(FormattedMessage, { id: "related_tags.title" })),
            React.createElement("div", { className: "flex flex-row mt-1 flex-wrap items-center" },
                tags.companies.map(function (c) { return (React.createElement("button", { type: "button", key: "company/" + c.id, className: "mr-2 my-1", onClick: function () { return onCompanySelected(c); } },
                    React.createElement(ColouredTag, { tag: c.name, colour: c.color }))); }),
                tags.contacts.map(function (c) {
                    var _a, _b;
                    return (React.createElement("button", { type: "button", key: "contact/" + c.id, className: "mr-2 my-1", onClick: function () { return onContactSelected(c); } },
                        React.createElement(ContactTag, { name: c.name, avatarUrl: (_b = (_a = c.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined })));
                }),
                tags.deals.map(function (deal) { return (React.createElement("button", { type: "button", key: "deal/" + deal.id, className: "mr-2 my-1", onClick: function () { return onDealSelected(deal); } },
                    React.createElement(ColouredTag, { tag: deal.name, colour: deal.color }))); }),
                tags.priorities.map(function (priority) { return (React.createElement("button", { type: "button", key: "priority/" + priority.id, className: "mr-2 my-1", onClick: function () { return onPrioritySelected(priority); } },
                    React.createElement(ColouredTag, { tag: priority.name, colour: priority.color }))); }),
                tags.otherTags.map(function (otherTag) { return (React.createElement("button", { type: "button", key: "otherTag/" + otherTag.id, className: "mr-2 my-1", onClick: function () { return onOtherTagSelected(otherTag); } },
                    React.createElement(ColouredTag, { tag: otherTag.name, colour: otherTag.color }))); }),
                tags.customTags.map(function (tag) { return (React.createElement("button", { type: "button", key: "tag/" + tag.id, className: "mr-2 my-1", onClick: function () { return onCustomTagSelected(tag); } },
                    React.createElement(ColouredTag, { tag: tag.name, colour: tag.color }))); }),
                searchTagsCount(tags) < tags.total && (React.createElement("button", { type: "button", className: "mr-2 my-1 h-6 align-middle text-indigo-700 text-xs leading-4", onClick: openRelevantTagsDialog },
                    React.createElement(FormattedMessage, { id: "related_tags.view_more", values: { count: tags.total } }))))),
        React.createElement(RelevantTagsDialog, { isOpened: isRelevantTagsDialogOpened, onClose: closeRelevantTagsDialog })));
});

export var DefaultStartPageTypeValues = [
    "unread",
    "all-emails-and-files",
];
export var UserExtractors = {
    id: function (u) { return u.id; },
    name: function (u) { var _a; return (_a = u.fullName) !== null && _a !== void 0 ? _a : u.username; },
    username: function (u) { return u.username; },
    avatarUrl: function (u) { var _a, _b; return (_b = (_a = u.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined; },
};

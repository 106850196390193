import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { listIndustryRequestSchema, listIndustryResponseSchema, getAllIndustriesResponseSchema, createIndustryRequestSchema, industrySchema, editIndustryRequestSchema, mergeIndustryRequestSchema, } from "@shared/models/api/industry_api";
export var IndustryAPIContext = React.createContext(undefined);
export var IndustryAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getIndustry = useCallback(function (industryId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("industries/" + industryId)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, industrySchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var listIndustries = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listIndustryRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("industries", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listIndustryResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var getAllIndustries = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("industries/all")];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, getAllIndustriesResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var createIndustry = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = createIndustryRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("industries", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, industrySchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updateIndustry = useCallback(function (id, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = editIndustryRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.put("industries/" + id, body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, industrySchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var enableIndustry = useCallback(function (industryId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("industries/" + industryId + "/enable", {})];
        });
    }); }, [apiContext]);
    var disableIndustry = useCallback(function (industryId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("industries/" + industryId + "/disable", {})];
        });
    }); }, [apiContext]);
    var deleteIndustry = useCallback(function (industryId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.delete("industries/" + industryId)];
        });
    }); }, [apiContext]);
    var mergeIndustry = useCallback(function (id, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body;
        return __generator(this, function (_a) {
            body = mergeIndustryRequestSchema.validateSync(request);
            return [2 /*return*/, apiContext.post("industries/" + id + "/merge", body)];
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            listIndustries: listIndustries,
            getAllIndustries: getAllIndustries,
            createIndustry: createIndustry,
            getIndustry: getIndustry,
            updateIndustry: updateIndustry,
            enableIndustry: enableIndustry,
            disableIndustry: disableIndustry,
            deleteIndustry: deleteIndustry,
            mergeIndustry: mergeIndustry,
        };
    }, [
        listIndustries,
        getAllIndustries,
        createIndustry,
        getIndustry,
        updateIndustry,
        enableIndustry,
        disableIndustry,
        deleteIndustry,
        mergeIndustry,
    ]);
    return (React.createElement(IndustryAPIContext.Provider, { value: value }, children));
});

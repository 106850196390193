import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { listDealRequestSchema, listDealResponseSchema, createDealRequestSchema, dealSchema, editDealRequestSchema, } from "@shared/models/api/deal_api";
export var DealAPIContext = React.createContext(undefined);
export var DealAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getDeal = useCallback(function (dealId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("deals/" + dealId)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, dealSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var listDeals = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listDealRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("deals", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listDealResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var createDeal = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = createDealRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("deals", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, dealSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updateDeal = useCallback(function (id, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = editDealRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.put("deals/" + id, body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, dealSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var enableDeal = useCallback(function (dealId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("deals/" + dealId + "/enable", {})];
        });
    }); }, [apiContext]);
    var disableDeal = useCallback(function (dealId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("deals/" + dealId + "/disable", {})];
        });
    }); }, [apiContext]);
    var deleteDeal = useCallback(function (dealId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.delete("deals/" + dealId)];
        });
    }); }, [apiContext]);
    var mergeDeal = useCallback(function (dealId, sourceId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("deals/" + dealId + "/merge", {
                    source_id: sourceId,
                })];
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            listDeals: listDeals,
            createDeal: createDeal,
            getDeal: getDeal,
            updateDeal: updateDeal,
            enableDeal: enableDeal,
            disableDeal: disableDeal,
            deleteDeal: deleteDeal,
            mergeDeal: mergeDeal,
        };
    }, [
        listDeals,
        createDeal,
        getDeal,
        updateDeal,
        enableDeal,
        disableDeal,
        deleteDeal,
        mergeDeal,
    ]);
    return (React.createElement(DealAPIContext.Provider, { value: value }, children));
});

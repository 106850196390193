import { useCallback } from "react";
import { useIntl } from "react-intl";
import { UserExtractors } from "@shared/models/user";
export function useUserLabel() {
    var intl = useIntl();
    return useCallback(function (user) {
        var name = UserExtractors.name(user);
        if (user.isEnabled) {
            return name;
        }
        var disabledStr = intl.formatMessage({ id: "common.disbled" });
        return name + " - " + disabledStr;
    }, [intl]);
}

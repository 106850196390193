import { useCallback } from "react";
import { useIntl } from "react-intl";
import { DepartmentExtractors } from "@shared/models/department";
export function useDepartmentLabel() {
    var intl = useIntl();
    return useCallback(function (department) {
        var name = DepartmentExtractors.name(department);
        if (department.isEnabled) {
            return name;
        }
        var disabledStr = intl.formatMessage({ id: "common.disbled" });
        return name + " - " + disabledStr;
    }, [intl]);
}

import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { getAllCountriesResponseSchema, listCountryResponseSchema, listCountryRequestSchema, assignRegionRequestSchema, assignRegionResponseSchema, } from "@shared/models/api/country_api";
export var CountryAPIContext = React.createContext(undefined);
export var CountryAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getAllCountries = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("countries/all")];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, getAllCountriesResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var listCountries = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listCountryRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("countries", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listCountryResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var assignRegion = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = assignRegionRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("countries/assign_region", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, assignRegionResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            getAllCountries: getAllCountries,
            listCountries: listCountries,
            assignRegion: assignRegion,
        };
    }, [getAllCountries, listCountries, assignRegion]);
    return (React.createElement(CountryAPIContext.Provider, { value: value }, children));
});

import * as yup from "yup";
import { generatePaginationSchema, paginatedSearchRequest, } from "../pagination";
export var countrySchema = yup
    .object({
    id: yup.number().defined(),
    name: yup.string().defined(),
    regionId: yup.number().defined().nullable(),
})
    .camelCase()
    .defined();
export var getAllCountriesResponseSchema = yup
    .object({
    data: yup.array().of(countrySchema).required(),
})
    .defined();
export var listCountryRequestSchema = yup
    .object()
    .shape({
    region_id: yup.number(),
    not_region_id: yup.number(),
    industry_id: yup.number(),
})
    .snakeCase()
    .defined()
    .concat(paginatedSearchRequest);
export var listCountryResponseSchema = generatePaginationSchema(countrySchema);
export var assignRegionRequestSchema = yup
    .object()
    .shape({
    country_ids: yup.array(yup.number().defined()).defined(),
    region_id: yup.number().defined().nullable(),
})
    .snakeCase()
    .defined();
export var assignRegionResponseSchema = yup
    .object()
    .shape({
    data: yup.array(countrySchema.defined()).defined(),
})
    .camelCase()
    .defined();

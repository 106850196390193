import { __assign, __read, __values } from "tslib";
import React, { useMemo, useReducer, useState, useCallback, useContext, } from "react";
import { DocumentAPIContext } from "@shared/contexts/api";
export var ThreadReadStatusContext = React.createContext(undefined);
function reducer(state, action) {
    var e_1, _a;
    switch (action.type) {
        case "updateStatuses": {
            var newMap = new Map(state.threadReadStatusMap);
            var newOffset = state.unreadCountOffset;
            try {
                for (var _b = __values(action.statuses), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), threadId = _d[0], newStatus = _d[1];
                    var prevStatus = state.threadReadStatusMap.get(threadId);
                    newMap.set(threadId, newStatus);
                    if (prevStatus != null && prevStatus !== newStatus) {
                        if (newStatus === true) {
                            newOffset -= 1;
                        }
                        else {
                            newOffset += 1;
                        }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return { threadReadStatusMap: newMap, unreadCountOffset: newOffset };
        }
        case "clearOffset": {
            return __assign(__assign({}, state), { unreadCountOffset: 0 });
        }
        default:
            break;
    }
    return state;
}
export var ThreadReadStatusContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var documentAPI = useContext(DocumentAPIContext);
    var _b = __read(useState(null), 2), unreadCount = _b[0], setUnreadCount = _b[1];
    var _c = __read(useReducer(reducer, {
        unreadCountOffset: 0,
        threadReadStatusMap: new Map(),
    }), 2), contextState = _c[0], dispatchState = _c[1];
    var unreadCountOffset = contextState.unreadCountOffset, threadReadStatusMap = contextState.threadReadStatusMap;
    var refreshUnreadCount = useCallback(function () {
        documentAPI
            .searchDocuments({ unread_only: true, perPage: 0, page: 1 })
            .then(function (resp) {
            setUnreadCount(resp.count);
            dispatchState({ type: "clearOffset" });
        })
            .catch(function () {
            console.error("Failed to fetch unread count");
        });
    }, [documentAPI]);
    var setReadStatuses = useCallback(function (threadsStatuses) {
        dispatchState({ type: "updateStatuses", statuses: threadsStatuses });
    }, []);
    var offsettedUnreadCount = useMemo(function () {
        if (unreadCount == null)
            return null;
        return unreadCount + unreadCountOffset;
    }, [unreadCount, unreadCountOffset]);
    var value = useMemo(function () {
        return {
            threadReadStatusMap: threadReadStatusMap,
            setReadStatuses: setReadStatuses,
            unreadCount: offsettedUnreadCount,
            refreshUnreadCount: refreshUnreadCount,
        };
    }, [
        offsettedUnreadCount,
        refreshUnreadCount,
        threadReadStatusMap,
        setReadStatuses,
    ]);
    return (React.createElement(ThreadReadStatusContext.Provider, { value: value }, children));
});

import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { Persona, PersonaSize } from "@fluentui/react";
import { ContactAPIContext } from "@shared/contexts/api/ContactAPIContext";
import { ContactExtractors } from "@shared/models/contact";
import { ROUTES } from "@router/routes";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { useAppToast } from "../common/Toast";
import { extractInitials } from "@utils/avatar";
import { TagDetailHeader } from "../settings/TagDetailHeader";
import { TagDetailInfo } from "../settings/TagDetailInfo";
import { useNavigation } from "@hooks/useNavigation";
import { ColouredTag } from "@components/common/ColouredTag";
import { getTagGroupCustomFieldsEntries, } from "@shared/models/tag";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { useMergeTagDialog, MergeTagDialog } from "../common/MergeTagDialog";
export var ContactTagDetailScreen = React.memo(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var tagId = useParams().tagId;
    var contactId = useMemo(function () { return parseInt(tagId, 10); }, [tagId]);
    var navigation = useNavigation();
    var location = useLocation();
    var showErrorToast = useAppToast().showErrorToast;
    var contactAPI = useContext(ContactAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _p = __read(useState(null), 2), contact = _p[0], setContact = _p[1];
    var _q = __read(useState(false), 2), isLoading = _q[0], setIsLoading = _q[1];
    var _r = __read(useState([]), 2), availableCustomProperties = _r[0], setAvailableCustomProperties = _r[1];
    var _s = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _s[0], setTagName = _s[1];
    var goGroupDetail = useCallback(function () {
        navigation.replace(ROUTES.settings.tags.contact.group_detail);
    }, [navigation]);
    var fetchContact = useCallback(function () {
        setIsLoading(true);
        Promise.all([
            contactAPI.getContact(contactId),
            tagGroupAPI.getTagGroupBySlug("contact"),
        ])
            .then(function (_a) {
            var _b = __read(_a, 2), contact = _b[0], tagGroup = _b[1];
            setContact(contact);
            setTagName(contact.name);
            setAvailableCustomProperties(getTagGroupCustomFieldsEntries(tagGroup.definition).map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return ({
                    name: value.name,
                    value: contact.additional[key],
                });
            }));
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            goGroupDetail();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [contactAPI, contactId, tagGroupAPI, showErrorToast, goGroupDetail]);
    var onEditTag = useCallback(function () {
        navigation.push(ROUTES.settings.tags.contact.group_detail + "/" + contactId + "/edit");
    }, [navigation, contactId]);
    var onEnableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, contactAPI.enableContact(contactId)];
        });
    }); }, [contactId, contactAPI]);
    var onDisableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, contactAPI.disableContact(contactId)];
        });
    }); }, [contactId, contactAPI]);
    var onDeleteTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, contactAPI.deleteContact(contactId).then(function () {
                    goGroupDetail();
                })];
        });
    }); }, [contactAPI, contactId, goGroupDetail]);
    var _t = useMergeTagDialog(), mergeDialogProps = _t.mergeDialogProps, openMergeDialog = _t.openMergeDialog, closeMergeDialog = _t.closeMergeDialog;
    var _u = __read(useState(false), 2), isMerging = _u[0], setIsMerging = _u[1];
    var onMergeTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var source, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openMergeDialog()];
                case 1:
                    source = _a.sent();
                    if (!source) {
                        return [2 /*return*/];
                    }
                    setIsMerging(true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, contactAPI.mergeContact(contactId, source.id)];
                case 3:
                    _a.sent();
                    closeMergeDialog();
                    fetchContact();
                    return [3 /*break*/, 6];
                case 4:
                    err_1 = _a.sent();
                    console.error("Failed to merge tag", err_1);
                    showErrorToast({
                        titleId: "merge_tag.error.title",
                        descriptionId: "merge_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 6];
                case 5:
                    setIsMerging(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [
        openMergeDialog,
        contactAPI,
        contactId,
        closeMergeDialog,
        showErrorToast,
        fetchContact,
    ]);
    useEffect(function () {
        fetchContact();
    }, [fetchContact]);
    return (React.createElement("div", { className: "flex flex-col min-w-0 flex-1" },
        React.createElement(MergeTagDialog, __assign({}, mergeDialogProps, { destinationId: contactId, tagName: tagName, fetch: contactAPI.listContacts, isMerging: isMerging, keyExtractor: ContactExtractors.id, labelExtractor: ContactExtractors.name })),
        React.createElement(TagDetailHeader, { typeId: "settings.tags.title.contact", tagName: tagName, isTagEnabled: (_c = contact === null || contact === void 0 ? void 0 : contact.isEnabled) !== null && _c !== void 0 ? _c : true, onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onMergeTag, onGoBack: goGroupDetail }),
        React.createElement("div", { className: "flex-1 mt-2 overflow-y-auto md:mt-0 md:p-4" },
            React.createElement(TagDetailInfo.Panel, { isLoading: isLoading },
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.contact.form.display_name" }), value: (_d = contact === null || contact === void 0 ? void 0 : contact.name) !== null && _d !== void 0 ? _d : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.contact.form.email" }), value: (_e = contact === null || contact === void 0 ? void 0 : contact.emails.join(",")) !== null && _e !== void 0 ? _e : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.contact.form.phone_number" }), value: (_f = contact === null || contact === void 0 ? void 0 : contact.phoneNumber) !== null && _f !== void 0 ? _f : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.contact.form.profile_picture" }), value: React.createElement(Persona, { className: "mr-4", imageUrl: (_h = (_g = contact === null || contact === void 0 ? void 0 : contact.avatar) === null || _g === void 0 ? void 0 : _g.url) !== null && _h !== void 0 ? _h : undefined, imageInitials: extractInitials((_k = (_j = contact === null || contact === void 0 ? void 0 : contact.name) !== null && _j !== void 0 ? _j : contact === null || contact === void 0 ? void 0 : contact.emails[0]) !== null && _k !== void 0 ? _k : "", 2), hidePersonaDetails: true, size: PersonaSize.size56 }) }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.contact.form.company" }), value: React.createElement(ColouredTag, { tag: (_m = (_l = contact === null || contact === void 0 ? void 0 : contact.company) === null || _l === void 0 ? void 0 : _l.name) !== null && _m !== void 0 ? _m : "", colour: (_o = contact === null || contact === void 0 ? void 0 : contact.company) === null || _o === void 0 ? void 0 : _o.color }) }),
                availableCustomProperties.map(function (value, index) { return (React.createElement(TagDetailInfo.Item, { key: index, title: value.name, value: value.value })); })))));
});

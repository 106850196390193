import { __read, __spreadArray } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { Switch, Router, useHistory, useRouteMatch } from "react-router-dom";
import { TagGroupList, } from "../components/settings/TagGroupList";
import cn from "classnames";
import { ROUTES } from "./routes";
import { CompanyTagGroupDetailScreen } from "@components/screens/CompanyTagGroupDetailScreen";
import { ContactTagGroupDetailScreen } from "@components/screens/ContactTagGroupDetailScreen";
import { ContactTagEditScreen } from "@components/screens/ContactTagEditScreen";
import { DealTagGroupDetailScreen } from "@components/screens/DealTagGroupDetailScreen";
import { IndustryTagGroupDetailScreen } from "@components/screens/IndustryTagGroupDetailScreen";
import { RegionTagGroupDetailScreen } from "@components/screens/RegionTagGroupDetailScreen";
import { OthersTagGroupDetailScreen } from "@components/screens/OthersTagGroupDetailScreen";
import { PriorityTagGroupDetailScreen } from "@components/screens/PriorityTagGroupDetailScreen";
import { CustomTagGroupDetailScreen } from "@components/screens/CustomTagGroupDetailScreen";
import { CompanyTagCreationScreen } from "@components/screens/CompanyTagCreationScreen";
import { CompanyTagEditScreen } from "@components/screens/CompanyTagEditScreen";
import { CompanyTagDetailScreen } from "@components/screens/CompanyTagDetailScreen";
import { IndustryTagCreationScreen } from "@components/screens/IndustryTagCreationScreen";
import { IndustryTagEditScreen } from "@components/screens/IndustryTagEditScreen";
import { RegionTagCreationScreen } from "@components/screens/RegionTagCreationScreen";
import { RegionTagEditScreen } from "@components/screens/RegionTagEditScreen";
import { PriorityTagCreationScreen } from "@components/screens/PriorityTagCreationScreen";
import { PriorityTagEditScreen } from "@components/screens/PriorityTagEditScreen";
import { OtherTagCreationScreen } from "@components/screens/OthersTagCreationScreen";
import { OtherTagEditScreen } from "@components/screens/OthersTagEditScreen";
import { ContactTagCreationScreen } from "@components/screens/ContactTagCreationScreen";
import { ContactTagDetailScreen } from "@components/screens/ContactTagDetailScreen";
import { DealTagCreationScreen } from "@components/screens/DealTagCreationScreen";
import { DealTagEditScreen } from "@components/screens/DealTagEditScreen";
import { IndustryTagDetailScreen } from "@components/screens/IndustryTagDetailScreen";
import { DealTagDetailScreen } from "@components/screens/DealTagDetailScreen";
import { OtherTagDetailScreen } from "@components/screens/OthersTagDetailScreen";
import { PriorityTagDetailScreen } from "@components/screens/PriorityTagDetailScreen";
import { RegionTagDetailScreen } from "@components/screens/RegionTagDetailScreen";
import { TagGroupCreationScreen } from "@components/screens/TagGroupCreationScreen";
import { useNavigation } from "@hooks/useNavigation";
import { TagGroupEditScreen } from "@components/screens/TagGroupEditScreen";
import { CompanyTagGroupEditScreen } from "@components/screens/CompanyTagGroupEditScreen";
import { ContactTagGroupEditScreen } from "@components/screens/ContactTagGroupEditScreen";
import { DealTagGroupEditScreen } from "@components/screens/DealTagGroupEditScreen";
import { IndustryTagGroupEditScreen } from "@components/screens/IndustryTagGroupEditScreen";
import { OthersTagGroupEditScreen } from "@components/screens/OthersTagGroupEditScreen";
import { PriorityTagGroupEditScreen } from "@components/screens/PriorityTagGroupEditScreen";
import { RegionTagGroupEditScreen } from "@components/screens/RegionTagGroupEditScreen";
import { CustomTagCreationScreen } from "@components/screens/CustomTagCreationScreen";
import { CustomTagEditScreen } from "@components/screens/CustomTagEditScreen";
import { CustomTagDetailScreen } from "@components/screens/CustomTagDetailScreen";
import { TagGroupSlug } from "@shared/models/tag";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import ScreenRoute from "./ScreenRoute";
import { Permission } from "@shared/models/auth";
import { TagGroupStoreContextProvider, TagGroupStoreContext, } from "@contexts/TagGroupStoreContext";
function getGroupPathFromSlug(slug) {
    switch (slug) {
        case TagGroupSlug.Company:
            return ROUTES.settings.tags.company.group_detail;
        case TagGroupSlug.Contact:
            return ROUTES.settings.tags.contact.group_detail;
        case TagGroupSlug.Industry:
            return ROUTES.settings.tags.industry.group_detail;
        case TagGroupSlug.Deal:
            return ROUTES.settings.tags.deal.group_detail;
        case TagGroupSlug.OtherTag:
            return ROUTES.settings.tags.others.group_detail;
        case TagGroupSlug.Priority:
            return ROUTES.settings.tags.priority.group_detail;
        case TagGroupSlug.Region:
            return ROUTES.settings.tags.region.group_detail;
        default:
            throw new Error("Unexpected tag group slug");
    }
}
export var TagSettingRouterContext = React.createContext(undefined);
var InternalTagSettingRouter = function () {
    var _a;
    var _b = __read(useState([]), 2), listItems = _b[0], setListItems = _b[1];
    var _c = __read(useState(true), 2), isInitializing = _c[0], setIsInitializing = _c[1];
    var history = useHistory();
    var navigation = useNavigation();
    var rootMatch = (_a = useRouteMatch(ROUTES.settings.tags.root)) === null || _a === void 0 ? void 0 : _a.isExact;
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var upsertTagGroupsToStore = useContext(TagGroupStoreContext).upsert;
    var onAddGroupClick = useCallback(function () {
        navigation.push(ROUTES.settings.tags.new_group);
    }, [navigation]);
    var fetchTagCounts = useCallback(function () {
        tagGroupAPI
            .getAllTagGroups({ isEnabled: "any" })
            .then(function (tagGroupsResp) {
            var groups = tagGroupsResp.data;
            var predefinedGroups = groups
                .filter(function (g) { return g.slug !== null; })
                .map(function (g) {
                var path = getGroupPathFromSlug(g.slug);
                return {
                    path: path,
                    tagGroupId: g.id,
                };
            });
            var customGroups = groups
                .filter(function (g) { return g.slug === null; })
                .map(function (g) {
                return {
                    tagGroupId: g.id,
                    path: ROUTES.settings.tags.root + "/" + g.id,
                };
            });
            upsertTagGroupsToStore(groups);
            setListItems(__spreadArray(__spreadArray([], __read(predefinedGroups)), __read(customGroups)));
        })
            .finally(function () {
            setIsInitializing(false);
        });
    }, [tagGroupAPI, upsertTagGroupsToStore]);
    var tagSettingRouterContextValue = useMemo(function () { return ({
        refreshSidebar: fetchTagCounts,
    }); }, [fetchTagCounts]);
    useEffect(function () {
        fetchTagCounts();
    }, [fetchTagCounts]);
    return (React.createElement(TagSettingRouterContext.Provider, { value: tagSettingRouterContextValue },
        React.createElement(Router, { history: history },
            React.createElement("div", { className: "flex flex-row w-full overflow-y-hidden md:min-w-[77.5rem]" },
                React.createElement(TagGroupList, { className: cn("w-full md:w-auto md:min-w-[22.5rem] md:border-r md:border-gray-200", !rootMatch && "hidden md:flex"), isLoading: isInitializing, items: listItems, onAddGroupClick: onAddGroupClick }),
                React.createElement(Switch, null,
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.company.group_detail, requiredPermission: Permission.TagGroupsRead }, function (props) { return (React.createElement(CompanyTagGroupDetailScreen, { key: props.location.key })); }),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.company.edit_group, requiredPermission: Permission.TagGroupsUpdate },
                        React.createElement(CompanyTagGroupEditScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.company.add, requiredPermission: Permission.TagsPublicCreate },
                        React.createElement(CompanyTagCreationScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.company.edit_tag, requiredPermission: Permission.TagsUpdate },
                        React.createElement(CompanyTagEditScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.company.tag_detail, requiredPermission: Permission.TagsRead },
                        React.createElement(CompanyTagDetailScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.contact.group_detail, requiredPermission: Permission.TagGroupsRead }, function (props) { return (React.createElement(ContactTagGroupDetailScreen, { key: props.location.key })); }),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.contact.edit_group, requiredPermission: Permission.TagGroupsUpdate },
                        React.createElement(ContactTagGroupEditScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.contact.edit_tag, requiredPermission: Permission.TagsUpdate },
                        React.createElement(ContactTagEditScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.contact.add, requiredPermission: Permission.TagsPublicCreate },
                        React.createElement(ContactTagCreationScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.contact.tag_detail, requiredPermission: Permission.TagsRead },
                        React.createElement(ContactTagDetailScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.deal.group_detail, requiredPermission: Permission.TagGroupsRead }, function (props) { return React.createElement(DealTagGroupDetailScreen, { key: props.location.key }); }),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.deal.edit_group, requiredPermission: Permission.TagGroupsUpdate },
                        React.createElement(DealTagGroupEditScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.deal.add, requiredPermission: Permission.TagsPublicCreate },
                        React.createElement(DealTagCreationScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.deal.edit_tag, requiredPermission: Permission.TagsUpdate },
                        React.createElement(DealTagEditScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.deal.tag_detail, requiredPermission: Permission.TagsRead },
                        React.createElement(DealTagDetailScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.industry.group_detail, requiredPermission: Permission.TagGroupsRead }, function (props) { return (React.createElement(IndustryTagGroupDetailScreen, { key: props.location.key })); }),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.industry.add, requiredPermission: Permission.TagsPublicCreate },
                        React.createElement(IndustryTagCreationScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.industry.edit_group, requiredPermission: Permission.TagGroupsUpdate },
                        React.createElement(IndustryTagGroupEditScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.industry.edit_tag, requiredPermission: Permission.TagsUpdate },
                        React.createElement(IndustryTagEditScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.industry.tag_detail, requiredPermission: Permission.TagsRead },
                        React.createElement(IndustryTagDetailScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.region.group_detail, requiredPermission: Permission.TagGroupsRead }, function (props) { return (React.createElement(RegionTagGroupDetailScreen, { key: props.location.key })); }),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.region.edit_group, requiredPermission: Permission.TagGroupsUpdate },
                        React.createElement(RegionTagGroupEditScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.region.add, requiredPermission: Permission.TagsPublicCreate },
                        React.createElement(RegionTagCreationScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.region.edit_tag, requiredPermission: Permission.TagsUpdate },
                        React.createElement(RegionTagEditScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.region.tag_detail, requiredPermission: Permission.TagsRead },
                        React.createElement(RegionTagDetailScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.others.group_detail, requiredPermission: Permission.TagGroupsRead }, function (props) { return (React.createElement(OthersTagGroupDetailScreen, { key: props.location.key })); }),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.others.edit_group, requiredPermission: Permission.TagGroupsUpdate },
                        React.createElement(OthersTagGroupEditScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.others.add, requiredPermission: Permission.TagsPublicCreate },
                        React.createElement(OtherTagCreationScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.others.edit_tag, requiredPermission: Permission.TagsUpdate },
                        React.createElement(OtherTagEditScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.others.tag_detail, requiredPermission: Permission.TagsRead },
                        React.createElement(OtherTagDetailScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.priority.group_detail, requiredPermission: Permission.TagGroupsRead }, function (props) { return (React.createElement(PriorityTagGroupDetailScreen, { key: props.location.key })); }),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.priority.edit_group, requiredPermission: Permission.TagGroupsUpdate },
                        React.createElement(PriorityTagGroupEditScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.priority.add, requiredPermission: Permission.TagsPublicCreate },
                        React.createElement(PriorityTagCreationScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.priority.edit_tag, requiredPermission: Permission.TagsUpdate },
                        React.createElement(PriorityTagEditScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.priority.tag_detail, requiredPermission: Permission.TagsRead },
                        React.createElement(PriorityTagDetailScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.new_group, requiredPermission: Permission.TagGroupsCreate },
                        React.createElement(TagGroupCreationScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.tags.custom.edit_group, requiredPermission: Permission.TagGroupsUpdate },
                        React.createElement(TagGroupEditScreen, null)),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.custom.group_detail, requiredPermission: Permission.TagGroupsRead }, function (props) { return (React.createElement(CustomTagGroupDetailScreen, { key: props.location.key })); }),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.custom.add, requiredPermission: Permission.TagsPublicCreate }, function (props) { return React.createElement(CustomTagCreationScreen, { key: props.location.key }); }),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.custom.edit_tag, requiredPermission: Permission.TagsUpdate }, function (props) { return React.createElement(CustomTagEditScreen, { key: props.location.key }); }),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.tags.custom.tag_detail, requiredPermission: Permission.TagsRead }, function (props) { return React.createElement(CustomTagDetailScreen, { key: props.location.key }); }))))));
};
export var TagSettingRouter = React.memo(function () {
    return (React.createElement(TagGroupStoreContextProvider, null,
        React.createElement(InternalTagSettingRouter, null)));
});

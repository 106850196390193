import { __awaiter, __generator, __read, __values } from "tslib";
import React, { useContext, useCallback, useMemo, useState, useEffect, } from "react";
import cn from "classnames";
import { TagIndexContext, } from "@contexts/TagIndexContext";
import { CollapsiblePaginatedList } from "@components/common/CollapsibleList/CollapsiblePaginatedList";
import { IndustryAPIContext, RegionAPIContext, CountryAPIContext, CompanyAPIContext, ContactAPIContext, DocumentAPIContext, } from "@shared/contexts/api";
import { SearchParamContext, composeTagIndexParam, } from "@contexts/SearchParamContext";
var PAGE_SIZE = 20;
var CountState;
(function (CountState) {
    CountState[CountState["loading"] = 0] = "loading";
    CountState[CountState["loaded"] = 1] = "loaded";
})(CountState || (CountState = {}));
export var TagIndexExternalList = React.memo(function (_a) {
    var className = _a.className;
    var industryAPI = useContext(IndustryAPIContext);
    var regionAPI = useContext(RegionAPIContext);
    var countryAPI = useContext(CountryAPIContext);
    var companyAPI = useContext(CompanyAPIContext);
    var contactAPI = useContext(ContactAPIContext);
    var documentAPI = useContext(DocumentAPIContext);
    var tagIndexContext = useContext(TagIndexContext);
    var searchParamContext = useContext(SearchParamContext);
    var selectItem = tagIndexContext.selectItem, selectedItem = tagIndexContext.selectedItem;
    var _b = __read(useState([]), 2), visibleItems = _b[0], setVisibleItems = _b[1];
    var _c = __read(useState(new Map()), 2), itemCountMap = _c[0], setItemCountMap = _c[1];
    var fetchCount = useCallback(function (tagIndex) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, documentAPI
                    .searchDocuments({
                    unread_only: searchParamContext.unreadOnly,
                    tag_index: composeTagIndexParam(tagIndex),
                    page: 1,
                    perPage: 0,
                })
                    .then(function (resp) { return resp.count; })];
        });
    }); }, [documentAPI, searchParamContext.unreadOnly]);
    var fetchCountsForItems = useCallback(function (items) {
        var e_1, _a;
        var _loop_1 = function (item) {
            var consoleError = console.error;
            setItemCountMap(function (countMap) {
                // Loaded already, no need to load again
                if (countMap.get(item) !== undefined)
                    return countMap;
                var newMap = new Map(countMap);
                var loadCountPromise = fetchCount(item);
                var loadingState = {
                    state: CountState.loading,
                    promise: loadCountPromise,
                };
                newMap.set(item, loadingState);
                loadCountPromise
                    .then(function (count) {
                    setItemCountMap(function (countMap) {
                        // Returned promise doesn't match loading state
                        if (countMap.get(item) !== loadingState)
                            return countMap;
                        var newMap = new Map(countMap);
                        newMap.set(item, { state: CountState.loaded, count: count });
                        return newMap;
                    });
                })
                    .catch(function (e) {
                    consoleError("Failed to fetch count", e);
                });
                return newMap;
            });
        };
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                _loop_1(item);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }, [fetchCount]);
    var fetchIndustry = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var page, perPage;
        var _a, _b;
        return __generator(this, function (_c) {
            page = (_a = params === null || params === void 0 ? void 0 : params.page) !== null && _a !== void 0 ? _a : 1;
            perPage = (_b = params === null || params === void 0 ? void 0 : params.perPage) !== null && _b !== void 0 ? _b : PAGE_SIZE;
            return [2 /*return*/, industryAPI
                    .listIndustries({
                    page: page,
                    perPage: perPage,
                })
                    .then(function (resp) {
                    return {
                        items: resp.data.map(function (item) {
                            return {
                                item: { type: "industry", industry: item },
                                fetchNextParams: { page: 1, perPage: PAGE_SIZE },
                                isToggleable: true,
                            };
                        }),
                        fetchNextParams: { page: page + 1, perPage: perPage },
                        hasMore: resp.data.length >= perPage,
                    };
                })];
        });
    }); }, [industryAPI]);
    var fetchRegion = useCallback(function (industry, params) { return __awaiter(void 0, void 0, void 0, function () {
        var page, perPage;
        var _a, _b;
        return __generator(this, function (_c) {
            page = (_a = params === null || params === void 0 ? void 0 : params.page) !== null && _a !== void 0 ? _a : 1;
            perPage = (_b = params === null || params === void 0 ? void 0 : params.perPage) !== null && _b !== void 0 ? _b : PAGE_SIZE;
            return [2 /*return*/, regionAPI
                    .listRegions({
                    page: page,
                    perPage: perPage,
                    industry_id: industry.industry.id,
                })
                    .then(function (resp) {
                    return {
                        items: resp.data.map(function (item) {
                            return {
                                item: {
                                    type: "region",
                                    region: item,
                                    industryId: industry.industry.id,
                                },
                                fetchNextParams: { page: 1, perPage: PAGE_SIZE },
                                isToggleable: true,
                            };
                        }),
                        fetchNextParams: { page: page + 1, perPage: perPage },
                        hasMore: resp.data.length >= perPage,
                    };
                })];
        });
    }); }, [regionAPI]);
    var fetchCountry = useCallback(function (region, params) { return __awaiter(void 0, void 0, void 0, function () {
        var page, perPage;
        var _a, _b;
        return __generator(this, function (_c) {
            page = (_a = params === null || params === void 0 ? void 0 : params.page) !== null && _a !== void 0 ? _a : 1;
            perPage = (_b = params === null || params === void 0 ? void 0 : params.perPage) !== null && _b !== void 0 ? _b : PAGE_SIZE;
            return [2 /*return*/, countryAPI
                    .listCountries({
                    page: page,
                    perPage: perPage,
                    region_id: region.region.id,
                    industry_id: region.industryId,
                })
                    .then(function (resp) {
                    return {
                        items: resp.data.map(function (item) {
                            return {
                                item: {
                                    type: "country",
                                    country: item,
                                    industryId: region.industryId,
                                    regionId: region.region.id,
                                },
                                fetchNextParams: { page: 1, perPage: PAGE_SIZE },
                                isToggleable: true,
                            };
                        }),
                        fetchNextParams: { page: page + 1, perPage: perPage },
                        hasMore: resp.data.length >= perPage,
                    };
                })];
        });
    }); }, [countryAPI]);
    var fetchCompany = useCallback(function (country, params) { return __awaiter(void 0, void 0, void 0, function () {
        var page, perPage;
        var _a, _b;
        return __generator(this, function (_c) {
            page = (_a = params === null || params === void 0 ? void 0 : params.page) !== null && _a !== void 0 ? _a : 1;
            perPage = (_b = params === null || params === void 0 ? void 0 : params.perPage) !== null && _b !== void 0 ? _b : PAGE_SIZE;
            return [2 /*return*/, companyAPI
                    .listCompanies({
                    page: page,
                    perPage: perPage,
                    industry_id: country.industryId,
                    country_id: country.country.id,
                })
                    .then(function (resp) {
                    return {
                        items: resp.data.map(function (item) {
                            return {
                                item: {
                                    type: "company",
                                    company: item,
                                    industryId: country.industryId,
                                    regionId: country.regionId,
                                    countryId: country.country.id,
                                },
                                fetchNextParams: { page: 1, perPage: PAGE_SIZE },
                                isToggleable: true,
                            };
                        }),
                        fetchNextParams: { page: page + 1, perPage: perPage },
                        hasMore: resp.data.length >= perPage,
                    };
                })];
        });
    }); }, [companyAPI]);
    var fetchContact = useCallback(function (company, params) { return __awaiter(void 0, void 0, void 0, function () {
        var page, perPage;
        var _a, _b;
        return __generator(this, function (_c) {
            page = (_a = params === null || params === void 0 ? void 0 : params.page) !== null && _a !== void 0 ? _a : 1;
            perPage = (_b = params === null || params === void 0 ? void 0 : params.perPage) !== null && _b !== void 0 ? _b : PAGE_SIZE;
            return [2 /*return*/, contactAPI
                    .listContacts({
                    page: page,
                    perPage: perPage,
                    companyId: company.company.id,
                })
                    .then(function (resp) {
                    return {
                        items: resp.data.map(function (item) {
                            return {
                                item: {
                                    type: "contact",
                                    contact: item,
                                    industryId: company.industryId,
                                    regionId: company.regionId,
                                    countryId: company.countryId,
                                    companyId: company.company.id,
                                },
                                fetchNextParams: undefined,
                                isToggleable: false,
                            };
                        }),
                        fetchNextParams: { page: page + 1, perPage: perPage },
                        hasMore: resp.data.length >= perPage,
                    };
                })];
        });
    }); }, [contactAPI]);
    var onFetchMore = useCallback(function (source, params) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (source === null) {
                return [2 /*return*/, fetchIndustry(params)];
            }
            switch (source.type) {
                case "industry":
                    return [2 /*return*/, fetchRegion(source, params)];
                case "region":
                    return [2 /*return*/, fetchCountry(source, params)];
                case "country":
                    return [2 /*return*/, fetchCompany(source, params)];
                case "company":
                    return [2 /*return*/, fetchContact(source, params)];
                default:
                    throw new Error("Unexpected: Contact should have no childrens");
            }
            return [2 /*return*/];
        });
    }); }, [fetchCompany, fetchContact, fetchCountry, fetchIndustry, fetchRegion]);
    var onVisibleItemsChanged = useCallback(function (items) {
        setVisibleItems(items);
    }, []);
    var externalLabelExtractor = useCallback(function (tag) {
        switch (tag.type) {
            case "industry":
                return tag.industry.name;
            case "region":
                return tag.region.name;
            case "country":
                return tag.country.name;
            case "company":
                return tag.company.name;
            case "contact":
                return tag.contact.name;
            default:
                throw new Error("Unexpected tag type " + tag.type);
        }
    }, []);
    var externalKeyExtractor = useCallback(function (tag) {
        switch (tag.type) {
            case "industry":
                return "industry/" + tag.industry.id;
            case "region":
                return "industry/" + tag.industryId + "/region/" + tag.region.id;
            case "country":
                return "industry/" + tag.industryId + "/region/" + tag.regionId + "/country/" + tag.country.id;
            case "company":
                return "industry/" + tag.industryId + "/region/" + tag.regionId + "/country/" + tag.countryId + "/comapny/" + tag.company.id;
            case "contact":
                return "industry/" + tag.industryId + "/region/" + tag.regionId + "/country/" + tag.countryId + "/comapny/" + tag.companyId + "/contact/" + tag.contact.id;
            default:
                throw new Error("Unexpected tag type " + tag.type);
        }
    }, []);
    var badgeTextExtractor = useCallback(function (item) {
        var count = itemCountMap.get(item);
        if (!count)
            return "";
        if (count.state === CountState.loading)
            return " ";
        return "" + count.count;
    }, [itemCountMap]);
    var onSelectExternalItem = useCallback(function (tag) {
        selectItem(tag);
    }, [selectItem]);
    var selectedExternalTagKey = useMemo(function () {
        if (selectedItem === null)
            return null;
        switch (selectedItem.type) {
            case "industry":
            case "region":
            case "country":
            case "company":
            case "contact":
                return externalKeyExtractor(selectedItem);
            default:
                return null;
        }
    }, [externalKeyExtractor, selectedItem]);
    useEffect(function () {
        // Clear loaded counts on fetchCount changed
        setItemCountMap(new Map());
        fetchCountsForItems(visibleItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchCount]);
    useEffect(function () {
        // Fetch counts on visible items changed
        fetchCountsForItems(visibleItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleItems]);
    return (React.createElement(CollapsiblePaginatedList, { className: cn(className), onFetchMore: onFetchMore, labelExtractor: externalLabelExtractor, keyExtractor: externalKeyExtractor, onItemSelected: onSelectExternalItem, selectedItemKey: selectedExternalTagKey, onVisibleItemsChange: onVisibleItemsChanged, badgeTextExtractor: badgeTextExtractor }));
});

import { __awaiter, __generator, __read, __spreadArray, __values } from "tslib";
import React, { useCallback, useContext, useState, useRef, useMemo, } from "react";
import cn from "classnames";
import { CompanyAPIContext, ContactAPIContext, DealAPIContext, OtherTagAPIContext, PriorityAPIContext, TagAPIContext, } from "@shared/contexts/api";
import { ThreadDetailForm, } from "./forms/ThreadDetailForm";
import { TagGroupSlug } from "@shared/models/tag";
import { ThreadAPIContext } from "@shared/contexts/api/ThreadAPIContext";
import { UserAPIContext } from "@shared/contexts/api/UserAPIContext";
import { DepartmentAPIContext } from "@shared/contexts/api/DepartmentAPIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { PanelFooterMessage } from "@shared/components/common/PanelFooterMessage";
import { MessageBarType } from "@fluentui/react";
import { RemarkFeed } from "../common/RemarkFeed";
import { ThreadRemarksForm, } from "./forms/ThreadRemarksForm";
import { ThreadDetailEditButton } from "./ThreadDetailEditButton";
import { Spinner } from "../common/Spinner";
var MAX_SUGGESTION_COUNT = 10;
function fetchSuggestions(func, search) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, func({
                    page: 1,
                    perPage: MAX_SUGGESTION_COUNT,
                    search: search,
                    isEnabled: "true",
                }).then(function (resp) { return resp.data; })];
        });
    });
}
function groupCustomTagsByGroupId(tags) {
    var tagMap = {};
    tags.forEach(function (t) {
        var _a;
        var list = (_a = tagMap[t.tagGroupId]) !== null && _a !== void 0 ? _a : [];
        list.push(t);
        tagMap[t.tagGroupId] = list;
    });
    return tagMap;
}
export var ThreadDetailPanel = React.memo(function (props) {
    var className = props.className, isLoading = props.isLoading, threadId = props.threadId, threadDetail = props.threadDetail, tagGroups = props.tagGroups, refreshThreadDetailInfo = props.refreshThreadDetailInfo;
    var intl = useIntl();
    var threadRemarksFormRef = useRef(null);
    var threadDetailFormRef = useRef(null);
    var _a = __read(useState(false), 2), isEditing = _a[0], setIsEditing = _a[1];
    var _b = __read(useState(false), 2), isFormValid = _b[0], setIsFormValid = _b[1];
    var _c = __read(useState(false), 2), isSubmittingTagChanges = _c[0], setIsSubmittingTagChanges = _c[1];
    var _d = __read(useState(false), 2), isSubmittingRemark = _d[0], setIsSubmittingRemark = _d[1];
    var _e = __read(useState([]), 2), successMessages = _e[0], setSuccessMessages = _e[1];
    var _f = __read(useState([]), 2), errorMessages = _f[0], setErrorMessages = _f[1];
    var tagGroupBySlug = useMemo(function () {
        var e_1, _a;
        var map = new Map();
        try {
            for (var tagGroups_1 = __values(tagGroups), tagGroups_1_1 = tagGroups_1.next(); !tagGroups_1_1.done; tagGroups_1_1 = tagGroups_1.next()) {
                var group = tagGroups_1_1.value;
                var slug = group.slug;
                if (!slug)
                    continue;
                map.set(slug, group);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (tagGroups_1_1 && !tagGroups_1_1.done && (_a = tagGroups_1.return)) _a.call(tagGroups_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return map;
    }, [tagGroups]);
    var customTagGroups = useMemo(function () {
        return tagGroups.filter(function (group) { return group.slug === null; });
    }, [tagGroups]);
    var initialFormValues = useMemo(function () { return ({
        companies: {
            data: threadDetail.companies,
        },
        contacts: {
            data: threadDetail.contacts,
        },
        deals: {
            data: threadDetail.deals,
        },
        priorities: {
            data: threadDetail.priorities,
        },
        otherTags: {
            data: threadDetail.otherTags,
        },
        sharedWiths: {
            data: threadDetail.sharedWiths,
        },
        departments: {
            data: threadDetail.departments,
        },
        customTags: groupCustomTagsByGroupId(threadDetail.customTags),
    }); }, [threadDetail]);
    var remarks = useMemo(function () { return threadDetail.remarks; }, [threadDetail]);
    var companyAPI = useContext(CompanyAPIContext);
    var contactAPI = useContext(ContactAPIContext);
    var dealAPI = useContext(DealAPIContext);
    var priorityAPI = useContext(PriorityAPIContext);
    var otherTagAPI = useContext(OtherTagAPIContext);
    var threadAPI = useContext(ThreadAPIContext);
    var tagAPI = useContext(TagAPIContext);
    var userAPI = useContext(UserAPIContext);
    var departmentAPI = useContext(DepartmentAPIContext);
    var fetchCompanySuggestion = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchSuggestions(companyAPI.listCompanies, search)];
    }); }); }, [companyAPI]);
    var fetchContactSuggestion = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchSuggestions(contactAPI.listContacts, search)];
    }); }); }, [contactAPI]);
    var fetchDealSuggestion = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchSuggestions(dealAPI.listDeals, search)];
    }); }); }, [dealAPI]);
    var fetchPrioritySuggestion = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchSuggestions(priorityAPI.listPriorities, search)];
    }); }); }, [priorityAPI]);
    var fetchOtherTagSuggestion = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchSuggestions(otherTagAPI.listOtherTags, search)];
    }); }); }, [otherTagAPI]);
    var fetchCustomTagSuggestion = useCallback(function (tagGroupId, search) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, fetchSuggestions(function (request) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2 /*return*/, tagAPI.listTags(tagGroupId, request)];
                }); }); }, search)];
        });
    }); }, [tagAPI]);
    var fetchUserSuggestion = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, userAPI
                    .listUsers({
                    page: 1,
                    perPage: MAX_SUGGESTION_COUNT,
                    search: search,
                    isEnabled: "true",
                    excludeSystem: true,
                })
                    .then(function (resp) { return resp.data; })];
        });
    }); }, [userAPI]);
    var fetchDepartmentSuggestion = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, fetchSuggestions(departmentAPI.listDepartments, search)];
    }); }); }, [departmentAPI]);
    var validateForm = useCallback(function (value) {
        var e_2, _a, e_3, _b;
        var contactGroup = tagGroupBySlug.get(TagGroupSlug.Contact);
        var companyGroup = tagGroupBySlug.get(TagGroupSlug.Company);
        var dealGroup = tagGroupBySlug.get(TagGroupSlug.Deal);
        var priorityGroup = tagGroupBySlug.get(TagGroupSlug.Priority);
        var otherTagGroup = tagGroupBySlug.get(TagGroupSlug.OtherTag);
        var companies = value.companies, contacts = value.contacts, deals = value.deals, priorities = value.priorities, otherTags = value.otherTags, customTags = value.customTags;
        var groupItemsPairs = [
            [contactGroup, contacts.data],
            [companyGroup, companies.data],
            [dealGroup, deals.data],
            [priorityGroup, priorities.data],
            [otherTagGroup, otherTags.data],
        ];
        try {
            for (var groupItemsPairs_1 = __values(groupItemsPairs), groupItemsPairs_1_1 = groupItemsPairs_1.next(); !groupItemsPairs_1_1.done; groupItemsPairs_1_1 = groupItemsPairs_1.next()) {
                var _c = __read(groupItemsPairs_1_1.value, 2), group = _c[0], items = _c[1];
                if ((group === null || group === void 0 ? void 0 : group.mandatory) && items.length < 1)
                    return false;
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (groupItemsPairs_1_1 && !groupItemsPairs_1_1.done && (_a = groupItemsPairs_1.return)) _a.call(groupItemsPairs_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        try {
            for (var customTagGroups_1 = __values(customTagGroups), customTagGroups_1_1 = customTagGroups_1.next(); !customTagGroups_1_1.done; customTagGroups_1_1 = customTagGroups_1.next()) {
                var customGroup = customTagGroups_1_1.value;
                var items = customTags[customGroup.id];
                if (customGroup.mandatory && (items == null || items.length < 1)) {
                    return false;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (customTagGroups_1_1 && !customTagGroups_1_1.done && (_b = customTagGroups_1.return)) _b.call(customTagGroups_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return true;
    }, [customTagGroups, tagGroupBySlug]);
    var onStartEditing = useCallback(function () {
        setIsEditing(true);
        setIsFormValid(validateForm(initialFormValues));
    }, [initialFormValues, validateForm]);
    var clearError = useCallback(function () {
        setErrorMessages([]);
    }, [setErrorMessages]);
    var onDismissError = useCallback(function (index) {
        setErrorMessages(function (messages) {
            return messages.filter(function (_, idx) { return idx !== index; });
        });
    }, [setErrorMessages]);
    var onDismissSuccess = useCallback(function (index) {
        setSuccessMessages(function (messages) {
            return messages.filter(function (_, idx) { return idx !== index; });
        });
    }, [setSuccessMessages]);
    var onSubmitRemark = useCallback(function (t) { return __awaiter(void 0, void 0, void 0, function () {
        var err_1, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsSubmittingRemark(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, threadAPI.createRemark(threadId, {
                            remark: t.remark,
                        })];
                case 2:
                    _b.sent();
                    clearError();
                    (_a = threadRemarksFormRef.current) === null || _a === void 0 ? void 0 : _a.resetForm();
                    refreshThreadDetailInfo();
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _b.sent();
                    console.error("Failed to submit remark", err_1);
                    error_1 = intl.formatMessage({
                        id: "uploads.detail.thread_detail.form.remarks.submission.error",
                    });
                    setErrorMessages(function (e) { return __spreadArray(__spreadArray([], __read(e)), [error_1]); });
                    return [3 /*break*/, 5];
                case 4:
                    setIsSubmittingRemark(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [clearError, refreshThreadDetailInfo, intl, threadAPI, threadId]);
    var onSubmitChanges = useCallback(function (t) { return __awaiter(void 0, void 0, void 0, function () {
        var mappedTagIds, err_2, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsSubmittingTagChanges(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    mappedTagIds = Object.values(t.customTags)
                        .filter(function (t) { return !!t; })
                        .flatMap(function (p) { return p.map(function (t) { return t.id; }); });
                    return [4 /*yield*/, threadAPI.setThreadTags(threadId, {
                            company_ids: t.companies.data.map(function (c) { return c.id; }),
                            contact_ids: t.contacts.data.map(function (c) { return c.id; }),
                            deal_ids: t.deals.data.map(function (d) { return d.id; }),
                            priority_ids: t.priorities.data.map(function (p) { return p.id; }),
                            other_tag_ids: t.otherTags.data.map(function (o) { return o.id; }),
                            share_with_ids: t.sharedWiths.data.map(function (p) { return p.id; }),
                            department_ids: t.departments.data.map(function (d) { return d.id; }),
                            tag_ids: mappedTagIds,
                        })];
                case 2:
                    _b.sent();
                    clearError();
                    setIsEditing(false);
                    setIsFormValid(false);
                    (_a = threadDetailFormRef.current) === null || _a === void 0 ? void 0 : _a.resetForm();
                    refreshThreadDetailInfo();
                    setSuccessMessages(function (s) { return __spreadArray(__spreadArray([], __read(s)), [
                        intl.formatMessage({
                            id: "uploads.detail.thread_detail.form.tags.submission.success",
                        }),
                    ]); });
                    return [3 /*break*/, 5];
                case 3:
                    err_2 = _b.sent();
                    console.error("ThreadDetailPanel submit error.", err_2);
                    error_2 = intl.formatMessage({
                        id: "uploads.detail.thread_detail.form.tags.submission.error",
                    });
                    setErrorMessages(function (e) { return __spreadArray(__spreadArray([], __read(e)), [error_2]); });
                    return [3 /*break*/, 5];
                case 4:
                    setIsSubmittingTagChanges(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [threadAPI, threadId, clearError, refreshThreadDetailInfo, intl]);
    var onPressSubmit = useCallback(function () {
        var _a;
        (_a = threadDetailFormRef.current) === null || _a === void 0 ? void 0 : _a.handleSubmit();
    }, []);
    var onFormValueChange = useCallback(function (value) {
        setIsFormValid(function () {
            return validateForm(value);
        });
    }, [validateForm]);
    return (React.createElement("div", { className: cn("bg-gray-50 flex flex-col overflow-hidden w-full md:w-auto md:w-[20.75rem]", className) },
        isLoading ? (React.createElement("div", { className: "flex-1 flex flex-col items-center justify-center" },
            React.createElement(Spinner, { size: "large" }),
            React.createElement("span", { className: "text-indigo-900 font-normal text-xs leading-4 mt-2" },
                React.createElement(FormattedMessage, { id: "common.loading" })))) : (React.createElement("div", { className: "flex flex-col overflow-auto p-4 space-y-4" },
            React.createElement("div", { className: "flex flex-row items-center justify-between" },
                React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900" },
                    React.createElement(FormattedMessage, { id: "uploads.detail.thread_detail.form.section.thread_details.title" })),
                threadDetail.isModifiable && (React.createElement(ThreadDetailEditButton, { isEditing: isEditing, canSubmit: isFormValid, onStartEditing: onStartEditing, onSubmitChanges: onPressSubmit }))),
            React.createElement(ThreadDetailForm, { ref: threadDetailFormRef, onChange: onFormValueChange, contactGroup: tagGroupBySlug.get(TagGroupSlug.Contact), companyGroup: tagGroupBySlug.get(TagGroupSlug.Company), dealGroup: tagGroupBySlug.get(TagGroupSlug.Deal), priorityGroup: tagGroupBySlug.get(TagGroupSlug.Priority), otherTagGroup: tagGroupBySlug.get(TagGroupSlug.OtherTag), customTagGroups: customTagGroups, isEditing: isEditing, isSubmitting: isSubmittingTagChanges, initialFormValues: initialFormValues, fetchCompanySuggestion: fetchCompanySuggestion, fetchContactSuggestion: fetchContactSuggestion, fetchDealSuggestion: fetchDealSuggestion, fetchPrioritySuggestion: fetchPrioritySuggestion, fetchOtherTagSuggestion: fetchOtherTagSuggestion, fetchCustomTagSuggestion: fetchCustomTagSuggestion, fetchUserSuggestion: fetchUserSuggestion, fetchDepartmentSuggestion: fetchDepartmentSuggestion, onSubmit: onSubmitChanges }),
            React.createElement(ThreadRemarksForm, { ref: threadRemarksFormRef, isSubmitting: isSubmittingRemark, onSubmit: onSubmitRemark }),
            React.createElement(RemarkFeed, { className: "mt-1", remarks: remarks }))),
        React.createElement("footer", { className: "block w-full" },
            errorMessages.map(function (e, idx) { return (React.createElement(PanelFooterMessage, { key: idx, index: idx, type: MessageBarType.error, message: e, onDismiss: onDismissError })); }),
            successMessages.map(function (e, idx) { return (React.createElement(PanelFooterMessage, { key: idx, index: idx, type: MessageBarType.success, message: e, onDismiss: onDismissSuccess })); }))));
});

import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { Persona, PersonaSize } from "@fluentui/react";
import { CompanyAPIContext } from "@shared/contexts/api/CompanyAPIContext";
import { ContactAPIContext } from "@shared/contexts/api/ContactAPIContext";
import { useDebounce } from "@hooks/useDebounce";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { CompanyExtractors } from "@shared/models/company";
import { ContactExtractors } from "@shared/models/contact";
import { ROUTES } from "@router/routes";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { PrimaryButton } from "../common/PrimaryButton";
import { SearchBar } from "../common/SearchBar";
import { SkeletonTable, Table, } from "../common/Table";
import { useAppToast } from "../common/Toast";
import { ColouredTag } from "@components/common/ColouredTag";
import { extractInitials } from "@utils/avatar";
import { TagDetailHeader } from "../settings/TagDetailHeader";
import { TagDetailInfo } from "../settings/TagDetailInfo";
import { useNavigation } from "@hooks/useNavigation";
import { PeoplePicker } from "../common/PeoplePicker";
import { CheckIcon, ExclamationIcon, XIcon } from "@heroicons/react/solid";
import { Dialog, useDialog } from "../common/Dialog";
import { DropDown } from "../common/DropDown";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { getTagGroupCustomFieldsEntries, } from "@shared/models/tag";
import { useMergeTagDialog, MergeTagDialog } from "../common/MergeTagDialog";
import RequirePermission from "../common/RequirePermission";
import { Permission } from "@shared/models/auth";
import useHasPermission from "@hooks/useHasPermission";
var MAX_CONTACT_SUGGESTION_COUNT = 10;
var ContactContextMenu = React.memo(function (props) {
    var contact = props.contact, onDelete_ = props.onDelete;
    var onDelete = useCallback(function () {
        onDelete_(contact);
    }, [contact, onDelete_]);
    return (React.createElement(DropDown.Button, null,
        React.createElement(DropDown.Item, { onClick: onDelete },
            React.createElement(FormattedMessage, { id: "settings.tags.company.tag.detail.contact.remove_contact" }))));
});
var ContactEditor = React.memo(function (props) {
    var companyId = props.companyId, isSubmitting = props.isSubmitting, onConfirm_ = props.onConfirm, onCancel = props.onCancel;
    var contactAPI = useContext(ContactAPIContext);
    var _a = __read(useState([]), 2), selectedContacts = _a[0], setSelectedContacts = _a[1];
    var onConfirm = useCallback(function () {
        onConfirm_(selectedContacts);
    }, [selectedContacts, onConfirm_]);
    var fetchContactSuggestion = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, contactAPI
                    .listContacts({
                    page: 1,
                    perPage: MAX_CONTACT_SUGGESTION_COUNT,
                    search: search,
                    notCompanyId: companyId,
                })
                    .then(function (resp) { return resp.data; })];
        });
    }); }, [companyId, contactAPI]);
    var isSubmissionDisabled = useMemo(function () {
        return isSubmitting || selectedContacts.length === 0;
    }, [isSubmitting, selectedContacts.length]);
    return (React.createElement("div", { className: "flex flex-row items-center px-3 py-2 space-x-3" },
        React.createElement(PeoplePicker, { className: "flex-1", inputClassName: "!h-[2.625rem]", fetchTags: fetchContactSuggestion, keyExtractor: ContactExtractors.id, labelExtractor: ContactExtractors.name, avatarExtractor: ContactExtractors.avatarUrl, onTagChange: setSelectedContacts }),
        React.createElement("button", { type: "button", className: "h-auto inline-flex items-center rounded-full text-indigo-700 disabled:cursor-not-allowed disabled:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500", disabled: isSubmissionDisabled, onClick: onConfirm },
            React.createElement(CheckIcon, { className: "h-6 w-6", "aria-hidden": "true" })),
        React.createElement("button", { type: "button", className: "h-auto inline-flex items-center rounded-full text-gray-600  disabled:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500", disabled: isSubmitting, onClick: onCancel },
            React.createElement(XIcon, { className: "h-6 w-6", "aria-hidden": "true" }))));
});
// eslint-disable-next-line complexity
export var CompanyTagDetailScreen = React.memo(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var tagId = useParams().tagId;
    var companyId = useMemo(function () { return parseInt(tagId, 10); }, [tagId]);
    var navigation = useNavigation();
    var location = useLocation();
    var showErrorToast = useAppToast().showErrorToast;
    var companyAPI = useContext(CompanyAPIContext);
    var contactAPI = useContext(ContactAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _k = useDialog(), dialogProps = _k.dialogProps, openDeleteContactDialog = _k.openDialog;
    var _l = __read(useState(null), 2), company = _l[0], setCompany = _l[1];
    var _m = __read(useState([]), 2), availableCustomProperties = _m[0], setAvailableCustomProperties = _m[1];
    var _o = __read(useState(false), 2), isLoading = _o[0], setIsLoading = _o[1];
    var _p = __read(useState(null), 2), contactSearchValue = _p[0], setContactSearchValue = _p[1];
    var _q = __read(useState(false), 2), showContactEditor = _q[0], setShowContactEditor = _q[1];
    var _r = __read(useState(false), 2), isEditingContact = _r[0], setIsEditingContact = _r[1];
    var debouncedContactSearchValue = useDebounce(contactSearchValue, 500);
    var _s = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _s[0], setTagName = _s[1];
    var intl = useIntl();
    var goGroupDetail = useCallback(function () {
        navigation.replace(ROUTES.settings.tags.company.group_detail);
    }, [navigation]);
    var _t = useSearchPagination(contactAPI.listContacts, {
        page: 1,
        perPage: 100,
        companyId: companyId,
    }), data = _t.data, initialTotalCount = _t.initialTotalCount, totalCount = _t.totalCount, isInitializing = _t.isInitializing, isFetchingMore = _t.isFetchingMore, hasMore = _t.hasMore, nextPage = _t.nextPage, refresh = _t.refresh, search = _t.search;
    var fetchCompany = useCallback(function () {
        setIsLoading(true);
        Promise.all([
            companyAPI.getCompany(companyId),
            tagGroupAPI.getTagGroupBySlug("company"),
        ])
            .then(function (_a) {
            var _b = __read(_a, 2), company = _b[0], tagGroup = _b[1];
            setCompany(company);
            setTagName(company.name);
            setAvailableCustomProperties(getTagGroupCustomFieldsEntries(tagGroup.definition).map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return ({
                    name: value.name,
                    value: company.additional[key],
                });
            }));
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            goGroupDetail();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        companyId,
        companyAPI,
        tagGroupAPI,
        setIsLoading,
        setAvailableCustomProperties,
        showErrorToast,
        goGroupDetail,
    ]);
    var onEditTag = useCallback(function () {
        navigation.push(ROUTES.settings.tags.company.group_detail + "/" + companyId + "/edit");
    }, [navigation, companyId]);
    var onEnableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, companyAPI.enableCompany(companyId)];
        });
    }); }, [companyId, companyAPI]);
    var onDisableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, companyAPI.disableCompany(companyId)];
        });
    }); }, [companyId, companyAPI]);
    var onDeleteTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, companyAPI.deleteCompany(companyId).then(function () {
                    goGroupDetail();
                })];
        });
    }); }, [companyAPI, companyId, goGroupDetail]);
    var _u = useMergeTagDialog(), mergeDialogProps = _u.mergeDialogProps, openMergeDialog = _u.openMergeDialog, closeMergeDialog = _u.closeMergeDialog;
    var _v = __read(useState(false), 2), isMerging = _v[0], setIsMerging = _v[1];
    var onMergeTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var source, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openMergeDialog()];
                case 1:
                    source = _a.sent();
                    if (!source) {
                        return [2 /*return*/];
                    }
                    setIsMerging(true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, companyAPI.mergeCompany(companyId, source.id)];
                case 3:
                    _a.sent();
                    closeMergeDialog();
                    fetchCompany();
                    refresh();
                    return [3 /*break*/, 6];
                case 4:
                    err_1 = _a.sent();
                    console.error("Failed to merge tag", err_1);
                    showErrorToast({
                        titleId: "merge_tag.error.title",
                        descriptionId: "merge_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 6];
                case 5:
                    setIsMerging(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [
        openMergeDialog,
        companyAPI,
        companyId,
        closeMergeDialog,
        refresh,
        showErrorToast,
        fetchCompany,
    ]);
    var onContactSearchValueChange = useCallback(function (e) {
        setContactSearchValue(e.currentTarget.value);
    }, [setContactSearchValue]);
    var renderPersonaText = useCallback(function (props) {
        return (React.createElement("span", { className: "px-1 text-sm leading-5 font-bold text-gray-800" }, props === null || props === void 0 ? void 0 : props.text));
    }, []);
    var onAddNewContact = useCallback(function () {
        setShowContactEditor(true);
    }, [setShowContactEditor]);
    var onCancelAddNewContact = useCallback(function () {
        setShowContactEditor(false);
    }, [setShowContactEditor]);
    var onConfirmAddNewContact = useCallback(function (contacts) {
        setIsEditingContact(true);
        contactAPI
            .assignCompany({
            companyId: companyId,
            contactIds: contacts.map(function (c) { return c.id; }),
        })
            .then(function () {
            refresh();
        })
            .catch(function (err) {
            console.error(err);
            showErrorToast({
                titleId: "settings.tags.company.tag.detail.add_contact.submission.failure.title",
                descriptionId: "settings.tags.company.tag.detail.add_contact.submission.failure.description",
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsEditingContact(false);
            setShowContactEditor(false);
        });
    }, [
        companyId,
        contactAPI,
        showErrorToast,
        refresh,
        setShowContactEditor,
        setIsEditingContact,
    ]);
    var onDeleteContact = useCallback(function (c) { return __awaiter(void 0, void 0, void 0, function () {
        var confirm, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openDeleteContactDialog({
                        title: intl.formatMessage({
                            id: "settings.tags.company.tag.detail.remove_contact.confirmation.title",
                        }, {
                            name: c.name || c.emails[0],
                        }),
                        description: intl.formatMessage({
                            id: "settings.tags.company.tag.detail.remove_contact.confirmation.description",
                        }, {
                            name: c.name || c.emails[0],
                        }),
                    })];
                case 1:
                    confirm = _a.sent();
                    if (!confirm) {
                        return [2 /*return*/];
                    }
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, contactAPI.assignCompany({
                            contactIds: [c.id],
                            companyId: null,
                        })];
                case 3:
                    _a.sent();
                    refresh();
                    return [3 /*break*/, 5];
                case 4:
                    err_2 = _a.sent();
                    console.error(err_2);
                    showErrorToast({
                        titleId: "settings.tags.company.tag.detail.remove_contact.submission.failure.title",
                        descriptionId: "settings.tags.company.tag.detail.remove_contact.submission.failure.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [contactAPI, intl, refresh, openDeleteContactDialog, showErrorToast]);
    var contactTableColumns = useMemo(function () { return [
        {
            titleId: "settings.tags.contact.detail.table.contact_tag",
            accessor: function (c) { return c.name; },
            render: function (_, c) {
                var _a, _b;
                return (React.createElement(Persona, { size: PersonaSize.size24, text: c.name || c.emails[0], imageInitials: extractInitials(c.name || c.emails[0], 2), imageUrl: (_b = (_a = c.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined, onRenderPrimaryText: renderPersonaText, className: "bg-gray-100 rounded-full w-max max-w-full" }));
            },
        },
        {
            titleId: "settings.tags.contact.detail.table.full_name",
            accessor: function (c) { return c.name; },
        },
        {
            titleId: "settings.tags.contact.detail.table.company",
            accessor: function (c) { var _a, _b; return (_b = (_a = c.company) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : null; },
        },
    ]; }, [renderPersonaText]);
    var contactTableActions = useMemo(function () { return [
        {
            className: "flex items-center justify-end",
            render: function (c) { return (React.createElement(ContactContextMenu, { contact: c, onDelete: onDeleteContact })); },
        },
    ]; }, [onDeleteContact]);
    useEffect(function () {
        if (debouncedContactSearchValue != null) {
            search(debouncedContactSearchValue, {
                companyId: companyId,
            });
        }
    }, [debouncedContactSearchValue, companyId, search]);
    useEffect(function () {
        fetchCompany();
    }, [fetchCompany]);
    var hasPermission = useHasPermission();
    return (React.createElement("div", { className: "flex flex-col min-w-0 flex-1" },
        React.createElement(Dialog, __assign({}, dialogProps, { confirmLabelId: "common.delete", cancelLabelId: "common.cancel", icon: React.createElement(ExclamationIcon, { className: "h-6 w-6 text-red-600", "aria-hidden": "true" }) })),
        React.createElement(MergeTagDialog, __assign({}, mergeDialogProps, { destinationId: companyId, tagName: tagName, fetch: companyAPI.listCompanies, isMerging: isMerging, keyExtractor: CompanyExtractors.id, labelExtractor: CompanyExtractors.name })),
        React.createElement(TagDetailHeader, { typeId: "settings.tags.title.company", tagName: tagName, isTagEnabled: (_c = company === null || company === void 0 ? void 0 : company.isEnabled) !== null && _c !== void 0 ? _c : true, description: intl.formatMessage({
                id: "settings.tags.tag_counts.contact",
            }, {
                count: initialTotalCount,
            }), onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onMergeTag, onGoBack: goGroupDetail }),
        React.createElement("div", { className: "flex-1 mt-2 overflow-y-auto md:mt-0 md:p-4" },
            React.createElement(TagDetailInfo.Panel, { isLoading: isLoading },
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.company.form.full_name" }), value: (_d = company === null || company === void 0 ? void 0 : company.fullName) !== null && _d !== void 0 ? _d : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.company.form.country" }), value: (_e = company === null || company === void 0 ? void 0 : company.countries.map(function (c) { return c.name; }).join(", ")) !== null && _e !== void 0 ? _e : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.company.form.industry" }), value: (_g = (_f = company === null || company === void 0 ? void 0 : company.industry) === null || _f === void 0 ? void 0 : _f.fullName) !== null && _g !== void 0 ? _g : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.company.form.domain" }), value: (_h = company === null || company === void 0 ? void 0 : company.domains.join(",")) !== null && _h !== void 0 ? _h : "" }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.company.form.color" }), value: (company === null || company === void 0 ? void 0 : company.color) ? (React.createElement(ColouredTag, { tag: company.color.name, colour: company.color })) : undefined }),
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.company.form.address" }), value: (_j = company === null || company === void 0 ? void 0 : company.address) !== null && _j !== void 0 ? _j : "" }),
                availableCustomProperties.map(function (value, index) { return (React.createElement(TagDetailInfo.Item, { key: index, title: value.name, value: value.value })); })),
            React.createElement("div", { className: "flex flex-col py-[1.125rem] px-4 md:items-center md:flex-row" },
                React.createElement("div", { className: "flex-1 flex-col" },
                    React.createElement("h1", { className: "text-base leading-6 font-bold text-gray-900" },
                        React.createElement(FormattedMessage, { id: "settings.tags.company.tag.detail.contact.title" })),
                    React.createElement("span", { className: "text-sm leading-5 font-normal text-gray-600" },
                        React.createElement(FormattedMessage, { id: "settings.tags.tag_counts.contact", values: { count: totalCount } }))),
                React.createElement("div", { className: " flex flex-col space-y-2 items-start justify-center mt-[0.375rem] md:mt-0 md:space-y-0 md:flex-row md:space-x-2 md:items-center" },
                    React.createElement(SearchBar, { containerClassName: "flex-1 min-w-[20rem] bg-white rounded-md border border-gray-300", searchIconClassName: "text-gray-400", inputClassName: "text-gray-500 placeholder-gray-500 md:text-sm", placeholder: intl.formatMessage({
                            id: "settings.tags.company.tag.detail.contact.search_placeholder",
                        }), value: contactSearchValue !== null && contactSearchValue !== void 0 ? contactSearchValue : "", onChange: onContactSearchValueChange }),
                    React.createElement(RequirePermission, { permission: Permission.TagsUpdate },
                        React.createElement(PrimaryButton, { size: "sm", onClick: onAddNewContact },
                            React.createElement(FormattedMessage, { id: "settings.tags.company.tag.detail.contact.add_contact" }))))),
            React.createElement("div", { className: "h-full max-h-[25.3125rem] flex flex-col overflow-x-hidden" }, isInitializing || isLoading ? (React.createElement(SkeletonTable, { labelClassName: "text-xs leading-4 font-medium text-gray-500", cellClassName: "text-sm leading-5 font-normal text-gray-500", columns: contactTableColumns })) : (React.createElement(Table, { data: data, labelClassName: "text-xs leading-4 font-medium text-gray-500", cellClassName: "text-sm leading-5 font-normal text-gray-500", columns: contactTableColumns, actions: 
                // Disassociate contact from company, use update permission
                hasPermission(Permission.TagsUpdate)
                    ? contactTableActions
                    : undefined, onFetchMore: nextPage, hasMore: hasMore, isFetchingMore: isFetchingMore, prependedElement: showContactEditor ? (React.createElement(ContactEditor, { companyId: companyId, isSubmitting: isEditingContact, onCancel: onCancelAddNewContact, onConfirm: onConfirmAddNewContact })) : undefined }))))));
});

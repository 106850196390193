import { __read } from "tslib";
import React, { useContext, useCallback, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import cn from "classnames";
import { FilterContext } from "@contexts/FilterContext";
import { TagGroupAPIContext } from "@shared/contexts/api";
import { Spinner } from "@components/common/Spinner";
import { PrimaryButton } from "@components/common/PrimaryButton";
import { WhiteButton } from "@components/common/WhiteButton";
import { useAppToast } from "@components/common/Toast";
import { FromFilter } from "./FromFilter";
import { ToCcFilter } from "./ToCcFilter";
import { UploadedByFilter } from "./UploadedByFilter";
import { ContactFilter } from "./ContactFilter";
import { IndustryFilter } from "./IndustryFilter";
import { CountryFilter } from "./CountryFilter";
import { RegionFilter } from "./RegionFilter";
import { PriorityFilter } from "./PriorityFilter";
import { OthersFilter } from "./OthersFilter";
import { TagFilter } from "./TagFilter";
var MoreFilterPanelBody = React.memo(function (props) {
    var customGroups = props.customGroups;
    var _a = __read(useState(null), 2), expandedFilter = _a[0], setExpanedFilter = _a[1];
    var _b = useContext(FilterContext), clearAllFilters = _b.clearAllFilters, closeFilterPanel = _b.closeFilterPanel;
    var onClearFilterClick = useCallback(function () {
        clearAllFilters();
    }, [clearAllFilters]);
    var onApplyFilterClick = useCallback(function () {
        closeFilterPanel();
    }, [closeFilterPanel]);
    var toggleFilter = useCallback(function (key) {
        setExpanedFilter(function (currentKey) {
            if (key === currentKey) {
                return null;
            }
            return key;
        });
    }, []);
    return (React.createElement("section", { className: "block bg-white p-4 pt-0" },
        React.createElement("div", null,
            React.createElement(FromFilter, { filterKey: "from", isCollapsed: expandedFilter !== "from", onToggle: toggleFilter })),
        React.createElement("div", { className: "mt-3" },
            React.createElement(ToCcFilter, { filterKey: "toOrCc", isCollapsed: expandedFilter !== "toOrCc", onToggle: toggleFilter })),
        React.createElement("div", { className: "mt-3" },
            React.createElement(UploadedByFilter, { filterKey: "uploadedBy", isCollapsed: expandedFilter !== "uploadedBy", onToggle: toggleFilter })),
        React.createElement("div", { className: "mt-3" },
            React.createElement(ContactFilter, { filterKey: "contacts", isCollapsed: expandedFilter !== "contacts", onToggle: toggleFilter })),
        React.createElement("div", { className: "mt-3" },
            React.createElement(IndustryFilter, { filterKey: "industry", isCollapsed: expandedFilter !== "industry", onToggle: toggleFilter })),
        React.createElement("div", { className: "mt-3" },
            React.createElement(CountryFilter, { filterKey: "country", isCollapsed: expandedFilter !== "country", onToggle: toggleFilter })),
        React.createElement("div", { className: "mt-3" },
            React.createElement(RegionFilter, { filterKey: "region", isCollapsed: expandedFilter !== "region", onToggle: toggleFilter })),
        React.createElement("div", { className: "mt-3" },
            React.createElement(PriorityFilter, { filterKey: "priority", isCollapsed: expandedFilter !== "priority", onToggle: toggleFilter })),
        React.createElement("div", { className: "mt-3" },
            React.createElement(OthersFilter, { filterKey: "others", isCollapsed: expandedFilter !== "others", onToggle: toggleFilter })),
        customGroups.map(function (group) {
            return (React.createElement("div", { className: "mt-3", key: group.id },
                React.createElement(TagFilter, { tagGroup: group, filterKey: "tagGroup/" + group.id, isCollapsed: expandedFilter !== "tagGroup/" + group.id, onToggle: toggleFilter })));
        }),
        React.createElement("footer", { className: "flex justify-end items-center pt-4" },
            React.createElement(WhiteButton, { size: "sm", onClick: onClearFilterClick },
                React.createElement(FormattedMessage, { id: "more_filter_panel.clear_filter" })),
            React.createElement(PrimaryButton, { size: "sm", className: "ml-2", onClick: onApplyFilterClick },
                React.createElement(FormattedMessage, { id: "more_filter_panel.apply_filter" })))));
});
export var MoreFilterPanel = React.memo(function (props) {
    var className = props.className;
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var showErrorToast = useAppToast().showErrorToast;
    var _a = __read(useState({ state: "idle" }), 2), customTagGroupState = _a[0], setCustomTagGroupState = _a[1];
    useEffect(function () {
        setCustomTagGroupState({ state: "loading" });
        tagGroupAPI
            .getAllTagGroups({ isCustom: true, isVisible: true })
            .then(function (resp) {
            setCustomTagGroupState({ state: "loaded", data: resp.data });
        })
            .catch(function (e) {
            console.error("MoreFilterPanel load custom tag group error.", e);
            setCustomTagGroupState({ state: "error", error: e });
            showErrorToast({
                titleId: "common.errors.unknown.title",
                descriptionId: "common.errors.unknown.description",
            }, {
                position: "bottom-center",
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement("div", { className: cn("bg-[#6B7280BF]", "overflow-y-auto", className) }, customTagGroupState.state === "loaded" ? (React.createElement(MoreFilterPanelBody, { customGroups: customTagGroupState.data })) : customTagGroupState.state === "loading" ? (React.createElement("div", { className: "bg-white p-4" },
        React.createElement(Spinner, { size: "large" }))) : null));
});

import { __assign, __rest } from "tslib";
import React, { useCallback } from "react";
import { TagPicker } from "./TagPicker";
import { PeoplePickerItem, PeoplePickerItemSuggestion, PersonaSize, ValidationState, } from "@fluentui/react";
import { extractInitials } from "@utils/avatar";
function PeoplePickerImpl(pickerProps) {
    var labelExtractor = pickerProps.labelExtractor, avatarExtractor = pickerProps.avatarExtractor, restProps = __rest(pickerProps, ["labelExtractor", "avatarExtractor"]);
    var renderItem = useCallback(function (props) {
        var personaConfig = {
            ValidationState: ValidationState.valid,
            imageInitials: extractInitials(labelExtractor(props.item), 2),
            imageUrl: avatarExtractor === null || avatarExtractor === void 0 ? void 0 : avatarExtractor(props.item),
            size: PersonaSize.size24,
            text: labelExtractor(props.item),
            className: "bg-gray-100 rounded-full overflow-hidden min-w-0 overflow-ellipsis",
        };
        return (React.createElement(PeoplePickerItem, { key: props.key, index: props.index, className: "m-1 overflow-hidden", disabled: props.disabled, onRemoveItem: props.onRemoveItem, item: personaConfig }));
    }, [avatarExtractor, labelExtractor]);
    var renderSuggestionItem = useCallback(function (props) {
        var personaConfig = {
            imageInitials: extractInitials(labelExtractor(props), 2),
            imageUrl: avatarExtractor === null || avatarExtractor === void 0 ? void 0 : avatarExtractor(props),
            text: labelExtractor(props),
            size: PersonaSize.size24,
        };
        return (React.createElement(PeoplePickerItemSuggestion, { compact: true, personaProps: personaConfig }));
    }, [labelExtractor, avatarExtractor]);
    return (React.createElement(TagPicker, __assign({}, restProps, { renderItem: renderItem, renderSuggestionItem: renderSuggestionItem })));
}
export var PeoplePicker = React.memo(PeoplePickerImpl);

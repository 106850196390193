import { __read } from "tslib";
import React, { useCallback, useContext, useMemo } from "react";
import { SingleEmailContactSuggestionItem } from "./ContactSuggestionItem";
import { MainSearchBarSuggestionsContainer } from "./MainSearchBarSuggestionsContainer";
import { MainSearchBarContext } from "./MainSearchBar";
import { useSelectedIndex } from "./hooks";
export var MainSearchBarContactSuggestions = React.memo(function (props) {
    var _a = useContext(MainSearchBarContext), onContactAutoComplete = _a.onContactAutoComplete, eventEmitter = _a.eventEmitter;
    var suggestions = props.suggestions;
    var items = useMemo(function () {
        return suggestions.contacts.map(function (c) { return ({
            type: "ContactEmail",
            item: c,
        }); });
    }, [suggestions]);
    var onSelectContact = useCallback(function (contact) {
        onContactAutoComplete(contact);
    }, [onContactAutoComplete]);
    var onSelectedItem = useCallback(function (item) {
        onSelectContact(item.item);
    }, [onSelectContact]);
    var _b = __read(useSelectedIndex(eventEmitter, items, onSelectedItem), 1), selectedItemIndex = _b[0];
    if (items.length === 0) {
        return null;
    }
    return (React.createElement(MainSearchBarSuggestionsContainer, null, items.map(function (item, idx) {
        return (React.createElement(SingleEmailContactSuggestionItem, { contact: item.item, key: item.item.email, onSelect: onSelectContact, isSelected: idx === selectedItemIndex }));
    })));
});

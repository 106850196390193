import { __assign, __rest } from "tslib";
import React from "react";
import { TextInput } from "../common/TextInput";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import cn from "classnames";
import { TextAreaInput } from "../common/TextAreaInput";
export var TextField = React.memo(function (props) {
    var containerClassName = props.containerClassName, isError = props.isError, className = props.className, isTextArea = props.isTextArea, restProps = __rest(props, ["containerClassName", "isError", "className", "isTextArea"]);
    return (React.createElement("div", { className: cn("flex flex-row items-center", containerClassName) },
        isTextArea ? (React.createElement(TextAreaInput, __assign({}, restProps, { className: cn("py-2 px-3 border shadow-sm w-full", className, isError ? "border-error pr-8" : "border-gray-300") }))) : (React.createElement(TextInput, __assign({}, restProps, { className: cn("py-2 px-3 border shadow-sm w-full", className, isError ? "border-error pr-8" : "border-gray-300") }))),
        isError && (React.createElement(ExclamationCircleIcon, { className: "-ml-8 w-4 h-4 text-error" }))));
});

import { __assign, __read, __rest, __spreadArray } from "tslib";
import React from "react";
import cn from "classnames";
var buttonLayoutClassBySize = {
    sm: ["px-3", "h-[2.125rem]", "rounded-md"],
    base: ["px-4", "h-[2.375rem]", "rounded-md"],
    xs: ["px-3", "h-[1.875rem]", "rounded"],
};
var buttonTextClassBySize = {
    sm: ["text-sm", "leading-4", "font-bold"],
    base: ["text-sm", "leading-4", "font-bold"],
    xs: ["text-xs", "leading-4", "font-normal"],
};
export var WhiteButton = React.memo(function (props) {
    var _a;
    var size = props.size, className = props.className, iconURL = props.iconURL, children = props.children, type = props.type, buttonProps = __rest(props, ["size", "className", "iconURL", "children", "type"]);
    var buttonLayoutClass = React.useMemo(function () {
        return buttonLayoutClassBySize[props.size];
    }, [props.size]);
    return (React.createElement("button", __assign({}, buttonProps, { 
        // eslint-disable-next-line react/button-has-type
        type: (_a = props.type) !== null && _a !== void 0 ? _a : "button", className: cn.apply(void 0, __spreadArray(__spreadArray([], __read(buttonLayoutClass)), ["cursor-pointer",
            "bg-white",
            "hover:bg-gray-50",
            "shadow-sm",
            "flex",
            "items-center",
            "border",
            "border-gray-300",
            "focus:outline-none",
            "focus:ring-2",
            "focus:ring-offset-2",
            "focus:ring-orange-600",
            "disabled:border-0",
            "disabled:text-gray-400",
            "disabled:bg-gray-200",
            "disabled:cursor-not-allowed",
            className])) }),
        React.createElement("div", { className: cn("align-middle", "inline-flex", "items-center", !!iconURL ? "-ml-px" : null) },
            !!iconURL && (React.createElement("img", { className: cn("object-contain", "mr-2"), src: iconURL })),
            React.createElement("span", { className: cn(buttonTextClassBySize[props.size]) }, children))));
});

import { __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { ROUTES } from "@router/routes";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { useAppToast } from "../common/Toast";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
import { ContactForm } from "../settings/forms/ContactForm";
import { parseTagUpdateErrorMessage } from "@shared/models/error";
import { ContactAPIContext } from "@shared/contexts/api/ContactAPIContext";
import { FileAPIContext } from "@shared/contexts/api/FileAPIContext";
import { useImageResizer } from "@hooks/useImageResizer";
import { useNavigation } from "@hooks/useNavigation";
export var ContactTagEditScreen = React.memo(function () {
    var _a, _b, _c;
    var tagId = useParams().tagId;
    var showErrorToast = useAppToast().showErrorToast;
    var contactId = useMemo(function () { return parseInt(tagId, 10); }, [tagId]);
    var navigation = useNavigation();
    var location = useLocation();
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var fileAPI = useContext(FileAPIContext);
    var contactAPI = useContext(ContactAPIContext);
    var _d = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _d[0], setTagName = _d[1];
    var _e = __read(useState(undefined), 2), initialFormValues = _e[0], setInitialFormValues = _e[1];
    var _f = __read(useState(undefined), 2), initialAvatar = _f[0], setInitialAvatar = _f[1];
    var _g = __read(useState({}), 2), additionalFields = _g[0], setAdditionalFields = _g[1];
    var _h = __read(useState(true), 2), isLoading = _h[0], setIsLoading = _h[1];
    var _j = __read(useState(false), 2), isSubmitting = _j[0], setIsSubmitting = _j[1];
    var resizeImageFile = useImageResizer().resizeImageFile;
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.contact.group_detail);
        }
    }, [navigation]);
    var handleSubmit = useCallback(function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
        var avatarId, resizedImage, err_1, _a, titleId, descriptionId;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsSubmitting(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, 7, 8]);
                    avatarId = initialAvatar === null || initialAvatar === void 0 ? void 0 : initialAvatar.id;
                    if (!formValues.avatar) return [3 /*break*/, 4];
                    return [4 /*yield*/, resizeImageFile(formValues.avatar, {
                            maxHeight: 64,
                            maxWidth: 64,
                        })];
                case 2:
                    resizedImage = _b.sent();
                    return [4 /*yield*/, fileAPI.uploadImage(resizedImage)];
                case 3:
                    avatarId = _b.sent();
                    _b.label = 4;
                case 4: return [4 /*yield*/, contactAPI.updateContact(contactId, {
                        name: formValues.name.trim(),
                        emails: formValues.email.split(",").map(function (d) { return d.trim(); }),
                        companyId: !!formValues.company ? formValues.company.id : null,
                        phoneNumber: !!formValues.phoneNumber
                            ? formValues.phoneNumber.trim()
                            : null,
                        avatar: avatarId !== null && avatarId !== void 0 ? avatarId : null,
                        additional: formValues.additional,
                    })];
                case 5:
                    _b.sent();
                    handleGoBack();
                    return [3 /*break*/, 8];
                case 6:
                    err_1 = _b.sent();
                    _a = __read(parseTagUpdateErrorMessage(err_1, "contact"), 2), titleId = _a[0], descriptionId = _a[1];
                    showErrorToast({
                        titleId: titleId,
                        descriptionId: descriptionId,
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 8];
                case 7:
                    setIsSubmitting(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); }, [
        contactAPI,
        contactId,
        fileAPI,
        initialAvatar,
        handleGoBack,
        setIsSubmitting,
        showErrorToast,
        resizeImageFile,
    ]);
    useEffect(function () {
        setIsLoading(true);
        Promise.all([
            contactAPI.getContact(contactId),
            tagGroupAPI.getTagGroupBySlug("contact"),
        ])
            .then(function (_a) {
            var _b, _c;
            var _d = __read(_a, 2), contact = _d[0], tagGroupResp = _d[1];
            // Handles displaying tag name when we enter the page by typing in the url
            setTagName(contact.name);
            setAdditionalFields(tagGroupResp.definition);
            setInitialAvatar((_b = contact.avatar) !== null && _b !== void 0 ? _b : undefined);
            setInitialFormValues({
                name: contact.name,
                email: contact.emails.join(","),
                phoneNumber: (_c = contact.phoneNumber) !== null && _c !== void 0 ? _c : "",
                company: contact.company,
                avatar: null,
                additional: contact.additional,
            });
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        contactId,
        contactAPI,
        tagGroupAPI,
        handleGoBack,
        setTagName,
        showErrorToast,
        setAdditionalFields,
        setInitialFormValues,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tags.form.edit.title", values: {
                        tagName: tagName,
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(ContactForm, { initialAvatarUrl: (_c = initialAvatar === null || initialAvatar === void 0 ? void 0 : initialAvatar.url) !== null && _c !== void 0 ? _c : undefined, initialFormValues: initialFormValues, additionalFields: additionalFields, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleGoBack })))));
});

import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import { useEffectOnce } from "@hooks/useEffectOnce";
import { useNavigation } from "@hooks/useNavigation";
import { OrganizationSettingRouterContext } from "@router/OrganizationSettingRouter";
import { ROUTES } from "@router/routes";
import { DepartmentAPIContext } from "@shared/contexts/api/DepartmentAPIContext";
import { APIResponseError } from "@shared/contexts/api/errors";
import { parseDepartmentCreateErrorMessage } from "@shared/models/error";
import React, { useCallback, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAppToast } from "../common/Toast";
import DepartmentForm from "../settings/forms/DepartmentForm";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
// Only allows 2 levels of departments
var MAX_DEPARTMENT_DEPTH = 2;
var OrganizationDepartmentCreationScreen = function () {
    var _a = __read(useState(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var _b = __read(useState(false), 2), isSubmitting = _b[0], setIsSubmitting = _b[1];
    var _c = __read(useState([]), 2), parentDepartmentOptions = _c[0], setParentDepartmentOptions = _c[1];
    var _d = useContext(DepartmentAPIContext), getDepartmentTree = _d.getDepartmentTree, createDepartment = _d.createDepartment;
    var refreshSidebar = useContext(OrganizationSettingRouterContext).refreshSidebar;
    var showErrorToast = useAppToast().showErrorToast;
    var navigation = useNavigation();
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.organization.root);
        }
    }, [navigation]);
    var handleGoToDepartmentDetails = useCallback(function (departmentID) {
        navigation.push(ROUTES.settings.organization.department_detail.replace(":departmentId", departmentID.toString(10)));
    }, [navigation]);
    useEffectOnce(function () {
        setIsLoading(true);
        getDepartmentTree({ isEnabled: "true" })
            .then(function (res) {
            var options = [];
            var toBeVisited = res.trees;
            while (toBeVisited.length > 0) {
                var node = toBeVisited.shift();
                if (node == null) {
                    continue;
                }
                if (node.depth >= MAX_DEPARTMENT_DEPTH) {
                    continue;
                }
                toBeVisited.unshift.apply(toBeVisited, __spreadArray([], __read(node.children)));
                options.push(node);
            }
            setParentDepartmentOptions(options);
        })
            .catch(function (e) {
            console.error(e);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, function () { return true; });
    var handleSubmit = useCallback(function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1, _a, titleId, descriptionId;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (formValues.name.length <= 0 || formValues.parentID == null) {
                        return [2 /*return*/];
                    }
                    setIsSubmitting(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createDepartment({
                            name: formValues.name,
                            parentID: formValues.parentID,
                        })];
                case 2:
                    res = _b.sent();
                    refreshSidebar();
                    handleGoToDepartmentDetails(res.department.id);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    console.error(e_1);
                    if (e_1 instanceof APIResponseError) {
                        _a = __read(parseDepartmentCreateErrorMessage(e_1), 2), titleId = _a[0], descriptionId = _a[1];
                        showErrorToast({
                            titleId: titleId,
                            descriptionId: descriptionId,
                        }, {
                            position: "bottom-center",
                        });
                        return [2 /*return*/];
                    }
                    showErrorToast({
                        titleId: "common.errors.data.submit_failed.title",
                        descriptionId: "common.errors.data.submit_failed.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 4];
                case 4:
                    setIsSubmitting(false);
                    return [2 /*return*/];
            }
        });
    }); }, [
        createDepartment,
        handleGoToDepartmentDetails,
        refreshSidebar,
        showErrorToast,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.organization.createDepartment.title" }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(DepartmentForm, { parentDepartmentOptions: parentDepartmentOptions, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleGoBack })))));
};
export default OrganizationDepartmentCreationScreen;

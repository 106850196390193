import { __awaiter, __extends, __generator } from "tslib";
import React, { useCallback } from "react";
import { BasePicker, makeStyles, } from "@fluentui/react";
import { XIcon } from "@heroicons/react/solid";
import cn from "classnames";
// TODO: see if there's alternative ways to handle this
var useStyle = makeStyles({
    root: {
        "& .ms-SelectionZone": {
            height: "100%",
        },
        "& .ms-BasePicker-text": {
            height: "100%",
            borderRadius: "0.375rem",
            borderColor: "var(--color-gray-300) !important",
            padding: "0.25rem 0.5rem",
        },
        "&.disabled .ms-SelectionZone .ms-BasePicker-text": {
            border: "none",
            padding: 0,
        },
        "&.disabled .ms-SelectionZone .ms-BasePicker-text button": {
            PointerEvent: "none !important",
            display: "none", // Remove button doesn't respect disabled param
        },
        "& .ms-BasePicker-text::after": {
            borderRadius: "0.375rem",
        },
    },
});
var TagPickerComponent = /** @class */ (function (_super) {
    __extends(TagPickerComponent, _super);
    function TagPickerComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TagPickerComponent;
}(BasePicker));
function TagPickerImpl(props) {
    var _this = this;
    var className = props.className, inputClassName = props.inputClassName, labelClassName = props.labelClassName, placeholder = props.placeholder, defaultSelectedItems = props.defaultSelectedItems, selectedItems = props.selectedItems, disabled = props.disabled, itemLimit = props.itemLimit, fetchTags = props.fetchTags, keyExtractor = props.keyExtractor, labelExtractor = props.labelExtractor, colorExtractor = props.colorExtractor, renderItem_ = props.renderItem, renderSuggestionItem_ = props.renderSuggestionItem, onTagChange_ = props.onTagChange;
    var classes = useStyle();
    var onTagChange = useCallback(function (items) {
        onTagChange_(items !== null && items !== void 0 ? items : []);
    }, [onTagChange_]);
    var onResolveSuggestions = useCallback(function (filter, selectedItems) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, fetchTags(filter).then(function (tags) {
                    return tags.filter(function (t) {
                        if (!selectedItems) {
                            return true;
                        }
                        return !selectedItems.map(keyExtractor).includes(keyExtractor(t));
                    });
                })];
        });
    }); }, [fetchTags, keyExtractor]);
    var renderSuggestionItem = useCallback(function (props) {
        var _a;
        return React.createElement("div", { className: "p-2" }, (_a = labelExtractor === null || labelExtractor === void 0 ? void 0 : labelExtractor(props)) !== null && _a !== void 0 ? _a : props);
    }, [labelExtractor]);
    var renderItem = useCallback(function (props) {
        var color = colorExtractor === null || colorExtractor === void 0 ? void 0 : colorExtractor(props.item);
        return (React.createElement("div", { key: props.key, className: "flex flex-row items-center justify-center px-2 py-0.5 mx-0.5 my-1 rounded bg-gray-100 text-gray-800 overflow-hidden", style: {
                backgroundColor: color === null || color === void 0 ? void 0 : color.backgroundColor,
                color: color === null || color === void 0 ? void 0 : color.textColor,
            } },
            !!labelExtractor ? (React.createElement("span", { className: cn(labelClassName, "flex-1 overflow-hidden min-w-0 overflow-ellipsis") }, labelExtractor(props.item))) : (props.item),
            !props.disabled && (React.createElement("button", { type: "button", className: "ml-4 flex items-center justify-center", onClick: props.onRemoveItem },
                React.createElement(XIcon, { className: "h-4 w-4 text-gray-400" })))));
    }, [colorExtractor, labelExtractor, labelClassName]);
    return (React.createElement(TagPickerComponent, { className: cn(classes.root, !disabled ? "bg-white shadow-sm" : "disabled", "rounded-md", className), disabled: disabled, defaultSelectedItems: defaultSelectedItems, selectedItems: selectedItems, onRenderItem: renderItem_ !== null && renderItem_ !== void 0 ? renderItem_ : renderItem, onRenderSuggestionsItem: renderSuggestionItem_ !== null && renderSuggestionItem_ !== void 0 ? renderSuggestionItem_ : renderSuggestionItem, onResolveSuggestions: onResolveSuggestions, getTextFromItem: labelExtractor, onChange: onTagChange, inputProps: {
            className: cn("!p-0 placeholder-gray-500 text-sm leading-6 font-normal text-gray-900 rounded-md bg-white", disabled && "hidden", inputClassName),
            placeholder: placeholder,
        }, itemLimit: itemLimit }));
}
export var TagPicker = React.memo(TagPickerImpl);

import { PrimaryButton } from "@components/common/PrimaryButton";
import { WhiteButton } from "@components/common/WhiteButton";
import React from "react";
import { DirtyFormPrompt } from "@components/common/DirtyFormPrompt";
export var FormShell = function (props) {
    var isSubmitting = props.isSubmitting, cancelLabel = props.cancelLabel, submitLabel = props.submitLabel, children = props.children, onSubmit = props.onSubmit, onCancel = props.onCancel;
    return (React.createElement("form", { onSubmit: onSubmit, className: "h-full w-full flex flex-col overflow-hidden" },
        React.createElement(DirtyFormPrompt, null),
        React.createElement("div", { className: "flex-1 min-h-0 my-2 md:my-0 md:p-4" },
            React.createElement("fieldset", { className: "h-full bg-white p-4 flex flex-col space-y-6 overflow-y-auto md:h-auto md:max-h-full md:px-8 md:py-1 md:space-y-0 md:divide-y md:divide-gray-200 md:rounded-lg" }, children)),
        React.createElement("div", { className: "p-4 bg-white flex flex-row justify-end items-center space-x-3" },
            React.createElement(WhiteButton, { type: "button", size: "base", disabled: isSubmitting, onClick: onCancel }, cancelLabel),
            React.createElement(PrimaryButton, { type: "submit", size: "base", disabled: isSubmitting }, submitLabel))));
};

import cn from "classnames";
import React from "react";
export var ColouredTag = function (_a) {
    var tag = _a.tag, colour = _a.colour, className = _a.className;
    if (!tag) {
        return null;
    }
    return (React.createElement("div", { className: cn("w-max max-w-[9.625rem] px-3 py-1 rounded text-sm leading-5 font-bold", !colour && "bg-gray-100 text-gray-800", className), style: { backgroundColor: colour === null || colour === void 0 ? void 0 : colour.backgroundColor } },
        React.createElement("span", { className: "line-clamp-1", style: { color: colour === null || colour === void 0 ? void 0 : colour.textColor } }, tag)));
};

import React, { useCallback, useRef, useEffect } from "react";
import cn from "classnames";
export var DocumentGroupSuggestionItem = React.memo(function (props) {
    var className = props.className, item = props.item, onSelect = props.onSelect, isSelected = props.isSelected;
    var onMouseDown = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onSelect(item);
    }, [item, onSelect]);
    var elRef = useRef(null);
    useEffect(function () {
        var _a;
        if (isSelected) {
            (_a = elRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
        }
    }, [isSelected]);
    return (React.createElement("button", { ref: elRef, type: "button", className: cn("px-4", "py-2", "flex", "items-center", "w-full", "hover:bg-gray-50", isSelected && "bg-gray-50", className), onMouseDown: onMouseDown },
        React.createElement("div", { className: "flex-none px-3 text-gray-800 text-sm font-bold py-0.5" }, item.subject),
        React.createElement("div", { className: "flex-1 text-sm text-left text-gray-600 truncate font-normal" }, item.bodyPreview)));
});

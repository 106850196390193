import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { listRegionRequestSchema, listRegionResponseSchema, createRegionRequestSchema, regionSchema, editRegionRequestSchema, mergeRegionRequestSchema, } from "@shared/models/api/region_api";
export var RegionAPIContext = React.createContext(undefined);
export var RegionAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getRegion = useCallback(function (regionId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("regions/" + regionId)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, regionSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var listRegions = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listRegionRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("regions", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listRegionResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var createRegion = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = createRegionRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("regions", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, regionSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updateRegion = useCallback(function (id, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = editRegionRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.put("regions/" + id, body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, regionSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var enableRegion = useCallback(function (regionId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("regions/" + regionId + "/enable", {})];
        });
    }); }, [apiContext]);
    var disableRegion = useCallback(function (regionId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("regions/" + regionId + "/disable", {})];
        });
    }); }, [apiContext]);
    var deleteRegion = useCallback(function (regionId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.delete("regions/" + regionId)];
        });
    }); }, [apiContext]);
    var mergeRegion = useCallback(function (id, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body;
        return __generator(this, function (_a) {
            body = mergeRegionRequestSchema.validateSync(request);
            return [2 /*return*/, apiContext.post("regions/" + id + "/merge", body)];
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            listRegions: listRegions,
            createRegion: createRegion,
            getRegion: getRegion,
            updateRegion: updateRegion,
            enableRegion: enableRegion,
            disableRegion: disableRegion,
            deleteRegion: deleteRegion,
            mergeRegion: mergeRegion,
        };
    }, [
        listRegions,
        createRegion,
        getRegion,
        updateRegion,
        enableRegion,
        disableRegion,
        deleteRegion,
        mergeRegion,
    ]);
    return (React.createElement(RegionAPIContext.Provider, { value: value }, children));
});

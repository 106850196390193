import { TrashIcon } from "@heroicons/react/outline";
import cn from "classnames";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { WhiteButton } from "../common/WhiteButton";
import { Checkbox } from "../form/Checkbox";
import { TextField } from "../form/TextField";
var Item = React.memo(function (props) {
    var intl = useIntl();
    var onRemoveProperty = useCallback(function () {
        if (props.propertyType !== "custom")
            return;
        props.onRemoveProperty(props.fieldId);
    }, [props]);
    if (props.propertyType === "custom") {
        var fieldId = props.fieldId, fieldName = props.fieldName, mandatory_1 = props.mandatory, error = props.error, className_1 = props.className, handleChange = props.handleChange, handleBlur = props.handleBlur;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn("flex flex-row space-x-4", className_1) },
                React.createElement("div", { className: "flex flex-1 flex-col space-y-2 md:grid-rows-none md:space-x-4 md:flex-row" },
                    React.createElement(TextField, { name: "definitions." + fieldId + ".name", value: fieldName, onChange: handleChange, onBlur: handleBlur, containerClassName: "flex-1", isError: !!(error === null || error === void 0 ? void 0 : error.name) }),
                    (error === null || error === void 0 ? void 0 : error.name) && (React.createElement("span", { className: "mt-1 text-error text-sm leading-5 font-normal flex md:hidden" },
                        React.createElement(FormattedMessage, { id: error.name }))),
                    React.createElement(TextField, { containerClassName: "md:w-[12.5rem]", value: intl.formatMessage({
                            id: "settings.tags.tag_group.form.tag_property.text",
                        }), disabled: true }),
                    React.createElement(Checkbox, { className: "md:w-[12.5rem] items-center", name: "definitions." + fieldId + ".mandatory", titleId: "settings.tags.tag_group.form.tag_property.mandatory_on_create", value: mandatory_1, onChange: handleChange, titleClassName: "text-sm leading-5 font-bold text-gray-700" })),
                React.createElement("div", { className: "flex items-start mt-2 justify-center md:mt-0 md:items-center" },
                    React.createElement("button", { type: "button", onClick: onRemoveProperty, className: "flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" },
                        React.createElement(TrashIcon, { className: "h-6 w-6", "aria-hidden": "true" })))),
            (error === null || error === void 0 ? void 0 : error.name) && (React.createElement("span", { className: "mt-1 text-error text-sm leading-5 font-normal hidden md:flex" },
                React.createElement(FormattedMessage, { id: error.name })))));
    }
    var fieldNameId = props.fieldNameId, typeId = props.typeId, mandatory = props.mandatory, className = props.className;
    return (React.createElement("div", { className: cn("flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-4", className) },
        React.createElement(TextField, { containerClassName: "flex-1", value: intl.formatMessage({
                id: fieldNameId,
            }), disabled: true }),
        React.createElement(TextField, { containerClassName: "md:w-[12.5rem]", value: intl.formatMessage({
                id: typeId,
            }), disabled: true }),
        React.createElement(Checkbox, { className: "md:w-[12.5rem] items-center", titleId: "settings.tags.tag_group.form.tag_property.mandatory_on_create", value: mandatory, disabled: true, titleClassName: "text-sm leading-5 font-bold text-gray-700" }),
        React.createElement("div", { className: "w-6" })));
});
export var Panel = function (props) {
    var children = props.children, onAddNewProperty = props.onAddNewProperty;
    return (React.createElement("div", { className: "flex flex-col py-6" },
        React.createElement("h1", { className: "text-base leading-6 font-bold text-gray-900" },
            React.createElement(FormattedMessage, { id: "settings.tags.tag_group.form.tag_property.title" })),
        React.createElement("div", { className: "flex flex-col" },
            React.createElement("div", { className: "flex-row py-4 space-x-4 border-b border-gray-200 text-sm leading-5 font-bold text-gray-700 hidden md:flex" },
                React.createElement("span", { className: "flex-1" },
                    React.createElement(FormattedMessage, { id: "settings.tags.tag_group.form.tag_property.property" })),
                React.createElement("span", { className: "w-[12.5rem]" },
                    React.createElement(FormattedMessage, { id: "settings.tags.tag_group.form.tag_property.type" })),
                React.createElement("span", { className: "w-[12.5rem]" },
                    React.createElement(FormattedMessage, { id: "settings.tags.tag_group.form.tag_property.mandatory_on_create" })),
                React.createElement("div", { className: "w-6" })),
            children),
        React.createElement("div", { className: "mt-5" },
            React.createElement(WhiteButton, { size: "sm", onClick: onAddNewProperty },
                React.createElement("span", { className: "text-xs leading-4 font-normal text-gray-700" },
                    React.createElement(FormattedMessage, { id: "settings.tags.tag_group.form.tag_property.add_new_property" }))))));
};
export var TagProperty = {
    Item: Item,
    Panel: Panel,
};

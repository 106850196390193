import { __read } from "tslib";
import React, { useMemo, useState, useCallback } from "react";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import { WhiteButton } from "../common/WhiteButton";
import { PlusCircleIcon } from "@heroicons/react/outline";
import listCollapsedIcon from "../../assets/ic-list-collapsed.svg";
import listExpandedIcon from "../../assets/ic-list-expanded.svg";
import { ROUTES } from "@router/routes";
import { Link, useRouteMatch } from "react-router-dom";
import RequirePermission from "../common/RequirePermission";
import { Permission } from "@shared/models/auth";
import { useDepartmentLabel } from "@hooks/useDepartmentLabel";
export var DepartmentItem = function (props) {
    var node = props.node, activeDepartmentID = props.activeDepartmentID;
    var totalUserCount = useMemo(function () {
        return node.userCount + node.descendantUserCount;
    }, [node.descendantUserCount, node.userCount]);
    var _a = __read(useState(false), 2), isCollapsed = _a[0], setIsCollapsed = _a[1];
    var onToggleCollapse = useCallback(function (ev) {
        ev.preventDefault();
        ev.stopPropagation();
        setIsCollapsed(function (prev) { return !prev; });
    }, []);
    var paddingLeftForHierarchyInset = useMemo(function () {
        return node.depth * 16;
    }, [node.depth]);
    var marginLeftForButtonPlaceholder = useMemo(function () {
        return node.children.length <= 0 ? "ml-7" : null;
    }, [node.children.length]);
    var isActive = useMemo(function () {
        return activeDepartmentID === node.id.toString(10);
    }, [activeDepartmentID, node.id]);
    var departmentLabel = useDepartmentLabel();
    return (React.createElement("li", null,
        React.createElement(Link, { className: cn("group flex flex-row justify-between items-center p-2 hover:bg-gray-50 max-w-[22.5rem]", isActive && "bg-gray-100"), style: {
                paddingLeft: paddingLeftForHierarchyInset,
            }, to: "" + ROUTES.settings.organization.department_detail.replace(":departmentId", node.id.toString(10)) },
            node.children.length > 0 && (React.createElement("button", { type: "button", onClick: onToggleCollapse, className: "p-1.5 mr-2" },
                React.createElement("img", { className: "object-contain", src: isCollapsed ? listCollapsedIcon : listExpandedIcon }))),
            React.createElement("h5", { className: cn("flex-1 text-sm leading-5 font-medium text-gray-600 group-hover:text-gray-900 overflow-ellipsis whitespace-nowrap overflow-hidden mr-2", marginLeftForButtonPlaceholder) }, departmentLabel(node)),
            React.createElement("span", { className: "rounded-full bg-gray-300 px-3 py-0.5 text-xs leading-4 font-medium text-gray-700" }, totalUserCount)),
        node.children.length > 0 && (React.createElement("ul", { className: cn(isCollapsed && "hidden") }, node.children.map(function (node) { return (React.createElement(DepartmentItem, { key: node.id, node: node, activeDepartmentID: activeDepartmentID })); })))));
};
var OrganizationDepartmentListSkeleton = React.memo(function () {
    return (React.createElement("div", { className: "animate-pulse flex-grow flex flex-col min-h-0 bg-white pt-3.5 px-4 border-b border-gray-200" },
        React.createElement("div", { className: "flex items-center mb-3.5 justify-between flex-wrap sm:flex-nowrap" },
            React.createElement("div", { className: "flex flex-col" },
                React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900" },
                    React.createElement(FormattedMessage, { id: "settings.organization.title" })),
                React.createElement("div", { className: "h-4 w-16 bg-gray-200 rounded-full" }))),
        React.createElement("div", { className: "-mx-4 overflow-y-auto min-h-0 flex-grow" }, new Array(13).fill(null).map(function (_, index) { return (React.createElement("div", { key: index, className: "flex flex-row justify-between items-center p-2" },
            React.createElement("div", { className: "mx-2 h-5 w-full text-sm bg-gray-200 rounded-full" }),
            React.createElement("div", { className: "w-7.5 h-5 rounded-full bg-gray-300 px-3 py-0.5" }))); }))));
});
export var OrganizationDepartmentList = function (props) {
    var departmentTrees = props.departmentTrees, className = props.className, onClickCreate = props.onClickCreate, isLoading = props.isLoading;
    var totalDepartmentCount = useMemo(function () {
        return departmentTrees.reduce(function (prev, curr) {
            return prev + curr.descendantCount; // do not count the root dept
        }, 0);
    }, [departmentTrees]);
    // tried to use useParam but always got undefined; using useRouteMatch instead
    var departmentDetailRouteMatch = useRouteMatch(ROUTES.settings.organization.department_detail);
    var activeDepartmentID = useMemo(function () {
        var _a;
        if (departmentDetailRouteMatch == null) {
            return null;
        }
        return (_a = departmentDetailRouteMatch.params.departmentId) !== null && _a !== void 0 ? _a : null;
    }, [departmentDetailRouteMatch]);
    return (React.createElement("div", { className: cn("flex", className) }, isLoading ? (React.createElement(OrganizationDepartmentListSkeleton, null)) : (React.createElement("div", { className: cn("flex-grow flex flex-col min-h-0 bg-white pt-3.5 px-4 border-b border-gray-200") },
        React.createElement("div", { className: "flex items-center mb-3.5 justify-between flex-wrap sm:flex-nowrap" },
            React.createElement("div", { className: "flex flex-col" },
                React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900" },
                    React.createElement(FormattedMessage, { id: "settings.organization.title" })),
                React.createElement("span", { className: "font-normal text-sm leading-5 text-gray-600" },
                    React.createElement(FormattedMessage, { id: "settings.organization.departmentCount", values: {
                            count: totalDepartmentCount,
                        } }))),
            React.createElement(RequirePermission, { permission: Permission.DepartmentsCreate },
                React.createElement("div", { className: "flex-shrink-0" },
                    React.createElement(WhiteButton, { size: "base", className: "hidden md:flex", onClick: onClickCreate },
                        React.createElement(FormattedMessage, { id: "settings.organization.addNewDepartment" })),
                    React.createElement(WhiteButton, { size: "sm", className: "md:hidden", onClick: onClickCreate },
                        React.createElement(PlusCircleIcon, { className: "h-4 w-4 text-gray-500" }))))),
        React.createElement("ul", { className: "divide-y divide-gray-200 -mx-4 overflow-y-auto min-h-0 flex-grow" }, departmentTrees.map(function (node) { return (React.createElement(DepartmentItem, { key: node.id, node: node, activeDepartmentID: activeDepartmentID })); }))))));
};
export default OrganizationDepartmentList;

import { __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { DealAPIContext } from "@shared/contexts/api/DealAPIContext";
import { ROUTES } from "@router/routes";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { useAppToast } from "../common/Toast";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
import { DealForm } from "../settings/forms/DealForm";
import { parseTagUpdateErrorMessage } from "@shared/models/error";
import { ColorAPIContext } from "@shared/contexts/api/ColorAPIContext";
import { UserAPIContext } from "@shared/contexts/api/UserAPIContext";
import { useNavigation } from "@hooks/useNavigation";
var MAX_USER_SUGGESTION_COUNT = 10;
export var DealTagEditScreen = React.memo(function () {
    var _a, _b;
    var tagId = useParams().tagId;
    var showErrorToast = useAppToast().showErrorToast;
    var dealId = useMemo(function () { return parseInt(tagId, 10); }, [tagId]);
    var navigation = useNavigation();
    var location = useLocation();
    var colorAPI = useContext(ColorAPIContext);
    var userAPI = useContext(UserAPIContext);
    var dealAPI = useContext(DealAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _c = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _c[0], setTagName = _c[1];
    var _d = __read(useState(undefined), 2), initialFormValues = _d[0], setInitialFormValues = _d[1];
    var _e = __read(useState([]), 2), colors = _e[0], setColors = _e[1];
    var _f = __read(useState({}), 2), additionalFields = _f[0], setAdditionalFields = _f[1];
    var _g = __read(useState(true), 2), isLoading = _g[0], setIsLoading = _g[1];
    var _h = __read(useState(false), 2), isSubmitting = _h[0], setIsSubmitting = _h[1];
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.deal.group_detail);
        }
    }, [navigation]);
    var handleSubmit = useCallback(function (formValues) {
        var _a, _b;
        setIsSubmitting(true);
        dealAPI
            .updateDeal(dealId, {
            name: formValues.tagName.trim(),
            fullName: !!formValues.fullName ? formValues.fullName.trim() : null,
            remarks: !!formValues.remarks ? formValues.remarks.trim() : null,
            colorId: (_b = (_a = formValues.color) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
            teammates: formValues.teammates.data.map(function (t) { return t.id; }),
            additional: formValues.additional,
        })
            .then(function () {
            handleGoBack();
        })
            .catch(function (err) {
            var _a = __read(parseTagUpdateErrorMessage(err), 2), titleId = _a[0], descriptionId = _a[1];
            showErrorToast({
                titleId: titleId,
                descriptionId: descriptionId,
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    }, [dealId, dealAPI, setIsSubmitting, showErrorToast, handleGoBack]);
    var fetchUserSuggestion = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, userAPI
                    .listUsers({
                    page: 1,
                    perPage: MAX_USER_SUGGESTION_COUNT,
                    search: search,
                    isEnabled: "true",
                    excludeSystem: true,
                })
                    .then(function (resp) { return resp.data; })];
        });
    }); }, [userAPI]);
    useEffect(function () {
        setIsLoading(true);
        Promise.all([
            dealAPI.getDeal(dealId),
            colorAPI.getAllColors(),
            tagGroupAPI.getTagGroupBySlug("deal"),
        ])
            .then(function (_a) {
            var _b, _c, _d;
            var _e = __read(_a, 3), deal = _e[0], colorResp = _e[1], tagGroupResp = _e[2];
            // Handles displaying tag name when we enter the page by typing in the url
            setTagName(deal.name);
            setColors(colorResp.data);
            setAdditionalFields(tagGroupResp.definition);
            setInitialFormValues({
                tagName: deal.name,
                fullName: (_b = deal.fullName) !== null && _b !== void 0 ? _b : "",
                remarks: (_c = deal.remarks) !== null && _c !== void 0 ? _c : "",
                color: (_d = deal.color) !== null && _d !== void 0 ? _d : colorResp.data[0],
                teammates: {
                    data: deal.teammates,
                },
                additional: deal.additional,
            });
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        dealId,
        dealAPI,
        colorAPI,
        tagGroupAPI,
        setColors,
        setTagName,
        showErrorToast,
        setAdditionalFields,
        setInitialFormValues,
        handleGoBack,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tags.form.edit.title", values: {
                        tagName: tagName,
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(DealForm, { colors: colors, fetchUserSuggestion: fetchUserSuggestion, initialFormValues: initialFormValues, additionalFields: additionalFields, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleGoBack })))));
});

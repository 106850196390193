import { __read } from "tslib";
import React, { useCallback, useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
export var InfiniteScroll = function (props) {
    var onFetchMore = props.onFetchMore, containerElement = props.containerElement, children = props.children;
    var _a = __read(useState(0), 2), key = _a[0], setKey = _a[1];
    useEffect(function () {
        setKey(function (k) { return k + 1; });
    }, [containerElement]);
    var onEnter = useCallback(function () {
        onFetchMore();
    }, [onFetchMore]);
    return (React.createElement(Waypoint, { key: key, onEnter: onEnter, scrollableAncestor: containerElement }, children));
};

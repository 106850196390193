import { __assign, __rest } from "tslib";
import { NotFoundScreen } from "@components/screens/NotFoundScreen";
import React from "react";
import { AuthRoute, AuthState } from "./AuthRoute";
import { ROUTES } from "./routes";
var ScreenRoute = function (props) {
    var requiredPermission = props.requiredPermission, children = props.children, restProps = __rest(props, ["requiredPermission", "children"]);
    return (React.createElement(AuthRoute, __assign({ requiredAuthState: AuthState.authenticated, authFallbackPath: ROUTES.login, requiredPermission: requiredPermission, permissionFallbackScreen: NotFoundScreen }, restProps), children));
};
export default ScreenRoute;

import { AuthContext } from "@shared/contexts/AuthContext";
import { useCallback, useContext } from "react";
var useHasPermission = function () {
    var me = useContext(AuthContext).me;
    return useCallback(function (permission) {
        var _a;
        var myPermissions = (_a = me === null || me === void 0 ? void 0 : me.permissions) !== null && _a !== void 0 ? _a : [];
        return myPermissions.includes(permission);
    }, [me]);
};
export default useHasPermission;

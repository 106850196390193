import { __assign, __read, __spreadArray } from "tslib";
import React, { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, ChevronDownIcon } from "@heroicons/react/solid";
import cn from "classnames";
import { SearchBar } from "../common/SearchBar";
import { useDebounce } from "@hooks/useDebounce";
import { useWindowEvent } from "@hooks/useWindowEvent";
import { sameWidthModifier } from "@constants/modifiers";
import { usePopper } from "react-popper";
import { Portal } from "../common/Portal";
import { Spinner } from "@components/common/Spinner";
import { InfiniteScroll } from "../common/InfiniteScroll";
var SelectMenuImpl = function (props) {
    var labelExtractor = props.labelExtractor, keyExtractor = props.keyExtractor, className = props.className, isError = props.isError, placeholder = props.placeholder, icon = props.icon, onFetchMore = props.onFetchMore, onSearch = props.onSearch, items = props.items;
    var _a = __read(useState(false), 2), isOpen = _a[0], setIsOpen = _a[1];
    var _b = __read(useState(null), 2), buttonRef = _b[0], setButtonRef = _b[1];
    var _c = __read(useState(null), 2), optionsRef = _c[0], setOptionsRef = _c[1];
    var searchBarRef = useRef(null);
    var _d = usePopper(buttonRef, optionsRef, {
        placement: "bottom-end",
        strategy: "fixed",
        modifiers: [sameWidthModifier],
    }), styles = _d.styles, attributes = _d.attributes;
    var _e = __read(useState(""), 2), searchTerm = _e[0], setSearchTerm = _e[1];
    var debouncedSearchTerm = useDebounce(searchTerm, 500);
    var _f = __read(useMemo(function () {
        var items = props.multiple
            ? props.selected
            : props.selected
                ? [props.selected]
                : [];
        var map = new Map(items.map(function (i) { return [keyExtractor(i), i]; }));
        return [items, map];
    }, [props.multiple, props.selected, keyExtractor]), 2), selectedItems = _f[0], selectedItemMap = _f[1];
    var onListboxChange = useCallback(function (option) {
        if (!props.multiple) {
            props.onSelect(option);
            setIsOpen(false);
            return;
        }
        var optionKey = keyExtractor(option);
        if (selectedItemMap.has(optionKey)) {
            selectedItemMap.delete(optionKey);
        }
        else {
            selectedItemMap.set(optionKey, option);
        }
        props.onSelect(__spreadArray([], __read(selectedItemMap.values())));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItemMap, props.multiple, props.onSelect, keyExtractor]);
    var listboxButtonClick = useCallback(function (e) {
        e.preventDefault();
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);
    var onSearchChange = useCallback(function (e) {
        setSearchTerm(e.currentTarget.value);
    }, [setSearchTerm]);
    var onSearchBarKeyDown = useCallback(function (e) {
        e.stopPropagation();
    }, []);
    useEffect(function () {
        if (onSearch) {
            onSearch(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm, onSearch]);
    // Replicate close menu on outside click logic from ListBox
    useWindowEvent("mousedown", function (event) {
        var _a;
        var target = event.target;
        if (!isOpen) {
            return;
        }
        if ((buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.contains(target)) ||
            (optionsRef === null || optionsRef === void 0 ? void 0 : optionsRef.contains(target)) ||
            ((_a = searchBarRef.current) === null || _a === void 0 ? void 0 : _a.contains(target))) {
            return;
        }
        setIsOpen(false);
    });
    return (React.createElement(Listbox, { value: null, onChange: onListboxChange }, function () { return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn("mt-1 relative", className) },
            React.createElement("button", { ref: setButtonRef, onClick: listboxButtonClick, type: "button", className: cn("relative w-full bg-white border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm", isError ? "border-error" : "border-gray-300") },
                React.createElement("span", { className: cn("block truncate min-h-[1.25rem]", !selectedItems.length && "text-gray-500") }, selectedItems.length
                    ? selectedItems.map(function (i) { return labelExtractor(i); }).join(", ")
                    : placeholder),
                React.createElement("span", { className: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }, icon === "chevron-down" ? (React.createElement(ChevronDownIcon, { className: "h-5 w-5 text-gray-400", "aria-hidden": "true" })) : (React.createElement(SelectorIcon, { className: "h-5 w-5 text-gray-400", "aria-hidden": "true" })))),
            React.createElement(Portal, null,
                React.createElement(Transition, { as: "div", show: isOpen, leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                    React.createElement(Listbox.Options, __assign({ className: cn("z-40 origin-top-right absolute right-0  mt-1 bg-white shadow-lg max-h-60 rounded-md py-2 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm", props.onSearch && items.length && "mt-2"), ref: setOptionsRef, style: styles.popper }, attributes.popper),
                        props.onSearch && (React.createElement("div", { className: "mx-3" },
                            React.createElement(SearchBar, { ref: searchBarRef, hideSearchIcon: true, containerClassName: "border border-gray-300 rounded shadow-sm", inputClassName: "text-sm leading-5 text-gray-500", value: searchTerm, onKeyDown: onSearchBarKeyDown, onChange: onSearchChange }))),
                        items.map(function (item, index) {
                            var isSelected = selectedItemMap.has(keyExtractor(item));
                            return (React.createElement(Listbox.Option, { key: index, className: cn("cursor-default select-none flex flex-row py-2 px-3 bg-white hover:bg-gray-50"), value: item }, function () { return (React.createElement(React.Fragment, null,
                                props.multiple && (React.createElement("div", { className: "inset-y-0 flex items-center h-5" },
                                    React.createElement("input", { type: "checkbox", checked: isSelected, readOnly: true, className: "h-4 w-4 text-indigo-600 border-gray-300 rounded" }))),
                                React.createElement("span", { className: cn("block truncate font-bold leading-5 text-gray-700 ml-2") }, labelExtractor(item)))); }));
                        }),
                        onFetchMore && (React.createElement(InfiniteScroll, { onFetchMore: onFetchMore, containerElement: optionsRef }, props.isFetchingMore ? (React.createElement("div", { className: "py-2 flex items-center justify-center" },
                            React.createElement(Spinner, { size: "large" }))) : (React.createElement("div", null)))))))))); }));
};
export var SelectMenu = React.memo(SelectMenuImpl);

import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { listPriorityRequestSchema, listPriorityResponseSchema, createPriorityRequestSchema, prioritySchema, editPriorityRequestSchema, getAllPrioritiesResponseSchema, } from "@shared/models/api/priority_api";
export var PriorityAPIContext = React.createContext(undefined);
export var PriorityAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getPriority = useCallback(function (priorityId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("priorities/" + priorityId)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, prioritySchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var listPriorities = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listPriorityRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("priorities", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listPriorityResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var createPriority = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = createPriorityRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("priorities", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, prioritySchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updatePriority = useCallback(function (id, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = editPriorityRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.put("priorities/" + id, body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, prioritySchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var enablePriority = useCallback(function (priorityId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("priorities/" + priorityId + "/enable", {})];
        });
    }); }, [apiContext]);
    var disablePriority = useCallback(function (priorityId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("priorities/" + priorityId + "/disable", {})];
        });
    }); }, [apiContext]);
    var deletePriority = useCallback(function (priorityId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.delete("priorities/" + priorityId)];
        });
    }); }, [apiContext]);
    var getAllPriorities = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("priorities/all")];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, getAllPrioritiesResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var mergePriority = useCallback(function (priorityId, sourceId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("priorities/" + priorityId + "/merge", {
                    source_id: sourceId,
                })];
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            listPriorities: listPriorities,
            createPriority: createPriority,
            getPriority: getPriority,
            updatePriority: updatePriority,
            enablePriority: enablePriority,
            disablePriority: disablePriority,
            deletePriority: deletePriority,
            getAllPriorities: getAllPriorities,
            mergePriority: mergePriority,
        };
    }, [
        listPriorities,
        createPriority,
        getPriority,
        updatePriority,
        enablePriority,
        disablePriority,
        deletePriority,
        getAllPriorities,
        mergePriority,
    ]);
    return (React.createElement(PriorityAPIContext.Provider, { value: value }, children));
});

import { __assign, __awaiter, __generator, __rest } from "tslib";
import { DirtyFormPrompt } from "@components/common/DirtyFormPrompt";
import { PeoplePicker } from "@components/common/PeoplePicker";
import { TagPicker } from "@components/common/TagPicker";
import { CompanyExtractors } from "@shared/models/company";
import { ContactExtractors } from "@shared/models/contact";
import { DealExtractors } from "@shared/models/deal";
import { DepartmentExtractors } from "@shared/models/department";
import { OtherTagExtractors } from "@shared/models/other_tag";
import { PriorityExtractors } from "@shared/models/priority";
import { TagExtractors } from "@shared/models/tag";
import { UserExtractors } from "@shared/models/user";
import { Formik, useFormikContext } from "formik";
import React, { useCallback, useMemo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { threadDetailFormSchema } from "./models";
import { useUserLabel } from "@hooks/useUserLabel";
import { useDepartmentLabel } from "@hooks/useDepartmentLabel";
var CustomTagPickerField = React.memo(function (props) {
    var isEditing = props.isEditing, tagGroup = props.tagGroup, selectedItems = props.selectedItems, fetchCustomTagSuggestion = props.fetchCustomTagSuggestion, onChange = props.onChange;
    var fetchSuggestions = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, fetchCustomTagSuggestion(tagGroup.id, search)];
        });
    }); }, [tagGroup, fetchCustomTagSuggestion]);
    var onPickerChange = useCallback(function (items) {
        onChange(tagGroup.id, items);
    }, [tagGroup, onChange]);
    return (React.createElement("div", { className: "flex flex-col space-y-1" },
        React.createElement("h1", { className: "font-bold text-sm leading-5 text-gray-700" },
            tagGroup.name,
            tagGroup.mandatory ? "*" : ""),
        React.createElement(TagPicker, { className: "w-full md:max-w-xs", inputClassName: "!h-8", labelClassName: "text-sm leading-5 font-bold", disabled: !isEditing, defaultSelectedItems: selectedItems, keyExtractor: TagExtractors.id, labelExtractor: TagExtractors.name, colorExtractor: TagExtractors.color, fetchTags: fetchSuggestions, onTagChange: onPickerChange })));
});
var TagGroupFieldTitle = React.memo(function (_a) {
    var messageId = _a.messageId, isMandatory = _a.isMandatory;
    return (React.createElement("h1", { className: "font-bold text-sm leading-5 text-gray-700" },
        React.createElement(FormattedMessage, { id: messageId }),
        isMandatory && "*"));
});
var ThreadDetailFormView = React.memo(function (props) {
    var formik = useFormikContext();
    var isEditing = props.isEditing, contactGroup = props.contactGroup, companyGroup = props.companyGroup, dealGroup = props.dealGroup, priorityGroup = props.priorityGroup, otherTagGroup = props.otherTagGroup, customTagGroups = props.customTagGroups, fetchCompanySuggestion = props.fetchCompanySuggestion, fetchContactSuggestion = props.fetchContactSuggestion, fetchDealSuggestion = props.fetchDealSuggestion, fetchPrioritySuggestion = props.fetchPrioritySuggestion, fetchOtherTagSuggestion = props.fetchOtherTagSuggestion, fetchCustomTagSuggestion = props.fetchCustomTagSuggestion, fetchUserSuggestion = props.fetchUserSuggestion, fetchDepartmentSuggestion = props.fetchDepartmentSuggestion, onChange = props.onChange;
    var onCompaniesChange = useCallback(function (items) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formik.setFieldTouched("companies", true)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, formik.setFieldValue("companies", { data: items }, true)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [formik]);
    var onContactsChange = useCallback(function (items) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formik.setFieldTouched("contacts", true)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, formik.setFieldValue("contacts", { data: items }, true)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [formik]);
    var onDealsChange = useCallback(function (items) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formik.setFieldTouched("deals", true)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, formik.setFieldValue("deals", { data: items }, true)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [formik]);
    var onPrioritiesChange = useCallback(function (items) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formik.setFieldTouched("priorities", true)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, formik.setFieldValue("priorities", { data: items }, true)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [formik]);
    var onOtherTagsChange = useCallback(function (items) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formik.setFieldTouched("otherTags", true)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, formik.setFieldValue("otherTags", { data: items }, true)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [formik]);
    var onCustomTagChange = useCallback(function (tagGroupId, items) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formik.setFieldTouched("customTags." + tagGroupId, true)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, formik.setFieldValue("customTags." + tagGroupId, items, true)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [formik]);
    var onSharedWithChange = useCallback(function (items) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formik.setFieldTouched("sharedWiths", true)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, formik.setFieldValue("sharedWiths", { data: items }, true)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [formik]);
    var onDepartmentChange = useCallback(function (items) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formik.setFieldTouched("departments", true)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, formik.setFieldValue("departments", { data: items }, true)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [formik]);
    var userLabel = useUserLabel();
    var departmentLabel = useDepartmentLabel();
    useEffect(function () {
        onChange(formik.values);
    }, [formik.values, onChange]);
    return (React.createElement("form", { className: "flex flex-col space-y-4" },
        React.createElement(DirtyFormPrompt, null),
        React.createElement("div", { className: "flex flex-col space-y-4 pr-2" },
            (companyGroup === null || companyGroup === void 0 ? void 0 : companyGroup.visible) && (React.createElement("div", { className: "flex flex-col space-y-1" },
                React.createElement(TagGroupFieldTitle, { messageId: "uploads.detail.thread_detail.form.companies.title", isMandatory: companyGroup.mandatory }),
                React.createElement(TagPicker, { className: "w-full md:max-w-xs", inputClassName: "!h-8", labelClassName: "text-sm leading-5 font-bold", disabled: !isEditing, defaultSelectedItems: formik.values.companies.data, keyExtractor: CompanyExtractors.id, labelExtractor: CompanyExtractors.name, colorExtractor: CompanyExtractors.color, fetchTags: fetchCompanySuggestion, onTagChange: onCompaniesChange }))),
            (contactGroup === null || contactGroup === void 0 ? void 0 : contactGroup.visible) && (React.createElement("div", { className: "flex flex-col space-y-1" },
                React.createElement(TagGroupFieldTitle, { messageId: "uploads.detail.thread_detail.form.contacts.title", isMandatory: contactGroup.mandatory }),
                React.createElement(PeoplePicker, { className: "w-full md:max-w-xs", inputClassName: "!h-8", disabled: !isEditing, defaultSelectedItems: formik.values.contacts.data, keyExtractor: ContactExtractors.id, labelExtractor: ContactExtractors.name, avatarExtractor: ContactExtractors.avatarUrl, fetchTags: fetchContactSuggestion, onTagChange: onContactsChange }))),
            (dealGroup === null || dealGroup === void 0 ? void 0 : dealGroup.visible) && (React.createElement("div", { className: "flex flex-col space-y-1" },
                React.createElement(TagGroupFieldTitle, { messageId: "uploads.detail.thread_detail.form.deals.title", isMandatory: dealGroup.mandatory }),
                React.createElement(TagPicker, { className: "w-full md:max-w-xs", inputClassName: "!h-8", labelClassName: "text-sm leading-5 font-bold", disabled: !isEditing, defaultSelectedItems: formik.values.deals.data, keyExtractor: DealExtractors.id, labelExtractor: DealExtractors.name, colorExtractor: DealExtractors.color, fetchTags: fetchDealSuggestion, onTagChange: onDealsChange }))),
            (priorityGroup === null || priorityGroup === void 0 ? void 0 : priorityGroup.visible) && (React.createElement("div", { className: "flex flex-col space-y-1" },
                React.createElement(TagGroupFieldTitle, { messageId: "uploads.detail.thread_detail.form.priorities.title", isMandatory: priorityGroup.mandatory }),
                React.createElement(TagPicker, { className: "w-full md:max-w-xs", inputClassName: "!h-8", labelClassName: "text-sm leading-5 font-bold", disabled: !isEditing, defaultSelectedItems: formik.values.priorities.data, keyExtractor: PriorityExtractors.id, labelExtractor: PriorityExtractors.name, colorExtractor: PriorityExtractors.color, fetchTags: fetchPrioritySuggestion, onTagChange: onPrioritiesChange }))),
            (otherTagGroup === null || otherTagGroup === void 0 ? void 0 : otherTagGroup.visible) && (React.createElement("div", { className: "flex flex-col space-y-1" },
                React.createElement(TagGroupFieldTitle, { messageId: "uploads.detail.thread_detail.form.other_tags.title", isMandatory: otherTagGroup.mandatory }),
                React.createElement(TagPicker, { className: "w-full md:max-w-xs", inputClassName: "!h-8", labelClassName: "text-sm leading-5 font-bold", disabled: !isEditing, defaultSelectedItems: formik.values.otherTags.data, keyExtractor: OtherTagExtractors.id, labelExtractor: OtherTagExtractors.name, colorExtractor: OtherTagExtractors.color, fetchTags: fetchOtherTagSuggestion, onTagChange: onOtherTagsChange }))),
            customTagGroups.map(function (g) {
                var _a;
                return (React.createElement(CustomTagPickerField, { key: g.id, isEditing: isEditing, tagGroup: g, selectedItems: (_a = formik.values.customTags[g.id]) !== null && _a !== void 0 ? _a : [], fetchCustomTagSuggestion: fetchCustomTagSuggestion, onChange: onCustomTagChange }));
            })),
        React.createElement("div", { className: "border-b border-gray-300" }),
        React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900" },
            React.createElement(FormattedMessage, { id: "uploads.detail.thread_detail.form.section.share_with.title" })),
        React.createElement("div", { className: "flex flex-col space-y-4 pr-2" },
            React.createElement("div", { className: "flex flex-col space-y-1" },
                React.createElement("h1", { className: "font-bold text-sm leading-5 text-gray-700" },
                    React.createElement(FormattedMessage, { id: "uploads.detail.thread_detail.form.people.title" })),
                React.createElement(PeoplePicker, { className: "w-full md:max-w-xs", inputClassName: "!h-8 ", disabled: !isEditing, defaultSelectedItems: formik.values.sharedWiths.data, keyExtractor: UserExtractors.id, labelExtractor: userLabel, avatarExtractor: UserExtractors.avatarUrl, fetchTags: fetchUserSuggestion, onTagChange: onSharedWithChange })),
            React.createElement("div", { className: "flex flex-col space-y-1" },
                React.createElement("h1", { className: "font-bold text-sm leading-5 text-gray-700" },
                    React.createElement(FormattedMessage, { id: "uploads.detail.thread_detail.form.departments.title" })),
                React.createElement(TagPicker, { className: "w-full md:max-w-xs", inputClassName: "!h-8", disabled: !isEditing, defaultSelectedItems: formik.values.departments.data, keyExtractor: DepartmentExtractors.id, labelExtractor: departmentLabel, fetchTags: fetchDepartmentSuggestion, onTagChange: onDepartmentChange }))),
        React.createElement("div", { className: "border-b border-gray-300" })));
});
export var ThreadDetailForm = React.forwardRef(function (props, refs) {
    var initialFormValues = props.initialFormValues, onSubmit = props.onSubmit, rest = __rest(props, ["initialFormValues", "onSubmit"]);
    var initialValues = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return ({
            companies: {
                data: (_a = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.companies.data) !== null && _a !== void 0 ? _a : [],
            },
            contacts: {
                data: (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.contacts.data) !== null && _b !== void 0 ? _b : [],
            },
            deals: {
                data: (_c = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.deals.data) !== null && _c !== void 0 ? _c : [],
            },
            priorities: {
                data: (_d = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.priorities.data) !== null && _d !== void 0 ? _d : [],
            },
            otherTags: {
                data: (_e = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.otherTags.data) !== null && _e !== void 0 ? _e : [],
            },
            sharedWiths: {
                data: (_f = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.sharedWiths.data) !== null && _f !== void 0 ? _f : [],
            },
            departments: {
                data: (_g = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.departments.data) !== null && _g !== void 0 ? _g : [],
            },
            customTags: (_h = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.customTags) !== null && _h !== void 0 ? _h : {},
        });
    }, [initialFormValues]);
    return (React.createElement(Formik, { initialValues: initialValues, innerRef: refs, validationSchema: threadDetailFormSchema, validateOnChange: true, onSubmit: onSubmit },
        React.createElement(ThreadDetailFormView, __assign({}, rest))));
});

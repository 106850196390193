import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useMemo, useRef } from "react";
import { tagGroupFormSchema } from "./models";
import { FormattedMessage, useIntl } from "react-intl";
import { FormField } from "@components/form/FormField";
import { Formik } from "formik";
import cn from "classnames";
import { TextField } from "@components/form/TextField";
import { Checkbox } from "@components/form/Checkbox";
import { WhiteButton } from "@components/common/WhiteButton";
import { PrimaryButton } from "@components/common/PrimaryButton";
import { TagProperty } from "../TagProperty";
import { v4 as uuidv4 } from "uuid";
import { DirtyFormPrompt } from "@components/common/DirtyFormPrompt";
import { getTagGroupCustomFieldsEntries } from "@shared/models/tag";
var defaultPredefinedProperties = [
    {
        fieldNameId: "settings.tags.tag_group.form.tag_property.full_name",
        typeId: "settings.tags.tag_group.form.tag_property.text",
        mandatory: false,
    },
    {
        fieldNameId: "settings.tags.tag_group.form.tag_property.tag_name",
        typeId: "settings.tags.tag_group.form.tag_property.text",
        mandatory: true,
    },
    {
        fieldNameId: "settings.tags.tag_group.form.tag_property.color",
        typeId: "settings.tags.tag_group.form.tag_property.color_picker",
        mandatory: false,
    },
];
export var TagGroupForm = React.memo(function (props) {
    var initialFormValues = props.initialFormValues, isSubmitting = props.isSubmitting, _a = props.nameEditable, nameEditable = _a === void 0 ? false : _a, _b = props.flagsFreezed, flagsFreezed = _b === void 0 ? false : _b, _c = props.predefinedProperties, predefinedProperties = _c === void 0 ? defaultPredefinedProperties : _c, onSubmit = props.onSubmit, onCancel = props.onCancel;
    var intl = useIntl();
    var formikRef = useRef(null);
    var isEditing = useMemo(function () { return !!initialFormValues; }, [initialFormValues]);
    var initialValues = useMemo(function () {
        var _a, _b, _c, _d;
        return ({
            name: (_a = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.name) !== null && _a !== void 0 ? _a : "",
            visible: (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.visible) !== null && _b !== void 0 ? _b : true,
            mandatory: (_c = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.mandatory) !== null && _c !== void 0 ? _c : false,
            public: (_d = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.public) !== null && _d !== void 0 ? _d : false,
            definitions: (initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.definitions)
                ? Object.keys(initialFormValues.definitions).reduce(function (acc, curr) {
                    var _a;
                    return (__assign(__assign({}, acc), (_a = {}, _a[curr] = initialFormValues.definitions[curr], _a)));
                }, {})
                : {},
        });
    }, [initialFormValues]);
    var onAddNewProperty = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var id, definitions;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!formikRef.current)
                        return [2 /*return*/];
                    id = uuidv4();
                    definitions = formikRef.current.values.definitions;
                    return [4 /*yield*/, formikRef.current.setFieldTouched("definitions", true)];
                case 1:
                    _b.sent();
                    return [4 /*yield*/, formikRef.current.setFieldValue("definitions", __assign(__assign({}, definitions), (_a = {}, _a[id] = {
                            name: "",
                            mandatory: false,
                            createdAt: new Date(),
                        }, _a)))];
                case 2:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var onRemoveProperty = useCallback(function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var definitions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!formikRef.current)
                        return [2 /*return*/];
                    definitions = formikRef.current.values.definitions;
                    delete definitions[id];
                    return [4 /*yield*/, formikRef.current.setFieldTouched("definitions", true)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, formikRef.current.setFieldValue("definitions", definitions)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, []);
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: tagGroupFormSchema, validateOnChange: true, onSubmit: onSubmit, innerRef: formikRef }, function (_a) {
        var values = _a.values, errors = _a.errors, touched = _a.touched, handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur;
        return (React.createElement("form", { onSubmit: handleSubmit, className: "h-full w-full flex flex-col overflow-hidden" },
            React.createElement(DirtyFormPrompt, null),
            React.createElement("div", { className: "flex-1 overflow-y-auto min-h-0 my-2 md:my-0 md:p-4" },
                React.createElement("fieldset", { className: "bg-white p-4 flex flex-col space-y-6 md:h-auto  md:px-8 md:py-1 md:space-y-0 md:divide-y md:divide-gray-200 md:rounded-lg" },
                    React.createElement(FormField, { titleId: "settings.tags.tag_group.form.group_name", errorId: touched.name ? errors.name : undefined, isOptional: false },
                        React.createElement(TextField, { name: "name", onChange: handleChange, onBlur: handleBlur, value: values.name, disabled: !nameEditable && isEditing, placeholder: intl.formatMessage({
                                id: "settings.tags.tag_group.form.group_name.placeholder",
                            }), isError: touched.name && !!errors.name, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
                    React.createElement(FormField, { titleId: "settings.tags.tag_group.form.visible.title", alignment: "start", isOptional: false },
                        React.createElement(Checkbox, { name: "visible", disabled: flagsFreezed, titleId: "settings.tags.tag_group.form.visible.title", descriptionId: "settings.tags.tag_group.form.visible.description", value: values.visible, onChange: handleChange })),
                    React.createElement(FormField, { titleId: "settings.tags.tag_group.form.mandatory.title", alignment: "start", isOptional: false },
                        React.createElement(Checkbox, { name: "mandatory", disabled: flagsFreezed, titleId: "settings.tags.tag_group.form.mandatory.title", descriptionId: "settings.tags.tag_group.form.mandatory.description", value: values.mandatory, onChange: handleChange })),
                    React.createElement(FormField, { titleId: "settings.tags.tag_group.form.public.title", alignment: "start", isOptional: false },
                        React.createElement(Checkbox, { name: "public", disabled: flagsFreezed, titleId: "settings.tags.tag_group.form.public.title", descriptionId: "settings.tags.tag_group.form.public.description", value: values.public, onChange: handleChange }))),
                React.createElement("fieldset", { className: "mt-4 bg-white p-4 flex flex-col space-y-6 md:px-8 md:py-1 md:space-y-0 md:divide-y md:divide-gray-200 md:rounded-lg" },
                    React.createElement(TagProperty.Panel, { onAddNewProperty: onAddNewProperty },
                        predefinedProperties.map(function (property) { return (React.createElement(TagProperty.Item, { className: "mt-5", key: property.fieldNameId, propertyType: "predefined", fieldNameId: property.fieldNameId, typeId: property.typeId, mandatory: property.mandatory })); }),
                        getTagGroupCustomFieldsEntries(values.definitions).map(function (_a) {
                            var _b;
                            var _c = __read(_a, 2), key = _c[0], value = _c[1];
                            return (React.createElement(TagProperty.Item, { className: "mt-5", key: key, propertyType: "custom", fieldId: key, fieldName: value.name, error: (_b = errors.definitions) === null || _b === void 0 ? void 0 : _b[key], mandatory: value.mandatory, onRemoveProperty: onRemoveProperty, handleBlur: handleBlur, handleChange: handleChange }));
                        })))),
            React.createElement("div", { className: "p-4 bg-white flex flex-row justify-end items-center space-x-3" },
                React.createElement(WhiteButton, { type: "button", size: "base", disabled: isSubmitting, onClick: onCancel },
                    React.createElement(FormattedMessage, { id: "common.cancel" })),
                React.createElement(PrimaryButton, { type: "submit", size: "base", disabled: isSubmitting },
                    React.createElement(FormattedMessage, { id: isEditing
                            ? "settings.tags.form.edit.submit"
                            : "settings.tags.form.create.submit", values: {
                            type: intl.formatMessage({
                                id: "settings.tags.tag_group.tag_group",
                            }),
                        } })))));
    }));
});

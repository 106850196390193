import { __read } from "tslib";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ROUTES } from "@router/routes";
import { TagGroupForm, } from "../settings/forms/TagGroupForm";
import { useNavigation } from "@hooks/useNavigation";
import { useAppToast } from "../common/Toast";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { parseTagGroupUpdateErrorMessage } from "@shared/models/error";
import { APIResponseError } from "@shared/contexts/api";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
var GROUP_SLUG = "priority";
var predefinedProperties = [
    {
        fieldNameId: "settings.tags.priority.form.full_name",
        typeId: "settings.tags.tag_group.form.tag_property.text",
        mandatory: false,
    },
    {
        fieldNameId: "settings.tags.priority.form.tag_name",
        typeId: "settings.tags.tag_group.form.tag_property.text",
        mandatory: true,
    },
    {
        fieldNameId: "settings.tags.priority.form.color",
        typeId: "settings.tags.tag_group.form.tag_property.color_picker",
        mandatory: false,
    },
    {
        fieldNameId: "settings.tags.priority.form.remarks",
        typeId: "settings.tags.tag_group.form.tag_property.text",
        mandatory: false,
    },
];
export var PriorityTagGroupEditScreen = React.memo(function () {
    var intl = useIntl();
    var navigation = useNavigation();
    var showErrorToast = useAppToast().showErrorToast;
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _a = __read(useState(undefined), 2), initialFormValue = _a[0], setInitialFormValue = _a[1];
    var _b = __read(useState(false), 2), isSubmitting = _b[0], setIsSubmitting = _b[1];
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var handleSubmit = useCallback(function (formValues) {
        setIsSubmitting(true);
        tagGroupAPI
            .updatePredefinedTagGroup(GROUP_SLUG, {
            is_mandatory: formValues.mandatory,
            is_public: formValues.public,
            is_visible: formValues.visible,
            definition: formValues.definitions,
        })
            .then(function () {
            navigation.push(ROUTES.settings.tags.priority.group_detail);
        })
            .catch(function (err) {
            if (err instanceof APIResponseError) {
                var _a = __read(parseTagGroupUpdateErrorMessage(err), 2), titleId = _a[0], descriptionId = _a[1];
                showErrorToast({
                    titleId: titleId,
                    descriptionId: descriptionId,
                }, {
                    position: "bottom-center",
                });
            }
            else {
                throw err;
            }
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    }, [tagGroupAPI, navigation, setIsSubmitting, showErrorToast]);
    var handleCancel = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.priority.group_detail);
        }
    }, [navigation]);
    useEffect(function () {
        setIsLoading(true);
        tagGroupAPI
            .getTagGroupBySlug(GROUP_SLUG)
            .then(function (group) {
            setInitialFormValue({
                name: group.name,
                mandatory: group.mandatory,
                visible: group.visible,
                public: group.public,
                definitions: group.definition,
            });
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            navigation.push(ROUTES.settings.tags.priority.group_detail);
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        navigation,
        tagGroupAPI,
        setIsLoading,
        setInitialFormValue,
        showErrorToast,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tag_group.predefined.form.edit.title", values: {
                        type: intl.formatMessage({
                            id: "settings.tags.title.priority",
                        }),
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(TagGroupForm, { initialFormValues: initialFormValue, predefinedProperties: predefinedProperties, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleCancel })))));
});

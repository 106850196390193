import { __assign, __read } from "tslib";
import * as yup from "yup";
export function aggregateFormSchema(baseSchema, additionalFields) {
    if (!additionalFields) {
        return baseSchema;
    }
    var additionalSchema = yup
        .object(Object.entries(additionalFields).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        return __assign(__assign({}, acc), (_b = {}, _b[key] = value.mandatory
            ? yup.string().trim().required("form.validation.error.required")
            : yup.string(), _b));
    }, {}))
        .defined();
    return baseSchema.concat(yup
        .object()
        .shape({
        additional: additionalSchema.defined(),
    })
        .defined());
}
export function notEmpty(value) {
    return value !== null && value !== undefined;
}

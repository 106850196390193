import { __assign, __read } from "tslib";
import React, { useCallback, useMemo } from "react";
import cn from "classnames";
import { MailIcon, DocumentDuplicateIcon, PaperClipIcon, } from "@heroicons/react/outline";
import { Persona, PersonaSize } from "@fluentui/react";
import { extractInitials } from "@utils/avatar";
import { ColouredTag } from "@components/common/ColouredTag";
import { DocumentGroupType } from "@shared/models/document";
import { formatDate } from "@utils/date";
import { ScreenSize, useMediaQuery } from "@hooks/useMediaQuery";
var DocumentListItemSideInfo = React.memo(function (props) {
    var group = props.group, className = props.className, isCompact_ = props.isCompact;
    var screenSize = useMediaQuery();
    var isCompact = useMemo(function () { return screenSize <= ScreenSize.md || isCompact_; }, [isCompact_, screenSize]);
    return (React.createElement("div", { className: cn("min-w-0 flex flex-row items-center flex-none", className) },
        group.type === DocumentGroupType.Files && group.itemCount > 0 && (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "flex-none font-normal text-xs leading-4 text-gray-500" }, group.itemCount),
            React.createElement(DocumentDuplicateIcon, { className: "flex-none ml-1 h-4 w-4 text-gray-500" }))),
        group.hasAttachments && (React.createElement(PaperClipIcon, { className: "flex-none ml-2 w-4 h-4 text-gray-500" })),
        !!group.deals.length && (React.createElement("div", { className: cn("ml-2 flex-1  overflow-hidden flex-row items-center", isCompact ? "hidden" : "flex") },
            React.createElement(ColouredTag, { tag: group.deals[0].name, colour: group.deals[0].color }),
            group.deals.length > 1 && (React.createElement("span", { className: "ml-2 font-normal text-xs leading-4 text-gray-500" }, "+" + (group.deals.length - 1))))),
        React.createElement("span", { className: "w-14 flex-none ml-2 font-normal text-[0.625rem] leading-4 text-gray-600" }, formatDate(group.lastCreatedAt))));
});
var CompactDealSection = React.memo(function (_a) {
    var isHidden = _a.isHidden, group = _a.group;
    return (React.createElement("div", { className: cn("flex-row overflow-hidden items-center", !isHidden ? "flex" : "hidden") },
        React.createElement(ColouredTag, { tag: group.deals[0].name, colour: group.deals[0].color }),
        group.deals.length > 1 && (React.createElement("span", { className: cn("ml-2 font-normal text-xs leading-4 text-gray-500") }, "+" + (group.deals.length - 1)))));
});
var DocumentListItemHead = React.memo(function (_a) {
    var isCompact = _a.isCompact, isRead = _a.isRead, groupType = _a.groupType;
    return (React.createElement("div", { className: cn("flex-none flex items-center", isCompact ? "flex-col-reverse" : "flex-row", !isCompact && isRead && "ml-3") },
        React.createElement("div", { className: cn("h-2 w-2 rounded-full bg-orange-600", isRead && "hidden", isCompact ? "mt-2" : "mt-0 mr-1") }),
        groupType === DocumentGroupType.Files ? (React.createElement(DocumentDuplicateIcon, { className: "w-4 h-4 text-gray-400" })) : (React.createElement(MailIcon, { className: "w-4 h-4 text-gray-400" }))));
});
var DocumentListItemSubject = React.memo(function (_a) {
    var isCompact = _a.isCompact, groupType = _a.groupType, subject = _a.subject, itemCount = _a.itemCount, isRead = _a.isRead;
    return (React.createElement("div", { className: cn("flex flex-none flex-shrink-0 flex-row overflow-hidden", isCompact ? "mt-1" : "mt-0 ml-5 md:max-w-[50%]") },
        React.createElement("h1", { className: cn("text-sm leading-5 text-gray-900 whitespace-nowrap overflow-hidden overflow-ellipsis", !isRead && "font-bold") }, subject),
        groupType === DocumentGroupType.Messages && itemCount > 0 && (React.createElement("span", { className: "ml-2 font-normal text-sm leading-5 text-gray-500" }, itemCount))));
});
export var DocumentListItem = React.memo(function (props) {
    var _a;
    var isRead = props.isRead, group = props.group, isCompact_ = props.isCompact, onClick = props.onClick;
    var screenSize = useMediaQuery();
    var onItemClick = useCallback(function () {
        onClick(group);
    }, [group, onClick]);
    var isCompact = useMemo(function () { return screenSize <= ScreenSize.md || isCompact_; }, [isCompact_, screenSize]);
    var _b = __read(useMemo(function () {
        var _a, _b;
        var identifier = "";
        var avatar;
        if (group.firstSenderContact) {
            identifier =
                (((_a = group.firstSenderContact.contact) === null || _a === void 0 ? void 0 : _a.name) &&
                    group.firstSenderContact.contact.name) ||
                    group.firstSenderContact.name ||
                    group.firstSenderContact.address;
            avatar = (_b = group.firstSenderContact.contact) === null || _b === void 0 ? void 0 : _b.avatar;
        }
        if (!identifier.length) {
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            identifier = group.uploadedBy.fullName || group.uploadedBy.emails[0];
            avatar = group.uploadedBy.avatar;
        }
        return [identifier, avatar !== null && avatar !== void 0 ? avatar : undefined];
    }, [group.firstSenderContact, group.uploadedBy]), 2), senderIdentifier = _b[0], senderAvatar = _b[1];
    return (React.createElement("button", { className: cn("w-full min-w-0 flex flex-row py-2 px-3", isCompact ? "items-start" : "items-center"), type: "button", onClick: onItemClick },
        React.createElement(DocumentListItemHead, { isCompact: !!isCompact, groupType: group.type, isRead: isRead }),
        React.createElement(Persona, { className: "ml-2", imageUrl: (_a = senderAvatar === null || senderAvatar === void 0 ? void 0 : senderAvatar.url) !== null && _a !== void 0 ? _a : undefined, imageInitials: extractInitials(senderIdentifier, 2), hidePersonaDetails: true, size: PersonaSize.size24 }),
        React.createElement("div", { className: cn("flex-1 min-w-0 flex", isCompact
                ? "mb-2 space-y-1 ml-3 flex-col w-full"
                : "mb-0 space-y-0 ml-0 flex-row") },
            React.createElement("div", { className: cn("flex flex-none flex-row justify-between", !isCompact && "ml-3") },
                React.createElement("span", { className: cn("font-normal text-gray-900 text-left overflow-ellipsis overflow-hidden whitespace-nowrap", isCompact ? "tex-txs leading-4" : "text-sm leading-5 w-[6.75rem]") }, senderIdentifier),
                React.createElement(DocumentListItemSideInfo, __assign({}, props, { className: cn(isCompact ? "flex flex-shrink-0" : "hidden") }))),
            React.createElement(DocumentListItemSubject, { isCompact: !!isCompact, isRead: isRead, subject: group.subject, itemCount: group.itemCount, groupType: group.type }),
            React.createElement("div", { className: "flex-1 min-w-0 flex items-center" },
                React.createElement("p", { className: cn("font-normal text-xs leading-4 text-gray-900 text-left overflow-hidden overflow-ellipsis whitespace-nowrap", !isCompact && "mt-0 mx-5") }, group.bodyPreview)),
            !!group.deals.length && (React.createElement(CompactDealSection, { isHidden: !isCompact, group: group }))),
        React.createElement(DocumentListItemSideInfo, __assign({}, props, { className: cn(isCompact ? "hidden" : "flex-none flex") }))));
});

import { __awaiter, __generator } from "tslib";
import { useCallback } from "react";
import Resizer from "react-image-file-resizer";
export function useImageResizer() {
    var _this = this;
    var resizeImageFile = useCallback(function (file, config) { return __awaiter(_this, void 0, void 0, function () {
        var maxWidth, maxHeight, _a, compressFormat;
        return __generator(this, function (_b) {
            maxWidth = config.maxWidth, maxHeight = config.maxHeight, _a = config.compressFormat, compressFormat = _a === void 0 ? "PNG" : _a;
            return [2 /*return*/, new Promise(function (resolve) {
                    Resizer.imageFileResizer(file, maxWidth, maxHeight, compressFormat, 100, 0, function (f) { return resolve(f); }, "file");
                })];
        });
    }); }, []);
    return { resizeImageFile: resizeImageFile };
}

import React from "react";
import cn from "classnames";
var alignmentClass = {
    start: "md:items-start",
    center: "md:items-center",
    end: "md:items-end",
};
export var Item = React.memo(function (props) {
    var title = props.title, value = props.value, _a = props.alignment, alignment = _a === void 0 ? "center" : _a;
    return (React.createElement("div", { className: cn("flex flex-col justify-center p-4 md:justify-start md:flex-row md:px-6 md:py-5", alignmentClass[alignment]) },
        React.createElement("h1", { className: "text-sm leading-5 font-bold text-gray-500 md:min-w-[17.5rem]" }, title),
        value && (React.createElement("span", { className: "mt-1 text-sm leading-5 font-normal text-gray-900 md:mt-0" }, value))));
});
var SkeletonItem = React.memo(function () {
    return (React.createElement("div", { className: cn("flex flex-col items-center justify-center p-4 md:justify-start md:flex-row md:px-6 md:py-5") },
        React.createElement("div", { className: "h-5 animate-pulse w-full bg-gray-200 rounded-full" })));
});
var SkeletonPanel = React.memo(function () {
    return (React.createElement("div", { className: "flex flex-col bg-white divide-y divide-gray-200 shadow-sm md:rounded-lg" }, new Array(8).fill(null).map(function (_, idx) { return (React.createElement(SkeletonItem, { key: idx })); })));
});
export var Panel = function (props) {
    var isLoading = props.isLoading, children = props.children;
    if (isLoading) {
        return React.createElement(SkeletonPanel, null);
    }
    return (React.createElement("div", { className: "flex flex-col bg-white divide-y divide-gray-200 shadow-sm md:rounded-lg" }, children));
};
export var TagDetailInfo = {
    Panel: Panel,
    Item: Item,
};

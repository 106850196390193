import { __read } from "tslib";
import React, { Fragment, useState, useMemo } from "react";
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { Portal, PortalContext } from "./Portal";
import cn from "classnames";
export var BasicDialog = React.memo(function (props) {
    var isOpened = props.isOpened, onClose = props.onClose, children = props.children, panelClassName = props.panelClassName;
    var _a = __read(useState(null), 2), containerEl = _a[0], setContainerEl = _a[1];
    var portalContextValue = useMemo(function () {
        return {
            containerEl: containerEl,
        };
    }, [containerEl]);
    return (React.createElement(Portal, null,
        React.createElement(Transition.Root, { show: isOpened, as: Fragment },
            React.createElement(HeadlessDialog, { as: "div", className: "fixed z-10 inset-0 overflow-y-auto", onClose: onClose },
                React.createElement("div", { ref: setContainerEl, className: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" },
                    React.createElement(PortalContext.Provider, { value: portalContextValue },
                        React.createElement(Transition.Child, { as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in duration-200", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                            React.createElement(HeadlessDialog.Overlay, { className: "fixed inset-0 bg-gray-500 opacity-75 transition-opacity" })),
                        React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen", "aria-hidden": "true" }, "\u200B"),
                        React.createElement(Transition.Child, { as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95", enterTo: "opacity-100 translate-y-0 sm:scale-100", leave: "ease-in duration-200", leaveFrom: "opacity-100 translate-y-0 sm:scale-100", leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" },
                            React.createElement("div", { className: cn("inline-block align-bottom bg-white rounded-lg text-left", "overflow-hidden shadow-xl transform transition-all", "sm:my-8 sm:align-middle sm:max-w-sm sm:w-full", panelClassName) }, children))))))));
});

import * as yup from "yup";
import { generatePaginationSchema, paginatedSearchRequest, } from "../pagination";
import { countrySchema } from "./country_api";
import { industrySchema } from "./industry_api";
import { colorSchema } from "./color_api";
import { toggleableParamSchema } from "./params";
export var companySchema = yup
    .object()
    .shape({
    id: yup.number().defined(),
    name: yup.string().defined(),
    isEnabled: yup.boolean().defined(),
    fullName: yup.string().defined().nullable(),
    domains: yup.array(yup.string().defined()).defined(),
    address: yup.string().defined().nullable(),
    countries: yup.array().of(countrySchema).defined(),
    industry: industrySchema.defined().nullable(),
    color: colorSchema.defined().nullable(),
    additional: yup.object(),
})
    .camelCase()
    .defined();
export var listCompanyRequestSchema = yup
    .object({
    country_id: yup.number().optional(),
    industry_id: yup.number().optional(),
})
    .concat(paginatedSearchRequest)
    .concat(toggleableParamSchema)
    .defined();
export var listCompanyResponseSchema = generatePaginationSchema(companySchema);
export var createCompanyRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    full_name: yup.string().defined().nullable(),
    domains: yup.array(yup.string().defined()).defined(),
    address: yup.string().defined().nullable(),
    country_ids: yup.array(yup.number().defined()).defined(),
    industry_id: yup.number().defined(),
    color_id: yup.number().defined().nullable(),
    additional: yup.object().defined().nullable(),
})
    .snakeCase()
    .defined();
export var editCompanyRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    full_name: yup.string().nullable(),
    domains: yup.array(yup.string().defined()).defined(),
    address: yup.string().defined().nullable(),
    country_ids: yup.array(yup.number().defined()).required(),
    industry_id: yup.number().defined(),
    color_id: yup.number().defined().nullable(),
    additional: yup.object().defined().nullable(),
})
    .snakeCase()
    .defined();
export var getAllCompaniesResponseSchema = yup
    .object({
    data: yup.array().of(companySchema).required(),
})
    .defined();

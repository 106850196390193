import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { listAllTagParamSchema, listAllTagResponseSchema, } from "@shared/models/api/all_tag_api";
export var AllTagAPIContext = React.createContext(undefined);
export var AllTagAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var listAllTags = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var param, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    param = listAllTagParamSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("all_tags", param)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listAllTagResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            listAllTags: listAllTags,
        };
    }, [listAllTags]);
    return (React.createElement(AllTagAPIContext.Provider, { value: value }, children));
});

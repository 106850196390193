import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DropDown } from "../common/DropDown";
import { WhiteButton } from "../common/WhiteButton";
import cn from "classnames";
import { PencilIcon, ChevronLeftIcon } from "@heroicons/react/outline";
import { useAppToast } from "../common/Toast";
import { Dialog, useDialog } from "../common/Dialog";
import { ExclamationIcon } from "@heroicons/react/solid";
import RequirePermission from "../common/RequirePermission";
import { Permission } from "@shared/models/auth";
export var TagDetailHeader = React.memo(function (props) {
    var typeId = props.typeId, tagName = props.tagName, className = props.className, description = props.description, isTagEnabled = props.isTagEnabled, onEdit = props.onEdit, onEnable = props.onEnable, onDisable = props.onDisable, onDelete = props.onDelete, onMerge = props.onMerge, onGoBack = props.onGoBack;
    var _a = __read(useState(isTagEnabled), 2), enableState = _a[0], setEnableState = _a[1];
    var _b = __read(useState(false), 2), isDeleting = _b[0], setIsDeleting = _b[1];
    var intl = useIntl();
    var _c = useAppToast(), showErrorToast = _c.showErrorToast, showSuccessToast = _c.showSuccessToast;
    var _d = useDialog(), deleteTagDialogProps = _d.dialogProps, openDeleteTagDialog = _d.openDialog;
    var onEnableTag = useCallback(function () {
        setEnableState(true);
        onEnable()
            .then(function () {
            showSuccessToast({
                titleId: "settings.tags.enable_tag.success.title",
            }, {
                position: "bottom-center",
            });
        })
            .catch(function (err) {
            console.error("Failed to enable tag", err);
            showErrorToast({
                titleId: "settings.tags.enable_tag.error.title",
                descriptionId: "settings.tags.enable_tag.error.description",
            }, {
                position: "bottom-center",
            });
            setEnableState(isTagEnabled);
        });
    }, [onEnable, showSuccessToast, showErrorToast, isTagEnabled]);
    var onDisableTag = useCallback(function () {
        setEnableState(false);
        onDisable()
            .then(function () {
            showSuccessToast({
                titleId: "settings.tags.disable_tag.success.title",
            }, {
                position: "bottom-center",
            });
        })
            .catch(function (err) {
            console.error("Failed to disable tag", err);
            showErrorToast({
                titleId: "settings.tags.disable_tag.error.title",
                descriptionId: "settings.tags.disable_tag.error.description",
            }, {
                position: "bottom-center",
            });
            setEnableState(isTagEnabled);
        });
    }, [isTagEnabled, onDisable, showErrorToast, showSuccessToast]);
    var onDeleteTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var confirm, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openDeleteTagDialog()];
                case 1:
                    confirm = _a.sent();
                    if (!confirm) {
                        return [2 /*return*/];
                    }
                    setIsDeleting(true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, onDelete()];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 4:
                    err_1 = _a.sent();
                    console.error("Failed to disable tag", err_1);
                    showErrorToast({
                        titleId: "settings.tags.delete_tag.error.title",
                        descriptionId: "settings.tags.delete_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 6];
                case 5:
                    setIsDeleting(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [onDelete, setIsDeleting, showErrorToast, openDeleteTagDialog]);
    useEffect(function () {
        setEnableState(isTagEnabled);
    }, [isTagEnabled]);
    return (React.createElement("div", { className: cn("flex flex-row p-4 items-center justify-between bg-white", className) },
        React.createElement("div", { className: "flex flex-row items-center" },
            React.createElement("button", { type: "button", className: "md:hidden mr-4", onClick: onGoBack },
                React.createElement(ChevronLeftIcon, { className: "h-6 text-gray-500" })),
            React.createElement("div", { className: "flex flex-col h-10 justify-center" },
                React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900 line-clamp-1" }, tagName),
                !!description && (React.createElement("span", { className: "font-normal text-sm leading-5 text-gray-600 line-clamp-1" }, description)))),
        React.createElement(RequirePermission, { permission: Permission.TagsUpdate },
            React.createElement("div", { className: "flex flex-row space-x-2" },
                React.createElement(Dialog, __assign({}, deleteTagDialogProps, { title: intl.formatMessage({
                        id: "settings.tags.delete_tag.confirmation.title",
                    }, {
                        tagName: tagName,
                    }), description: intl.formatMessage({
                        id: "settings.tags.delete_tag.confirmation.description",
                    }, {
                        tagName: tagName,
                    }), confirmLabelId: "common.delete", cancelLabelId: "common.cancel", icon: React.createElement(ExclamationIcon, { className: "h-6 w-6 text-red-600", "aria-hidden": "true" }) })),
                React.createElement(DropDown.Button, { labelId: "settings.tags.detail.header.actions", hideLabelOnMobile: true },
                    React.createElement(DropDown.Item, { onClick: enableState ? onDisableTag : onEnableTag, disabled: isDeleting },
                        React.createElement(FormattedMessage, { id: enableState
                                ? "settings.tags.disable_tag"
                                : "settings.tags.enable_tag", values: {
                                type: intl.formatMessage({
                                    id: typeId,
                                }),
                            } })),
                    React.createElement(DropDown.Item, { onClick: onMerge },
                        React.createElement(FormattedMessage, { id: "settings.tags.merge_tag", values: {
                                type: intl.formatMessage({
                                    id: typeId,
                                }),
                            } })),
                    React.createElement(RequirePermission, { permission: Permission.TagsDelete },
                        React.createElement(DropDown.Separator, null),
                        React.createElement(DropDown.Item, { onClick: onDeleteTag, disabled: isDeleting },
                            React.createElement(FormattedMessage, { id: "settings.tags.delete_tag", values: {
                                    type: intl.formatMessage({
                                        id: typeId,
                                    }),
                                } })))),
                React.createElement(WhiteButton, { size: "base", className: "hidden md:flex", onClick: onEdit },
                    React.createElement(FormattedMessage, { id: "settings.tags.edit_tag", values: {
                            type: intl.formatMessage({
                                id: typeId,
                            }),
                        } })),
                React.createElement(WhiteButton, { size: "sm", className: "md:hidden", onClick: onEdit },
                    React.createElement(PencilIcon, { className: "h-4 w-4 text-gray-500" }))))));
});

export function isSearchTagsEmpty(tags) {
    return (tags.companies.length === 0 &&
        tags.contacts.length === 0 &&
        tags.deals.length === 0 &&
        tags.priorities.length === 0 &&
        tags.otherTags.length === 0 &&
        tags.customTags.length === 0);
}
export function searchTagsCount(tags) {
    return (tags.companies.length +
        tags.contacts.length +
        tags.deals.length +
        tags.priorities.length +
        tags.otherTags.length +
        tags.customTags.length);
}

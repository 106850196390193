import { __awaiter, __generator, __read } from "tslib";
import React, { useMemo, useCallback } from "react";
import cryptoBrowserify from "crypto-browserify";
import base64url from "base64url";
import { STORAGE_KEYS } from "@constants/consts";
export var AzureOAuthContext = React.createContext(undefined);
function getOAuthAuthorizeURL(options) {
    return [
        "https://login.microsoftonline.com/" + window.CONFIGS.AZURE_OAUTH_AD_ID + "/oauth2/v2.0/authorize?",
        "client_id=" + window.CONFIGS.AZURE_APP_ID,
        "&response_type=code",
        "&redirect_uri=" + encodeURIComponent(window.CONFIGS.OAUTH_REDIRECT_URL),
        "&response_mode=query",
        "&scope=" + encodeURIComponent("openid profile"),
        "&code_challenge=" + options.codeChallenge,
        "&code_challenge_method=S256",
    ].join("");
}
function getOAuthTokenURL() {
    return "https://login.microsoftonline.com/" + window.CONFIGS.AZURE_OAUTH_AD_ID + "/oauth2/v2.0/token";
}
function getOAuthTokenRequestBody(options) {
    var formBody = {
        client_id: window.CONFIGS.AZURE_APP_ID,
        scope: "openid profile",
        code: options.code,
        redirect_uri: window.CONFIGS.OAUTH_REDIRECT_URL,
        grant_type: "authorization_code",
        code_verifier: options.codeVerifier,
    };
    return Object.entries(formBody)
        .map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return encodeURIComponent(key) + "=" + encodeURIComponent(value);
    })
        .join("&");
}
export var AzureOAuthContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var initiateOauthFlow = useCallback(function () {
        var codeVerifier = cryptoBrowserify.randomBytes(64).toString("hex");
        var hash = cryptoBrowserify.createHash("sha256");
        var codeChallenge = base64url(hash.update(codeVerifier).digest());
        var oauthUrl = getOAuthAuthorizeURL({
            codeChallenge: codeChallenge,
        });
        sessionStorage.setItem(STORAGE_KEYS.OAUTH_CODE_VERIFIER, codeVerifier);
        window.location.href = oauthUrl;
    }, []);
    var handleAuthorizationCode = useCallback(function (code) { return __awaiter(void 0, void 0, void 0, function () {
        var codeVerifier, resp, result, idToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    codeVerifier = sessionStorage.getItem(STORAGE_KEYS.OAUTH_CODE_VERIFIER);
                    if (!codeVerifier) {
                        return [2 /*return*/, null];
                    }
                    sessionStorage.removeItem(STORAGE_KEYS.OAUTH_CODE_VERIFIER);
                    return [4 /*yield*/, fetch(getOAuthTokenURL(), {
                            method: "POST",
                            mode: "cors",
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                            },
                            body: getOAuthTokenRequestBody({
                                code: code,
                                codeVerifier: codeVerifier,
                            }),
                        })];
                case 1:
                    resp = _a.sent();
                    if (resp.status !== 200) {
                        console.error(resp);
                        throw new Error("AzureOAuthContext: request failed, " + resp.status);
                    }
                    return [4 /*yield*/, resp.json()];
                case 2:
                    result = _a.sent();
                    idToken = result.id_token;
                    if (!idToken) {
                        throw new Error("AzureOAuthContext: id_token missing");
                    }
                    return [2 /*return*/, { idToken: idToken }];
            }
        });
    }); }, []);
    var _b = __read(React.useState({}), 2), oauthState = _b[0], setOAuthState = _b[1];
    var value = useMemo(function () {
        return {
            initiateOauthFlow: initiateOauthFlow,
            handleAuthorizationCode: handleAuthorizationCode,
            oauthState: oauthState,
            setOAuthState: setOAuthState,
        };
    }, [initiateOauthFlow, handleAuthorizationCode, oauthState]);
    return (React.createElement(AzureOAuthContext.Provider, { value: value }, children));
});

import React from "react";
import cn from "classnames";
import { Spinner as FluentSpinner, SpinnerSize as FluentSpinnerSize, } from "@fluentui/react";
var fluentSpinnerSizeMap = {
    large: FluentSpinnerSize.large,
    small: FluentSpinnerSize.small,
};
var fluentSpinnerStyles = {
    circle: {
        borderLeftColor: "var(--color-indigo-300)",
        borderBottomColor: "var(--color-indigo-300)",
        borderRightColor: "var(--color-indigo-300)",
        borderTopColor: "var(--color-indigo-900)",
    },
};
export var Spinner = React.memo(function (props) {
    return (React.createElement(FluentSpinner, { className: cn(props.className), size: fluentSpinnerSizeMap[props.size], styles: fluentSpinnerStyles }));
});

import { __assign, __read, __rest, __spreadArray } from "tslib";
import { useCallback, useState } from "react";
import { useEffectOnce } from "./useEffectOnce";
export function useSearchPagination(fetch, initialOptions, onError, lazy) {
    if (lazy === void 0) { lazy = false; }
    var initialPage = initialOptions.page, perPage = initialOptions.perPage, initialSearch = initialOptions.search, initialExtraOptions = __rest(initialOptions, ["page", "perPage", "search"]);
    var _a = __read(useState(initialPage !== null && initialPage !== void 0 ? initialPage : 1), 2), page = _a[0], setPage = _a[1];
    var _b = __read(useState(initialSearch), 2), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = __read(useState(initialExtraOptions), 2), extraOptions = _c[0], setExtraOptions = _c[1];
    var _d = __read(useState([]), 2), data = _d[0], setData = _d[1];
    var _e = __read(useState(0), 2), initialTotalCount = _e[0], setInitialTotalCount = _e[1];
    var _f = __read(useState(0), 2), totalCount = _f[0], setTotalCount = _f[1];
    var _g = __read(useState(false), 2), isFetchingMore = _g[0], setIsFetchingMore = _g[1];
    var _h = __read(useState(true), 2), isInitializing = _h[0], setIsInitializing = _h[1];
    var _j = __read(useState(false), 2), hasMore = _j[0], setHasMore = _j[1];
    var fetchData = useCallback(function (options, shouldAppend) {
        setIsFetchingMore(true);
        fetch(options)
            .then(function (res) {
            setData(function (oldData) {
                return shouldAppend ? __spreadArray(__spreadArray([], __read(oldData)), __read(res.data)) : res.data;
            });
            setTotalCount(res.count);
            if (!shouldAppend) {
                setInitialTotalCount(res.count);
            }
            setHasMore(res.data.length === res.perPage);
        })
            .catch(function (err) {
            onError === null || onError === void 0 ? void 0 : onError(err);
        })
            .finally(function () {
            setIsInitializing(false);
            setIsFetchingMore(false);
        });
    }, [fetch, onError]);
    var nextPage = useCallback(function () {
        if (!hasMore) {
            return;
        }
        setPage(function (prev) { return prev + 1; });
        fetchData(__assign({ page: page + 1, perPage: perPage, search: searchTerm }, extraOptions), true);
    }, [extraOptions, fetchData, hasMore, page, perPage, searchTerm]);
    var search = useCallback(function (keyword, extraOptions) {
        setPage(initialPage !== null && initialPage !== void 0 ? initialPage : 1);
        setSearchTerm(keyword);
        if (extraOptions !== undefined) {
            setExtraOptions(extraOptions);
        }
        fetchData(__assign({ page: initialPage !== null && initialPage !== void 0 ? initialPage : 1, perPage: perPage, search: keyword }, extraOptions), false);
    }, [initialPage, fetchData, perPage]);
    var refresh = useCallback(function () {
        setIsInitializing(true);
        setPage(initialPage !== null && initialPage !== void 0 ? initialPage : 1);
        setSearchTerm(initialSearch);
        setExtraOptions(initialExtraOptions);
        fetchData(__assign({ page: initialPage !== null && initialPage !== void 0 ? initialPage : 1, perPage: perPage, search: initialSearch }, initialExtraOptions), false);
    }, [fetchData, initialExtraOptions, initialPage, initialSearch, perPage]);
    useEffectOnce(function () {
        refresh();
    }, function () { return !lazy; });
    return {
        data: data,
        initialTotalCount: initialTotalCount,
        totalCount: totalCount,
        isInitializing: isInitializing,
        isFetchingMore: isFetchingMore,
        hasMore: hasMore,
        nextPage: nextPage,
        search: search,
        refresh: refresh,
    };
}

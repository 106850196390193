import { __assign, __awaiter, __generator, __read } from "tslib";
import { PriorityAPIContext } from "@shared/contexts/api/PriorityAPIContext";
import { useNavigation } from "@hooks/useNavigation";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { PriorityExtractors } from "@shared/models/priority";
import { ROUTES } from "@router/routes";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { ColouredTag } from "@components/common/ColouredTag";
import { DefaultTagGroupDetail } from "../settings/DefaultTagGroupDetail";
import { TagContextMenu } from "../settings/TagContextMenu";
import cn from "classnames";
import { useAppToast } from "../common/Toast";
import { MergeTagDialog, useMergeTagDialog } from "../common/MergeTagDialog";
import { TagGroupSlug } from "@shared/models/tag";
export var PriorityTagGroupDetailScreen = React.memo(function () {
    var navigation = useNavigation();
    var priorityAPI = useContext(PriorityAPIContext);
    var _a = useAppToast(), showErrorToast = _a.showErrorToast, showSuccessToast = _a.showSuccessToast;
    var _b = __read(useState({}), 2), enableStates = _b[0], setEnableStates = _b[1];
    var onGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.root);
        }
    }, [navigation]);
    var onFetchListItemError = useCallback(function () {
        showErrorToast({
            titleId: "common.errors.data.fetch_failed.title",
            descriptionId: "common.errors.data.fetch_failed.description",
        }, {
            position: "bottom-center",
        });
        onGoBack();
    }, [onGoBack, showErrorToast]);
    var _c = useSearchPagination(priorityAPI.listPriorities, {
        page: 1,
        perPage: 100,
        isEnabled: "any",
    }, onFetchListItemError), data = _c.data, totalCount = _c.totalCount, isInitializing = _c.isInitializing, isFetchingMore = _c.isFetchingMore, hasMore = _c.hasMore, nextPage = _c.nextPage, search = _c.search, refresh = _c.refresh;
    var onAddNewPriority = useCallback(function () {
        navigation.push(ROUTES.settings.tags.priority.add);
    }, [navigation]);
    var onEditGroup = useCallback(function () {
        navigation.push(ROUTES.settings.tags.priority.edit_group);
    }, [navigation]);
    var onEditTag = useCallback(function (priority) {
        navigation.push(ROUTES.settings.tags.priority.group_detail + "/" + priority.id + "/edit");
    }, [navigation]);
    var onEnableTag = useCallback(function (priority) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setEnableStates(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[priority.id] = true, _a)));
            });
            priorityAPI
                .enablePriority(priority.id)
                .then(function () {
                showSuccessToast({
                    titleId: "settings.tags.enable_tag.success.title",
                }, {
                    position: "bottom-center",
                });
            })
                .catch(function (err) {
                console.error("Failed to enable tag", err);
                showErrorToast({
                    titleId: "settings.tags.enable_tag.error.title",
                    descriptionId: "settings.tags.enable_tag.error.description",
                }, {
                    position: "bottom-center",
                });
                setEnableStates(function (prevState) {
                    var _a;
                    return (__assign(__assign({}, prevState), (_a = {}, _a[priority.id] = priority.isEnabled, _a)));
                });
            });
            return [2 /*return*/];
        });
    }); }, [priorityAPI, showErrorToast, showSuccessToast]);
    var onDisableTag = useCallback(function (priority) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setEnableStates(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[priority.id] = false, _a)));
            });
            priorityAPI
                .disablePriority(priority.id)
                .then(function () {
                showSuccessToast({
                    titleId: "settings.tags.disable_tag.success.title",
                }, {
                    position: "bottom-center",
                });
            })
                .catch(function (err) {
                console.error("Failed to disable tag", err);
                showErrorToast({
                    titleId: "settings.tags.disable_tag.error.title",
                    descriptionId: "settings.tags.disable_tag.error.description",
                }, {
                    position: "bottom-center",
                });
                setEnableStates(function (prevState) {
                    var _a;
                    return (__assign(__assign({}, prevState), (_a = {}, _a[priority.id] = priority.isEnabled, _a)));
                });
            });
            return [2 /*return*/];
        });
    }); }, [priorityAPI, showErrorToast, showSuccessToast]);
    var onDeleteTag = useCallback(function (priority) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, priorityAPI.deletePriority(priority.id).then(function () {
                    refresh();
                })];
        });
    }); }, [priorityAPI, refresh]);
    var _d = useMergeTagDialog(), mergeDialogProps = _d.mergeDialogProps, openMergeDialog = _d.openMergeDialog, closeMergeDialog = _d.closeMergeDialog;
    var _e = __read(useState(false), 2), isMerging = _e[0], setIsMerging = _e[1];
    var _f = __read(useState(null), 2), mergeItem = _f[0], setMergeItem = _f[1];
    var onMergeTag = useCallback(function (item) { return __awaiter(void 0, void 0, void 0, function () {
        var source, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setMergeItem(item);
                    return [4 /*yield*/, openMergeDialog()];
                case 1:
                    source = _a.sent();
                    if (!source) {
                        return [2 /*return*/];
                    }
                    setIsMerging(true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, priorityAPI.mergePriority(item.id, source.id)];
                case 3:
                    _a.sent();
                    closeMergeDialog();
                    refresh();
                    return [3 /*break*/, 6];
                case 4:
                    err_1 = _a.sent();
                    console.error("Failed to merge tag", err_1);
                    showErrorToast({
                        titleId: "merge_tag.error.title",
                        descriptionId: "merge_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 6];
                case 5:
                    setIsMerging(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [openMergeDialog, priorityAPI, closeMergeDialog, refresh, showErrorToast]);
    var onGoToTagDetail = useCallback(function (priority) {
        navigation.push(ROUTES.settings.tags.priority.group_detail + "/" + priority.id, {
            tagName: priority.name,
        });
    }, [navigation]);
    var renderContextMenu = useCallback(function (p) {
        var _a;
        return (React.createElement(TagContextMenu, { typeId: "settings.tags.title.priority", item: p, itemEnableState: (_a = enableStates[p.id]) !== null && _a !== void 0 ? _a : p.isEnabled, onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onMergeTag }));
    }, [
        enableStates,
        onEditTag,
        onEnableTag,
        onDisableTag,
        onDeleteTag,
        onMergeTag,
    ]);
    var tableColumns = useMemo(function () { return [
        {
            titleId: "settings.tags.priority.detail.table.priority_tag",
            accessor: function (c) { return c.name; },
            render: function (_, c) {
                var _a;
                return (React.createElement(ColouredTag, { className: cn(!((_a = enableStates[c.id]) !== null && _a !== void 0 ? _a : c.isEnabled) && "opacity-50"), tag: c.name, colour: c.color }));
            },
        },
        {
            titleId: "settings.tags.priority.detail.table.full_name",
            accessor: function (c) { return c.fullName; },
        },
    ]; }, [enableStates]);
    var tableActions = useMemo(function () { return [
        {
            className: "flex items-center justify-end",
            render: renderContextMenu,
        },
    ]; }, [renderContextMenu]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DefaultTagGroupDetail, { headerTitleId: "settings.tags.title.priority", headerTagCountId: "settings.tags.tag_counts.priority", searchPlaceholderId: "settings.tags.priority.detail.search_placeholder", addNewTagId: "settings.tags.priority.detail.add_new_priority", tableData: data, tableColumns: tableColumns, tableActions: tableActions, tagCount: totalCount, isInitializing: isInitializing, isFetchingMore: isFetchingMore, hasMoreData: hasMore, onTableItemClick: onGoToTagDetail, onFetchMore: nextPage, onGoBack: onGoBack, onAddNewClick: onAddNewPriority, onEditGroup: onEditGroup, onSearch: search, tagGroupSlug: TagGroupSlug.Priority }),
        mergeItem && (React.createElement(MergeTagDialog, __assign({}, mergeDialogProps, { destinationId: mergeItem.id, tagName: mergeItem.name, isMerging: isMerging, fetch: priorityAPI.listPriorities, keyExtractor: PriorityExtractors.id, labelExtractor: PriorityExtractors.name })))));
});

import { __assign, __read } from "tslib";
import React, { useMemo, useRef } from "react";
import { regionFormSchema } from "./models";
import { useIntl } from "react-intl";
import { FormField } from "@components/form/FormField";
import { Formik } from "formik";
import cn from "classnames";
import { TextField } from "@components/form/TextField";
import { getTagGroupCustomFieldsEntries, } from "@shared/models/tag";
import { aggregateFormSchema } from "./utils";
import { FormShell } from "./FormShell";
export var RegionForm = React.memo(function (props) {
    var additionalFields = props.additionalFields, initialFormValues = props.initialFormValues, isSubmitting = props.isSubmitting, onSubmit = props.onSubmit, onCancel = props.onCancel;
    var intl = useIntl();
    var formikRef = useRef(null);
    var isEditing = useMemo(function () { return !!initialFormValues; }, [initialFormValues]);
    var initialValues = useMemo(function () {
        var _a, _b;
        return ({
            fullName: (_a = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.fullName) !== null && _a !== void 0 ? _a : "",
            tagName: (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.tagName) !== null && _b !== void 0 ? _b : "",
            additional: Object.keys(additionalFields).reduce(function (acc, curr) {
                var _a;
                var _b;
                return (__assign(__assign({}, acc), (_a = {}, _a[curr] = (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.additional[curr]) !== null && _b !== void 0 ? _b : "", _a)));
            }, {}),
        });
    }, [initialFormValues, additionalFields]);
    var aggregatedSchema = useMemo(function () { return aggregateFormSchema(regionFormSchema, additionalFields); }, [additionalFields]);
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: aggregatedSchema, validateOnChange: true, onSubmit: onSubmit, innerRef: formikRef }, function (_a) {
        var values = _a.values, errors = _a.errors, touched = _a.touched, handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur;
        return (React.createElement(FormShell, { isSubmitting: isSubmitting, onCancel: onCancel, onSubmit: handleSubmit, cancelLabel: intl.formatMessage({ id: "common.cancel" }), submitLabel: intl.formatMessage({
                id: isEditing
                    ? "settings.tags.form.edit.submit"
                    : "settings.tags.form.create.submit",
            }, {
                type: intl.formatMessage({
                    id: "settings.tags.title.region",
                }),
            }) },
            React.createElement(FormField, { titleId: "settings.tags.region.form.full_name", errorId: touched.fullName ? errors.fullName : undefined, isOptional: false },
                React.createElement(TextField, { name: "fullName", onChange: handleChange, onBlur: handleBlur, value: values.fullName, placeholder: intl.formatMessage({
                        id: "settings.tags.region.form.full_name.placeholder",
                    }), isError: touched.fullName && !!errors.fullName, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
            React.createElement(FormField, { titleId: "settings.tags.region.form.tag_name", errorId: touched.tagName ? errors.tagName : undefined, isOptional: false },
                React.createElement(TextField, { name: "tagName", onChange: handleChange, onBlur: handleBlur, value: values.tagName, placeholder: intl.formatMessage({
                        id: "settings.tags.region.form.tag_name.placeholder",
                    }), isError: touched.tagName && !!errors.tagName, className: cn("h-[2.375rem] w-full md:max-w-xs") })),
            getTagGroupCustomFieldsEntries(additionalFields).map(function (_a) {
                var _b, _c, _d, _e;
                var _f = __read(_a, 2), key = _f[0], _g = _f[1], name = _g.name, mandatory = _g.mandatory;
                return (React.createElement(FormField, { key: key, title: name, errorId: ((_b = touched.additional) === null || _b === void 0 ? void 0 : _b[key])
                        ? (_c = errors.additional) === null || _c === void 0 ? void 0 : _c[key]
                        : undefined, isOptional: !mandatory },
                    React.createElement(TextField, { name: "additional." + key, onChange: handleChange, onBlur: handleBlur, isError: ((_d = touched.additional) === null || _d === void 0 ? void 0 : _d[key]) && !!((_e = errors.additional) === null || _e === void 0 ? void 0 : _e[key]), value: values.additional[key], className: "py-2 px-3 border border-gray-300 shadow-sm h-[2.375rem] w-full" })));
            })));
    }));
});

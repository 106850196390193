import * as yup from "yup";
import { documentSchema } from "@shared/models/api/document_api";
import { generatePaginationSchema, } from "../pagination";
import { companySchema } from "./company_api";
import { contactSchema } from "./contact_api";
import { dealSchema } from "./deal_api";
import { prioritySchema } from "./priority_api";
import { otherTagSchema } from "./other_tag_api";
import { tagSchema } from "./tag_api";
import { userSchema } from "./user_api";
import { departmentSchema } from "./department_api";
export var setThreadTagsRequestSchema = yup
    .object({
    contact_ids: yup.array(yup.number().defined()).defined(),
    company_ids: yup.array(yup.number().defined()).defined(),
    deal_ids: yup.array(yup.number().defined()).defined(),
    priority_ids: yup.array(yup.number().defined()).defined(),
    other_tag_ids: yup.array(yup.number().defined()).defined(),
    tag_ids: yup.array(yup.number().defined()).defined(),
    share_with_ids: yup.array(yup.number().defined()).defined(),
    department_ids: yup.array(yup.number().defined()).defined(),
})
    .snakeCase()
    .defined();
export var setThreadTagsResponseSchema = yup
    .object({
    contacts: yup.array(contactSchema).defined(),
    companies: yup.array(companySchema).defined(),
    deals: yup.array(dealSchema).defined(),
    priorities: yup.array(prioritySchema).defined(),
    otherTags: yup.array(otherTagSchema).defined(),
    tags: yup.array(tagSchema).defined(),
    shareWiths: yup.array(userSchema).defined(),
    departments: yup.array(departmentSchema).defined(),
})
    .camelCase()
    .defined();
export var listMessageInThreadRequestSchema = yup
    .object()
    .shape({
    page: yup.number().defined(),
    perPage: yup.number(),
})
    .snakeCase()
    .defined();
export var listMessageInThreadResponseSchema = generatePaginationSchema(documentSchema);
export var remarkSchema = yup
    .object()
    .shape({
    id: yup.number().defined(),
    threadId: yup.number().defined().nullable(),
    documentId: yup.number().defined().nullable(),
    content: yup.string().defined(),
    createdBy: userSchema.defined(),
    createdAt: yup.date().defined(),
})
    .camelCase()
    .defined();
export var threadDetailSchema = yup
    .object()
    .shape({
    id: yup.number().defined(),
    subject: yup.string().defined(),
    isModifiable: yup.boolean().defined(),
    companies: yup.array(companySchema.defined()).defined(),
    contacts: yup.array(contactSchema.defined()).defined(),
    deals: yup.array(dealSchema.defined()).defined(),
    priorities: yup.array(prioritySchema.defined()).defined(),
    otherTags: yup.array(otherTagSchema.defined()).defined(),
    customTags: yup.array(tagSchema.defined()).defined(),
    sharedWiths: yup.array(userSchema.defined()).defined(),
    departments: yup.array(departmentSchema.defined()).defined(),
    remarks: yup.array(remarkSchema.defined()).defined(),
    isRead: yup.boolean().defined(),
})
    .camelCase()
    .defined();
export var createThreadRemarkRequestSchema = yup
    .object()
    .shape({
    remark: yup.string().defined(),
})
    .camelCase()
    .defined();

import { __assign, __awaiter, __generator } from "tslib";
import React, { useCallback } from "react";
import { DropDown } from "./DropDown";
import { FormattedMessage, useIntl } from "react-intl";
import { Dialog, useDialog } from "../common/Dialog";
import { ExclamationIcon } from "@heroicons/react/solid";
var StaffDetailDropDownMenu = React.memo(function (props) {
    var _a;
    var className = props.className, user = props.user, onToggleUserEnabled = props.onToggleUserEnabled;
    var intl = useIntl();
    var _b = useDialog(), toggleUserEnabledDialogProps = _b.dialogProps, openToggleUserEnabledDialog = _b.openDialog;
    var onClick = useCallback(function (ev) { return __awaiter(void 0, void 0, void 0, function () {
        var confirm;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ev.preventDefault();
                    ev.stopPropagation();
                    return [4 /*yield*/, openToggleUserEnabledDialog()];
                case 1:
                    confirm = _a.sent();
                    if (!confirm) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, onToggleUserEnabled()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [onToggleUserEnabled, openToggleUserEnabledDialog]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DropDown.Button, { className: className, labelId: "settings.staffDetail.actions.buttonTitle", hideLabelOnMobile: true },
            React.createElement(DropDown.Item, { onClick: onClick },
                React.createElement(FormattedMessage, { id: user.isEnabled
                        ? "settings.staffDetail.actions.disableStaff"
                        : "settings.staffDetail.actions.enableStaff" }))),
        React.createElement(Dialog, __assign({}, toggleUserEnabledDialogProps, { title: intl.formatMessage({
                id: user.isEnabled
                    ? "settings.staffEdit.disable.confirmation.title"
                    : "settings.staffEdit.enable.confirmation.title",
            }), description: intl.formatMessage({
                id: user.isEnabled
                    ? "settings.staffEdit.disable.confirmation.description"
                    : "settings.staffEdit.enable.confirmation.description",
            }, { name: (_a = user.fullName) !== null && _a !== void 0 ? _a : user.username }), confirmLabelId: user.isEnabled ? "common.disable" : "common.enable", cancelLabelId: "common.cancel", icon: React.createElement(ExclamationIcon, { className: "h-6 w-6 text-red-600", "aria-hidden": "true" }) }))));
});
export default StaffDetailDropDownMenu;

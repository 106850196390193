import { __awaiter, __generator } from "tslib";
import React, { useContext, useCallback } from "react";
import { FilterContext } from "@contexts/FilterContext";
import { CompanyAPIContext } from "@shared/contexts/api/CompanyAPIContext";
import { CompanyExtractors } from "@shared/models/company";
import { MultiSelectDropdownFilter } from "@components/filter/MultiSelectDropdownFilter";
import { useIntl } from "react-intl";
export var CompanyFilter = React.memo(function () {
    var _a = useContext(FilterContext), appliedFilters = _a.appliedFilters, setAppliedFilter = _a.setAppliedFilter;
    var companyAPI = useContext(CompanyAPIContext);
    var intl = useIntl();
    var fetchOptions = useCallback(function (page, perPage, options) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, companyAPI.listCompanies({
                    page: page,
                    perPage: perPage,
                    search: options.search,
                })];
        });
    }); }, [companyAPI]);
    var onSelect = useCallback(function (companies) {
        setAppliedFilter({ companies: companies });
    }, [setAppliedFilter]);
    var getSelectedMessage = useCallback(function (items) {
        return intl.formatMessage({ id: "filter.companies.selected" }, { count: items.length });
    }, [intl]);
    return (React.createElement(MultiSelectDropdownFilter, { selected: appliedFilters.companies, getOptions: fetchOptions, onSelect: onSelect, keyExtractor: CompanyExtractors.id, labelExtractor: CompanyExtractors.name, selectedMessage: getSelectedMessage, placeholder: intl.formatMessage({ id: "common.tags.company" }) }));
});

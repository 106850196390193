import * as yup from "yup";
import { allDepartmentRoleValues, } from "../department";
import { paginatedSearchRequest, generatePaginationSchema, } from "../pagination";
import { toggleableParamSchema } from "./params";
export var departmentSchema = yup
    .object()
    .shape({
    id: yup.number().defined(),
    name: yup.string().defined(),
    parentId: yup.number().defined().nullable(),
    isEnabled: yup.bool().defined(),
})
    .camelCase()
    .defined();
export var departmentRoleValueSchema = yup.string().oneOf(allDepartmentRoleValues).defined();
export var departmentRoleSchema = yup
    .object()
    .shape({
    departmentId: yup.number().defined(),
    role: departmentRoleValueSchema.defined(),
})
    .camelCase()
    .defined();
export var listDepartmentsRequestSchema = paginatedSearchRequest;
export var listDepartmentsResponseSchema = generatePaginationSchema(departmentSchema);
export var departmentTreeNodeSchema = yup
    .object()
    .shape({
    id: yup.number().defined(),
    name: yup.string().defined(),
    parentId: yup.number().defined().nullable(),
    isEnabled: yup.bool().defined(),
    userCount: yup.number().defined(),
    depth: yup.number().defined(),
    descendantCount: yup.number().defined(),
    descendantUserCount: yup.number().defined(),
    children: yup.lazy(function () {
        return yup.array().of(departmentTreeNodeSchema).defined();
    }),
})
    .camelCase()
    .defined();
export var getDepartmentTreeQuerySchema = yup
    .object()
    .concat(toggleableParamSchema)
    .snakeCase()
    .defined();
export var getDepartmentTreeResponseSchema = yup
    .object()
    .shape({
    trees: yup.array().of(departmentTreeNodeSchema).defined(),
})
    .camelCase()
    .defined();
export var createDepartmentRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    parent_id: yup.number().integer().defined(),
})
    .snakeCase()
    .defined();
export var createDepartmentResponseSchema = yup
    .object()
    .shape({
    department: departmentSchema.defined(),
})
    .camelCase()
    .defined();

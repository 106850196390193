import * as yup from "yup";
import { generatePaginationSchema, paginatedSearchRequest, } from "../pagination";
import { fileSchema } from "./file_api";
import { companySchema } from "./company_api";
import { toggleableParamSchema } from "./params";
export var contactSchema = yup
    .object()
    .shape({
    id: yup.number().defined(),
    name: yup.string().defined(),
    isEnabled: yup.boolean().defined(),
    emails: yup.array(yup.string().defined()).defined(),
    phoneNumber: yup.string().defined().nullable(),
    company: companySchema.defined().nullable(),
    avatar: fileSchema.defined().nullable(),
    additional: yup.object(),
})
    .camelCase()
    .defined();
export var contactEmailSchema = yup
    .object()
    .shape({
    email: yup.string().defined(),
    contact: contactSchema.defined(),
})
    .camelCase()
    .defined();
export var listContactRequestSchema = yup
    .object()
    .shape({
    company_id: yup.number(),
    not_company_id: yup.number(),
})
    .snakeCase()
    .defined()
    .concat(paginatedSearchRequest)
    .concat(toggleableParamSchema);
export var listContactResponseSchema = generatePaginationSchema(contactSchema);
export var createContactRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    emails: yup.array(yup.string().defined()).defined(),
    phone_number: yup.string().defined().nullable(),
    company_id: yup.number().defined().nullable(),
    avatar: yup.string().defined().nullable(),
    additional: yup.object().defined().nullable(),
})
    .snakeCase()
    .defined();
export var editContactRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    emails: yup.array(yup.string().defined()).defined(),
    phone_number: yup.string().defined().nullable(),
    company_id: yup.number().defined().nullable(),
    avatar: yup.string().defined().nullable(),
    additional: yup.object().defined().nullable(),
})
    .snakeCase()
    .defined();
export var assignCompanyRequestSchema = yup
    .object()
    .shape({
    contact_ids: yup.array(yup.number().defined()).defined(),
    company_id: yup.number().defined().nullable(),
})
    .snakeCase()
    .defined();
export var assignCompanyResponseSchema = yup
    .object()
    .shape({
    data: yup.array(contactSchema.defined()).defined(),
})
    .camelCase()
    .defined();
export var listContactEmailRequestSchema = yup
    .object()
    .shape({})
    .snakeCase()
    .defined()
    .concat(paginatedSearchRequest)
    .concat(toggleableParamSchema);
export var listContactEmailResponseSchema = generatePaginationSchema(contactEmailSchema);

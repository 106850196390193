import * as yup from "yup";
export var paginatedRequest = yup
    .object()
    .shape({
    page: yup.number(),
    perPage: yup.number(),
})
    .snakeCase()
    .defined();
export var paginatedSearchRequest = yup
    .object()
    .shape({
    search: yup.string(),
})
    .concat(paginatedRequest)
    .snakeCase()
    .defined();
export function generatePaginationSchema(schema) {
    return yup
        .object()
        .shape({
        data: yup.array().of(schema).required(),
        page: yup.number().required(),
        perPage: yup.number().required(),
        count: yup.number().required(),
    })
        .camelCase()
        .required();
}

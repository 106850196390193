export function countFilters(filters) {
    var filterLength = filters.from.length +
        filters.toOrCc.length +
        filters.uploaders.length +
        filters.contacts.length +
        filters.companies.length +
        filters.deals.length +
        filters.priorities.length +
        filters.otherTags.length +
        filters.customTags.length +
        filters.industries.length +
        filters.countries.length +
        filters.regions.length;
    return ((filters.dateRange.start === null ? 0 : 1) +
        (filters.dateRange.end === null ? 0 : 1) +
        filterLength);
}

import { __awaiter, __generator } from "tslib";
import React, { useContext, useCallback } from "react";
import { FilterContext } from "@contexts/FilterContext";
import { DealAPIContext } from "@shared/contexts/api/DealAPIContext";
import { DealExtractors } from "@shared/models/deal";
import { MultiSelectDropdownFilter } from "@components/filter/MultiSelectDropdownFilter";
import { useIntl } from "react-intl";
export var DealFilter = React.memo(function () {
    var _a = useContext(FilterContext), appliedFilters = _a.appliedFilters, setAppliedFilter = _a.setAppliedFilter;
    var dealAPI = useContext(DealAPIContext);
    var intl = useIntl();
    var fetchOptions = useCallback(function (page, perPage, options) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, dealAPI.listDeals({
                    page: page,
                    perPage: perPage,
                    search: options.search,
                })];
        });
    }); }, [dealAPI]);
    var onSelect = useCallback(function (deals) {
        setAppliedFilter({ deals: deals });
    }, [setAppliedFilter]);
    var getSelectedMessage = useCallback(function (items) {
        return intl.formatMessage({ id: "filter.deals.selected" }, { count: items.length });
    }, [intl]);
    return (React.createElement(MultiSelectDropdownFilter, { selected: appliedFilters.deals, getOptions: fetchOptions, onSelect: onSelect, keyExtractor: DealExtractors.id, labelExtractor: DealExtractors.name, selectedMessage: getSelectedMessage, placeholder: intl.formatMessage({ id: "common.tags.deal" }) }));
});

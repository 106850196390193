import { useEffect, useRef } from "react";
export function useEffectOnce(effect, condition) {
    var didRunOnce = useRef(false);
    useEffect(function () {
        if (didRunOnce.current) {
            return undefined;
        }
        if (condition()) {
            didRunOnce.current = true;
            return effect();
        }
        return undefined;
    });
}

import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { listCompanyRequestSchema, listCompanyResponseSchema, createCompanyRequestSchema, companySchema, getAllCompaniesResponseSchema, editCompanyRequestSchema, } from "@shared/models/api/company_api";
export var CompanyAPIContext = React.createContext(undefined);
export var CompanyAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getCompany = useCallback(function (companyId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("companies/" + companyId)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, companySchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var listCompanies = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listCompanyRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("companies", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listCompanyResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var createCompany = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = createCompanyRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("companies", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, companySchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updateCompany = useCallback(function (id, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = editCompanyRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.put("companies/" + id, body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, companySchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var getAllCompaniesByDomains = useCallback(function (domains) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("companies/all/domains", {
                        domains: domains.join(","),
                    })];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, getAllCompaniesResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var enableCompany = useCallback(function (companyId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("companies/" + companyId + "/enable", {})];
        });
    }); }, [apiContext]);
    var disableCompany = useCallback(function (companyId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("companies/" + companyId + "/disable", {})];
        });
    }); }, [apiContext]);
    var deleteCompany = useCallback(function (companyId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.delete("companies/" + companyId)];
        });
    }); }, [apiContext]);
    var mergeCompany = useCallback(function (companyId, sourceId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("companies/" + companyId + "/merge", {
                    source_id: sourceId,
                })];
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            listCompanies: listCompanies,
            createCompany: createCompany,
            getCompany: getCompany,
            updateCompany: updateCompany,
            enableCompany: enableCompany,
            disableCompany: disableCompany,
            deleteCompany: deleteCompany,
            mergeCompany: mergeCompany,
            getAllCompaniesByDomains: getAllCompaniesByDomains,
        };
    }, [
        listCompanies,
        createCompany,
        getCompany,
        updateCompany,
        enableCompany,
        disableCompany,
        deleteCompany,
        mergeCompany,
        getAllCompaniesByDomains,
    ]);
    return (React.createElement(CompanyAPIContext.Provider, { value: value }, children));
});

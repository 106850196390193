import { __read } from "tslib";
export var TagGroupSlug;
(function (TagGroupSlug) {
    TagGroupSlug["Company"] = "company";
    TagGroupSlug["Contact"] = "contact";
    TagGroupSlug["Industry"] = "industry";
    TagGroupSlug["Deal"] = "deal";
    TagGroupSlug["OtherTag"] = "other_tag";
    TagGroupSlug["Priority"] = "priority";
    TagGroupSlug["Region"] = "region";
})(TagGroupSlug || (TagGroupSlug = {}));
export var getTagGroupCustomFieldsEntries = function (fields) {
    return Object.entries(fields).sort(function (_a, _b) {
        var _c, _d, _e, _f;
        var _g = __read(_a, 2), aKey = _g[0], a = _g[1];
        var _h = __read(_b, 2), bKey = _h[0], b = _h[1];
        var aCreatedAt = (_d = (_c = a.createdAt) === null || _c === void 0 ? void 0 : _c.getTime()) !== null && _d !== void 0 ? _d : 0;
        var bCreatedAt = (_f = (_e = b.createdAt) === null || _e === void 0 ? void 0 : _e.getTime()) !== null && _f !== void 0 ? _f : 0;
        var createdAtCompareResult = aCreatedAt - bCreatedAt;
        if (createdAtCompareResult === 0) {
            return aKey.localeCompare(bKey);
        }
        return createdAtCompareResult;
    });
};
export var TagExtractors = {
    id: function (u) { return u.id; },
    name: function (u) { return u.name; },
    fullName: function (u) { var _a; return (_a = u.fullName) !== null && _a !== void 0 ? _a : u.name; },
    color: function (u) { return u.color; },
};

import { __assign, __awaiter, __generator, __read } from "tslib";
import { ROUTES } from "@router/routes";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { ContactExtractors } from "@shared/models/contact";
import { DefaultTagGroupDetail } from "../settings/DefaultTagGroupDetail";
import { ContactAPIContext } from "@shared/contexts/api/ContactAPIContext";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { Persona, PersonaSize } from "@fluentui/react";
import { extractInitials } from "@utils/avatar";
import { TagContextMenu } from "../settings/TagContextMenu";
import { useNavigation } from "@hooks/useNavigation";
import { useAppToast } from "../common/Toast";
import cn from "classnames";
import { MergeTagDialog, useMergeTagDialog } from "../common/MergeTagDialog";
import { TagGroupSlug } from "@shared/models/tag";
export var ContactTagGroupDetailScreen = React.memo(function () {
    var navigation = useNavigation();
    var contactAPI = useContext(ContactAPIContext);
    var _a = useAppToast(), showErrorToast = _a.showErrorToast, showSuccessToast = _a.showSuccessToast;
    var _b = __read(useState({}), 2), enableStates = _b[0], setEnableStates = _b[1];
    var onGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.root);
        }
    }, [navigation]);
    var onFetchListItemError = useCallback(function () {
        showErrorToast({
            titleId: "common.errors.data.fetch_failed.title",
            descriptionId: "common.errors.data.fetch_failed.description",
        }, {
            position: "bottom-center",
        });
        onGoBack();
    }, [onGoBack, showErrorToast]);
    var _c = useSearchPagination(contactAPI.listContacts, {
        page: 1,
        perPage: 100,
        isEnabled: "any",
    }, onFetchListItemError), data = _c.data, totalCount = _c.totalCount, isInitializing = _c.isInitializing, isFetchingMore = _c.isFetchingMore, hasMore = _c.hasMore, nextPage = _c.nextPage, search = _c.search, refresh = _c.refresh;
    var onAddNewContact = useCallback(function () {
        navigation.push(ROUTES.settings.tags.contact.add);
    }, [navigation]);
    var onEditGroup = useCallback(function () {
        navigation.push(ROUTES.settings.tags.contact.edit_group);
    }, [navigation]);
    var onEditTag = useCallback(function (contact) {
        navigation.push(ROUTES.settings.tags.contact.group_detail + "/" + contact.id + "/edit");
    }, [navigation]);
    var onEnableTag = useCallback(function (contact) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setEnableStates(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[contact.id] = true, _a)));
            });
            contactAPI
                .enableContact(contact.id)
                .then(function () {
                showSuccessToast({
                    titleId: "settings.tags.enable_tag.success.title",
                }, {
                    position: "bottom-center",
                });
            })
                .catch(function (err) {
                console.error("Failed to enable tag", err);
                showErrorToast({
                    titleId: "settings.tags.enable_tag.error.title",
                    descriptionId: "settings.tags.enable_tag.error.description",
                }, {
                    position: "bottom-center",
                });
                setEnableStates(function (prevState) {
                    var _a;
                    return (__assign(__assign({}, prevState), (_a = {}, _a[contact.id] = contact.isEnabled, _a)));
                });
            });
            return [2 /*return*/];
        });
    }); }, [contactAPI, showErrorToast, showSuccessToast]);
    var onDisableTag = useCallback(function (contact) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setEnableStates(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[contact.id] = false, _a)));
            });
            contactAPI
                .disableContact(contact.id)
                .then(function () {
                showSuccessToast({
                    titleId: "settings.tags.disable_tag.success.title",
                }, {
                    position: "bottom-center",
                });
            })
                .catch(function (err) {
                console.error("Failed to disable tag", err);
                showErrorToast({
                    titleId: "settings.tags.disable_tag.error.title",
                    descriptionId: "settings.tags.disable_tag.error.description",
                }, {
                    position: "bottom-center",
                });
                setEnableStates(function (prevState) {
                    var _a;
                    return (__assign(__assign({}, prevState), (_a = {}, _a[contact.id] = contact.isEnabled, _a)));
                });
            });
            return [2 /*return*/];
        });
    }); }, [contactAPI, showErrorToast, showSuccessToast]);
    var onDeleteTag = useCallback(function (contact) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, contactAPI.deleteContact(contact.id).then(function () {
                    refresh();
                })];
        });
    }); }, [contactAPI, refresh]);
    var _d = useMergeTagDialog(), mergeDialogProps = _d.mergeDialogProps, openMergeDialog = _d.openMergeDialog, closeMergeDialog = _d.closeMergeDialog;
    var _e = __read(useState(false), 2), isMerging = _e[0], setIsMerging = _e[1];
    var _f = __read(useState(null), 2), mergeItem = _f[0], setMergeItem = _f[1];
    var onMergeTag = useCallback(function (item) { return __awaiter(void 0, void 0, void 0, function () {
        var source, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setMergeItem(item);
                    return [4 /*yield*/, openMergeDialog()];
                case 1:
                    source = _a.sent();
                    if (!source) {
                        return [2 /*return*/];
                    }
                    setIsMerging(true);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, contactAPI.mergeContact(item.id, source.id)];
                case 3:
                    _a.sent();
                    closeMergeDialog();
                    refresh();
                    return [3 /*break*/, 6];
                case 4:
                    err_1 = _a.sent();
                    console.error("Failed to merge tag", err_1);
                    showErrorToast({
                        titleId: "merge_tag.error.title",
                        descriptionId: "merge_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 6];
                case 5:
                    setIsMerging(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [openMergeDialog, contactAPI, closeMergeDialog, showErrorToast, refresh]);
    var onGoToTagDetail = useCallback(function (contact) {
        navigation.push(ROUTES.settings.tags.contact.group_detail + "/" + contact.id, {
            tagName: contact.name,
        });
    }, [navigation]);
    var renderContextMenu = useCallback(function (c) {
        var _a;
        return (React.createElement(TagContextMenu, { typeId: "settings.tags.title.contact", item: c, itemEnableState: (_a = enableStates[c.id]) !== null && _a !== void 0 ? _a : c.isEnabled, onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onMergeTag }));
    }, [
        enableStates,
        onEditTag,
        onEnableTag,
        onDisableTag,
        onDeleteTag,
        onMergeTag,
    ]);
    var renderPersonaText = useCallback(function (props) {
        return (React.createElement("span", { className: "px-1 text-sm leading-5 font-bold text-gray-800 line-clamp-1 w-max max-w-full" }, props === null || props === void 0 ? void 0 : props.text));
    }, []);
    var tableColumns = useMemo(function () { return [
        {
            titleId: "settings.tags.contact.detail.table.contact_tag",
            accessor: function (c) { return c.name; },
            render: function (_, c) {
                var _a, _b, _c;
                return (React.createElement(Persona, { size: PersonaSize.size24, text: c.name || c.emails[0], imageInitials: extractInitials(c.name || c.emails[0], 2), imageUrl: (_b = (_a = c.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined, onRenderPrimaryText: renderPersonaText, className: cn("bg-gray-100 rounded-full w-max max-w-full", !((_c = enableStates[c.id]) !== null && _c !== void 0 ? _c : c.isEnabled) && "opacity-50") }));
            },
        },
        {
            titleId: "settings.tags.contact.detail.table.full_name",
            accessor: function (c) { return c.name; },
        },
        {
            titleId: "settings.tags.contact.detail.table.company",
            accessor: function (c) { var _a, _b; return (_b = (_a = c.company) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : null; },
        },
    ]; }, [enableStates, renderPersonaText]);
    var tableActions = useMemo(function () { return [
        {
            className: "flex items-center justify-end",
            render: renderContextMenu,
        },
    ]; }, [renderContextMenu]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DefaultTagGroupDetail, { headerTitleId: "settings.tags.title.contact", headerTagCountId: "settings.tags.tag_counts.contact", searchPlaceholderId: "settings.tags.contact.detail.search_placeholder", addNewTagId: "settings.tags.contact.detail.add_new_contact", tableData: data, isInitializing: isInitializing, isFetchingMore: isFetchingMore, tableColumns: tableColumns, tableActions: tableActions, tagCount: totalCount, hasMoreData: hasMore, onTableItemClick: onGoToTagDetail, onFetchMore: nextPage, onGoBack: onGoBack, onAddNewClick: onAddNewContact, onEditGroup: onEditGroup, onSearch: search, tagGroupSlug: TagGroupSlug.Contact }),
        mergeItem && (React.createElement(MergeTagDialog, __assign({}, mergeDialogProps, { destinationId: mergeItem.id, tagName: mergeItem.name, isMerging: isMerging, fetch: contactAPI.listContacts, keyExtractor: ContactExtractors.id, labelExtractor: ContactExtractors.name })))));
});

import { __assign, __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import cloverLogoWhite from "@assets/ic-clover-logo-white.svg";
import { MenuAlt1Icon, LogoutIcon } from "@heroicons/react/outline";
import { MainSearchBar } from "@components/search/MainSearchBar";
import { AuthContext } from "@contexts/AuthContext";
import { Persona, PersonaSize } from "@fluentui/react";
import { extractInitials } from "@utils/avatar";
import { ROUTES } from "@router/routes";
import { DropDown } from "@components/common/DropDown";
import { FormattedMessage, useIntl } from "react-intl";
import { AppContext } from "@contexts/AppContext";
import { Dialog, useDialog } from "../common/Dialog";
export var Header = React.memo(function (props) {
    var _a, _b, _c;
    var setSideBarActive = props.setSideBarActive;
    var intl = useIntl();
    var authContext = useContext(AuthContext);
    var user = useMemo(function () {
        var _a;
        return (_a = authContext.me) === null || _a === void 0 ? void 0 : _a.user;
    }, [authContext]);
    var _d = useDialog(), logoutDialogProps = _d.dialogProps, openLogoutDialog = _d.openDialog;
    var app = useContext(AppContext);
    var onLogout = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var confirm;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openLogoutDialog()];
                case 1:
                    confirm = _a.sent();
                    if (!confirm) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, app.logout()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [app, openLogoutDialog]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn("z-10", "flex-shrink-0", "flex", "h-16", "bg-indigo-800", "border-b", "border-gray-200", "md:hidden") },
            React.createElement("button", { type: "button", className: "px-4 border-gray-200 text-gray-100 bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 md:hidden", onClick: function () { return setSideBarActive(true); } },
                React.createElement(MenuAlt1Icon, { className: "h-6 w-6", "aria-hidden": "true" })),
            React.createElement("div", { className: "flex-1 flex justify-between bg-indigo-800 md:px-6 lg:px-8" },
                React.createElement("div", { className: "flex-1 flex" },
                    React.createElement(MainSearchBar, { className: "flex-1" })))),
        React.createElement("div", { className: cn("py-2", "px-4", "bg-indigo-900", "hidden", "md:flex", "flex-row", "justify-between", "items-center") },
            React.createElement(Link, { to: ROUTES.root, className: "flex-none flex items-center" },
                React.createElement("img", { className: "object-contain", src: cloverLogoWhite }),
                window.CONFIGS.IS_JP && (React.createElement("div", { className: "h-5 ml-1 text-lg leading-7 font-normal text-white" },
                    React.createElement(FormattedMessage, { id: "header.japan" })))),
            React.createElement("div", { className: "flex-1 overflow-hidden max-w-2xl mx-2" },
                React.createElement(MainSearchBar, null)),
            React.createElement(DropDown.Button, { yOffset: 12, buttonNode: React.createElement(Persona, { imageUrl: (_b = (_a = user === null || user === void 0 ? void 0 : user.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined, imageInitials: extractInitials((_c = user === null || user === void 0 ? void 0 : user.fullName) !== null && _c !== void 0 ? _c : "", 2), hidePersonaDetails: true, size: PersonaSize.size24 }) },
                React.createElement(DropDown.Item, { onClick: onLogout },
                    React.createElement(FormattedMessage, { id: "logout.title" }))),
            React.createElement(Dialog, __assign({}, logoutDialogProps, { title: intl.formatMessage({
                    id: "logout.confirmation.title",
                }), description: intl.formatMessage({
                    id: "logout.confirmation.description",
                }), confirmLabelId: "common.confirm", cancelLabelId: "common.cancel", icon: React.createElement(LogoutIcon, { className: "h-6 w-6 text-red-600", "aria-hidden": "true" }) })))));
});

import React, { useContext, useCallback, useMemo } from "react";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import { MainRouteContext } from "@contexts/MainRouteContext";
var RouteLink = React.memo(function (props) {
    var _a, _b, _c;
    var item = props.item, parent = props.parent, setSideBarActive = props.setSideBarActive, remountMainRouteScreen = props.remountMainRouteScreen;
    var routeMatch = useRouteMatch({ path: item.path, exact: false });
    var isActive = useMemo(function () {
        return routeMatch != null;
    }, [routeMatch]);
    var Icon = item.icon;
    var onLinkClick = useCallback(function () {
        setSideBarActive(false);
        if (item.alwaysRemountOnClick) {
            remountMainRouteScreen();
        }
    }, [item, remountMainRouteScreen, setSideBarActive]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Link, { to: (_a = item.path) !== null && _a !== void 0 ? _a : "", className: cn(isActive
                ? "bg-gray-100 text-gray-900"
                : "text-gray-600 md:text-gray hover:bg-gray-50 hover:text-gray-900", ((_b = item.children) === null || _b === void 0 ? void 0 : _b.length) || !parent
                ? "font-medium text-base leading-6 py-2 md:text-sm md:font-bold md:leading-5 md:py-2.5"
                : "font-bold text-sm leading-6 py-2 md:font-normal md:text-xs md:leading-none md:py-3.5", !item.path && "pointer-events-none cursor-default", "group flex items-center rounded-md"), onClick: onLinkClick },
            Icon ? (React.createElement(Icon, { className: cn(isActive
                    ? "text-gray-500"
                    : "text-gray-400 group-hover:text-gray-500", "mr-3 flex-shrink-0 h-6 w-6") })) : (React.createElement("div", { className: "mr-9" })),
            React.createElement("span", { className: "flex-1" },
                React.createElement(FormattedMessage, { id: item.titleId })),
            item.count ? (React.createElement("span", { className: cn("bg-orange-600", "mr-3 py-[0.125rem] px-2 text-xs leading-4 font-medium rounded-full text-white md:px-3") }, item.count)) : (React.createElement("span", { className: "h-4" }))), (_c = item.children) === null || _c === void 0 ? void 0 :
        _c.map(function (child, index) { return (React.createElement(RouteLink, { item: child, parent: item, key: index, setSideBarActive: setSideBarActive, remountMainRouteScreen: remountMainRouteScreen })); })));
});
var NavigationSideBarImpl = function (props) {
    var sideBarItems = useContext(MainRouteContext).sideBarItems;
    var className = props.className, setSideBarActive = props.setSideBarActive, remountMainRouteScreen = props.remountMainRouteScreen;
    return (React.createElement("div", { className: cn("flex-1", "flex", "flex-col", "flex-grow", "border-r", "border-gray-200", "bg-white", "overflow-y-auto", className) },
        React.createElement("nav", { className: cn("flex-1", "px-2", "bg-white", "space-y-1"), "aria-label": "Sidebar" }, sideBarItems.map(function (item, index) { return (React.createElement(RouteLink, { key: index, item: item, setSideBarActive: setSideBarActive, remountMainRouteScreen: remountMainRouteScreen })); }))));
};
var NavigationSideBar = React.memo(NavigationSideBarImpl);
export { NavigationSideBar };

import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { useDebounce } from "@hooks/useDebounce";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { ROUTES } from "@router/routes";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { PrimaryButton } from "../common/PrimaryButton";
import { SearchBar } from "../common/SearchBar";
import { SkeletonTable, Table, } from "../common/Table";
import { useAppToast } from "../common/Toast";
import { TagDetailHeader } from "../settings/TagDetailHeader";
import { TagDetailInfo } from "../settings/TagDetailInfo";
import { useNavigation } from "@hooks/useNavigation";
import { CheckIcon, ExclamationIcon, XIcon } from "@heroicons/react/solid";
import { Dialog, useDialog } from "../common/Dialog";
import { RegionAPIContext } from "@shared/contexts/api/RegionAPIContext";
import { CountryAPIContext } from "@shared/contexts/api/CountryAPIContext";
import { CountryExtractor } from "@shared/models/country";
import { SelectMenu } from "../form/SelectMenu";
import { getTagGroupCustomFieldsEntries, } from "@shared/models/tag";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { MergeRegionDialog } from "@components/mergeTag/MergeRegionDialog";
import useHasPermission from "@hooks/useHasPermission";
import { Permission } from "@shared/models/auth";
import RequirePermission from "../common/RequirePermission";
var CountryDeleteButton = React.memo(function (props) {
    var country = props.country, onDelete_ = props.onDelete;
    var onDelete = useCallback(function () {
        onDelete_(country);
    }, [country, onDelete_]);
    return (React.createElement("button", { type: "button", onClick: onDelete, className: "text-sm leading-5 font-normal text-indigo-700 hover:text-indigo-500" },
        React.createElement(FormattedMessage, { id: "settings.tags.region.tag.detail.country.remove" })));
});
var CountrySelector = React.memo(function (props) {
    var countries = props.countries, isSubmitting = props.isSubmitting, onConfirm_ = props.onConfirm, onCancel = props.onCancel;
    var _a = __read(useState(countries), 2), countryOptions = _a[0], setCountryOptions = _a[1];
    var _b = __read(useState([]), 2), selectedCountries = _b[0], setSelectedCountries = _b[1];
    var onConfirm = useCallback(function () {
        onConfirm_(selectedCountries);
    }, [selectedCountries, onConfirm_]);
    var onCountrySearch = useCallback(function (term) {
        if (term) {
            setCountryOptions(countries.filter(function (i) {
                return CountryExtractor.name(i).toLowerCase().includes(term.toLowerCase());
            }));
        }
        else {
            setCountryOptions(countries);
        }
    }, [countries]);
    var isSubmissionDisabled = useMemo(function () {
        return selectedCountries.length === 0 || isSubmitting;
    }, [isSubmitting, selectedCountries.length]);
    return (React.createElement("div", { className: "flex flex-col px-3 py-2" },
        React.createElement("div", { className: "flex flex-row items-center space-x-3" },
            React.createElement(SelectMenu, { className: "flex-1", selected: selectedCountries, onSelect: setSelectedCountries, multiple: true, items: countryOptions, keyExtractor: CountryExtractor.id, labelExtractor: CountryExtractor.name, onSearch: onCountrySearch }),
            React.createElement("button", { type: "button", className: "h-auto inline-flex items-center rounded-full text-indigo-700 disabled:cursor-not-allowed disabled:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500", disabled: isSubmissionDisabled, onClick: onConfirm },
                React.createElement(CheckIcon, { className: "h-6 w-6", "aria-hidden": "true" })),
            React.createElement("button", { type: "button", className: "h-auto inline-flex items-center rounded-full text-gray-600  disabled:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500", disabled: isSubmitting, onClick: onCancel },
                React.createElement(XIcon, { className: "h-6 w-6", "aria-hidden": "true" }))),
        React.createElement("span", { className: "mt-2 text-sm leading-5 font-normal text-gray-500" },
            React.createElement(FormattedMessage, { id: "settings.tags.region.tag.detail.country.hint" }))));
});
// eslint-disable-next-line complexity
export var RegionTagDetailScreen = React.memo(function () {
    var _a, _b, _c, _d;
    var tagId = useParams().tagId;
    var regionId = useMemo(function () { return parseInt(tagId, 10); }, [tagId]);
    var navigation = useNavigation();
    var location = useLocation();
    var showErrorToast = useAppToast().showErrorToast;
    var regionAPI = useContext(RegionAPIContext);
    var countryAPI = useContext(CountryAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _e = useDialog(), dialogProps = _e.dialogProps, openDeleteCountryDialog = _e.openDialog;
    var _f = __read(useState(null), 2), region = _f[0], setRegion = _f[1];
    var _g = __read(useState([]), 2), countries = _g[0], setCountries = _g[1];
    var _h = __read(useState(true), 2), isInitializing = _h[0], setIsInitializing = _h[1];
    var _j = __read(useState(null), 2), countrySearchValue = _j[0], setCountrySearchValue = _j[1];
    var _k = __read(useState(false), 2), showCountryEditor = _k[0], setShowCountryEditor = _k[1];
    var _l = __read(useState(false), 2), isEditingCountry = _l[0], setIsEditingCountry = _l[1];
    var debouncedCountrySearchValue = useDebounce(countrySearchValue, 500);
    var _m = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _m[0], setTagName = _m[1];
    var _o = __read(useState([]), 2), availableCustomProperties = _o[0], setAvailableCustomProperties = _o[1];
    var intl = useIntl();
    var goGroupDetail = useCallback(function () {
        navigation.replace(ROUTES.settings.tags.region.group_detail);
    }, [navigation]);
    var _p = useSearchPagination(countryAPI.listCountries, {
        page: 1,
        perPage: 100,
        region_id: regionId,
    }), data = _p.data, initialTotalCount = _p.initialTotalCount, totalCount = _p.totalCount, isTableInitializing = _p.isInitializing, isFetchingMore = _p.isFetchingMore, hasMore = _p.hasMore, nextPage = _p.nextPage, refreshContries = _p.refresh, search = _p.search;
    var fetchData = useCallback(function () {
        Promise.all([
            regionAPI.getRegion(regionId),
            countryAPI.getAllCountries(),
            tagGroupAPI.getTagGroupBySlug("region"),
        ])
            .then(function (_a) {
            var _b = __read(_a, 3), region = _b[0], countries = _b[1], tagGroup = _b[2];
            setRegion(region);
            setTagName(region.name);
            setCountries(countries.data.filter(function (c) { return c.regionId === null || c.regionId !== regionId; }));
            setAvailableCustomProperties(getTagGroupCustomFieldsEntries(tagGroup.definition).map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return ({
                    name: value.name,
                    value: region.additional[key],
                });
            }));
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            goGroupDetail();
        })
            .finally(function () {
            setIsInitializing(false);
        });
    }, [
        regionAPI,
        regionId,
        countryAPI,
        tagGroupAPI,
        showErrorToast,
        goGroupDetail,
    ]);
    var refreshScreen = useCallback(function () {
        refreshContries();
        fetchData();
    }, [fetchData, refreshContries]);
    var onEditTag = useCallback(function () {
        navigation.push(ROUTES.settings.tags.region.group_detail + "/" + regionId + "/edit");
    }, [navigation, regionId]);
    var onEnableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, regionAPI.enableRegion(regionId)];
        });
    }); }, [regionId, regionAPI]);
    var onDisableTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, regionAPI.disableRegion(regionId)];
        });
    }); }, [regionId, regionAPI]);
    var onDeleteTag = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, regionAPI.deleteRegion(regionId).then(function () {
                    goGroupDetail();
                })];
        });
    }); }, [regionAPI, regionId, goGroupDetail]);
    var _q = __read(useState(null), 2), mergeItem = _q[0], setMergeItem = _q[1];
    var onCloseMergeDialog = useCallback(function () { return setMergeItem(null); }, []);
    var onStartMerge = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, setMergeItem(region)];
    }); }); }, [region]);
    var onCountrySearchValueChange = useCallback(function (e) {
        setCountrySearchValue(e.currentTarget.value);
    }, [setCountrySearchValue]);
    var onAddNewCountry = useCallback(function () {
        setShowCountryEditor(true);
    }, [setShowCountryEditor]);
    var onCancelAddNewCountry = useCallback(function () {
        setShowCountryEditor(false);
    }, [setShowCountryEditor]);
    var onConfirmAddNewCountry = useCallback(function (countries) { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsEditingCountry(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, countryAPI.assignRegion({
                            regionId: regionId,
                            countryIds: countries.map(function (c) { return c.id; }),
                        })];
                case 2:
                    _a.sent();
                    refreshScreen();
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _a.sent();
                    console.error(err_1);
                    showErrorToast({
                        titleId: "settings.tags.region.tag.detail.add_country.submission.failure.title",
                        descriptionId: "settings.tags.region.tag.detail.add_country.submission.failure.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 5];
                case 4:
                    setIsEditingCountry(false);
                    setShowCountryEditor(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [countryAPI, regionId, refreshScreen, showErrorToast]);
    var onDeleteCountry = useCallback(function (c) { return __awaiter(void 0, void 0, void 0, function () {
        var confirm, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openDeleteCountryDialog({
                        title: intl.formatMessage({
                            id: "settings.tags.region.tag.detail.remove_country.confirmation.title",
                        }, {
                            name: c.name,
                        }),
                        description: intl.formatMessage({
                            id: "settings.tags.region.tag.detail.remove_country.confirmation.description",
                        }, {
                            name: c.name,
                        }),
                    })];
                case 1:
                    confirm = _a.sent();
                    if (!confirm) {
                        return [2 /*return*/];
                    }
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, countryAPI.assignRegion({
                            countryIds: [c.id],
                            regionId: null,
                        })];
                case 3:
                    _a.sent();
                    refreshScreen();
                    return [3 /*break*/, 5];
                case 4:
                    err_2 = _a.sent();
                    console.error(err_2);
                    showErrorToast({
                        titleId: "settings.tags.region.tag.detail.remove_country.submission.failure.title",
                        descriptionId: "settings.tags.region.tag.detail.remove_country.submission.failure.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [openDeleteCountryDialog, intl, countryAPI, refreshScreen, showErrorToast]);
    var countryTableColumns = useMemo(function () { return [
        {
            titleId: "settings.tags.region.detail.table.country",
            accessor: function (c) { return c.name; },
        },
    ]; }, []);
    var countryTableActions = useMemo(function () { return [
        {
            className: "flex items-center justify-end",
            render: function (c) { return (React.createElement(CountryDeleteButton, { country: c, onDelete: onDeleteCountry })); },
        },
    ]; }, [onDeleteCountry]);
    useEffect(function () {
        if (debouncedCountrySearchValue != null) {
            search(debouncedCountrySearchValue, {
                region_id: regionId,
            });
        }
    }, [debouncedCountrySearchValue, regionId, search]);
    useEffect(function () {
        fetchData();
    }, [fetchData]);
    var hasPermission = useHasPermission();
    return (React.createElement("div", { className: "flex flex-col min-w-0 flex-1" },
        React.createElement(Dialog, __assign({}, dialogProps, { confirmLabelId: "common.delete", cancelLabelId: "common.cancel", icon: React.createElement(ExclamationIcon, { className: "h-6 w-6 text-red-600", "aria-hidden": "true" }) })),
        mergeItem && (React.createElement(MergeRegionDialog, { destination: mergeItem, onClose: onCloseMergeDialog, onDone: refreshScreen })),
        React.createElement(TagDetailHeader, { typeId: "settings.tags.title.region", tagName: tagName, isTagEnabled: (_c = region === null || region === void 0 ? void 0 : region.isEnabled) !== null && _c !== void 0 ? _c : true, description: intl.formatMessage({
                id: "settings.tags.tag_counts.country",
            }, {
                count: initialTotalCount,
            }), onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onStartMerge, onGoBack: goGroupDetail }),
        React.createElement("div", { className: "flex-1 mt-2 overflow-y-auto md:mt-0 md:p-4" },
            React.createElement(TagDetailInfo.Panel, { isLoading: isInitializing },
                React.createElement(TagDetailInfo.Item, { title: React.createElement(FormattedMessage, { id: "settings.tags.region.form.full_name" }), value: (_d = region === null || region === void 0 ? void 0 : region.fullName) !== null && _d !== void 0 ? _d : "" }),
                availableCustomProperties.map(function (value, index) { return (React.createElement(TagDetailInfo.Item, { key: index, title: value.name, value: value.value })); })),
            React.createElement("div", { className: "flex flex-col py-[1.125rem] px-4 md:items-center md:flex-row" },
                React.createElement("div", { className: "flex-1 flex-col" },
                    React.createElement("h1", { className: "text-base leading-6 font-bold text-gray-900" },
                        React.createElement(FormattedMessage, { id: "settings.tags.region.tag.detail.country.title" })),
                    React.createElement("span", { className: "text-sm leading-5 font-normal text-gray-600" },
                        React.createElement(FormattedMessage, { id: "settings.tags.tag_counts.country", values: { count: totalCount } }))),
                React.createElement("div", { className: " flex flex-col space-y-2 items-start justify-center mt-[0.375rem] md:mt-0 md:space-y-0 md:flex-row md:space-x-2 md:items-center" },
                    React.createElement(SearchBar, { containerClassName: "flex-1 min-w-[20rem] bg-white rounded-md border border-gray-300", searchIconClassName: "text-gray-400", inputClassName: "text-gray-500 placeholder-gray-500 md:text-sm", placeholder: intl.formatMessage({
                            id: "settings.tags.region.tag.detail.country.search_placeholder",
                        }), value: countrySearchValue !== null && countrySearchValue !== void 0 ? countrySearchValue : "", onChange: onCountrySearchValueChange }),
                    React.createElement(RequirePermission, { permission: Permission.TagsUpdate },
                        React.createElement(PrimaryButton, { size: "sm", onClick: onAddNewCountry },
                            React.createElement(FormattedMessage, { id: "settings.tags.region.tag.detail.country.add_country" }))))),
            React.createElement("div", { className: "h-full max-h-[46.6875rem] flex flex-col overflow-x-hidden" }, isInitializing || isTableInitializing ? (React.createElement(SkeletonTable, { labelClassName: "text-xs leading-4 font-medium text-gray-500", cellClassName: "text-sm leading-5 font-normal text-gray-500", columns: countryTableColumns })) : (React.createElement(Table, { data: data, labelClassName: "text-xs leading-4 font-medium text-gray-500", cellClassName: "text-sm leading-5 font-normal text-gray-500", columns: countryTableColumns, actions: 
                // Disassociate country from region, check update permission
                hasPermission(Permission.TagsUpdate)
                    ? countryTableActions
                    : undefined, onFetchMore: nextPage, hasMore: hasMore, isFetchingMore: isFetchingMore, prependedElement: showCountryEditor ? (React.createElement(CountrySelector, { countries: countries, isSubmitting: isEditingCountry, onCancel: onCancelAddNewCountry, onConfirm: onConfirmAddNewCountry })) : undefined }))))));
});

import React, { useCallback, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { ScopeButton } from "./ScopeButton";
import { MainSearchBarContext } from "./MainSearchBar";
import searchScopeFromIcon from "@assets/search-scope-from.svg";
import searchScopeToIcon from "@assets/search-scope-to.svg";
import searchScopeSubjectIcon from "@assets/search-scope-subject.svg";
import searchScopeBodyIcon from "@assets/search-scope-body.svg";
import searchScopeTagsIcon from "@assets/search-scope-tags.svg";
import searchScopeFileIcon from "@assets/search-scope-file.svg";
import searchScopeRemarkIcon from "@assets/search-scope-remarks.svg";
export var MainSearchBarSuggestionsEmpty = React.memo(function () {
    var onAddScope = useContext(MainSearchBarContext).onAddScope;
    var onClickFrom = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onAddScope("from");
    }, [onAddScope]);
    var onClickToCc = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onAddScope("to,cc");
    }, [onAddScope]);
    var onClickSubject = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onAddScope("subject");
    }, [onAddScope]);
    var onClickBody = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onAddScope("body");
    }, [onAddScope]);
    var onClickTags = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onAddScope("tags");
    }, [onAddScope]);
    var onClickFile = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onAddScope("file");
    }, [onAddScope]);
    var onClickRemarks = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onAddScope("remarks");
    }, [onAddScope]);
    return (React.createElement("div", { className: "p-4 shadow-xl rounded-[0.625rem] bg-white" },
        React.createElement("p", { className: "text-xs leading-4 font-normal text-gray-600" },
            React.createElement(FormattedMessage, { id: "main_search_bar.suggestions.placeholder" })),
        React.createElement("div", { className: "flex flex-row flex-wrap" },
            React.createElement(ScopeButton, { className: "mr-2 mt-2", icon: searchScopeFromIcon, textId: "main_search_bar.suggestions.placeholder.from", onMouseDown: onClickFrom }),
            React.createElement(ScopeButton, { className: "mr-2 mt-2", icon: searchScopeToIcon, textId: "main_search_bar.suggestions.placeholder.to_cc", onMouseDown: onClickToCc }),
            React.createElement(ScopeButton, { className: "mr-2 mt-2", icon: searchScopeSubjectIcon, textId: "main_search_bar.suggestions.placeholder.subject", onMouseDown: onClickSubject }),
            React.createElement(ScopeButton, { className: "mr-2 mt-2", icon: searchScopeBodyIcon, textId: "main_search_bar.suggestions.placeholder.body", onMouseDown: onClickBody }),
            React.createElement(ScopeButton, { className: "mr-2 mt-2", icon: searchScopeTagsIcon, textId: "main_search_bar.suggestions.placeholder.tags", onMouseDown: onClickTags }),
            React.createElement(ScopeButton, { className: "mr-2 mt-2", icon: searchScopeFileIcon, textId: "main_search_bar.suggestions.placeholder.file", onMouseDown: onClickFile }),
            React.createElement(ScopeButton, { className: "mr-2 mt-2", icon: searchScopeRemarkIcon, textId: "main_search_bar.suggestions.placeholder.remarks", onMouseDown: onClickRemarks }))));
});

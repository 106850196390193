import { __awaiter, __generator, __read, __values } from "tslib";
import React, { useContext, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { FilterContext } from "@contexts/FilterContext";
import { TagAPIContext } from "@shared/contexts/api";
import { TagExtractors } from "@shared/models/tag";
import { CollapsibleInlineFilter } from "./CollapsibleInlineFilter";
export var TagFilter = React.memo(function (props) {
    var tagGroup = props.tagGroup, filterKey = props.filterKey, isCollapsed = props.isCollapsed, onToggle = props.onToggle;
    var intl = useIntl();
    var tagAPI = useContext(TagAPIContext);
    var _a = useContext(FilterContext), appliedFilters = _a.appliedFilters, setAppliedFilter = _a.setAppliedFilter;
    var fetchOptions = useCallback(function (page, perPage, options) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, tagAPI.listTags(tagGroup.id, {
                    page: page,
                    perPage: perPage,
                    search: options.search,
                })];
        });
    }); }, [tagAPI, tagGroup.id]);
    var _b = __read(useMemo(function () {
        var e_1, _a;
        var groupTags = [];
        var otherGroupTags = [];
        try {
            for (var _b = __values(appliedFilters.customTags), _c = _b.next(); !_c.done; _c = _b.next()) {
                var tag = _c.value;
                if (tag.tagGroupId === tagGroup.id) {
                    groupTags.push(tag);
                }
                else {
                    otherGroupTags.push(tag);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return [groupTags, otherGroupTags];
    }, [appliedFilters.customTags, tagGroup.id]), 2), groupTags = _b[0], otherGroupTags = _b[1];
    var onSelect = useCallback(function (items) {
        setAppliedFilter({ customTags: otherGroupTags.concat(items) });
    }, [otherGroupTags, setAppliedFilter]);
    var searchPlaceholder = useMemo(function () {
        return intl.formatMessage({ id: "common.search" });
    }, [intl]);
    var onToggleFilter = useCallback(function () {
        onToggle(filterKey);
    }, [filterKey, onToggle]);
    return (React.createElement(CollapsibleInlineFilter, { isCollapsed: isCollapsed, onToggle: onToggleFilter, selected: groupTags, onSelect: onSelect, getOptions: fetchOptions, title: tagGroup.name, searchPlaceholder: searchPlaceholder, labelExtractor: TagExtractors.name, descriptionExtractor: TagExtractors.fullName, keyExtractor: TagExtractors.id }));
});

import { __assign, __read, __spreadArray, __values } from "tslib";
import { PHONE_NUMBER } from "@root/constants/regex";
import { MAX_IMAGE_SIZE } from "@root/constants/consts";
import * as yup from "yup";
import { allDepartmentRoleValues, } from "@shared/models/department";
import { userSchema } from "@shared/models/api/user_api";
export var companyFormSchema = yup.object().shape({
    fullName: yup.string(),
    tagName: yup.string().trim().required("form.validation.error.required"),
    countries: yup
        .object()
        .shape({
        data: yup
            .array(yup.object())
            .min(1, "form.validation.error.at_least_one"),
    })
        .defined(),
    industry: yup
        .object()
        .defined()
        .nullable()
        .required("form.validation.error.required"),
    domain: yup
        .string()
        .test({
        name: "domain",
        exclusive: true,
        message: "form.validation.error.invalid_domain",
        test: function (value) {
            if (value == null)
                return true;
            if (typeof value === "string") {
                var domains = value
                    .split(",")
                    .map(function (domain) { return domain.trim(); });
                return domains.every(function (domain) { return !!domain; });
            }
            return false;
        },
    })
        .required("form.validation.error.required"),
    color: yup.object().defined().nullable(),
    address: yup.string(),
});
export var departmentFormSchema = yup.object().shape({
    name: yup.string().required("form.validation.error.required"),
    parentID: yup
        .number()
        .defined()
        .typeError("form.validation.error.at_least_one"), // catches NaN (none selected)
});
export var contactFormSchema = yup.object().shape({
    name: yup.string().required("form.validation.error.required"),
    email: yup
        .string()
        .required("form.validation.error.required")
        // eslint-disable-next-line no-template-curly-in-string
        .test("is-emails", "form.validation.error.invalid_email", function (value) {
        var e_1, _a;
        if (typeof value !== "string") {
            return false;
        }
        var values = value.split(",");
        try {
            for (var values_1 = __values(values), values_1_1 = values_1.next(); !values_1_1.done; values_1_1 = values_1.next()) {
                var v = values_1_1.value;
                var valid = yup.string().defined().email().isValidSync(v.trim());
                if (!valid) {
                    return false;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (values_1_1 && !values_1_1.done && (_a = values_1.return)) _a.call(values_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return true;
    }),
    phoneNumber: yup
        .string()
        .matches(PHONE_NUMBER, "form.validation.error.invalid_phone"),
    avatar: yup
        .mixed()
        .test("fileSize", "form.validation.error.file_too_large", function (value) {
        if (!value)
            return true;
        return value.size <= MAX_IMAGE_SIZE;
    }),
    company: yup.object().required("form.validation.error.required").nullable(), // make yup happy, null is actually caught by required
});
export var industryFormSchema = yup.object().shape({
    fullName: yup.string(),
    tagName: yup.string().trim().required("form.validation.error.required"),
    remarks: yup.string(),
});
export var regionFormSchema = yup.object().shape({
    fullName: yup.string().required("form.validation.error.required"),
    tagName: yup.string().trim().required("form.validation.error.required"),
});
export var priorityFormSchema = yup.object().shape({
    fullName: yup.string(),
    tagName: yup.string().trim().required("form.validation.error.required"),
    remarks: yup.string(),
    color: yup.object(),
});
export var otherTagFormSchema = yup.object().shape({
    fullName: yup.string(),
    tagName: yup.string().trim().required("form.validation.error.required"),
    remarks: yup.string(),
    color: yup.object(),
});
export var dealFormSchema = yup.object().shape({
    fullName: yup.string(),
    tagName: yup.string().trim().required("form.validation.error.required"),
    remarks: yup.string(),
    color: yup.object(),
    teammates: yup.object().shape({
        data: yup.array(yup.object().defined()).defined(),
    }),
});
export var tagFormSchema = yup.object().shape({
    fullName: yup.string(),
    tagName: yup.string().trim().required("form.validation.error.required"),
    color: yup.object(),
});
export var tagGroupFormSchema = yup.object().shape({
    name: yup.string().required("form.validation.error.required"),
    visible: yup.boolean().defined(),
    mandatory: yup.boolean().defined(),
    public: yup.boolean().defined(),
    definitions: yup.lazy(function (o) {
        return yup
            .object(Object.keys(o).reduce(function (acc, curr) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[curr] = yup
                .object({
                name: yup.string().required("form.validation.error.required"),
                mandatory: yup.boolean().defined(),
                createdAt: yup.date().defined().nullable(),
            })
                .defined(), _a)));
        }, {}))
            .defined();
    }),
});
export var allDepartmentUserFormRoleOptions = __spreadArray(__spreadArray([], __read(allDepartmentRoleValues)), ["staff"]);
export var departmentUserFormSchema = yup.object().shape({
    userID: yup
        .number()
        .defined()
        .integer()
        .typeError("form.validation.error.required")
        .required("form.validation.error.required"),
    departmentID: yup
        .number()
        .defined()
        .typeError("form.validation.error.at_least_one"),
    role: yup
        .string()
        .defined()
        .oneOf(allDepartmentUserFormRoleOptions, "form.validation.error.at_least_one")
        .nullable(), // to make yup happy, non-null should be covered by oneOf
});
export var allStaffFormRoleOptions = __spreadArray(__spreadArray([], __read(allDepartmentRoleValues)), [
    "staff",
]);
export var staffFormSchema = yup
    .object()
    .shape({
    fullName: yup.string().required("form.validation.error.required"),
    avatar: yup
        .mixed()
        .test("fileSize", "form.validation.error.file_too_large", function (value) {
        if (!value)
            return true;
        return value.size <= MAX_IMAGE_SIZE;
    }),
    departmentId: yup
        .number()
        .defined()
        .nullable()
        .typeError("form.validation.error.at_least_one"),
    role: yup.string().defined().nullable(),
    managers: yup.array(userSchema.defined()).defined(),
})
    .defined();

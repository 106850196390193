import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useImageResizer } from "@hooks/useImageResizer";
import { useNavigation } from "@hooks/useNavigation";
import { ROUTES } from "@router/routes";
import { StaffSettingRouterContext } from "@router/StaffSettingRouter";
import { APIResponseError, UserAPIContext } from "@shared/contexts/api";
import { DepartmentAPIContext } from "@shared/contexts/api/DepartmentAPIContext";
import { FileAPIContext } from "@shared/contexts/api/FileAPIContext";
import { parseErrorMessage } from "@shared/models/error";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { useParams } from "react-router-dom";
import { useAppToast } from "../common/Toast";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
import StaffForm from "../settings/forms/StaffForm";
var MAX_USER_SUGGESTION_COUNT = 10;
var StaffEditScreen = React.memo(function () {
    var _a, _b;
    var userId = useParams().userId;
    var navigation = useNavigation();
    var onGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.staff.root);
        }
    }, [navigation]);
    var _c = __read(useState(null), 2), user = _c[0], setUser = _c[1];
    var _d = __read(useState(false), 2), isUserLoading = _d[0], setIsUserLoading = _d[1];
    var _e = useContext(UserAPIContext), getUser = _e.getUser, listUsers = _e.listUsers, updateUser = _e.updateUser;
    var showErrorToast = useAppToast().showErrorToast;
    useEffect(function () {
        setUser(null);
        setIsUserLoading(true);
        if (userId == null) {
            return;
        }
        var _userID = parseInt(userId, 10);
        getUser(_userID)
            .then(function (user) {
            setUser(user);
        })
            .catch(function (e) {
            console.error(e);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsUserLoading(false);
        });
    }, [getUser, showErrorToast, userId]);
    var _f = __read(useState(false), 2), isDepartmentOptionsLoading = _f[0], setIsDepartmentOptionsLoading = _f[1];
    var _g = __read(useState([]), 2), departmentOptions = _g[0], setDepartmentOptions = _g[1];
    var listDepartments = useContext(DepartmentAPIContext).listDepartments;
    var listAllDepartments = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var perPage, hasNextPage, page, departments, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    perPage = 100;
                    hasNextPage = true;
                    page = 1;
                    departments = [];
                    _a.label = 1;
                case 1:
                    if (!hasNextPage) return [3 /*break*/, 3];
                    return [4 /*yield*/, listDepartments({
                            page: page,
                            perPage: perPage,
                        })];
                case 2:
                    res = _a.sent();
                    departments.push.apply(departments, __spreadArray([], __read(res.data)));
                    hasNextPage = res.data.length >= perPage;
                    page += 1;
                    return [3 /*break*/, 1];
                case 3: return [2 /*return*/, departments];
            }
        });
    }); }, [listDepartments]);
    useEffect(function () {
        setIsDepartmentOptionsLoading(true);
        listAllDepartments()
            .then(function (departments) {
            setDepartmentOptions(departments);
        })
            .catch(function (e) {
            console.error(e);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsDepartmentOptionsLoading(false);
        });
    }, [listAllDepartments, showErrorToast]);
    var fetchUserSuggestion = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, listUsers({
                    page: 1,
                    perPage: MAX_USER_SUGGESTION_COUNT,
                    search: search,
                    isEnabled: "true",
                    excludeSystem: true,
                }).then(function (resp) { return resp.data; })];
        });
    }); }, [listUsers]);
    // Only retain one department-role for form, find the best option
    var _h = __read(useMemo(function () {
        if (user == null) {
            return [null, null];
        }
        if (user.departmentRoles.length > 0) {
            var role_1 = user.departmentRoles[0];
            var department = user.departments.find(function (d) { return d.id === role_1.departmentId; });
            return [department !== null && department !== void 0 ? department : null, role_1.role];
        }
        if (user.departments.length > 0) {
            return [user.departments[0], "staff"];
        }
        return [null, null];
    }, [user]), 2), userDepartment = _h[0], userRole = _h[1];
    var initialFormValues = useMemo(function () {
        var _a, _b, _c;
        return ({
            fullName: (_a = user === null || user === void 0 ? void 0 : user.fullName) !== null && _a !== void 0 ? _a : "",
            avatar: null,
            departmentId: (_b = userDepartment === null || userDepartment === void 0 ? void 0 : userDepartment.id) !== null && _b !== void 0 ? _b : null,
            role: userRole,
            managers: (_c = user === null || user === void 0 ? void 0 : user.managers) !== null && _c !== void 0 ? _c : [],
        });
    }, [user === null || user === void 0 ? void 0 : user.fullName, user === null || user === void 0 ? void 0 : user.managers, userDepartment === null || userDepartment === void 0 ? void 0 : userDepartment.id, userRole]);
    var _j = __read(useState(false), 2), isSubmitting = _j[0], setIsSubmitting = _j[1];
    var resizeImageFile = useImageResizer().resizeImageFile;
    var uploadImage = useContext(FileAPIContext).uploadImage;
    var refreshSidebar = useContext(StaffSettingRouterContext).refreshSidebar;
    var onSubmit = useCallback(function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var avatarId, resizedImage, e_1, _a, titleId, descriptionId;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 5, , 6]);
                    if (user == null) {
                        return [2 /*return*/];
                    }
                    setIsSubmitting(true);
                    avatarId = null;
                    if (!(value.avatar != null)) return [3 /*break*/, 3];
                    return [4 /*yield*/, resizeImageFile(value.avatar, {
                            maxHeight: 64,
                            maxWidth: 64,
                        })];
                case 1:
                    resizedImage = _d.sent();
                    return [4 /*yield*/, uploadImage(resizedImage)];
                case 2:
                    avatarId = _d.sent();
                    _d.label = 3;
                case 3: return [4 /*yield*/, updateUser(user.id, {
                        fullName: value.fullName,
                        emails: user.emails,
                        avatarId: (_c = avatarId !== null && avatarId !== void 0 ? avatarId : (_b = user.avatar) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null,
                        departmentId: value.departmentId,
                        role: value.role !== "staff" ? value.role : null,
                        managerIds: value.managers.map(function (m) { return m.id; }),
                        isEnabled: user.isEnabled,
                    })];
                case 4:
                    _d.sent();
                    setIsSubmitting(false);
                    refreshSidebar();
                    navigation.push(ROUTES.settings.staff.staff_detail.format(user.id));
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _d.sent();
                    console.error(e_1);
                    if (e_1 instanceof APIResponseError) {
                        _a = __read(parseErrorMessage(e_1), 2), titleId = _a[0], descriptionId = _a[1];
                        showErrorToast({
                            titleId: titleId,
                            descriptionId: descriptionId,
                        }, {
                            position: "bottom-center",
                        });
                        return [2 /*return*/];
                    }
                    showErrorToast({
                        titleId: "common.errors.data.submit_failed.title",
                        descriptionId: "common.errors.data.submit_failed.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [
        navigation,
        refreshSidebar,
        resizeImageFile,
        showErrorToast,
        updateUser,
        uploadImage,
        user,
    ]);
    var onCancel = useCallback(function () {
        onGoBack();
    }, [onGoBack]);
    var isLoading = useMemo(function () {
        return isUserLoading || isDepartmentOptionsLoading;
    }, [isDepartmentOptionsLoading, isUserLoading]);
    return (React.createElement("div", { className: "flex flex-1 min-w-0 flex-col" },
        React.createElement("div", { className: "flex flex-row bg-white px-1" },
            React.createElement("button", { type: "button", className: "md:hidden", onClick: onGoBack },
                React.createElement(ChevronLeftIcon, { className: "h-9" })),
            React.createElement("div", { className: "px-4 py-6 bg-white" },
                React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900 line-clamp-1 whitespace-no-warp overflow-ellipsis break-all h-6" }, (_b = (_a = user === null || user === void 0 ? void 0 : user.fullName) !== null && _a !== void 0 ? _a : user === null || user === void 0 ? void 0 : user.username) !== null && _b !== void 0 ? _b : ""))),
        React.createElement("div", { className: "flex-1 mt-2 overflow-y-auto md:mt-0" }, isLoading || user == null ? (React.createElement(SkeletonForm, null)) : (React.createElement(StaffForm, { departmentOptions: departmentOptions, fetchUserSuggestion: fetchUserSuggestion, onSubmit: onSubmit, onCancel: onCancel, initialFormValues: initialFormValues, isSubmitting: isSubmitting, user: user })))));
});
export default StaffEditScreen;

import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import cn from "classnames";
var alignmentClass = {
    start: "md:items-start",
    center: "md:items-center",
    end: "md:items-end",
};
export var FormField = function (props) {
    var titleId = props.titleId, title = props.title, isOptional = props.isOptional, className = props.className, children = props.children, errorId = props.errorId, _a = props.alignment, alignment = _a === void 0 ? "center" : _a;
    var intl = useIntl();
    return (React.createElement("div", { className: cn("flex flex-col", className) },
        React.createElement("div", { className: cn("flex flex-col items-start justify-center md:flex-row md:py-5", errorId && "md:pb-0", alignmentClass[alignment]) },
            React.createElement("h1", { className: "text-sm leading-5 font-bold text-gray-700 md:min-w-[17.5rem] mb-1 md:mb-0 md:mr-6" },
                React.createElement(FormattedMessage, { id: "form.form_field.label", values: {
                        label: titleId ? intl.formatMessage({ id: titleId }) : title,
                        optional: isOptional,
                    } })),
            React.createElement("div", { className: "flex-1 w-full" }, children)),
        errorId && (React.createElement("span", { className: "md:ml-[19rem] mt-1 md:pb-5 text-error text-sm leading-5 font-normal" },
            React.createElement(FormattedMessage, { id: errorId })))));
};

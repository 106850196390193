import React, { useMemo, useContext } from "react";
import { createPortal } from "react-dom";
export var PortalContext = React.createContext(undefined);
export var Portal = function (props) {
    var children = props.children;
    var portalContext = useContext(PortalContext);
    var containerEl = useMemo(function () {
        if (portalContext === null || portalContext === void 0 ? void 0 : portalContext.containerEl)
            return portalContext.containerEl;
        return document.body;
    }, [portalContext === null || portalContext === void 0 ? void 0 : portalContext.containerEl]);
    return createPortal(children, containerEl);
};

import * as yup from "yup";
import { paginatedSearchRequest, generatePaginationSchema, } from "../pagination";
import { toggleableParamSchema } from "./params";
export var regionSchema = yup
    .object({
    id: yup.number().defined(),
    name: yup.string().defined(),
    isEnabled: yup.boolean().defined(),
    fullName: yup.string().defined(),
    countryCount: yup.number().defined(),
    additional: yup.object(),
})
    .camelCase()
    .defined();
export var listRegionRequestSchema = yup
    .object({
    industry_id: yup.number().optional(),
})
    .concat(paginatedSearchRequest)
    .concat(toggleableParamSchema)
    .defined();
export var listRegionResponseSchema = generatePaginationSchema(regionSchema);
export var createRegionRequestSchema = yup
    .object()
    .shape({
    name: yup.string().required(),
    full_name: yup.string().required(),
    additional: yup.object().nullable(),
})
    .snakeCase()
    .defined();
export var editRegionRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    full_name: yup.string().defined(),
    additional: yup.object().defined().nullable(),
})
    .snakeCase()
    .defined();
export var mergeRegionRequestSchema = yup
    .object()
    .shape({
    source_id: yup.number().defined(),
})
    .snakeCase()
    .defined();

import React from "react";
import cn from "classnames";
import { Persona, PersonaSize } from "@fluentui/react";
import { extractInitials } from "@utils/avatar";
export var ContactTag = React.memo(function (props) {
    var className = props.className, avatarUrl = props.avatarUrl, name = props.name;
    return (React.createElement("div", { className: cn("flex items-center bg-gray-100 rounded-full", className) },
        React.createElement(Persona, { className: "flex-none", imageUrl: avatarUrl, imageInitials: extractInitials(name, 2), hidePersonaDetails: true, size: PersonaSize.size24 }),
        React.createElement("span", { className: "flex-none px-3 text-gray-800 text-sm font-bold py-0.5" }, name)));
});

import * as yup from "yup";
export var fileSchema = yup
    .object()
    .shape({
    id: yup.string().defined(),
    fileName: yup.string().defined().nullable(),
    fileType: yup.string().defined(),
    fileSize: yup.number().defined(),
    fileExtension: yup.string().defined(),
    url: yup.string().defined().nullable(),
})
    .camelCase()
    .defined();
export var presignRequestSchema = yup
    .object()
    .shape({
    file_name: yup.string().defined(),
    file_type: yup.string().defined(),
    file_size: yup.number().defined(),
})
    .snakeCase()
    .defined();
export var presignResponseSchema = yup
    .object()
    .shape({
    id: yup.string().defined(),
    assetName: yup.string().defined(),
    method: yup.string().defined(),
    url: yup.string().defined(),
})
    .camelCase()
    .defined();

import { __read } from "tslib";
import React, { useState, useMemo, useCallback } from "react";
import cn from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { Persona, PersonaSize } from "@fluentui/react";
import { extractInitials } from "@utils/avatar";
import { Link, useRouteMatch } from "react-router-dom";
import { ROUTES } from "@router/routes";
import { SearchBar } from "../common/SearchBar";
import { InfiniteScroll } from "../common/InfiniteScroll";
import { Spinner } from "../common/Spinner";
import { useUserLabel } from "@hooks/useUserLabel";
var StaffItem = function (props) {
    var _a, _b, _c;
    var className = props.className, user = props.user, activeUserID = props.activeUserID;
    var detailURL = useMemo(function () {
        return ROUTES.settings.staff.staff_detail.format(user.id);
    }, [user.id]);
    var isActive = useMemo(function () {
        return user.id.toString(10) === activeUserID;
    }, [activeUserID, user.id]);
    var userLabel = useUserLabel();
    return (React.createElement("li", { className: className },
        React.createElement(Link, { to: detailURL, className: cn("flex flex-row p-4", isActive && "bg-gray-100") },
            React.createElement(Persona, { className: "mr-3", imageUrl: (_b = (_a = user.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined, imageInitials: extractInitials((_c = user.fullName) !== null && _c !== void 0 ? _c : user.username, 2), hidePersonaDetails: true, size: PersonaSize.size40 }),
            React.createElement("div", { className: "flex flex-col min-w-0" },
                React.createElement("h5", { className: "text-sm leading-5 font-medium text-gray-900 line-clamp-1 whitespace-no-warp overflow-ellipsis break-all" }, userLabel(user)),
                React.createElement("h6", { className: "text-sm leading-5 font-normal text-gray-500 line-clamp-1 whitespace-no-warp overflow-ellipsis" }, user.departments.map(function (d) { return d.name; }).join(", "))))));
};
var StaffListSkeleton = React.memo(function () {
    return (React.createElement("ul", { className: "animate-pulse" }, new Array(10).fill(null).map(function (_, index) { return (React.createElement("li", { className: "flex flex-row p-4 -mx-4", key: index },
        React.createElement("div", { className: "bg-gray-200 rounded-full w-10 h-10 flex-0 mr-3" }),
        React.createElement("div", { className: "flex flex-col flex-1" },
            React.createElement("div", { className: "h-4 mb-1 flex-1 text-sm bg-gray-200 rounded-full" }),
            React.createElement("div", { className: "h-4 flex-1 text-sm bg-gray-200 rounded-full" })))); })));
});
var StaffList = function (props) {
    var className = props.className, searchValue = props.searchValue, setSearchValue = props.setSearchValue, searchPaginationContext = props.searchPaginationContext;
    var users = searchPaginationContext.data, totalCount = searchPaginationContext.totalCount, isInitializing = searchPaginationContext.isInitializing, isFetchingMore = searchPaginationContext.isFetchingMore, hasMore = searchPaginationContext.hasMore, nextPage = searchPaginationContext.nextPage;
    var staffDetailRouteMatch = useRouteMatch(ROUTES.settings.staff.staff_detail.toString());
    var activeUserID = useMemo(function () {
        var _a;
        if (staffDetailRouteMatch == null) {
            return null;
        }
        return (_a = staffDetailRouteMatch.params.userId) !== null && _a !== void 0 ? _a : null;
    }, [staffDetailRouteMatch]);
    var intl = useIntl();
    var onSearchValueChange = useCallback(function (ev) {
        setSearchValue(ev.target.value);
    }, [setSearchValue]);
    var _a = __read(useState(null), 2), listEl = _a[0], setListEl = _a[1];
    return (React.createElement("div", { className: cn("flex", className) },
        React.createElement("div", { className: cn("flex-grow flex flex-col min-h-0 bg-white pt-3.5 px-4 border-b border-gray-200") },
            React.createElement("div", { className: "flex items-center mb-3.5 justify-between flex-wrap sm:flex-nowrap" },
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900" },
                        React.createElement(FormattedMessage, { id: "settings.staffList.title" })),
                    isInitializing ? (React.createElement("div", { className: "animate-pulse h-5 w-16 bg-gray-200 rounded-full" })) : (React.createElement("span", { className: "font-normal text-sm leading-5 text-gray-600" },
                        React.createElement(FormattedMessage, { id: "settings.staffList.userCount", values: {
                                count: totalCount,
                            } }))))),
            React.createElement(SearchBar, { containerClassName: "w-full mb-2 bg-white rounded-md border border-gray-300", searchIconClassName: "text-gray-400", inputClassName: "text-gray-500 placeholder-gray-500 md:text-sm", placeholder: intl.formatMessage({
                    id: "settings.staffList.searchPlaceholder",
                }), value: searchValue !== null && searchValue !== void 0 ? searchValue : "", onChange: onSearchValueChange }),
            isInitializing ? (React.createElement(StaffListSkeleton, null)) : (React.createElement("ul", { ref: setListEl, className: "divide-y divide-gray-200 -mx-4 overflow-y-auto min-h-0 flex-grow" },
                users.map(function (u) { return (React.createElement(StaffItem, { key: u.id, user: u, activeUserID: activeUserID })); }),
                hasMore && (React.createElement(InfiniteScroll, { onFetchMore: nextPage, containerElement: listEl }, isFetchingMore ? (React.createElement("li", { className: "py-2 flex items-center justify-center" },
                    React.createElement(Spinner, { size: "large" }))) : (React.createElement("li", null)))))))));
};
export default StaffList;

import { __assign, __read, __rest } from "tslib";
import React, { useCallback, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import { usePopper } from "react-popper";
import { Portal } from "./Portal";
import cn from "classnames";
var Button = function (props) {
    var className = props.className, children = props.children, hideLabelOnMobile = props.hideLabelOnMobile, labelId = props.labelId, isMenuOpened = props.isMenuOpened, buttonNode = props.buttonNode, yOffset = props.yOffset;
    var items = React.Children.toArray(children);
    var _a = __read(useState(null), 2), buttonRef = _a[0], setButtonRef = _a[1];
    var _b = __read(useState(null), 2), containerRef = _b[0], setContainerRef = _b[1];
    var _c = usePopper(buttonRef, containerRef, {
        placement: "bottom-end",
        strategy: "fixed",
        modifiers: [{ name: "offset", options: { offset: [0, yOffset !== null && yOffset !== void 0 ? yOffset : 0] } }],
    }), styles = _c.styles, attributes = _c.attributes;
    return (React.createElement(Menu, { as: "div", className: cn("relative inline-block text-left", className) },
        React.createElement("div", null, buttonNode ? (React.createElement(Menu.Button, { ref: setButtonRef, className: "flex items-center" }, buttonNode)) : !labelId ? (React.createElement(Menu.Button, { ref: setButtonRef, className: "flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" },
            React.createElement(DotsVerticalIcon, { className: "h-4 w-4", "aria-hidden": "true" }))) : (React.createElement(Menu.Button, { ref: setButtonRef, className: "flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 md:px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-500 md:text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-orange-600" },
            React.createElement("span", { className: cn("md:inline-block", hideLabelOnMobile && "hidden") },
                React.createElement(FormattedMessage, { id: labelId })),
            React.createElement(ChevronDownIcon, { className: cn("md:-mr-1 md:ml-2 h-4 w-4", !hideLabelOnMobile && "ml-3"), "aria-hidden": "true" })))),
        React.createElement(Portal, null,
            React.createElement(Transition, { as: "div", show: isMenuOpened, className: "origin-top-right", enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95" },
                React.createElement(Menu.Items, __assign({ ref: setContainerRef, style: styles.popper, className: "z-40 absolute right-0 w-56 py-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" }, attributes.popper), items.map(function (item, index) {
                    return React.createElement(Menu.Item, { key: index }, item);
                }))))));
};
var Item = function (_a) {
    var children = _a.children, onClick_ = _a.onClick, buttonProps = __rest(_a, ["children", "onClick"]);
    var onClick = useCallback(function (e) {
        e.stopPropagation();
        onClick_ === null || onClick_ === void 0 ? void 0 : onClick_(e);
    }, [onClick_]);
    return (React.createElement("button", __assign({}, buttonProps, { onClick: onClick_ ? onClick : undefined, type: "button", className: "block w-full rounded-md text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 disabled:text-gray-400 disabled:cursor-default" }), children));
};
var Separator = React.memo(function (_a) {
    var className = _a.className;
    return (React.createElement("div", { className: cn("my-1", "border-t", "border-gray-100", className) }));
});
export var DropDown = {
    Button: Button,
    Item: Item,
    Separator: Separator,
};

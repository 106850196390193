import { __awaiter, __generator } from "tslib";
import React, { useContext, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { FilterContext } from "@contexts/FilterContext";
import { RegionAPIContext } from "@shared/contexts/api";
import { RegionExtractor } from "@shared/models/region";
import { CollapsibleInlineFilter } from "./CollapsibleInlineFilter";
export var RegionFilter = React.memo(function (props) {
    var filterKey = props.filterKey, isCollapsed = props.isCollapsed, onToggle = props.onToggle;
    var intl = useIntl();
    var regionAPI = useContext(RegionAPIContext);
    var _a = useContext(FilterContext), appliedFilters = _a.appliedFilters, setAppliedFilter = _a.setAppliedFilter;
    var fetchOptions = useCallback(function (page, perPage, options) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, regionAPI.listRegions({
                    page: page,
                    perPage: perPage,
                    search: options.search,
                })];
        });
    }); }, [regionAPI]);
    var onSelect = useCallback(function (items) {
        setAppliedFilter({ regions: items });
    }, [setAppliedFilter]);
    var title = useMemo(function () {
        return intl.formatMessage({ id: "common.tags.region" });
    }, [intl]);
    var searchPlaceholder = useMemo(function () {
        return intl.formatMessage({ id: "common.search" });
    }, [intl]);
    var onToggleFilter = useCallback(function () {
        onToggle(filterKey);
    }, [filterKey, onToggle]);
    return (React.createElement(CollapsibleInlineFilter, { isCollapsed: isCollapsed, onToggle: onToggleFilter, selected: appliedFilters.regions, onSelect: onSelect, getOptions: fetchOptions, title: title, searchPlaceholder: searchPlaceholder, labelExtractor: RegionExtractor.name, descriptionExtractor: RegionExtractor.fullName, keyExtractor: RegionExtractor.id }));
});

import React, { useContext, useCallback } from "react";
import cn from "classnames";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { TagIndexContext } from "@contexts/TagIndexContext";
import { TagIndexPanelHeader } from "@components/tagIndex/TagIndexPanelHeader";
import { TagIndexExternalList } from "./TagIndexExternalList";
import { TagIndexInternalList } from "./TagIndexInternalList";
export var TagIndexPanel = React.memo(function (_a) {
    var classNames = _a.classNames;
    var tagIndexContext = useContext(TagIndexContext);
    var isTagIndexPanelOpened = tagIndexContext.isTagIndexPanelOpened, setTagIndexPanelOpened = tagIndexContext.setTagIndexPanelOpened, selectedTab = tagIndexContext.selectedTab;
    var togglePanel = useCallback(function () {
        setTagIndexPanelOpened(!isTagIndexPanelOpened);
    }, [isTagIndexPanelOpened, setTagIndexPanelOpened]);
    return (React.createElement("div", { className: cn(classNames, "flex flex-row") },
        isTagIndexPanelOpened && (React.createElement("div", { className: "w-[18.75rem] flex-none bg-white flex flex-col" },
            React.createElement(TagIndexPanelHeader, null),
            React.createElement(TagIndexExternalList, { className: cn("flex-1", selectedTab !== "external" && "hidden") }),
            React.createElement(TagIndexInternalList, { className: cn("flex-1", selectedTab !== "internal" && "hidden") }))),
        React.createElement("button", { type: "button", className: "bg-gray-50 w-8 p-0.5 hidden md:block flex-none", onClick: togglePanel },
            React.createElement("div", { className: "w-6 h-6 flex items-center justify-center bg-gray-200 rounded-full" }, !isTagIndexPanelOpened ? (React.createElement(ChevronRightIcon, { className: "w-4 h-4 text-gray-400" })) : (React.createElement(ChevronLeftIcon, { className: "w-4 h-4 text-gray-400" }))))));
});

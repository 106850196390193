import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { listDepartmentsRequestSchema, listDepartmentsResponseSchema, getDepartmentTreeResponseSchema, createDepartmentRequestSchema, createDepartmentResponseSchema, getDepartmentTreeQuerySchema, } from "@shared/models/api/department_api";
import { createDepartmentUserRequestSchema, createDepartmentUserResponseSchema, } from "@shared/models/api/department_api_create_department_user";
export var DepartmentAPIContext = React.createContext(undefined);
export var DepartmentAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var listDepartments = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listDepartmentsRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("departments", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listDepartmentsResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var getDepartmentTree = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var query, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    query = getDepartmentTreeQuerySchema.validateSync(params);
                    return [4 /*yield*/, apiContext.get("department_tree", query)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, getDepartmentTreeResponseSchema.validateSync(res)];
            }
        });
    }); }, [apiContext]);
    var createDepartment = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = createDepartmentRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("departments", params)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, createDepartmentResponseSchema.validateSync(res)];
            }
        });
    }); }, [apiContext]);
    var createDepartmentUser = useCallback(function (departmentID, request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = createDepartmentUserRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("departments/" + departmentID.toString(10) + "/users", params)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, createDepartmentUserResponseSchema.validateSync(res)];
            }
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            listDepartments: listDepartments,
            getDepartmentTree: getDepartmentTree,
            createDepartment: createDepartment,
            createDepartmentUser: createDepartmentUser,
        };
    }, [
        listDepartments,
        getDepartmentTree,
        createDepartment,
        createDepartmentUser,
    ]);
    return (React.createElement(DepartmentAPIContext.Provider, { value: value }, children));
});

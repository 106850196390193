import React, { useCallback, useRef, useEffect } from "react";
import cn from "classnames";
import { ContactTag } from "@components/common/ContactTag";
export var ContactSuggestionItemBase = React.memo(function (props) {
    var _a, _b;
    var className = props.className, contact = props.contact, email = props.email, onMouseDown = props.onMouseDown, isSelected = props.isSelected;
    var elRef = useRef(null);
    useEffect(function () {
        var _a;
        if (isSelected) {
            (_a = elRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
        }
    }, [isSelected]);
    return (React.createElement("button", { ref: elRef, type: "button", className: cn("px-4", "py-2", "flex", "items-center", "w-full", "hover:bg-gray-50", isSelected && "bg-gray-50", className), onMouseDown: onMouseDown },
        React.createElement(ContactTag, { className: "flex-none", name: contact.name, avatarUrl: (_b = (_a = contact.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined }),
        React.createElement("div", { className: "px-3 flex-1 text-sm text-left text-gray-600 truncate font-normal" }, email)));
});
export var ContactSuggestionItem = React.memo(function (props) {
    var className = props.className, contact = props.contact, onSelect = props.onSelect, isSelected = props.isSelected;
    var onClickRow = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onSelect(contact);
    }, [contact, onSelect]);
    return (React.createElement(ContactSuggestionItemBase, { onMouseDown: onClickRow, className: className, contact: contact, email: contact.emails.join(","), isSelected: isSelected }));
});
export var SingleEmailContactSuggestionItem = React.memo(function (props) {
    var className = props.className, contact = props.contact, onSelect = props.onSelect, isSelected = props.isSelected;
    var onClickRow = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onSelect(contact);
    }, [contact, onSelect]);
    return (React.createElement(ContactSuggestionItemBase, { onMouseDown: onClickRow, className: className, contact: contact.contact, email: contact.email, isSelected: isSelected }));
});

import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import * as Sentry from "@sentry/react";
import { initializeIcons } from "@fluentui/react";
if (window.CONFIGS.SENTRY_DSN) {
    Sentry.init({
        dsn: window.CONFIGS.SENTRY_DSN,
        environment: window.CONFIGS.SENTRY_ENV,
    });
}
initializeIcons();
var root = document.getElementById("react-root");
if (root) {
    ReactDOM.render(React.createElement(App, null), root);
}

import { __assign, __read } from "tslib";
import React, { useContext, useCallback, useState, useEffect, useMemo, } from "react";
import EventEmitter from "events";
import cn from "classnames";
import { useIntl } from "react-intl";
import { useHistory, useRouteMatch } from "react-router";
import { SearchContext, } from "@contexts/SearchContext";
import { SearchIcon } from "@heroicons/react/outline";
import { usePopper } from "react-popper";
import { Portal } from "@components/common/Portal";
import { MainSearchBarSuggestions } from "./MainSearchBarSuggestions";
import { sameWidthModifier } from "@constants/modifiers";
import { ROUTES } from "@router/routes";
import cancelIcon from "@assets/cancel.svg";
export var MainSearchBarContext = React.createContext(undefined);
export var MainSearchBar = React.memo(function (props) {
    var className = props.className;
    var searchContext = useContext(SearchContext);
    var intl = useIntl();
    var history = useHistory();
    var uploadRouteMatch = useRouteMatch({
        path: [ROUTES.uploads.allUploads, ROUTES.uploads.unreadUploads],
        exact: false,
    });
    var _a = __read(useState(false), 2), isSuggestionShowing = _a[0], setIsSuggestionShowing = _a[1];
    var _b = __read(useState(null), 2), barEl = _b[0], setBarEl = _b[1];
    var _c = __read(useState(null), 2), popupEl = _c[0], setPopupEl = _c[1];
    var _d = __read(useState(null), 2), inputEl = _d[0], setInputEl = _d[1];
    var _e = __read(useState(function () { return new EventEmitter(); }), 1), eventEmitter = _e[0];
    var onInputValueChange = useCallback(function (e) {
        var val = e.currentTarget.value;
        searchContext.setSearchText(val);
    }, [searchContext]);
    var onClear = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        searchContext.setSearchText("");
        inputEl === null || inputEl === void 0 ? void 0 : inputEl.focus();
    }, [inputEl, searchContext]);
    var onFocus = useCallback(function () {
        setIsSuggestionShowing(true);
    }, []);
    var onBlur = useCallback(function () {
        setIsSuggestionShowing(false);
        searchContext.commitSearchClauses();
    }, [searchContext]);
    var addScope = useCallback(function (scope) {
        if (!inputEl)
            return;
        inputEl.focus();
        var newValue = scope + ":\"\"";
        inputEl.value = newValue;
        inputEl.selectionStart = newValue.length - 1;
        inputEl.selectionEnd = newValue.length - 1;
        searchContext.setSearchText(newValue);
    }, [inputEl, searchContext]);
    var autoCompleteContact = useCallback(function (contact) {
        var originalClauses = searchContext.parsedSearchClauses;
        var lastClause = originalClauses.at(-1);
        if (!lastClause)
            return;
        var newRawValue = "\"" + contact.email + "\"";
        var newClause = {
            scope: lastClause.scope,
            value: contact.email,
            valueRaw: newRawValue,
        };
        var newClauses = Array.from(originalClauses);
        newClauses[newClauses.length - 1] = newClause;
        searchContext.setSearchClauses(newClauses);
    }, [searchContext]);
    var autoCompleteTag = useCallback(function (tagName) {
        var originalClauses = searchContext.parsedSearchClauses;
        var lastClause = originalClauses.at(-1);
        if (!lastClause)
            return;
        var newRawValue = "\"" + tagName + "\"";
        var newClause = {
            scope: lastClause.scope,
            value: tagName,
            valueRaw: newRawValue,
        };
        var newClauses = Array.from(originalClauses);
        newClauses[newClauses.length - 1] = newClause;
        searchContext.setSearchClauses(newClauses);
    }, [searchContext]);
    var onSubmitSearch = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        inputEl === null || inputEl === void 0 ? void 0 : inputEl.blur();
    }, [inputEl]);
    var onInputKeydown = useCallback(function (e) {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            e.stopPropagation();
            eventEmitter.emit("suggestionIndexChange", 1);
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
            e.stopPropagation();
            eventEmitter.emit("suggestionIndexChange", -1);
        }
        if (e.key === "Enter") {
            eventEmitter.emit("suggestionSelected");
        }
    }, [eventEmitter]);
    var onTagSelected = useCallback(function (name) {
        searchContext.replaceGenericTail({
            scope: "tags",
            value: name,
            valueRaw: "\"" + name + "\"",
        });
    }, [searchContext]);
    var onDocumentGroupSelected = useCallback(function (documentGroup) {
        if (!inputEl)
            return;
        var newValue = "thread:" + documentGroup.threadId;
        searchContext.setSearchText(newValue);
        inputEl.blur();
    }, [inputEl, searchContext]);
    var _f = usePopper(barEl, popupEl, {
        placement: "bottom-end",
        strategy: "fixed",
        modifiers: [
            { name: "offset", options: { offset: [0, 4] } },
            sameWidthModifier,
        ],
    }), styles = _f.styles, attributes = _f.attributes;
    useEffect(function () {
        // Redirect to thread list screen if search clause is not empty
        var clauses = searchContext.commitedSearchClauses;
        if (clauses.length === 0) {
            return;
        }
        if (uploadRouteMatch === null) {
            history.push(ROUTES.uploads.allUploads);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchContext.commitedSearchClauses]);
    var contextValue = useMemo(function () {
        return {
            eventEmitter: eventEmitter,
            onAddScope: addScope,
            onContactAutoComplete: autoCompleteContact,
            onTagAutoComplete: autoCompleteTag,
            onTagSelected: onTagSelected,
            onDocumentGroupSelected: onDocumentGroupSelected,
        };
    }, [
        addScope,
        autoCompleteContact,
        autoCompleteTag,
        eventEmitter,
        onDocumentGroupSelected,
        onTagSelected,
    ]);
    return (React.createElement(MainSearchBarContext.Provider, { value: contextValue },
        React.createElement("form", { className: cn("flex text-gray-100 md:ml-0 relative bg-indigo-800 md:rounded-md", className), ref: setBarEl, onSubmit: onSubmitSearch },
            React.createElement("div", { className: "absolute inset-y-0 left-3 md:left-4 flex items-center pointer-events-none bg-transparent text-gray-100" },
                React.createElement(SearchIcon, { className: "h-5 w-5", "aria-hidden": "true" })),
            searchContext.searchText && (React.createElement("button", { type: "button", className: "absolute flex items-center top-0 bottom-0 right-0 px-3", onMouseDown: onClear },
                React.createElement("img", { src: cancelIcon, className: "w-4 h-4 object-contain" }))),
            React.createElement("input", { className: cn("flex", "w-full", "pr-10", "pl-11", "py-1.5", "bg-transparent", "text-gray-100", "placeholder-gray-100", "md:text-sm", "border-transparent", "focus:outline-none", "focus:ring-0", "focus:border-transparent"), ref: setInputEl, placeholder: intl.formatMessage({
                    id: "main_search_bar.placeholder",
                }), value: searchContext.searchText, onChange: onInputValueChange, onFocus: onFocus, onBlur: onBlur, onKeyDown: onInputKeydown })),
        isSuggestionShowing && (React.createElement(Portal, null,
            React.createElement("div", __assign({ ref: setPopupEl, style: styles.popper }, attributes.popper, { className: "z-40" }),
                React.createElement(MainSearchBarSuggestions, null))))));
});

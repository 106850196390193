import { __awaiter, __generator } from "tslib";
import React, { useCallback, useMemo, useContext } from "react";
import { listMessageInThreadRequestSchema, listMessageInThreadResponseSchema, threadDetailSchema, setThreadTagsRequestSchema, setThreadTagsResponseSchema, createThreadRemarkRequestSchema, remarkSchema, } from "@shared/models/api/thread_api";
import { APIContext } from "./APIContext";
export var ThreadAPIContext = React.createContext(undefined);
export var ThreadAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var listMessages = useCallback(function (threadId, request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listMessageInThreadRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("threads/" + threadId + "/messages", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listMessageInThreadResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var getThreadDetail = useCallback(function (threadId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("threads/" + threadId)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, threadDetailSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var setThreadTags = useCallback(function (threadId, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = setThreadTagsRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("threads/" + threadId + "/tags", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, setThreadTagsResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var createRemark = useCallback(function (threadId, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = createThreadRemarkRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("threads/" + threadId + "/remarks", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, remarkSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var readThread = useCallback(function (threadId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("threads/" + threadId + "/read", {})];
        });
    }); }, [apiContext]);
    var unreadThread = useCallback(function (threadId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("threads/" + threadId + "/unread", {})];
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            listMessages: listMessages,
            getThreadDetail: getThreadDetail,
            setThreadTags: setThreadTags,
            createRemark: createRemark,
            readThread: readThread,
            unreadThread: unreadThread,
        };
    }, [
        listMessages,
        getThreadDetail,
        setThreadTags,
        createRemark,
        readThread,
        unreadThread,
    ]);
    return (React.createElement(ThreadAPIContext.Provider, { value: value }, children));
});

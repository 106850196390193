import { __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { FormattedMessage } from "react-intl";
import { ROUTES } from "@router/routes";
import { TagGroupForm } from "../settings/forms/TagGroupForm";
import { useNavigation } from "@hooks/useNavigation";
import { useAppToast } from "../common/Toast";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { parseTagGroupUpdateErrorMessage } from "@shared/models/error";
import { APIResponseError } from "@shared/contexts/api";
import { useLocation, useParams } from "react-router-dom";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
export var TagGroupEditScreen = React.memo(function () {
    var _a, _b;
    var groupId = useParams().groupId;
    var tagGroupId = useMemo(function () { return parseInt(groupId, 10); }, [groupId]);
    var location = useLocation();
    var navigation = useNavigation();
    var showErrorToast = useAppToast().showErrorToast;
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _c = __read(useState(undefined), 2), initialFormValue = _c[0], setInitialFormValue = _c[1];
    var _d = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.groupName) !== null && _b !== void 0 ? _b : ""), 2), groupName = _d[0], setGroupName = _d[1];
    var _e = __read(useState(false), 2), isSubmitting = _e[0], setIsSubmitting = _e[1];
    var _f = __read(useState(false), 2), isLoading = _f[0], setIsLoading = _f[1];
    var handleSubmit = useCallback(function (formValues) {
        setIsSubmitting(true);
        tagGroupAPI
            .updateTagGroup(tagGroupId, {
            name: formValues.name.trim(),
            mandatory: formValues.mandatory,
            public: formValues.public,
            visible: formValues.visible,
            definition: formValues.definitions,
        })
            .then(function (group) {
            navigation.push(ROUTES.settings.tags.root + "/" + group.id);
        })
            .catch(function (err) {
            if (err instanceof APIResponseError) {
                var _a = __read(parseTagGroupUpdateErrorMessage(err), 2), titleId = _a[0], descriptionId = _a[1];
                showErrorToast({
                    titleId: titleId,
                    descriptionId: descriptionId,
                }, {
                    position: "bottom-center",
                });
            }
            else {
                throw err;
            }
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    }, [tagGroupId, tagGroupAPI, navigation, setIsSubmitting, showErrorToast]);
    var handleCancel = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.root);
        }
    }, [navigation]);
    useEffect(function () {
        setIsLoading(true);
        tagGroupAPI
            .getTagGroupById(tagGroupId)
            .then(function (group) {
            setGroupName(group.name);
            setInitialFormValue({
                name: group.name,
                mandatory: group.mandatory,
                visible: group.visible,
                public: group.public,
                definitions: group.definition,
            });
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            navigation.push(ROUTES.settings.tags.root);
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        navigation,
        tagGroupId,
        tagGroupAPI,
        setGroupName,
        setIsLoading,
        setInitialFormValue,
        showErrorToast,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tag_group.form.edit.title", values: {
                        groupName: groupName,
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(TagGroupForm, { initialFormValues: initialFormValue, isSubmitting: isSubmitting, nameEditable: true, onSubmit: handleSubmit, onCancel: handleCancel })))));
});

import { __read, __values } from "tslib";
import React, { useState, useMemo, useCallback } from "react";
export var TagGroupStoreContext = React.createContext(undefined);
export var TagGroupStoreContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var _b = __read(useState(new Map()), 2), tagGroupMap = _b[0], setTagGroupMap = _b[1];
    var upsert = useCallback(function (items) {
        setTagGroupMap(function (prevMap) {
            var e_1, _a;
            var newMap = new Map(prevMap);
            try {
                for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                    var item = items_1_1.value;
                    newMap.set(item.id, item);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return newMap;
        });
    }, []);
    var value = useMemo(function () {
        return {
            tagGroupMap: tagGroupMap,
            upsert: upsert,
        };
    }, [tagGroupMap, upsert]);
    return (React.createElement(TagGroupStoreContext.Provider, { value: value }, children));
});

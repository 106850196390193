import { __assign } from "tslib";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
export function useNavigation() {
    var history = useHistory();
    var location = useLocation();
    var push = useCallback(function (path, state) {
        history.push(path, __assign(__assign({}, (state !== null && state !== void 0 ? state : {})), { from: location.pathname }));
    }, [history, location]);
    var goBack = useCallback(function (state) {
        var _a;
        var fromPath = (_a = history.location.state) === null || _a === void 0 ? void 0 : _a.from;
        if (!fromPath) {
            return false;
        }
        if (fromPath === location.pathname) {
            return false;
        }
        push(fromPath, state);
        return true;
    }, [history, location, push]);
    var replace = useCallback(function (path, state) {
        history.replace(path, state);
    }, [history]);
    var value = useMemo(function () { return ({
        push: push,
        goBack: goBack,
        replace: replace,
    }); }, [replace, push, goBack]);
    return value;
}

export function isExternalTag(item) {
    var externalTypes = [
        "company",
        "contact",
        "country",
        "industry",
        "region",
    ];
    return externalTypes.includes(item.type);
}
export function isInternalTag(item) {
    var internalTypes = ["department", "user"];
    return internalTypes.includes(item.type);
}

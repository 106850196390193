import { __read } from "tslib";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useDebounce } from "@hooks/useDebounce";
import useHasPermission from "@hooks/useHasPermission";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { Permission } from "@shared/models/auth";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PrimaryButton } from "../common/PrimaryButton";
import RequirePermission from "../common/RequirePermission";
import { SearchBar } from "../common/SearchBar";
import { SkeletonTable, Table, } from "../common/Table";
import { useAppToast } from "../common/Toast";
import { TagGroupDetailHeader } from "./TagGroupDetailHeader";
function TagGroupDetail(props) {
    var onAddNewClick = props.onAddNewClick, onEditGroup = props.onEditGroup, onGoBack = props.onGoBack, onSearch = props.onSearch, onFetchMore = props.onFetchMore, onTableItemClick = props.onTableItemClick, headerTitleId = props.headerTitleId, headerTagCountId = props.headerTagCountId, searchPlaceholderId = props.searchPlaceholderId, addNewTagId = props.addNewTagId, tableData = props.tableData, tableColumns = props.tableColumns, tableActions = props.tableActions, tagCount = props.tagCount, isInitializing = props.isInitializing, isFetchingMore = props.isFetchingMore, hasMoreData = props.hasMoreData, tagGroupSlug = props.tagGroupSlug;
    var _a = __read(useState(null), 2), searchValue = _a[0], setSearchValue = _a[1];
    var debouncedSearchValue = useDebounce(searchValue, 500);
    var intl = useIntl();
    var onSearchValueChange = useCallback(function (e) {
        setSearchValue(e.currentTarget.value);
    }, [setSearchValue]);
    useEffect(function () {
        if (debouncedSearchValue != null) {
            onSearch(debouncedSearchValue);
        }
    }, [debouncedSearchValue, onSearch]);
    var showErrorToast = useAppToast().showErrorToast;
    var getTagGroupBySlug = useContext(TagGroupAPIContext).getTagGroupBySlug;
    var _b = __read(useState(null), 2), isTagGroupPublic = _b[0], setIsTagGroupPublic = _b[1];
    useEffect(function () {
        getTagGroupBySlug(tagGroupSlug)
            .then(function (res) {
            setIsTagGroupPublic(res.public);
        })
            .catch(function (e) {
            console.error(e);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
        });
    }, [getTagGroupBySlug, showErrorToast, tagGroupSlug]);
    var hasPermission = useHasPermission();
    return (React.createElement("div", { className: "flex flex-1 min-w-0 flex-col" },
        React.createElement("div", { className: "flex flex-row bg-white px-4" },
            React.createElement("button", { type: "button", className: "md:hidden", onClick: onGoBack },
                React.createElement(ChevronLeftIcon, { className: "h-6 text-gray-500 mr-4" })),
            React.createElement(TagGroupDetailHeader, { className: "flex-1", nameId: headerTitleId, tagCountMessageId: headerTagCountId, count: tagCount, isDefault: true, onEdit: onEditGroup })),
        React.createElement("div", { className: "flex flex-1 flex-col min-h-0 py-4 px-3 space-y-3 justify-center" },
            React.createElement("div", { className: "flex flex-col space-y-2 items-start justify-center md:space-y-0 md:flex-row md:space-x-2 md:items-center" },
                React.createElement(SearchBar, { containerClassName: "flex-1 bg-white rounded-md border border-gray-300", searchIconClassName: "text-gray-400", inputClassName: "text-gray-500 placeholder-gray-500 md:text-sm", placeholder: intl.formatMessage({ id: searchPlaceholderId }), value: searchValue !== null && searchValue !== void 0 ? searchValue : "", onChange: onSearchValueChange }),
                React.createElement(RequirePermission, { permission: (isTagGroupPublic !== null && isTagGroupPublic !== void 0 ? isTagGroupPublic : false)
                        ? Permission.TagsPublicCreate
                        : Permission.TagsPrivateCreate },
                    React.createElement(PrimaryButton, { size: "sm", onClick: onAddNewClick },
                        React.createElement(FormattedMessage, { id: addNewTagId })))),
            React.createElement("div", { className: "flex-1 flex flex-col min-h-0" }, isInitializing ? (React.createElement(SkeletonTable, { labelClassName: "text-xs leading-4 font-medium text-gray-500", cellClassName: "text-sm leading-5 font-normal text-gray-500", columns: tableColumns })) : (React.createElement(Table, { data: tableData, actions: hasPermission(Permission.TagsUpdate) ? tableActions : undefined, labelClassName: "text-xs leading-4 font-medium text-gray-500", cellClassName: "text-sm leading-5 font-normal text-gray-500", columns: tableColumns, onFetchMore: onFetchMore, onItemClick: onTableItemClick, hasMore: hasMoreData, isFetchingMore: isFetchingMore }))))));
}
export var DefaultTagGroupDetail = React.memo(TagGroupDetail);

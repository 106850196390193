import { __assign, __read, __spreadArray } from "tslib";
import React, { useMemo, useCallback, useState } from "react";
import cn from "classnames";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { SearchBar } from "@components/common/SearchBar";
import { usePagination } from "@hooks/usePagination";
import { useDebounce } from "@hooks/useDebounce";
import { InfiniteScroll } from "@components/common/InfiniteScroll";
import { Spinner } from "@components/common/Spinner";
var InlineFilterOptionImpl = function (props) {
    var item = props.item, isSelected = props.isSelected, onSelect = props.onSelect, label = props.label, description = props.description, className = props.className;
    var onItemClick = useCallback(function () {
        onSelect(item);
    }, [item, onSelect]);
    return (React.createElement("li", { onClick: onItemClick, className: cn("flex items-start cursor-pointer", className) },
        React.createElement("div", { className: "pt-0.5" },
            React.createElement("input", { type: "checkbox", checked: isSelected, readOnly: true, className: "h-4 w-4 text-indigo-600 block border-gray-300 rounded" })),
        React.createElement("div", { className: "ml-3 overflow-hidden" },
            React.createElement("p", { className: cn("block truncate font-bold leading-5 text-gray-700 text-sm") }, label),
            description && (React.createElement("p", { className: cn("block truncate font-normal leading-5 text-gray-500 text-sm") }, description)))));
};
var InlineFilterOption = React.memo(InlineFilterOptionImpl);
var InlineFilterImpl = function (props) {
    var getOptions = props.getOptions, keyExtractor = props.keyExtractor, selected = props.selected, labelExtractor = props.labelExtractor, descriptionExtractor = props.descriptionExtractor, onSelect = props.onSelect, searchPlaceholder = props.searchPlaceholder;
    var _a = __read(useState(""), 2), searchText = _a[0], setSearchText = _a[1];
    var _b = __read(useState(null), 2), optionsContainerEl = _b[0], setOptionsContainerEl = _b[1];
    var debouncedSearchText = useDebounce(searchText, 100);
    var _c = usePagination({
        fetch: getOptions,
        initialPage: 1,
        perPage: 10,
        extraOptions: { search: debouncedSearchText },
    }), loadedOptions = _c.data, isFetchingMore = _c.isFetchingMore, isInitializing = _c.isInitializing, nextPage = _c.nextPage;
    var onSearchChange = useCallback(function (e) {
        setSearchText(e.currentTarget.value);
    }, []);
    var selectedItemByKey = useMemo(function () {
        return new Map(selected.map(function (i) { return [keyExtractor(i), i]; }));
    }, [selected, keyExtractor]);
    var onOptionSelected = useCallback(function (option) {
        var optionKey = keyExtractor(option);
        if (selectedItemByKey.has(optionKey)) {
            selectedItemByKey.delete(optionKey);
        }
        else {
            selectedItemByKey.set(optionKey, option);
        }
        onSelect(__spreadArray([], __read(selectedItemByKey.values())));
    }, [keyExtractor, selectedItemByKey, onSelect]);
    var optionItems = useMemo(function () {
        var loadedOptionKeySet = new Set(loadedOptions.map(function (option) { return keyExtractor(option); }));
        return selected
            .filter(function (item) { return !loadedOptionKeySet.has(keyExtractor(item)); })
            .concat(loadedOptions);
    }, [keyExtractor, loadedOptions, selected]);
    return (React.createElement("div", { className: "pt-1 border-b border-gray-200 border-solid" },
        React.createElement(SearchBar, { hideSearchIcon: true, containerClassName: "border border-gray-300 rounded shadow-sm", inputClassName: "text-sm leading-5 text-gray-500", value: searchText, onChange: onSearchChange, placeholder: searchPlaceholder }),
        React.createElement("ul", { className: "block overflow-y-auto max-h-36 pt-1", ref: setOptionsContainerEl },
            optionItems.map(function (item, idx) {
                var itemKey = keyExtractor(item);
                var isSelected = selectedItemByKey.has(itemKey);
                return (React.createElement(InlineFilterOption, { className: cn("mt-2", idx === optionItems.length - 1 && "mb-4"), key: itemKey, isSelected: isSelected, label: labelExtractor(item), description: descriptionExtractor === null || descriptionExtractor === void 0 ? void 0 : descriptionExtractor(item), item: item, onSelect: onOptionSelected }));
            }),
            React.createElement(InfiniteScroll, { onFetchMore: nextPage, containerElement: optionsContainerEl }),
            (isFetchingMore || isInitializing) && (React.createElement("div", { className: "py-2" },
                React.createElement(Spinner, { size: "small" }))))));
};
var InlineFilter = React.memo(InlineFilterImpl);
var CollapsibleInlineFilterImpl = function (props) {
    var isCollapsed = props.isCollapsed, onToggle = props.onToggle, title = props.title, selected = props.selected;
    var onToggleClick = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onToggle(!isCollapsed);
    }, [isCollapsed, onToggle]);
    var ButtonIcon = useMemo(function () {
        if (isCollapsed) {
            return ChevronDownIcon;
        }
        return ChevronUpIcon;
    }, [isCollapsed]);
    return (React.createElement("section", { className: "block" },
        React.createElement("header", { className: "flex items-center cursor-pointer", onClick: onToggleClick },
            React.createElement("div", { className: "flex-1 truncate flex items-center" },
                React.createElement("h1", { className: "flex-initial text-gray-600 font-bold text-sm leading-5 truncate" }, title),
                selected.length > 0 && (React.createElement("div", { className: "ml-2 flex-none rounded-full bg-gray-300 py-0.5 px-3 text-gray-700 text-xs leading-4" }, selected.length))),
            React.createElement(ButtonIcon, { className: "h-5 w-5" })),
        !isCollapsed && React.createElement(InlineFilter, __assign({}, props))));
};
export var CollapsibleInlineFilter = React.memo(CollapsibleInlineFilterImpl);

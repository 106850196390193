export function extractInitials(name, maxInitials) {
    var splittedName = name.split(" ");
    if (splittedName.length === 1) {
        return name.slice(0, maxInitials).toUpperCase();
    }
    return splittedName
        .map(function (part) { return part.substring(0, 1).toUpperCase(); })
        .filter(function (v) { return !!v; })
        .slice(0, maxInitials)
        .join("")
        .toUpperCase();
}

import { __read } from "tslib";
import React, { useCallback, useContext, useMemo } from "react";
import { MainSearchBarSuggestionsContainer } from "./MainSearchBarSuggestionsContainer";
import { ContactSuggestionItem } from "./ContactSuggestionItem";
import { TagSuggestionItem } from "./TagSuggestionItem";
import { MainSearchBarContext } from "./MainSearchBar";
import { useSelectedIndex } from "./hooks";
export var MainSearchBarTagSuggestions = React.memo(function (props) {
    var suggestions = props.suggestions;
    var _a = useContext(MainSearchBarContext), onTagAutoComplete = _a.onTagAutoComplete, eventEmitter = _a.eventEmitter;
    var items = useMemo(function () {
        return suggestions.contacts
            .map(function (item) { return ({
            type: "Contact",
            item: item,
        }); })
            .concat(suggestions.companies.map(function (item) { return ({
            type: "Company",
            item: item,
        }); }))
            .concat(suggestions.deals.map(function (item) { return ({
            type: "Deal",
            item: item,
        }); }))
            .concat(suggestions.priorities.map(function (item) { return ({
            type: "Priority",
            item: item,
        }); }))
            .concat(suggestions.otherTags.map(function (item) { return ({
            type: "OtherTag",
            item: item,
        }); }))
            .concat(suggestions.customTags.map(function (item) { return ({
            type: "Tag",
            item: item,
        }); }));
    }, [suggestions]);
    var onSelectContact = useCallback(function (contact) {
        onTagAutoComplete(contact.name);
    }, [onTagAutoComplete]);
    var onSelectTag = useCallback(function (_, tagName) {
        onTagAutoComplete(tagName);
    }, [onTagAutoComplete]);
    var onSelectedItem = useCallback(function (item) {
        switch (item.type) {
            case "Contact":
                onSelectContact(item.item);
                break;
            case "Company":
            case "Deal":
            case "Priority":
            case "OtherTag":
            case "Tag":
                onSelectTag(item.item, item.item.name);
                break;
            default:
                break;
        }
    }, [onSelectContact, onSelectTag]);
    var _b = __read(useSelectedIndex(eventEmitter, items, onSelectedItem), 1), selectedItemIndex = _b[0];
    if (items.length === 0) {
        return null;
    }
    return (React.createElement(MainSearchBarSuggestionsContainer, null, items.map(function (item, idx) {
        var _a;
        switch (item.type) {
            case "Contact":
                return (React.createElement(ContactSuggestionItem, { contact: item.item, key: idx, onSelect: onSelectContact, isSelected: selectedItemIndex === idx }));
            case "Company":
            case "Deal":
            case "Priority":
            case "OtherTag":
            case "Tag":
                return (React.createElement(TagSuggestionItem, { key: idx, tag: item.item, name: item.item.name, description: (_a = item.item.fullName) !== null && _a !== void 0 ? _a : "", color: item.item.color, onSelect: onSelectTag, isSelected: selectedItemIndex === idx }));
            default:
                return null;
        }
    })));
});

import { __assign, __read } from "tslib";
import React, { useContext, useCallback, useMemo, useState, } from "react";
import { FormattedMessage } from "react-intl";
import { FilterContext } from "@contexts/FilterContext";
import { Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import cn from "classnames";
import { usePopper } from "react-popper";
import { Portal } from "@components/common/Portal";
import { DateRangePicker } from "@components/common/DateRangePicker";
import format from "date-fns/format";
var DATE_FORMAT = "dd/MM/yyyy";
export var DateRangeFilter = React.memo(function () {
    var _a = useContext(FilterContext), appliedFilters = _a.appliedFilters, setAppliedFilter = _a.setAppliedFilter;
    var _b = __read(useState(false), 2), isPickerOpen = _b[0], setIsPickerOpen = _b[1];
    var _c = __read(useState(null), 2), buttonEl = _c[0], setButtonEl = _c[1];
    var _d = __read(useState(null), 2), optionsContainerEl = _d[0], setOptionsContainerEl = _d[1];
    var _e = usePopper(buttonEl, optionsContainerEl, {
        placement: "bottom-start",
        strategy: "fixed",
    }), styles = _e.styles, attributes = _e.attributes;
    var closePicker = useCallback(function () {
        setIsPickerOpen(false);
    }, []);
    var onButtonClick = useCallback(function (e) {
        e.preventDefault();
        setIsPickerOpen(function (prev) { return !prev; });
    }, []);
    var onDateRangeChange = useCallback(function (start, end) {
        setAppliedFilter({
            dateRange: { start: start, end: end },
        });
        closePicker();
    }, [closePicker, setAppliedFilter]);
    var isActive = useMemo(function () {
        return (appliedFilters.dateRange.start !== null ||
            appliedFilters.dateRange.end !== null);
    }, [appliedFilters]);
    var value = useMemo(function () {
        var _a = appliedFilters.dateRange, start = _a.start, end = _a.end;
        if (start !== null && end !== null) {
            return (React.createElement(FormattedMessage, { id: "filter.date_range.value.from_and_to", values: {
                    from: format(start, DATE_FORMAT),
                    to: format(end, DATE_FORMAT),
                } }));
        }
        else if (start !== null) {
            return (React.createElement(FormattedMessage, { id: "filter.date_range.value.from", values: {
                    from: format(start, DATE_FORMAT),
                } }));
        }
        else if (end !== null) {
            return (React.createElement(FormattedMessage, { id: "filter.date_range.value.to", values: {
                    to: format(end, DATE_FORMAT),
                } }));
        }
        return React.createElement(FormattedMessage, { id: "filter.date_range.placeholder" });
    }, [appliedFilters]);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { ref: setButtonEl, onClick: onButtonClick, type: "button", className: cn("relative", "w-full", "border", "rounded-md", "shadow-sm", "pl-3", "pr-10", "py-2", "text-left", "cursor-default", "sm:text-sm", isActive
                ? ["bg-orange-600", "border-orange-600"]
                : ["bg-white", "border-gray-300"]) },
            React.createElement("span", { className: cn("block truncate min-h-[1.25rem]", isActive
                    ? ["text-white", "font-bold"]
                    : ["text-gray-900", "font-normal"]) }, value),
            React.createElement("span", { className: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" },
                React.createElement(SelectorIcon, { className: cn("h-5 w-5", isActive ? "text-white" : "text-gray-400"), "aria-hidden": "true" }))),
        React.createElement(Portal, null,
            React.createElement(Transition, { as: "div", show: isPickerOpen, leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                React.createElement("div", __assign({ style: styles.popper }, attributes.popper, { ref: setOptionsContainerEl, className: "bg-white rounded-xl shadow-xl" }),
                    React.createElement(DateRangePicker, { start: appliedFilters.dateRange.start, end: appliedFilters.dateRange.end, onApply: onDateRangeChange, onCancel: closePicker }))))));
});

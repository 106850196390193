import { __awaiter, __generator, __read } from "tslib";
import React, { useState, useCallback, useContext } from "react";
import { IndustryExtractor } from "@shared/models/industry";
import { IndustryAPIContext } from "@shared/contexts/api";
import { MergeTagDialog } from "@components/common/MergeTagDialog";
import { useAppToast } from "@components/common/Toast";
export var MergeIndustryDialog = React.memo(function (_a) {
    var destination = _a.destination, onDone = _a.onDone, onClose = _a.onClose;
    var industryAPI = useContext(IndustryAPIContext);
    var showErrorToast = useAppToast().showErrorToast;
    var _b = __read(useState(false), 2), isMerging = _b[0], setIsMerging = _b[1];
    var onMergeTag = useCallback(function (source) { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!source) {
                        return [2 /*return*/];
                    }
                    setIsMerging(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, industryAPI.mergeIndustry(destination.id, {
                            source_id: source.id,
                        })];
                case 2:
                    _a.sent();
                    onDone();
                    onClose();
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _a.sent();
                    console.error("Failed to merge tag", err_1);
                    showErrorToast({
                        titleId: "merge_tag.error.title",
                        descriptionId: "merge_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 5];
                case 4:
                    setIsMerging(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [industryAPI, destination.id, onDone, onClose, showErrorToast]);
    return (React.createElement(MergeTagDialog, { isOpened: true, onClose: onClose, onMergeClick: onMergeTag, destinationId: destination.id, tagName: destination.name, fetch: industryAPI.listIndustries, isMerging: isMerging, keyExtractor: IndustryExtractor.id, labelExtractor: IndustryExtractor.name }));
});

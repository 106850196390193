import React, { useCallback, useRef, useEffect } from "react";
import cn from "classnames";
import { ColouredTag } from "@components/common/ColouredTag";
var TagSuggestionItemImpl = function (props) {
    var className = props.className, tag = props.tag, name = props.name, description = props.description, color = props.color, onSelect = props.onSelect, isSelected = props.isSelected;
    var onMouseDown = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onSelect(tag, name);
    }, [name, onSelect, tag]);
    var elRef = useRef(null);
    useEffect(function () {
        var _a;
        if (isSelected) {
            (_a = elRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
        }
    }, [isSelected]);
    return (React.createElement("button", { ref: elRef, type: "button", className: cn("px-4", "py-2", "flex", "items-center", "w-full", "hover:bg-gray-50", isSelected && "bg-gray-50", className), onMouseDown: onMouseDown },
        React.createElement(ColouredTag, { tag: name, colour: color }),
        React.createElement("div", { className: "px-3 flex-1 text-sm text-left text-gray-600 truncate font-normal" }, description)));
};
export var TagSuggestionItem = React.memo(TagSuggestionItemImpl);

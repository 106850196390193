export var DocumentGroupType;
(function (DocumentGroupType) {
    DocumentGroupType["Messages"] = "messages";
    DocumentGroupType["Files"] = "files";
})(DocumentGroupType || (DocumentGroupType = {}));
export var DocumentType;
(function (DocumentType) {
    DocumentType["Message"] = "message";
    DocumentType["Files"] = "files";
})(DocumentType || (DocumentType = {}));

import { __read } from "tslib";
import { ColorAPIContext } from "@shared/contexts/api/ColorAPIContext";
import { CountryAPIContext } from "@shared/contexts/api/CountryAPIContext";
import { IndustryAPIContext } from "@shared/contexts/api/IndustryAPIContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CompanyForm } from "../settings/forms/CompanyForm";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
import { CompanyAPIContext } from "@shared/contexts/api/CompanyAPIContext";
import { ROUTES } from "@router/routes";
import { useAppToast } from "../common/Toast";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { TagSettingRouterContext } from "@router/TagSettingRouter";
import { parseTagCreationErrorMessage } from "@shared/models/error";
import { useNavigation } from "@hooks/useNavigation";
export var CompanyTagCreationScreen = React.memo(function () {
    var colorAPI = useContext(ColorAPIContext);
    var countryAPI = useContext(CountryAPIContext);
    var industryAPI = useContext(IndustryAPIContext);
    var companyAPI = useContext(CompanyAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var refreshSidebar = useContext(TagSettingRouterContext).refreshSidebar;
    var intl = useIntl();
    var navigation = useNavigation();
    var showErrorToast = useAppToast().showErrorToast;
    var _a = __read(useState([]), 2), colors = _a[0], setColors = _a[1];
    var _b = __read(useState([]), 2), countries = _b[0], setCountries = _b[1];
    var _c = __read(useState([]), 2), industries = _c[0], setIndustries = _c[1];
    var _d = __read(useState({}), 2), additionalFields = _d[0], setAdditionalFields = _d[1];
    var _e = __read(useState(false), 2), isLoading = _e[0], setIsLoading = _e[1];
    var _f = __read(useState(false), 2), isSubmitting = _f[0], setIsSubmitting = _f[1];
    var handleSubmit = useCallback(function (formValues) {
        var _a, _b;
        setIsSubmitting(true);
        companyAPI
            .createCompany({
            name: formValues.tagName,
            fullName: !!formValues.fullName ? formValues.fullName.trim() : null,
            domains: !!formValues.domain
                ? formValues.domain.split(",").map(function (d) { return d.trim(); })
                : [],
            address: !!formValues.address ? formValues.address.trim() : null,
            countryIds: formValues.countries.data.map(function (c) { return c.id; }),
            industryId: formValues.industry.id,
            colorId: (_b = (_a = formValues.color) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
            additional: formValues.additional,
        })
            .then(function (company) {
            refreshSidebar();
            navigation.push(ROUTES.settings.tags.company.group_detail + "/" + company.id);
        })
            .catch(function (err) {
            var _a = __read(parseTagCreationErrorMessage(err), 2), titleId = _a[0], descriptionId = _a[1];
            showErrorToast({
                titleId: titleId,
                descriptionId: descriptionId,
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    }, [companyAPI, navigation, setIsSubmitting, showErrorToast, refreshSidebar]);
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.company.group_detail);
        }
    }, [navigation]);
    useEffect(function () {
        setIsLoading(true);
        Promise.all([
            colorAPI.getAllColors(),
            countryAPI.getAllCountries(),
            industryAPI.getAllIndustries(),
            tagGroupAPI.getTagGroupBySlug("company"),
        ])
            .then(function (_a) {
            var _b = __read(_a, 4), colorResp = _b[0], countryResp = _b[1], industryResp = _b[2], tagGroupResp = _b[3];
            setColors(colorResp.data);
            setCountries(countryResp.data);
            setIndustries(industryResp.data);
            setAdditionalFields(tagGroupResp.definition);
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        colorAPI,
        countryAPI,
        industryAPI,
        tagGroupAPI,
        navigation,
        handleGoBack,
        showErrorToast,
        setColors,
        setCountries,
        setIndustries,
        setAdditionalFields,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tags.form.create.title", values: {
                        type: intl.formatMessage({
                            id: "settings.tags.title.company",
                        }),
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(CompanyForm, { colors: colors, industries: industries, countries: countries, additionalFields: additionalFields, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleGoBack })))));
});

import { __read } from "tslib";
import StaffList from "@components/settings/StaffList";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { Router, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { ROUTES } from "./routes";
import cn from "classnames";
import { StaffDetailScreen } from "@components/screens/StaffDetailScreen";
import StaffEditScreen from "@components/screens/StaffEditScreen";
import { UserAPIContext } from "@shared/contexts/api";
import { useAppToast } from "@components/common/Toast";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { useDebounce } from "@hooks/useDebounce";
import ScreenRoute from "./ScreenRoute";
import { Permission } from "@shared/models/auth";
export var StaffSettingRouterContext = React.createContext(null);
var StaffSettingRouter = function () {
    var _a;
    var history = useHistory();
    var isRootMatch = (_a = useRouteMatch(ROUTES.settings.staff.root)) === null || _a === void 0 ? void 0 : _a.isExact;
    var listUsers = useContext(UserAPIContext).listUsers;
    var showErrorToast = useAppToast().showErrorToast;
    var searchErrorHandler = useCallback(function (e) {
        console.error(e);
        showErrorToast({
            titleId: "common.errors.data.fetch_failed.title",
            descriptionId: "common.errors.data.fetch_failed.description",
        }, {
            position: "bottom-center",
        });
    }, [showErrorToast]);
    var searchPaginationContext = useSearchPagination(listUsers, {
        page: 1,
        perPage: 20,
        isEnabled: "any",
        excludeSystem: true,
    }, searchErrorHandler, true);
    var search = searchPaginationContext.search;
    var _b = __read(useState(null), 2), searchValue = _b[0], setSearchValue = _b[1];
    var debouncedSearchValue = useDebounce(searchValue, 500);
    useEffect(function () {
        search(debouncedSearchValue !== null && debouncedSearchValue !== void 0 ? debouncedSearchValue : "", {
            isEnabled: "any",
            excludeSystem: true,
        });
    }, [debouncedSearchValue, search]);
    var refreshSidebar = useCallback(function () {
        search(debouncedSearchValue !== null && debouncedSearchValue !== void 0 ? debouncedSearchValue : "", {
            isEnabled: "any",
            excludeSystem: true,
        });
    }, [debouncedSearchValue, search]);
    var staffSettingRouterContextValue = useMemo(function () {
        return {
            refreshSidebar: refreshSidebar,
        };
    }, [refreshSidebar]);
    return (React.createElement(StaffSettingRouterContext.Provider, { value: staffSettingRouterContextValue },
        React.createElement(Router, { history: history },
            React.createElement("div", { className: "flex flex-row w-full overflow-y-hidden md:min-w-[77.5rem]" },
                React.createElement(StaffList, { className: cn("w-full md:w-auto md:min-w-[22.5rem] md:border-r md:border-gray-200 md:max-w-[22.5rem]", !isRootMatch && "hidden md:flex"), searchValue: searchValue, setSearchValue: setSearchValue, searchPaginationContext: searchPaginationContext }),
                React.createElement(Switch, null,
                    React.createElement(ScreenRoute, { path: ROUTES.settings.staff.staff_edit.toString(), requiredPermission: Permission.UsersUpdate },
                        React.createElement(StaffEditScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.staff.staff_detail.toString(), requiredPermission: Permission.UsersRead },
                        React.createElement(StaffDetailScreen, null)))))));
};
export default StaffSettingRouter;

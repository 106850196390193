import { RefreshIcon } from "@heroicons/react/outline";
import React, { useCallback, useEffect, useMemo } from "react";
import { ColouredTag } from "@components/common/ColouredTag";
import cn from "classnames";
export var ColorRandomizor = React.memo(function (props) {
    var className = props.className, colors = props.colors, currentColor = props.currentColor, onColorChange = props.onColorChange;
    var colorPool = useMemo(function () { return colors.filter(function (c) { return c.name !== (currentColor === null || currentColor === void 0 ? void 0 : currentColor.name); }); }, [currentColor, colors]);
    var onRefreshColor = useCallback(function () {
        onColorChange(colorPool[Math.floor(Math.random() * colorPool.length)]);
    }, [colorPool, onColorChange]);
    useEffect(function () {
        if (currentColor === null) {
            onRefreshColor();
        }
    }, [currentColor, onRefreshColor]);
    return (React.createElement("div", { className: cn("flex flex-row items-center", className) },
        React.createElement("button", { type: "button", className: "mr-3", onClick: onRefreshColor },
            React.createElement(RefreshIcon, { className: "h-4 w-4" })),
        currentColor && (React.createElement(ColouredTag, { tag: currentColor.name, colour: currentColor }))));
});

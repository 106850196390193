import { __read } from "tslib";
import React, { useCallback, useState } from "react";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import { Spinner } from "@components/common/Spinner";
import { InfiniteScroll } from "./InfiniteScroll";
function SkeletonTableImpl(props) {
    var columns = props.columns, className = props.className, labelClassName = props.labelClassName, cellClassName = props.cellClassName;
    return (React.createElement("div", { className: cn("flex flex-1 flex-col min-h-0 isolate", className) },
        React.createElement("div", { className: "-my-2 overflow-x-auto" },
            React.createElement("div", { className: "py-2 h-full align-middle inline-block min-w-full" },
                React.createElement("div", { className: "shadow h-full overflow-auto border-b border-gray-200 sm:rounded-lg" },
                    React.createElement("table", { className: "min-w-full w-max h-full  divide-y divide-gray-200" },
                        React.createElement("thead", null,
                            React.createElement("tr", null, columns.map(function (c) {
                                var _a;
                                return (React.createElement("th", { key: c.titleId, scope: "col", className: cn("sticky top-0 z-20 bg-gray-50 px-6 py-3 whitespace-nowrap uppercase text-left", (_a = c.labelClassName) !== null && _a !== void 0 ? _a : labelClassName) },
                                    React.createElement(FormattedMessage, { id: c.titleId })));
                            }))),
                        React.createElement("tbody", { className: " bg-white divide-y divide-gray-200 overflow-y-auto" }, new Array(7).fill(null).map(function (_, index) { return (React.createElement("tr", { key: index }, columns.map(function (c) {
                            var _a;
                            return (React.createElement("td", { key: c.titleId, className: cn("px-6 py-4", (_a = c.className) !== null && _a !== void 0 ? _a : cellClassName) },
                                React.createElement("div", { className: "animate-pulse  w-full p-4 bg-gray-200 rounded-full" })));
                        }))); }))))))));
}
function TableImpl(props) {
    var data = props.data, columns = props.columns, className = props.className, labelClassName = props.labelClassName, cellClassName = props.cellClassName, actionClassName = props.actionClassName, actions = props.actions, hasMore = props.hasMore, isFetchingMore = props.isFetchingMore, onItemClick = props.onItemClick, onFetchMore = props.onFetchMore, prependedElement = props.prependedElement, PrependedComponent = props.PrependedComponent, _a = props.noResultMessageId, noResultMessageId = _a === void 0 ? "settings.tags.table.no_records" : _a;
    var _b = __read(useState(null), 2), scrollEle = _b[0], setScrollEle = _b[1];
    var updateScrollEle = useCallback(function (ele) {
        if (ele) {
            setScrollEle(ele);
        }
    }, []);
    return (React.createElement("div", { className: cn("flex flex-1 flex-col min-h-0", className) },
        React.createElement("div", { className: "overflow-x-auto flex items-stretch" },
            React.createElement("div", { className: "flex items-stretch align-middle inline-block min-w-full" },
                React.createElement("div", { ref: updateScrollEle, className: "w-full shadow flex items-stretch overflow-auto border-b border-gray-200 sm:rounded-lg" },
                    React.createElement("table", { className: "isolate min-w-full w-max divide-y divide-gray-200" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                columns.map(function (c) {
                                    var _a;
                                    return (React.createElement("th", { key: c.titleId, ref: c.headerRef, scope: "col", className: cn("sticky top-0 z-20 bg-gray-50 px-6 py-3 whitespace-nowrap uppercase text-left", (_a = c.labelClassName) !== null && _a !== void 0 ? _a : labelClassName) },
                                        React.createElement(FormattedMessage, { id: c.titleId })));
                                }), actions === null || actions === void 0 ? void 0 :
                                actions.map(function (a, index) { return (React.createElement("th", { key: index, ref: a.headerRef, scope: "col", className: "sticky top-0 z-20 bg-gray-50 relative px-6 py-3" })); }))),
                        React.createElement("tbody", { className: "bg-white  overflow-y-auto" },
                            PrependedComponent !== undefined && React.createElement(PrependedComponent, null),
                            prependedElement && (React.createElement("tr", null,
                                React.createElement("td", { colSpan: 99 }, prependedElement))),
                            data.length === 0 && (React.createElement("tr", null,
                                React.createElement("td", { colSpan: 99 },
                                    React.createElement("div", { className: "flex items-center justify-center py-7" },
                                        React.createElement("span", { className: "text-sm leading-5 font-400 text-gray-500" },
                                            React.createElement(FormattedMessage, { id: noResultMessageId })))))),
                            data.length !== 0 &&
                                data.map(function (d, index) { return (React.createElement("tr", { key: index, className: cn(!!onItemClick && "cursor-pointer", "odd:bg-white even:bg-gray-50"), onClick: function () { return onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick(d); } },
                                    columns.map(function (c) {
                                        var _a;
                                        return (React.createElement("td", { key: c.titleId, className: cn("px-6 py-4 max-w-[15rem]", (_a = c.className) !== null && _a !== void 0 ? _a : cellClassName) }, !!c.render ? (c.render(c.accessor(d), d)) : (React.createElement("span", { className: "line-clamp-1" }, c.accessor(d)))));
                                    }), actions === null || actions === void 0 ? void 0 :
                                    actions.map(function (a, index) {
                                        var _a;
                                        return (React.createElement("td", { key: "action-" + index, className: cn("px-6 py-4 h-full", (_a = a.className) !== null && _a !== void 0 ? _a : actionClassName) }, a.render(d)));
                                    }))); }),
                            !!onFetchMore && hasMore && (React.createElement(InfiniteScroll, { onFetchMore: onFetchMore, containerElement: scrollEle },
                                React.createElement("tr", null,
                                    React.createElement("td", { colSpan: 99 }, isFetchingMore && (React.createElement("div", { className: "py-2 flex items-center justify-center" },
                                        React.createElement(Spinner, { size: "large" }))))))))))))));
}
var Table = React.memo(TableImpl);
var SkeletonTable = React.memo(SkeletonTableImpl);
export { Table, SkeletonTable };

import { __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Router, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { ROUTES } from "./routes";
import OrganizationDepartmentDetailScreen from "@components/screens/OrganizationDepartmentDetailScreen";
import OrganizationDepartmentList from "@components/settings/OrganizationDepartmentList";
import cn from "classnames";
import { DepartmentAPIContext } from "@shared/contexts/api/DepartmentAPIContext";
import { AuthContext } from "@contexts/AuthContext";
import { useAppToast } from "@components/common/Toast";
import { useEffectOnce } from "@hooks/useEffectOnce";
import OrganizationDepartmentCreationScreen from "@components/screens/OrganizationDepartmentCreationScreen";
import { useNavigation } from "@hooks/useNavigation";
import ScreenRoute from "./ScreenRoute";
import { Permission, Identity } from "@shared/models/auth";
export var OrganizationSettingRouterContext = React.createContext(null);
var OrganizationSettingRouter = function () {
    var _a;
    var history = useHistory();
    var me = useContext(AuthContext).me;
    var isRootMatch = (_a = useRouteMatch(ROUTES.settings.organization.root)) === null || _a === void 0 ? void 0 : _a.isExact;
    var departmentAPI = useContext(DepartmentAPIContext);
    var _b = __read(useState([]), 2), departmentTrees = _b[0], setDepartmentTrees = _b[1];
    var showErrorToast = useAppToast().showErrorToast;
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var fetchDepartmentTree = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, departmentAPI.getDepartmentTree({
                            isEnabled: (me === null || me === void 0 ? void 0 : me.identity) === Identity.SystemAdmin ? "any" : "true",
                        })];
                case 2:
                    res = _a.sent();
                    setDepartmentTrees(res.trees);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.error(e_1);
                    showErrorToast({
                        titleId: "common.errors.data.fetch_failed.title",
                        descriptionId: "common.errors.data.fetch_failed.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [departmentAPI, me === null || me === void 0 ? void 0 : me.identity, showErrorToast]);
    useEffectOnce(function () {
        fetchDepartmentTree()
            .then(function () { })
            .catch(console.error);
    }, function () { return true; });
    var refreshSidebar = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchDepartmentTree()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [fetchDepartmentTree]);
    var organizationSettingRouterContextValue = useMemo(function () {
        return {
            refreshSidebar: refreshSidebar,
        };
    }, [refreshSidebar]);
    var navigation = useNavigation();
    var onClickCreate = useCallback(function () {
        navigation.push(ROUTES.settings.organization.add);
    }, [navigation]);
    return (React.createElement(OrganizationSettingRouterContext.Provider, { value: organizationSettingRouterContextValue },
        React.createElement(Router, { history: history },
            React.createElement("div", { className: "flex flex-row w-full overflow-y-hidden md:min-w-[77.5rem]" },
                React.createElement(OrganizationDepartmentList, { departmentTrees: departmentTrees, className: cn("w-full md:w-auto md:min-w-[22.5rem] md:border-r md:border-gray-200", !isRootMatch && "hidden md:flex"), onClickCreate: onClickCreate, isLoading: isLoading }),
                React.createElement(Switch, null,
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.settings.organization.add, requiredPermission: Permission.DepartmentsCreate },
                        React.createElement(OrganizationDepartmentCreationScreen, null)),
                    React.createElement(ScreenRoute, { path: ROUTES.settings.organization.department_detail, requiredPermission: Permission.DepartmentsRead },
                        React.createElement(OrganizationDepartmentDetailScreen, null)))))));
};
export default OrganizationSettingRouter;

import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useNavigation } from "@hooks/useNavigation";
import { ROUTES } from "@router/routes";
import { APIResponseError, UserAPIContext } from "@shared/contexts/api";
import { DepartmentAPIContext } from "@shared/contexts/api/DepartmentAPIContext";
import React, { useCallback, useState, useContext, useEffect, useMemo, } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { PrimaryButton } from "../common/PrimaryButton";
import { SearchBar } from "../common/SearchBar";
import { SkeletonTable, Table, } from "../common/Table";
import cn from "classnames";
import { Persona, PersonaSize } from "@fluentui/react";
import { extractInitials } from "@utils/avatar";
import { useAppToast } from "../common/Toast";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { useDebounce } from "@hooks/useDebounce";
import DepartmentUserForm from "../settings/forms/DepartmentUserForm";
import { OrganizationSettingRouterContext } from "@router/OrganizationSettingRouter";
import { parseErrorMessage } from "@shared/models/error";
import { useUserLabel } from "@hooks/useUserLabel";
import RequirePermission from "../common/RequirePermission";
import { Permission } from "@shared/models/auth";
import { useDepartmentLabel } from "@hooks/useDepartmentLabel";
var MAX_USER_SUGGESTION_COUNT = 10;
var UserInfoCell = React.memo(function (props) {
    var _a, _b, _c;
    var user = props.user, classNames = props.classNames;
    var userLabel = useUserLabel();
    return (React.createElement(Link, { to: ROUTES.settings.staff.staff_detail.format(user.id), className: cn(classNames, "flex flex-row") },
        React.createElement(Persona, { className: "mr-4", imageUrl: (_b = (_a = user.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined, imageInitials: extractInitials((_c = user.fullName) !== null && _c !== void 0 ? _c : user.username, 2), hidePersonaDetails: true, size: PersonaSize.size40 }),
        React.createElement("div", { className: "flex flex-col min-w-0" },
            React.createElement("h5", { className: "text-sm leading-5 font-medium text-indigo-700 line-clamp-1 whitespace-no-warp overflow-ellipsis break-all" }, userLabel(user)),
            React.createElement("h6", { className: "text-sm leading-5 font-normal text-gray-500 line-clamp-1 whitespace-no-warp overflow-ellipsis" }, user.emails.join(", ")))));
});
var OrganizationDepartmentDetailScreen = function () {
    var _a;
    var departmentId = useParams().departmentId;
    var navigation = useNavigation();
    var onGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.organization.root);
        }
    }, [navigation]);
    var _b = __read(useState(null), 2), departmentSubtree = _b[0], setDepartmentSubtree = _b[1];
    var flattenedDepartmentSubtree = useMemo(function () {
        if (departmentSubtree == null)
            return [];
        var toBeVisited = [departmentSubtree];
        var foundNodes = [];
        while (toBeVisited.length > 0) {
            var node = toBeVisited.shift();
            if (node == null) {
                continue;
            }
            toBeVisited.unshift.apply(toBeVisited, __spreadArray([], __read(node.children)));
            foundNodes.push(node);
        }
        return foundNodes;
    }, [departmentSubtree]);
    var getDepartmentTree = useContext(DepartmentAPIContext).getDepartmentTree;
    var showErrorToast = useAppToast().showErrorToast;
    var _c = __read(useState(false), 2), isAddStaffFormOpen = _c[0], setIsAddStaffFormOpen = _c[1];
    useEffect(function () {
        // department id changed
        setIsAddStaffFormOpen(false);
        if (departmentId == null) {
            return;
        }
        getDepartmentTree({
            isEnabled: "any",
        })
            .then(function (res) {
            // find subtree with id
            setDepartmentSubtree(null);
            var toBeVisited = res.trees;
            while (toBeVisited.length > 0) {
                var node = toBeVisited.shift();
                if (node == null) {
                    continue;
                }
                if (node.id.toString(10) === departmentId) {
                    // Found subtree
                    setDepartmentSubtree(node);
                    break;
                }
                // Visit children
                toBeVisited.unshift.apply(toBeVisited, __spreadArray([], __read(node.children)));
            }
        })
            .catch(function (e) {
            console.error(e);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
        });
    }, [departmentId, getDepartmentTree, showErrorToast]);
    var searchErrorHandler = useCallback(function (e) {
        console.error(e);
        showErrorToast({
            titleId: "common.errors.data.fetch_failed.title",
            descriptionId: "common.errors.data.fetch_failed.description",
        }, {
            position: "bottom-center",
        });
    }, [showErrorToast]);
    var userAPI = useContext(UserAPIContext);
    var _d = useSearchPagination(userAPI.listUsers, {
        page: 1,
        perPage: 20,
    }, searchErrorHandler, true), users = _d.data, totalCount = _d.totalCount, isInitializing = _d.isInitializing, isFetchingMore = _d.isFetchingMore, hasMore = _d.hasMore, nextPage = _d.nextPage, search = _d.search;
    var _e = __read(useState(users), 2), createdUsers = _e[0], setCreatedUsers = _e[1];
    var _users = useMemo(function () {
        return __spreadArray(__spreadArray([], __read(createdUsers)), __read(users.filter(function (u) { return !createdUsers.find(function (c) { return c.id === u.id; }); })));
    }, [createdUsers, users]);
    var _f = __read(useState(null), 2), searchValue = _f[0], setSearchValue = _f[1];
    var debouncedSearchValue = useDebounce(searchValue, 500);
    // reset search state on department change
    useEffect(function () {
        setSearchValue(null);
    }, [departmentId]);
    useEffect(function () {
        if (flattenedDepartmentSubtree.length > 0) {
            setCreatedUsers([]); // reset prepended records before refresh
            search(debouncedSearchValue !== null && debouncedSearchValue !== void 0 ? debouncedSearchValue : "", {
                departmentIds: flattenedDepartmentSubtree.map(function (node) { return node.id; }),
            });
        }
    }, [debouncedSearchValue, flattenedDepartmentSubtree, search]);
    var intl = useIntl();
    var onAddStaff = useCallback(function () {
        setIsAddStaffFormOpen(true);
    }, []);
    var onSearchValueChange = useCallback(function (ev) {
        setSearchValue(ev.target.value);
    }, []);
    var mapRoleToMessageID = useCallback(function (role) {
        switch (role) {
            case "head":
                return "settings.organization.departmentDetails.roles.head";
            case "admin":
                return "settings.organization.departmentDetails.roles.admin";
            default:
                return "settings.organization.departmentDetails.roles.staff";
        }
    }, []);
    var departmentLabel = useDepartmentLabel();
    var staffTableColumns = useMemo(function () { return [
        {
            titleId: "settings.organization.departmentDetails.tableHeader.staff",
            accessor: function (x) { var _a; return (_a = x.fullName) !== null && _a !== void 0 ? _a : x.username; },
            render: function (_, user) {
                return React.createElement(UserInfoCell, { user: user });
            },
        },
        {
            titleId: "settings.organization.departmentDetails.tableHeader.subDepartment",
            accessor: function (x) {
                return x.departments
                    .filter(function (d) {
                    return d.id !== (departmentSubtree === null || departmentSubtree === void 0 ? void 0 : departmentSubtree.id) &&
                        flattenedDepartmentSubtree.find(function (y) { return y.id === d.id; });
                })
                    .map(function (d) { return departmentLabel(d); })
                    .join(", ");
            },
        },
        {
            titleId: "settings.organization.departmentDetails.tableHeader.role",
            accessor: function (x) {
                var message = x.departmentRoles
                    .filter(function (r) {
                    return flattenedDepartmentSubtree.find(function (y) { return y.id === r.departmentId; }) != null;
                })
                    .map(function (r) {
                    return intl.formatMessage({
                        id: mapRoleToMessageID(r.role),
                    });
                })
                    .join(", ");
                return message.length > 0
                    ? message
                    : intl.formatMessage({
                        id: "settings.organization.departmentDetails.roles.staff",
                    });
            },
        },
    ]; }, [
        departmentLabel,
        departmentSubtree === null || departmentSubtree === void 0 ? void 0 : departmentSubtree.id,
        flattenedDepartmentSubtree,
        intl,
        mapRoleToMessageID,
    ]);
    var staffTableActions = useMemo(function () { return []; }, []);
    var fetchUserSuggestion = useCallback(function (search) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, userAPI
                    .listUsers({
                    page: 1,
                    perPage: MAX_USER_SUGGESTION_COUNT,
                    search: search,
                    isEnabled: "true",
                    excludeSystem: true,
                })
                    .then(function (resp) { return resp.data; })];
        });
    }); }, [userAPI]);
    var createDepartmentUser = useContext(DepartmentAPIContext).createDepartmentUser;
    var refreshSidebar = useContext(OrganizationSettingRouterContext).refreshSidebar;
    var onCreateFormSubmit = useCallback(function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
        var _role, res_1, e_1, _a, titleId, descriptionId;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (formValues.departmentID == null ||
                        formValues.userID == null ||
                        formValues.role == null) {
                        return [2 /*return*/];
                    }
                    _role = formValues.role !== "staff" ? formValues.role : undefined;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createDepartmentUser(formValues.departmentID, {
                            userID: formValues.userID,
                            role: _role,
                        })];
                case 2:
                    res_1 = _b.sent();
                    setCreatedUsers(function (prev) { return __spreadArray([
                        res_1.user
                    ], __read(prev.filter(function (u) { return u.id !== res_1.user.id; }))); });
                    setIsAddStaffFormOpen(false);
                    refreshSidebar();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    console.error(e_1);
                    if (e_1 instanceof APIResponseError) {
                        _a = __read(parseErrorMessage(e_1), 2), titleId = _a[0], descriptionId = _a[1];
                        showErrorToast({
                            titleId: titleId,
                            descriptionId: descriptionId,
                        }, {
                            position: "bottom-center",
                        });
                        return [2 /*return*/];
                    }
                    showErrorToast({
                        titleId: "common.errors.data.submit_failed.title",
                        descriptionId: "common.errors.data.submit_failed.description",
                    }, {
                        position: "bottom-center",
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [createDepartmentUser, refreshSidebar, showErrorToast]);
    var onCreateFormCancel = useCallback(function () {
        setIsAddStaffFormOpen(false);
    }, []);
    var DepartmentUserFormComponent = useCallback(function () {
        if (departmentSubtree == null)
            return null;
        return (React.createElement(DepartmentUserForm, { departmentOptions: flattenedDepartmentSubtree, currentDepartmentID: departmentSubtree.id, fetchUserSuggestion: fetchUserSuggestion, onSubmit: onCreateFormSubmit, onCancel: onCreateFormCancel }));
    }, [
        departmentSubtree,
        fetchUserSuggestion,
        flattenedDepartmentSubtree,
        onCreateFormCancel,
        onCreateFormSubmit,
    ]);
    return (React.createElement("div", { className: "flex flex-1 min-w-0 flex-col" },
        React.createElement("div", { className: "flex flex-row bg-white px-1" },
            React.createElement("button", { type: "button", className: "md:hidden", onClick: onGoBack },
                React.createElement(ChevronLeftIcon, { className: "h-9" })),
            React.createElement("div", { className: "px-4 py-6 bg-white" },
                React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900 line-clamp-1 whitespace-no-warp overflow-ellipsis" }, (_a = departmentSubtree === null || departmentSubtree === void 0 ? void 0 : departmentSubtree.name) !== null && _a !== void 0 ? _a : ""))),
        React.createElement("div", { className: "flex flex-col py-[1.125rem] px-4 md:items-center md:flex-row" },
            React.createElement("div", { className: "flex-1 flex-col" },
                React.createElement("h1", { className: "text-base leading-6 font-bold text-gray-900" },
                    React.createElement(FormattedMessage, { id: "settings.organization.departmentDetails.title" })),
                React.createElement("span", { className: "text-sm leading-5 font-normal text-gray-600" },
                    React.createElement(FormattedMessage, { id: "settings.organization.departmentDetails.staffCount", values: { count: totalCount } }))),
            React.createElement("div", { className: " flex flex-col space-y-2 items-start justify-center mt-[0.375rem] md:mt-0 md:space-y-0 md:flex-row md:space-x-2 md:items-center" },
                React.createElement(SearchBar, { containerClassName: "flex-1 min-w-[20rem] bg-white rounded-md border border-gray-300", searchIconClassName: "text-gray-400", inputClassName: "text-gray-500 placeholder-gray-500 md:text-sm", placeholder: intl.formatMessage({
                        id: "settings.organization.departmentDetails.searchPlaceholder",
                    }), value: searchValue !== null && searchValue !== void 0 ? searchValue : "", onChange: onSearchValueChange }),
                React.createElement(RequirePermission, { permission: Permission.DepartmentsUpdate },
                    React.createElement(PrimaryButton, { size: "sm", onClick: onAddStaff },
                        React.createElement(FormattedMessage, { id: "settings.organization.departmentDetails.addStaffToDepartment" }))))),
        React.createElement("div", { className: "h-full flex flex-col overflow-x-scroll px-4" }, isInitializing || departmentSubtree == null ? (React.createElement(SkeletonTable, { labelClassName: "text-xs leading-4 font-medium text-gray-500", cellClassName: "text-sm leading-5 font-normal text-gray-500", columns: staffTableColumns })) : (React.createElement(Table, { data: _users, className: "md:min-w-min", labelClassName: cn("text-xs leading-4 font-medium text-gray-500"), cellClassName: "text-sm leading-5 font-normal text-gray-500", columns: staffTableColumns, actions: staffTableActions, onFetchMore: nextPage, hasMore: hasMore, isFetchingMore: isFetchingMore, PrependedComponent: isAddStaffFormOpen ? DepartmentUserFormComponent : undefined })))));
};
export default OrganizationDepartmentDetailScreen;

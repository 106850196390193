import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { listOtherTagRequestSchema, listOtherTagResponseSchema, createOtherTagRequestSchema, otherTagSchema, editOtherTagRequestSchema, } from "@shared/models/api/other_tag_api";
export var OtherTagAPIContext = React.createContext(undefined);
export var OtherTagAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getOtherTag = useCallback(function (tagId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("other_tags/" + tagId)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, otherTagSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var listOtherTags = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listOtherTagRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("other_tags", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listOtherTagResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var createOtherTag = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = createOtherTagRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("other_tags", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, otherTagSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updateOtherTag = useCallback(function (id, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = editOtherTagRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.put("other_tags/" + id, body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, otherTagSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var enableOtherTag = useCallback(function (tagId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("other_tags/" + tagId + "/enable", {})];
        });
    }); }, [apiContext]);
    var disableOtherTag = useCallback(function (tagId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("other_tags/" + tagId + "/disable", {})];
        });
    }); }, [apiContext]);
    var deleteOtherTag = useCallback(function (tagId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.delete("other_tags/" + tagId)];
        });
    }); }, [apiContext]);
    var mergeOtherTag = useCallback(function (otherTagId, sourceId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("other_tags/" + otherTagId + "/merge", {
                    source_id: sourceId,
                })];
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            listOtherTags: listOtherTags,
            createOtherTag: createOtherTag,
            getOtherTag: getOtherTag,
            updateOtherTag: updateOtherTag,
            enableOtherTag: enableOtherTag,
            disableOtherTag: disableOtherTag,
            deleteOtherTag: deleteOtherTag,
            mergeOtherTag: mergeOtherTag,
        };
    }, [
        listOtherTags,
        createOtherTag,
        getOtherTag,
        updateOtherTag,
        enableOtherTag,
        disableOtherTag,
        deleteOtherTag,
        mergeOtherTag,
    ]);
    return (React.createElement(OtherTagAPIContext.Provider, { value: value }, children));
});

import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { listContactRequestSchema, listContactResponseSchema, createContactRequestSchema, contactSchema, editContactRequestSchema, assignCompanyRequestSchema, assignCompanyResponseSchema, listContactEmailRequestSchema, listContactEmailResponseSchema, } from "@shared/models/api/contact_api";
export var ContactAPIContext = React.createContext(undefined);
export var ContactAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getContact = useCallback(function (contactId) { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("contacts/" + contactId)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, contactSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var listContacts = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listContactRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("contacts", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listContactResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var listContactEmails = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var params, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = listContactEmailRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.get("contacts/emails", params)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, listContactEmailResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var createContact = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = createContactRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("contacts", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, contactSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var updateContact = useCallback(function (id, request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = editContactRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.put("contacts/" + id, body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, contactSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var assignCompany = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        var body, respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    body = assignCompanyRequestSchema.validateSync(request);
                    return [4 /*yield*/, apiContext.post("contacts/assign_company", body)];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, assignCompanyResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var enableContact = useCallback(function (contactId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("contacts/" + contactId + "/enable", {})];
        });
    }); }, [apiContext]);
    var disableContact = useCallback(function (contactId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("contacts/" + contactId + "/disable", {})];
        });
    }); }, [apiContext]);
    var deleteContact = useCallback(function (contactId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.delete("contacts/" + contactId)];
        });
    }); }, [apiContext]);
    var mergeContact = useCallback(function (contactId, sourceId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiContext.post("contacts/" + contactId + "/merge", {
                    source_id: sourceId,
                })];
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            listContacts: listContacts,
            listContactEmails: listContactEmails,
            createContact: createContact,
            getContact: getContact,
            updateContact: updateContact,
            assignCompany: assignCompany,
            enableContact: enableContact,
            disableContact: disableContact,
            deleteContact: deleteContact,
            mergeContact: mergeContact,
        };
    }, [
        listContacts,
        listContactEmails,
        createContact,
        getContact,
        updateContact,
        assignCompany,
        enableContact,
        disableContact,
        deleteContact,
        mergeContact,
    ]);
    return (React.createElement(ContactAPIContext.Provider, { value: value }, children));
});

import { __assign, __awaiter, __extends, __generator } from "tslib";
import React, { useContext } from "react";
import memoize from "memoize-one";
import qs from "query-string";
import EventEmitter from "events";
import { AuthContext } from "@shared/contexts/AuthContext";
import { APIContext, } from "./APIContext";
import { AuthAPIContextProvider } from "./AuthAPIContext";
import { CompanyAPIContextProvider } from "./CompanyAPIContext";
import { ContactAPIContextProvider } from "./ContactAPIContext";
import { IndustryAPIContextProvider } from "./IndustryAPIContext";
import { DealAPIContextProvider } from "./DealAPIContext";
import { OtherTagAPIContextProvider } from "./OtherTagAPIContext";
import { PriorityAPIContextProvider } from "./PriorityAPIContext";
import { RegionAPIContextProvider } from "./RegionAPIContext";
import { ColorAPIContextProvider } from "./ColorAPIContext";
import { CountryAPIContextProvider } from "./CountryAPIContext";
import { FileAPIContextProvider } from "./FileAPIContext";
import { UserAPIContextProvider } from "./UserAPIContext";
import { MessageAPIContextProvider } from "./MessageAPIContext";
import { DepartmentAPIContextProvider } from "./DepartmentAPIContext";
import { TagGroupAPIContextProvider } from "./TagGroupAPIContext";
import { TagAPIContextProvider } from "./TagAPIContext";
import { DocumentAPIContextProvider } from "./DocumentAPIContext";
import { ThreadAPIContextProvider } from "./ThreadAPIContext";
import { AllTagAPIContextProvider } from "./AllTagAPIContext";
import { APIAuthenticationError, APIResponseError } from "./errors";
var APIContextImpl = /** @class */ (function (_super) {
    __extends(APIContextImpl, _super);
    function APIContextImpl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.eventEmitter = new EventEmitter();
        _this.get = function (path, queryParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var _a, requireAuthentication;
                return __generator(this, function (_b) {
                    _a = options.requireAuthentication, requireAuthentication = _a === void 0 ? true : _a;
                    return [2 /*return*/, this.request(path, {
                            method: "GET",
                            queryParams: queryParams,
                            requireAuthentication: requireAuthentication,
                        })];
                });
            });
        };
        _this.post = function (path, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var _a, requireAuthentication;
                return __generator(this, function (_b) {
                    _a = options.requireAuthentication, requireAuthentication = _a === void 0 ? true : _a;
                    return [2 /*return*/, this.request(path, { method: "POST", body: body, requireAuthentication: requireAuthentication })];
                });
            });
        };
        _this.put = function (path, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var _a, requireAuthentication;
                return __generator(this, function (_b) {
                    _a = options.requireAuthentication, requireAuthentication = _a === void 0 ? true : _a;
                    return [2 /*return*/, this.request(path, { method: "PUT", body: body, requireAuthentication: requireAuthentication })];
                });
            });
        };
        _this.delete = function (path, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var _a, requireAuthentication;
                return __generator(this, function (_b) {
                    _a = options.requireAuthentication, requireAuthentication = _a === void 0 ? true : _a;
                    return [2 /*return*/, this.request(path, {
                            method: "DELETE",
                            body: body,
                            requireAuthentication: requireAuthentication,
                        })];
                });
            });
        };
        _this.contextValue = memoize(function () {
            return {
                get: _this.get,
                post: _this.post,
                put: _this.put,
                delete: _this.delete,
                eventEmitter: _this.eventEmitter,
            };
        });
        return _this;
    }
    APIContextImpl.prototype.request = function (path, options) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, accessToken, apiEndpoint, queryParams, url, headers, resp, e, json;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, accessToken = _a.accessToken, apiEndpoint = _a.apiEndpoint;
                        queryParams = options.queryParams
                            ? qs.stringify(options.queryParams, {
                                skipNull: true,
                                skipEmptyString: true,
                            })
                            : null;
                        url = apiEndpoint + "/" + path + (queryParams ? "?" + queryParams.toString() : "");
                        headers = {
                            "Content-Type": "application/json; charset=utf-8",
                        };
                        if (accessToken && options.requireAuthentication) {
                            headers["Authorization"] = "Bearer " + accessToken;
                        }
                        return [4 /*yield*/, fetch(url, {
                                method: options.method,
                                headers: headers,
                                body: options.body ? JSON.stringify(options.body) : undefined,
                            })];
                    case 1:
                        resp = _b.sent();
                        if (resp.status !== 200) {
                            e = void 0;
                            if (resp.status === 401 && options.requireAuthentication) {
                                e = new APIAuthenticationError(resp);
                            }
                            else {
                                e = new APIResponseError(resp);
                            }
                            this.eventEmitter.emit("error", e);
                            throw e;
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        json = _b.sent();
                        return [2 /*return*/, json];
                }
            });
        });
    };
    APIContextImpl.prototype.render = function () {
        return (React.createElement(APIContext.Provider, { value: this.contextValue() },
            React.createElement(AuthAPIContextProvider, null,
                React.createElement(CompanyAPIContextProvider, null,
                    React.createElement(ContactAPIContextProvider, null,
                        React.createElement(IndustryAPIContextProvider, null,
                            React.createElement(DealAPIContextProvider, null,
                                React.createElement(OtherTagAPIContextProvider, null,
                                    React.createElement(PriorityAPIContextProvider, null,
                                        React.createElement(RegionAPIContextProvider, null,
                                            React.createElement(ColorAPIContextProvider, null,
                                                React.createElement(CountryAPIContextProvider, null,
                                                    React.createElement(FileAPIContextProvider, null,
                                                        React.createElement(UserAPIContextProvider, null,
                                                            React.createElement(MessageAPIContextProvider, null,
                                                                React.createElement(TagGroupAPIContextProvider, null,
                                                                    React.createElement(DepartmentAPIContextProvider, null,
                                                                        React.createElement(TagAPIContextProvider, null,
                                                                            React.createElement(DocumentAPIContextProvider, null,
                                                                                React.createElement(ThreadAPIContextProvider, null,
                                                                                    React.createElement(AllTagAPIContextProvider, null, this.props.children)))))))))))))))))))));
    };
    return APIContextImpl;
}(React.PureComponent));
export var APIContextProvider = React.memo(function (props) {
    var authContext = useContext(AuthContext);
    return React.createElement(APIContextImpl, __assign({}, authContext, props));
});

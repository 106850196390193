import { __assign, __awaiter, __generator, __read } from "tslib";
import { RegionAPIContext } from "@shared/contexts/api/RegionAPIContext";
import { useNavigation } from "@hooks/useNavigation";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { ROUTES } from "@router/routes";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { DefaultTagGroupDetail } from "../settings/DefaultTagGroupDetail";
import { TagContextMenu } from "../settings/TagContextMenu";
import cn from "classnames";
import { useAppToast } from "../common/Toast";
import { MergeRegionDialog } from "@components/mergeTag/MergeRegionDialog";
import { TagGroupSlug } from "@shared/models/tag";
export var RegionTagGroupDetailScreen = React.memo(function () {
    var navigation = useNavigation();
    var regionAPI = useContext(RegionAPIContext);
    var _a = useAppToast(), showErrorToast = _a.showErrorToast, showSuccessToast = _a.showSuccessToast;
    var _b = __read(useState({}), 2), enableStates = _b[0], setEnableStates = _b[1];
    var onGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.root);
        }
    }, [navigation]);
    var onFetchListItemError = useCallback(function () {
        showErrorToast({
            titleId: "common.errors.data.fetch_failed.title",
            descriptionId: "common.errors.data.fetch_failed.description",
        }, {
            position: "bottom-center",
        });
        onGoBack();
    }, [onGoBack, showErrorToast]);
    var _c = useSearchPagination(regionAPI.listRegions, {
        page: 1,
        perPage: 100,
    }, onFetchListItemError), data = _c.data, totalCount = _c.totalCount, isInitializing = _c.isInitializing, isFetchingMore = _c.isFetchingMore, hasMore = _c.hasMore, nextPage = _c.nextPage, search = _c.search, refresh = _c.refresh;
    var onAddNewRegion = useCallback(function () {
        navigation.push(ROUTES.settings.tags.region.add);
    }, [navigation]);
    var onEditGroup = useCallback(function () {
        navigation.push(ROUTES.settings.tags.region.edit_group);
    }, [navigation]);
    var onEditTag = useCallback(function (region) {
        navigation.push(ROUTES.settings.tags.region.group_detail + "/" + region.id + "/edit");
    }, [navigation]);
    var onEnableTag = useCallback(function (region) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setEnableStates(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[region.id] = true, _a)));
            });
            return [2 /*return*/, regionAPI
                    .enableRegion(region.id)
                    .then(function () {
                    showSuccessToast({
                        titleId: "settings.tags.enable_tag.success.title",
                    }, {
                        position: "bottom-center",
                    });
                })
                    .catch(function (err) {
                    console.error("Failed to enable tag", err);
                    showErrorToast({
                        titleId: "settings.tags.enable_tag.error.title",
                        descriptionId: "settings.tags.enable_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    setEnableStates(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[region.id] = region.isEnabled, _a)));
                    });
                })];
        });
    }); }, [regionAPI, showErrorToast, showSuccessToast]);
    var onDisableTag = useCallback(function (region) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setEnableStates(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[region.id] = false, _a)));
            });
            return [2 /*return*/, regionAPI
                    .disableRegion(region.id)
                    .then(function () {
                    showSuccessToast({
                        titleId: "settings.tags.disable_tag.success.title",
                    }, {
                        position: "bottom-center",
                    });
                })
                    .catch(function (err) {
                    console.error("Failed to disable tag", err);
                    showErrorToast({
                        titleId: "settings.tags.disable_tag.error.title",
                        descriptionId: "settings.tags.disable_tag.error.description",
                    }, {
                        position: "bottom-center",
                    });
                    setEnableStates(function (prevState) {
                        var _a;
                        return (__assign(__assign({}, prevState), (_a = {}, _a[region.id] = region.isEnabled, _a)));
                    });
                })];
        });
    }); }, [regionAPI, showErrorToast, showSuccessToast]);
    var onDeleteTag = useCallback(function (region) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, regionAPI.deleteRegion(region.id).then(function () {
                    refresh();
                })];
        });
    }); }, [regionAPI, refresh]);
    var _d = __read(useState(null), 2), mergeItem = _d[0], setMergeItem = _d[1];
    var onCloseMergeDialog = useCallback(function () { return setMergeItem(null); }, []);
    var onStartMerge = useCallback(function (region) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, setMergeItem(region)];
    }); }); }, []);
    var onGoToTagDetail = useCallback(function (region) {
        navigation.push(ROUTES.settings.tags.region.group_detail + "/" + region.id, {
            tagName: region.name,
        });
    }, [navigation]);
    var renderContextMenu = useCallback(function (r) {
        var _a;
        return (React.createElement(TagContextMenu, { typeId: "settings.tags.title.region", item: r, itemEnableState: (_a = enableStates[r.id]) !== null && _a !== void 0 ? _a : r.isEnabled, onEdit: onEditTag, onEnable: onEnableTag, onDisable: onDisableTag, onDelete: onDeleteTag, onMerge: onStartMerge }));
    }, [
        enableStates,
        onEditTag,
        onEnableTag,
        onDisableTag,
        onDeleteTag,
        onStartMerge,
    ]);
    var tableColumns = useMemo(function () { return [
        {
            titleId: "settings.tags.region.detail.table.region",
            accessor: function (c) { return c.name; },
            render: function (name, c) {
                var _a;
                return (React.createElement("span", { className: cn("font-bold leading-5 text-sm text-indigo-600", !((_a = enableStates[c.id]) !== null && _a !== void 0 ? _a : c.isEnabled) && "opacity-50") }, name));
            },
        },
        {
            titleId: "settings.tags.region.detail.table.full_name",
            accessor: function (c) { return c.fullName; },
        },
        {
            titleId: "settings.tags.region.detail.table.country_count",
            accessor: function (c) { return c.countryCount; },
        },
    ]; }, [enableStates]);
    var tableActions = useMemo(function () { return [
        {
            className: "flex items-center justify-end",
            render: renderContextMenu,
        },
    ]; }, [renderContextMenu]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DefaultTagGroupDetail, { headerTitleId: "settings.tags.title.region", headerTagCountId: "settings.tags.tag_counts.region", searchPlaceholderId: "settings.tags.region.detail.search_placeholder", addNewTagId: "settings.tags.region.detail.add_new_region", tableData: data, tableColumns: tableColumns, tableActions: tableActions, tagCount: totalCount, isInitializing: isInitializing, isFetchingMore: isFetchingMore, hasMoreData: hasMore, onTableItemClick: onGoToTagDetail, onFetchMore: nextPage, onGoBack: onGoBack, onAddNewClick: onAddNewRegion, onEditGroup: onEditGroup, onSearch: search, tagGroupSlug: TagGroupSlug.Region }),
        mergeItem && (React.createElement(MergeRegionDialog, { destination: mergeItem, onClose: onCloseMergeDialog, onDone: refresh }))));
});

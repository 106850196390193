export function parseErrorMessage(err) {
    var _a;
    switch ((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.status) {
        case 400:
            return [
                "common.errors.bad_request.title",
                "common.errors.bad_request.description",
            ];
        case 401:
        case 403:
            return ["common.errors.access.title", "common.errors.access.description"];
        default:
            return [
                "common.errors.unknown.title",
                "common.errors.unknown.description",
            ];
    }
}
export function parseTagCreationErrorMessage(err, tagType) {
    var _a;
    switch ((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.status) {
        case 400:
            return [
                "settings.tags.create.submission.generic.error.title",
                "settings.tags.create.submission.generic.error.description",
            ];
        case 409:
            if (tagType === "contact") {
                return [
                    "settings.tags.edit.submission.contact.error.conflict.title",
                    "settings.tags.edit.submission.contact.error.conflict.desc",
                ];
            }
            return [
                "settings.tags.create.submission.duplicated.error.title",
                "settings.tags.create.submission.duplicated.error.description",
            ];
        default:
            return parseErrorMessage(err);
    }
}
export function parseTagGroupCreationErrorMessage(err) {
    var _a;
    switch ((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.status) {
        case 400:
            return [
                "settings.tag_group.create.submission.generic.error.title",
                "settings.tag_group.create.submission.generic.error.description",
            ];
        case 409:
            return [
                "settings.tag_group.create.submission.duplicated.error.title",
                "settings.tag_group.create.submission.duplicated.error.description",
            ];
        default:
            return parseErrorMessage(err);
    }
}
export function parseTagUpdateErrorMessage(err, tagType) {
    var _a;
    switch ((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.status) {
        case 400:
        case 404:
            return [
                "settings.tags.edit.submission.generic.error.title",
                "settings.tags.edit.submission.generic.error.description",
            ];
        case 409:
            if (tagType === "contact") {
                return [
                    "settings.tags.edit.submission.contact.error.conflict.title",
                    "settings.tags.edit.submission.contact.error.conflict.desc",
                ];
            }
            return [
                "settings.tags.edit.submission.duplicated.error.title",
                "settings.tags.edit.submission.duplicated.error.description",
            ];
        default:
            return parseErrorMessage(err);
    }
}
export function parseTagGroupUpdateErrorMessage(err) {
    switch (err.response.status) {
        case 400:
        case 404:
            return [
                "settings.tag_group.edit.submission.generic.error.title",
                "settings.tag_group.edit.submission.generic.error.description",
            ];
        case 409:
            return [
                "settings.tag_group.edit.submission.duplicated.error.title",
                "settings.tag_group.edit.submission.duplicated.error.description",
            ];
        default:
            return parseErrorMessage(err);
    }
}
export function parseUserPreferenceUpdateErrorMessage(err) {
    switch (err.response.status) {
        case 400:
        case 404:
            return [
                "my_preference.form.error.title",
                "my_preference.form.error.description",
            ];
        default:
            return parseErrorMessage(err);
    }
}
export function parseDepartmentCreateErrorMessage(err) {
    switch (err.response.status) {
        case 409:
            return [
                "settings.organization.createDepartment.error.conflict.title",
                "settings.organization.createDepartment.error.conflict.description",
            ];
        default:
            return parseErrorMessage(err);
    }
}

import { __read } from "tslib";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Formik, useFormikContext } from "formik";
import { allDepartmentUserFormRoleOptions, departmentUserFormSchema, } from "./models";
import { UserExtractors } from "@shared/models/user";
import { PeoplePicker } from "@components/common/PeoplePicker";
import { SelectMenu } from "@components/form/SelectMenu";
import RowFormField from "@components/form/RowFormField";
import RowForm from "@components/form/RowForm";
import { useIntl } from "react-intl";
import { makeStyles } from "@fluentui/react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import cn from "classnames";
var usePeoplePickerStyleOverride = makeStyles({
    root: {
        "& .ms-BasePicker-text": {
            // fix to make selected tag center vertically
            padding: "0 !important",
        },
    },
});
var FormView = React.memo(function (props) {
    var _a;
    var isSubmitting = props.isSubmitting, onCancel = props.onCancel, departmentOptions = props.departmentOptions, fetchUserSuggestion = props.fetchUserSuggestion, onUserIDChange = props.onUserIDChange, onDepartmentChange = props.onDepartmentChange, onRoleChange = props.onRoleChange, departmentKeyExtractor = props.departmentKeyExtractor, departmentLabelExtractor = props.departmentLabelExtractor, roleKeyExtractor = props.roleKeyExtractor, roleLabelExtractor = props.roleLabelExtractor, userFieldStyle = props.userFieldStyle, departmentFieldStyle = props.departmentFieldStyle, roleFieldStyle = props.roleFieldStyle;
    var _b = __read(useState(departmentOptions), 2), departmentOptionItems = _b[0], setDepartmentOptionItems = _b[1];
    var _c = useFormikContext(), values = _c.values, errors = _c.errors, touched = _c.touched, submitForm = _c.submitForm;
    var peoplePickerStyleOverride = usePeoplePickerStyleOverride();
    var onDepartmentSearch = useCallback(function (term) {
        if (term) {
            setDepartmentOptionItems(departmentOptions.filter(function (i) {
                return departmentLabelExtractor(i)
                    .toLowerCase()
                    .includes(term.toLowerCase());
            }));
        }
        else {
            setDepartmentOptionItems(departmentOptions);
        }
    }, [departmentLabelExtractor, departmentOptions]);
    return (React.createElement(RowForm, null,
        React.createElement(RowFormField, { className: "px-4 py-1.5 overflow-hidden", style: userFieldStyle, errorId: touched.userID ? errors.userID : undefined },
            React.createElement(PeoplePicker, { className: cn(peoplePickerStyleOverride.root, "h-[2.375rem] w-full -p-t-2 min-w-[12.5rem]"), inputClassName: "mx-2", keyExtractor: UserExtractors.id, labelExtractor: UserExtractors.name, avatarExtractor: UserExtractors.avatarUrl, fetchTags: fetchUserSuggestion, onTagChange: onUserIDChange, itemLimit: 1 })),
        React.createElement(RowFormField, { className: "px-4 py-1.5 overflow-hidden", style: departmentFieldStyle, errorId: touched.departmentID ? errors.departmentID : undefined },
            React.createElement(SelectMenu, { className: "h-10 w-full !mt-0 min-w-[12.5rem]", selected: (_a = departmentOptions.find(function (x) { return x.id === values.departmentID; })) !== null && _a !== void 0 ? _a : null, onSelect: onDepartmentChange, multiple: false, items: departmentOptionItems, keyExtractor: departmentKeyExtractor, labelExtractor: departmentLabelExtractor, onSearch: onDepartmentSearch })),
        React.createElement(RowFormField, { className: "px-4 py-1.5 overflow-hidden", style: roleFieldStyle, errorId: touched.role ? errors.role : undefined },
            React.createElement("div", { className: "min-w-[4.5rem] flex flex-row items-center" },
                React.createElement(SelectMenu, { className: "h-10 flex-1 !mt-0 mr-4 min-w-[12.5rem]", selected: values.role, onSelect: onRoleChange, multiple: false, items: allDepartmentUserFormRoleOptions, keyExtractor: roleKeyExtractor, labelExtractor: roleLabelExtractor }),
                React.createElement("button", { type: "button", className: "flex-none w-6 h-6 hover:text-indigo-700", disabled: isSubmitting, onClick: submitForm },
                    React.createElement(CheckIcon, null)),
                React.createElement("button", { type: "button", onClick: onCancel, className: "flex-none w-6 h-6 ml-2 hover:text-red-600" },
                    React.createElement(XIcon, null))))));
});
var DepartmentUserForm = React.memo(function (props) {
    var departmentOptions = props.departmentOptions, currentDepartmentID = props.currentDepartmentID, fetchUserSuggestion = props.fetchUserSuggestion, initialFormValues = props.initialFormValues, isSubmitting = props.isSubmitting, onSubmit = props.onSubmit, onCancel = props.onCancel;
    var formikRef = useRef(null);
    var intl = useIntl();
    var initialValues = useMemo(function () {
        var _a, _b, _c;
        return ({
            userID: (_a = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.userID) !== null && _a !== void 0 ? _a : null,
            departmentID: (_b = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.departmentID) !== null && _b !== void 0 ? _b : null,
            role: (_c = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.role) !== null && _c !== void 0 ? _c : null,
        });
    }, [
        initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.userID,
        initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.departmentID,
        initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues.role,
    ]);
    var onUserIDChange = useCallback(function (option) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("userID", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("userID", option.length > 0 ? option[0].id : null);
    }, []);
    var onDepartmentChange = useCallback(function (option) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("departmentID", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("departmentID", option === null || option === void 0 ? void 0 : option.id);
    }, []);
    var departmentKeyExtractor = useCallback(function (option) {
        return option.id;
    }, []);
    var departmentLabelExtractor = useCallback(function (option) {
        return option.id !== currentDepartmentID
            ? option.name
            : intl.formatMessage({
                id: "settings.organization.departmentDetails.form.noSubDepartment",
            }); // FIXME replace name of current dept
    }, [currentDepartmentID, intl]);
    var onRoleChange = useCallback(function (option) {
        var _a, _b;
        (_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.setFieldTouched("role", true);
        (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("role", option);
    }, []);
    var roleKeyExtractor = useCallback(function (option) {
        return option;
    }, []);
    var roleLabelExtractor = useCallback(function (option) {
        switch (option) {
            case "head":
                return intl.formatMessage({
                    id: "settings.organization.departmentDetails.roles.head",
                });
            case "admin":
                return intl.formatMessage({
                    id: "settings.organization.departmentDetails.roles.admin",
                });
            case "staff":
                return intl.formatMessage({
                    id: "settings.organization.departmentDetails.roles.staff",
                });
            default:
                return option;
        }
    }, [intl]);
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: departmentUserFormSchema, validateOnChange: true, onSubmit: onSubmit, innerRef: formikRef },
        React.createElement(FormView, { isSubmitting: isSubmitting !== null && isSubmitting !== void 0 ? isSubmitting : false, onCancel: onCancel, departmentOptions: departmentOptions, fetchUserSuggestion: fetchUserSuggestion, onUserIDChange: onUserIDChange, onDepartmentChange: onDepartmentChange, onRoleChange: onRoleChange, departmentKeyExtractor: departmentKeyExtractor, departmentLabelExtractor: departmentLabelExtractor, roleKeyExtractor: roleKeyExtractor, roleLabelExtractor: roleLabelExtractor })));
});
export default DepartmentUserForm;

import { __read, __spreadArray } from "tslib";
import { useCallback, useEffect, useState } from "react";
export function usePagination(options) {
    var fetch = options.fetch, initialPage = options.initialPage, perPage = options.perPage, extraOptions = options.extraOptions, onError = options.onError;
    var _a = __read(useState(null), 2), currentPage = _a[0], setCurrentPage = _a[1];
    var _b = __read(useState([]), 2), data = _b[0], setData = _b[1];
    var _c = __read(useState(undefined), 2), totalCount = _c[0], setTotalCount = _c[1];
    var _d = __read(useState(false), 2), isFetchingMore = _d[0], setIsFetchingMore = _d[1];
    var _e = __read(useState(true), 2), isInitializing = _e[0], setIsInitializing = _e[1];
    var _f = __read(useState(true), 2), hasMore = _f[0], setHasMore = _f[1];
    var _g = __read(useState(false), 2), shouldFetch = _g[0], setShouldFetch = _g[1];
    var nextPage = useCallback(function () {
        if (!hasMore) {
            return;
        }
        setShouldFetch(true);
    }, [hasMore]);
    var _resetAll = useCallback(function () {
        setCurrentPage(null);
        setData([]);
        setTotalCount(undefined);
        setIsFetchingMore(false);
        setIsInitializing(true);
        setHasMore(true);
        setShouldFetch(true);
    }, []);
    var refresh = useCallback(function () {
        _resetAll();
    }, [_resetAll]);
    useEffect(function () {
        _resetAll();
    }, __spreadArray([
        fetch,
        onError,
        perPage,
        initialPage
    ], __read(Object.values(extraOptions !== null && extraOptions !== void 0 ? extraOptions : {}))));
    useEffect(function () {
        if (!shouldFetch)
            return;
        function fetchNext() {
            if (!fetch)
                return;
            if (isFetchingMore)
                return;
            setIsFetchingMore(true);
            var realInitialPage = initialPage !== null && initialPage !== void 0 ? initialPage : 1;
            if (realInitialPage < 1) {
                realInitialPage = 1;
            }
            var nextPage = currentPage === null ? realInitialPage : currentPage + 1;
            fetch(nextPage, perPage, extraOptions)
                .then(function (res) {
                setData(__spreadArray(__spreadArray([], __read(data)), __read(res.data)));
                setCurrentPage(nextPage);
                setTotalCount(res.count);
                setHasMore(res.data.length === res.perPage);
            })
                .catch(function (err) {
                onError === null || onError === void 0 ? void 0 : onError(err);
            })
                .finally(function () {
                setIsInitializing(false);
                setIsFetchingMore(false);
            });
        }
        fetchNext();
        setShouldFetch(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldFetch]);
    return {
        data: data,
        totalCount: totalCount,
        isInitializing: isInitializing,
        isFetchingMore: isFetchingMore,
        hasMore: hasMore,
        nextPage: nextPage,
        refresh: refresh,
    };
}

import { __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useContext, useState, useEffect, useMemo, } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { SearchContext } from "@contexts/SearchContext";
import { FilterContext } from "@contexts/FilterContext";
import { BasicDialog } from "@components/common/BasicDialog";
import { ColouredTag } from "@components/common/ColouredTag";
import { ContactTag } from "@components/common/ContactTag";
import { CompanyAPIContext, ContactAPIContext, DealAPIContext, PriorityAPIContext, OtherTagAPIContext, TagAPIContext, TagGroupAPIContext, } from "@shared/contexts/api";
import { CompanyExtractors } from "@shared/models/company";
import { ContactExtractors } from "@shared/models/contact";
import { DealExtractors } from "@shared/models/deal";
import { PriorityExtractors } from "@shared/models/priority";
import { OtherTagExtractors } from "@shared/models/other_tag";
import { TagExtractors } from "@shared/models/tag";
import { Spinner } from "@components/common/Spinner";
import { useAppToast } from "@components/common/Toast";
import { usePagination } from "@hooks/usePagination";
import crossIcon from "@assets/cross.svg";
var RelevantTagsDialogHeader = React.memo(function (props) {
    var onCloseClick = props.onCloseClick;
    return (React.createElement("header", { className: "relative py-5 px-4 border-gray-200 border-b border-solid" },
        React.createElement("h1", { className: "text-gray-900 text-base font-medium leading-6 pr-8" },
            React.createElement(FormattedMessage, { id: "relevant_tags_dialog.title" })),
        React.createElement("button", { type: "button", onClick: onCloseClick, className: "absolute top-5 right-4" },
            React.createElement("img", { src: crossIcon, className: "h-6 w-6" }))));
});
var RelevantTagsSectionImpl = function (props) {
    var title = props.title, fetchFn = props.fetchFn, nameExtractor = props.nameExtractor, colorExtractor = props.colorExtractor, onSelect = props.onSelect;
    var onPaginationError = useCallback(function (e) {
        console.error("Pagination error in RelevantTagsSection:", e);
    }, []);
    var _a = usePagination({
        fetch: fetchFn,
        initialPage: 1,
        perPage: 5,
        onError: onPaginationError,
        extraOptions: undefined,
    }), data = _a.data, isFetchingMore = _a.isFetchingMore, hasMore = _a.hasMore, nextPage = _a.nextPage;
    if (!hasMore && data.length === 0) {
        return null;
    }
    return (React.createElement("section", { className: "block pt-2 px-4 pb-4" },
        React.createElement("h2", { className: "text-gray-500 font-xs leading-4" }, title),
        React.createElement("div", { className: "flex flex-row items-center flex-wrap" },
            data.map(function (item, idx) {
                return (React.createElement("button", { type: "button", key: idx, className: "mr-2 mt-2", onClick: function () { return onSelect(item); } },
                    React.createElement(ColouredTag, { colour: colorExtractor(item), tag: nameExtractor(item) })));
            }),
            isFetchingMore && (React.createElement("div", { className: "mx-2 mt-2" },
                React.createElement(Spinner, { size: "small" }))),
            hasMore && !isFetchingMore && (React.createElement("button", { type: "button", className: "mt-2 h-6 align-middle text-indigo-700 text-xs leading-4", onClick: nextPage },
                React.createElement(FormattedMessage, { id: "relevant_tags_dialog.view_more" }))))));
};
var RelevantContactsSectionImpl = function (props) {
    var title = props.title, fetchFn = props.fetchFn, nameExtractor = props.nameExtractor, avatarExtractor = props.avatarExtractor, onSelect = props.onSelect;
    var onPaginationError = useCallback(function (e) {
        console.error("Pagination error in RelevantContactsSection:", e);
    }, []);
    var _a = usePagination({
        fetch: fetchFn,
        initialPage: 1,
        perPage: 5,
        onError: onPaginationError,
        extraOptions: undefined,
    }), data = _a.data, isFetchingMore = _a.isFetchingMore, hasMore = _a.hasMore, nextPage = _a.nextPage;
    if (!hasMore && data.length === 0) {
        return null;
    }
    return (React.createElement("section", { className: "block pt-2 px-4 pb-4" },
        React.createElement("h2", { className: "text-gray-500 font-xs leading-4" }, title),
        React.createElement("div", { className: "flex flex-row items-center flex-wrap" },
            data.map(function (item, idx) {
                var _a;
                return (React.createElement("button", { type: "button", key: idx, className: "mr-2 mt-2", onClick: function () { return onSelect(item); } },
                    React.createElement(ContactTag, { avatarUrl: (_a = avatarExtractor(item)) !== null && _a !== void 0 ? _a : undefined, name: nameExtractor(item) })));
            }),
            isFetchingMore && (React.createElement("div", { className: "mx-2 mt-2" },
                React.createElement(Spinner, { size: "small" }))),
            hasMore && !isFetchingMore && (React.createElement("button", { type: "button", className: "mt-2 h-6 align-middle text-indigo-700 text-xs leading-4", onClick: nextPage },
                React.createElement(FormattedMessage, { id: "relevant_tags_dialog.view_more" }))))));
};
var RelevantTagsSection = React.memo(RelevantTagsSectionImpl);
var RelevantContactsSection = React.memo(RelevantContactsSectionImpl);
var RelevantTagsDialogBody = React.memo(function (props) {
    var customTagGroups = props.customTagGroups;
    var intl = useIntl();
    var relatedTagsSearchText = useContext(SearchContext).relatedTagsSearchText;
    var showSuccessToast = useAppToast().showSuccessToast;
    var filterContext = useContext(FilterContext);
    var companyAPI = useContext(CompanyAPIContext);
    var contactAPI = useContext(ContactAPIContext);
    var dealAPI = useContext(DealAPIContext);
    var priorityAPI = useContext(PriorityAPIContext);
    var otherTagAPI = useContext(OtherTagAPIContext);
    var tagAPI = useContext(TagAPIContext);
    var onContactSelected = useCallback(function (contact) {
        filterContext.addContact(contact);
        showSuccessToast({
            titleId: "relevant_tags_dialog.added_to_filter",
            titleValues: { name: contact.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    var onCompanySelected = useCallback(function (company) {
        filterContext.addCompany(company);
        showSuccessToast({
            titleId: "relevant_tags_dialog.added_to_filter",
            titleValues: { name: company.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    var onDealSelected = useCallback(function (deal) {
        filterContext.addDeal(deal);
        showSuccessToast({
            titleId: "relevant_tags_dialog.added_to_filter",
            titleValues: { name: deal.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    var onPrioritySelected = useCallback(function (priority) {
        filterContext.addPriority(priority);
        showSuccessToast({
            titleId: "relevant_tags_dialog.added_to_filter",
            titleValues: { name: priority.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    var onOtherTagSelected = useCallback(function (otherTag) {
        filterContext.addOtherTag(otherTag);
        showSuccessToast({
            titleId: "relevant_tags_dialog.added_to_filter",
            titleValues: { name: otherTag.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    var onCustomTagSelected = useCallback(function (tag) {
        filterContext.addCustomTag(tag);
        showSuccessToast({
            titleId: "relevant_tags_dialog.added_to_filter",
            titleValues: { name: tag.name },
        }, { position: "bottom-center" });
    }, [filterContext, showSuccessToast]);
    var listCompanies = useCallback(function (page, perPage) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, companyAPI.listCompanies({
                    page: page,
                    perPage: perPage,
                    search: relatedTagsSearchText !== null && relatedTagsSearchText !== void 0 ? relatedTagsSearchText : undefined,
                })];
        });
    }); }, [companyAPI, relatedTagsSearchText]);
    var listContacts = useCallback(function (page, perPage) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, contactAPI.listContacts({
                    page: page,
                    perPage: perPage,
                    search: relatedTagsSearchText !== null && relatedTagsSearchText !== void 0 ? relatedTagsSearchText : undefined,
                })];
        });
    }); }, [contactAPI, relatedTagsSearchText]);
    var listDeals = useCallback(function (page, perPage) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, dealAPI.listDeals({
                    page: page,
                    perPage: perPage,
                    search: relatedTagsSearchText !== null && relatedTagsSearchText !== void 0 ? relatedTagsSearchText : undefined,
                })];
        });
    }); }, [dealAPI, relatedTagsSearchText]);
    var listPriorities = useCallback(function (page, perPage) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, priorityAPI.listPriorities({
                    page: page,
                    perPage: perPage,
                    search: relatedTagsSearchText !== null && relatedTagsSearchText !== void 0 ? relatedTagsSearchText : undefined,
                })];
        });
    }); }, [priorityAPI, relatedTagsSearchText]);
    var listOtherTags = useCallback(function (page, perPage) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, otherTagAPI.listOtherTags({
                    page: page,
                    perPage: perPage,
                    search: relatedTagsSearchText !== null && relatedTagsSearchText !== void 0 ? relatedTagsSearchText : undefined,
                })];
        });
    }); }, [otherTagAPI, relatedTagsSearchText]);
    var listCustomTagsByGroupId = useMemo(function () {
        function generateListFn(tagGroupId) {
            return function listTag(page, perPage) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        return [2 /*return*/, tagAPI.listTags(tagGroupId, {
                                page: page,
                                perPage: perPage,
                                search: relatedTagsSearchText !== null && relatedTagsSearchText !== void 0 ? relatedTagsSearchText : undefined,
                            })];
                    });
                });
            };
        }
        return customTagGroups.reduce(function (map, group) {
            map[group.id] = generateListFn(group.id);
            return map;
        }, {});
    }, [customTagGroups, relatedTagsSearchText, tagAPI]);
    return (React.createElement("div", { className: "pt-2 pb-1" },
        React.createElement(RelevantTagsSection, { title: intl.formatMessage({ id: "common.tags.company" }), fetchFn: listCompanies, nameExtractor: CompanyExtractors.name, colorExtractor: CompanyExtractors.color, onSelect: onCompanySelected }),
        React.createElement(RelevantContactsSection, { title: intl.formatMessage({ id: "common.tags.contact" }), fetchFn: listContacts, avatarExtractor: ContactExtractors.avatarUrl, nameExtractor: ContactExtractors.name, onSelect: onContactSelected }),
        React.createElement(RelevantTagsSection, { title: intl.formatMessage({ id: "common.tags.deal" }), fetchFn: listDeals, nameExtractor: DealExtractors.name, colorExtractor: DealExtractors.color, onSelect: onDealSelected }),
        React.createElement(RelevantTagsSection, { title: intl.formatMessage({ id: "common.tags.priority" }), fetchFn: listPriorities, nameExtractor: PriorityExtractors.name, colorExtractor: PriorityExtractors.color, onSelect: onPrioritySelected }),
        React.createElement(RelevantTagsSection, { title: intl.formatMessage({ id: "common.tags.others" }), fetchFn: listOtherTags, nameExtractor: OtherTagExtractors.name, colorExtractor: OtherTagExtractors.color, onSelect: onOtherTagSelected }),
        customTagGroups.map(function (group) {
            return (React.createElement(RelevantTagsSection, { key: group.id, title: group.name, fetchFn: listCustomTagsByGroupId[group.id], nameExtractor: TagExtractors.name, colorExtractor: TagExtractors.color, onSelect: onCustomTagSelected }));
        })));
});
export var RelevantTagsDialog = React.memo(function (props) {
    var isOpened = props.isOpened, onClose = props.onClose;
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _a = __read(useState({
        state: "idle",
    }), 2), tagGroupState = _a[0], setTagGroupState = _a[1];
    useEffect(function () {
        setTagGroupState({ state: "loading" });
        tagGroupAPI
            .getAllTagGroups({
            isCustom: true,
        })
            .then(function (resp) {
            setTagGroupState({ state: "loaded", groups: resp.data });
        })
            .catch(function (e) {
            console.error("RelevantTagsDialog: Failed to load custom tag groups.", e);
            setTagGroupState({ state: "error", error: e });
        });
    }, [tagGroupAPI, isOpened]);
    var onCloseClick = useCallback(function () {
        onClose();
    }, [onClose]);
    return (React.createElement(BasicDialog, { isOpened: isOpened, onClose: onClose },
        React.createElement("div", null,
            React.createElement(RelevantTagsDialogHeader, { onCloseClick: onCloseClick }),
            tagGroupState.state === "loaded" ? (React.createElement(RelevantTagsDialogBody, { customTagGroups: tagGroupState.groups })) : tagGroupState.state === "loading" ? (React.createElement("div", { className: "py-5" },
                React.createElement(Spinner, { size: "large" }))) : tagGroupState.state === "error" ? (React.createElement("div", { className: "px-4 py-4" },
                React.createElement("p", { className: "text-gray-900 text-xs text-center" },
                    React.createElement(FormattedMessage, { id: "common.errors.unknown.title" })))) : null)));
});

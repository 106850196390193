export var ALL_SCOPE = [
    "from",
    "to",
    "cc",
    "to,cc",
    "subject",
    "body",
    "tags",
    "file",
    "remarks",
    "thread",
];

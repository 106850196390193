import { __read } from "tslib";
import React, { useMemo, useState, useCallback, useEffect } from "react";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { WhiteButton } from "@components/common/WhiteButton";
import { PrimaryButton } from "@components/common/PrimaryButton";
import startOfToday from "date-fns/startOfToday";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import endOfDay from "date-fns/endOfDay";
import getDate from "date-fns/getDate";
import add from "date-fns/add";
import isSameMonth from "date-fns/isSameMonth";
import format from "date-fns/format";
import parse from "date-fns/parse";
var DATE_FORMAT = "dd/MM/yyyy";
var DateCellButton = React.memo(function (props) {
    var date = props.date, isVisible = props.isVisible, onClick = props.onClick;
    var onButtonClick = useCallback(function () {
        onClick(date);
    }, [date, onClick]);
    return (React.createElement("button", { className: "text-center h-11 w-11 block", disabled: !isVisible, onClick: onButtonClick, type: "button" }, isVisible ? getDate(date) : null));
});
var Calendar = React.memo(function (props) {
    var initialDate = props.initialDate, onSelect = props.onSelect;
    var _a = __read(useState(function () { return new Date(); }), 2), currentDate = _a[0], setCurrentDate = _a[1];
    var navigateToNextMonth = useCallback(function () {
        setCurrentDate(function (prev) { return add(prev, { months: 1 }); });
    }, []);
    var navigateToPrevMonth = useCallback(function () {
        setCurrentDate(function (prev) { return add(prev, { months: -1 }); });
    }, []);
    var monthTitle = useMemo(function () {
        return format(currentDate, "LLLL yyyy");
    }, [currentDate]);
    useEffect(function () {
        setCurrentDate(initialDate);
    }, [initialDate]);
    var weeks = useMemo(function () {
        var monthStart = startOfMonth(currentDate);
        var monthEnd = endOfMonth(currentDate);
        var calendarStart = startOfWeek(monthStart, { weekStartsOn: 0 });
        var calendarEnd = endOfWeek(monthEnd, { weekStartsOn: 0 });
        var weekStarts = [calendarStart].flatMap(function (start) {
            var starts = [];
            var d = start;
            while (+d < +calendarEnd) {
                starts.push(d);
                d = add(d, { weeks: 1 });
            }
            return starts;
        });
        return weekStarts.map(function (weekStart) {
            var days = [];
            var weekEnd = endOfWeek(weekStart, { weekStartsOn: 0 });
            var d = weekStart;
            while (+d < +weekEnd) {
                days.push(d);
                d = add(d, { days: 1 });
            }
            return days;
        });
    }, [currentDate]);
    var weekdays = useMemo(function () {
        var weekdays = [];
        var start = startOfWeek(new Date());
        var end = endOfWeek(start);
        var d = start;
        while (+d < +end) {
            weekdays.push(format(d, "EEE", { weekStartsOn: 0 }));
            d = add(d, { days: 1 });
        }
        return weekdays;
    }, []);
    return (React.createElement("div", { className: "pt-3" },
        React.createElement("div", { className: "flex items-center px-4" },
            React.createElement("p", { className: "font-bold text-base leading-6 text-gray-800 flex-1 truncate" }, monthTitle),
            React.createElement("button", { type: "button", onClick: navigateToPrevMonth },
                React.createElement(ChevronLeftIcon, { className: "block w-5 h-5 text-gray-900" })),
            React.createElement("button", { type: "button", onClick: navigateToNextMonth },
                React.createElement(ChevronRightIcon, { className: "ml-4 block w-5 h-5 text-gray-900" }))),
        React.createElement("table", { className: "mx-1.5" },
            React.createElement("thead", null,
                React.createElement("tr", null, weekdays.map(function (weekday) { return (React.createElement("th", { key: weekday, className: "pt-3 uppercase text-center text-xs text-gray-400 leading-4 font-bold" }, weekday)); }))),
            React.createElement("tbody", null, weeks.map(function (week, idx) {
                return (React.createElement("tr", { key: idx }, week.map(function (date) { return (React.createElement("td", { key: date.getTime(), className: "pt-1" },
                    React.createElement(DateCellButton, { date: date, isVisible: isSameMonth(date, currentDate), onClick: onSelect }))); })));
            })))));
});
export var DateRangePicker = React.memo(function (props) {
    var start = props.start, end = props.end, onApply = props.onApply, onCancel = props.onCancel;
    var _a = __read(useState(""), 2), startInputValue = _a[0], setStartInputValue = _a[1];
    var _b = __read(useState(""), 2), endInputValue = _b[0], setEndInputValue = _b[1];
    var _c = __read(useState("start"), 2), selectedInput = _c[0], setSelectedInput = _c[1];
    var onStartInputChange = useCallback(function (e) {
        setStartInputValue(e.currentTarget.value);
    }, []);
    var onEndInputChange = useCallback(function (e) {
        setEndInputValue(e.currentTarget.value);
    }, []);
    var onStartInputFocus = useCallback(function () {
        setSelectedInput("start");
    }, []);
    var onEndInputFocus = useCallback(function () {
        setSelectedInput("end");
    }, []);
    var onStartInputBlur = useCallback(function () {
        var parsedValue = parse(startInputValue, DATE_FORMAT, startOfToday());
        if (Number.isNaN(parsedValue.getTime())) {
            setStartInputValue("");
        }
    }, [startInputValue]);
    var onEndInputBlur = useCallback(function () {
        var parsedValue = parse(endInputValue, DATE_FORMAT, startOfToday());
        if (Number.isNaN(parsedValue.getTime())) {
            setEndInputValue("");
        }
    }, [endInputValue]);
    var onCalendarSelected = useCallback(function (date) {
        if (selectedInput === "start") {
            setStartInputValue(format(date, DATE_FORMAT));
            setSelectedInput("end");
        }
        else {
            setEndInputValue(format(date, DATE_FORMAT));
        }
    }, [selectedInput]);
    var onClickApply = useCallback(function () {
        var start = parse(startInputValue, DATE_FORMAT, startOfToday());
        var end = parse(endInputValue, DATE_FORMAT, startOfToday());
        if (Number.isNaN(start.getTime())) {
            start = null;
        }
        if (Number.isNaN(end.getTime())) {
            end = null;
        }
        else {
            end = endOfDay(end);
        }
        onApply(start, end);
    }, [endInputValue, onApply, startInputValue]);
    var onClickCancel = useCallback(function () {
        onCancel();
    }, [onCancel]);
    var onClickClear = useCallback(function () {
        setStartInputValue("");
        setEndInputValue("");
        setSelectedInput("start");
    }, []);
    useEffect(function () {
        if (!start) {
            setStartInputValue("");
        }
        else {
            setStartInputValue(format(start, DATE_FORMAT));
        }
    }, [start]);
    useEffect(function () {
        if (!end) {
            setEndInputValue("");
        }
        else {
            setEndInputValue(format(end, DATE_FORMAT));
        }
    }, [end]);
    var inputClassNames = useMemo(function () {
        return cn([
            "py-2",
            "px-3",
            "border",
            "border-solid",
            "rounded-lg",
            "text-gray-900",
            "text-base",
            "leading-6",
            "w-0",
            "flex-1",
            "focus:shadow-none",
            "focus:outline-none",
            "focus:ring-transparent",
        ]);
    }, []);
    var calendarDate = useMemo(function () {
        if (start) {
            return start;
        }
        return new Date();
    }, [start]);
    return (React.createElement("div", { className: "py-4 rounded-xl" },
        React.createElement("div", { className: "flex mx-4 items-center pb-4 border-b border-solid border-gray-200" },
            React.createElement("input", { className: cn(inputClassNames, selectedInput === "start" ? "border-indigo-500" : "border-gray-300"), value: startInputValue, onBlur: onStartInputBlur, onChange: onStartInputChange, onFocus: onStartInputFocus }),
            React.createElement("span", { className: "text-black text-sm px-3" },
                React.createElement(FormattedMessage, { id: "date_range_picker.to" })),
            React.createElement("input", { className: cn(inputClassNames, selectedInput === "end" ? "border-indigo-500" : "border-gray-300"), value: endInputValue, onBlur: onEndInputBlur, onChange: onEndInputChange, onFocus: onEndInputFocus })),
        React.createElement(Calendar, { onSelect: onCalendarSelected, initialDate: calendarDate }),
        React.createElement("div", { className: "mt-1 flex items-center justify-end px-4" },
            React.createElement("button", { type: "button", className: "text-xs leading-4 text-indigo-700 p-1 underline", onClick: onClickClear },
                React.createElement(FormattedMessage, { id: "common.clear" })),
            React.createElement("div", { className: "flex-1" }),
            React.createElement(WhiteButton, { size: "xs", className: "ml-1", onClick: onClickCancel },
                React.createElement(FormattedMessage, { id: "common.cancel" })),
            React.createElement(PrimaryButton, { size: "xs", className: "ml-1", onClick: onClickApply },
                React.createElement(FormattedMessage, { id: "common.apply" })))));
});

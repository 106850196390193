import { __assign, __awaiter, __generator, __read } from "tslib";
import { useSearchPagination } from "@hooks/useSearchPagination";
import { ThreadAPIContext } from "@shared/contexts/api/ThreadAPIContext";
import React, { useMemo, useCallback, useContext, useState, useEffect, } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon, XCircleIcon, } from "@heroicons/react/outline";
import { MailIcon, ExclamationIcon, MailOpenIcon, } from "@heroicons/react/solid";
import { PrimaryButton } from "../common/PrimaryButton";
import { WhiteButton } from "../common/WhiteButton";
import { DocumentDetailPanel, DocumentDetailPanelSkeleton, } from "../common/DocumentDetailPanel";
import cn from "classnames";
import { useAppToast } from "../common/Toast";
import { useNavigation } from "@hooks/useNavigation";
import { DropDown } from "../common/DropDown";
import { ROUTES } from "@router/routes";
import { ThreadDetailPanel } from "../uploads/ThreadDetailPanel";
import { Dialog, useDialog } from "../common/Dialog";
import { DocumentAPIContext } from "@shared/contexts/api/DocumentAPIContext";
import { InfiniteScroll } from "../common/InfiniteScroll";
import { Spinner } from "../common/Spinner";
import { ThreadReadStatusContext } from "@contexts/ThreadReadStatusContext";
import { useEffectOnce } from "@hooks/useEffectOnce";
import { TagGroupAPIContext, APIResponseError } from "@shared/contexts/api";
import useHasPermission from "@root/hooks/useHasPermission";
import { Permission } from "@shared/models/auth";
var ThreadMessageList = React.memo(function (props) {
    var className = props.className, isLoading = props.isLoading, isFetchingMore = props.isFetchingMore, hasMore = props.hasMore, documents = props.documents, fetchMore = props.fetchMore, onRemove = props.onRemove;
    var _a = __read(useState(null), 2), scrollEl = _a[0], setScrollEl = _a[1];
    if (isLoading) {
        return (React.createElement("div", { className: cn("flex flex-col overflow-y-auto p-2 space-y-3", className) }, new Array(5).fill(null).map(function (_, index) { return (React.createElement(DocumentDetailPanelSkeleton, { key: index })); })));
    }
    return (React.createElement("div", { ref: setScrollEl, className: cn("flex flex-col overflow-y-auto p-2 space-y-3", className) },
        documents.map(function (d) { return (React.createElement(DocumentDetailPanel, { key: d.id, document: d, onRemove: onRemove })); }),
        !!fetchMore && hasMore && (React.createElement(InfiniteScroll, { onFetchMore: fetchMore, containerElement: scrollEl }, isFetchingMore && (React.createElement("div", { className: "py-2 flex items-center justify-center" },
            React.createElement(Spinner, { size: "large" })))))));
});
export var ThreadDetailContext = React.createContext(undefined);
var ThreadDetailHeader = React.memo(function (_a) {
    var className = _a.className, threadId = _a.threadId, subject = _a.subject, itemCount = _a.itemCount, isModifiable = _a.isModifiable, isReadStatusLoading = _a.isReadStatusLoading, onRemoveUploadClick = _a.onRemoveUploadClick, onToggleReadClick = _a.onToggleReadClick, onDisplayThreadDetail = _a.onDisplayThreadDetail, onBack = _a.onBack;
    var hasPermission = useHasPermission();
    var threadReadStatusMap = useContext(ThreadReadStatusContext).threadReadStatusMap;
    return (React.createElement("div", { className: cn("bg-white flex flex-row items-center py-4 pr-4 pl-1 md:py-3.5 md:px-4", className) },
        React.createElement("button", { type: "button", className: "md:hidden mr-1", onClick: onBack },
            React.createElement(ChevronLeftIcon, { className: "h-9 w-9 text-gray-500" })),
        React.createElement("div", { className: "flex-1 flex flex-row items-center justify-between overflow-hidden" },
            React.createElement("div", { className: "flex flex-col overflow-hidden pr-2" },
                React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900 whitespace-nowrap overflow-hidden overflow-ellipsis" }, subject),
                React.createElement("span", null,
                    React.createElement(FormattedMessage, { id: "uploads.detail.header.reply_count", values: { count: itemCount } }))),
            React.createElement(DropDown.Button, { className: "md:hidden", labelId: "uploads.detail.header.actions" },
                isModifiable && hasPermission(Permission.DocumentsDelete) && (React.createElement(DropDown.Item, { onClick: onRemoveUploadClick },
                    React.createElement(FormattedMessage, { id: "uploads.detail.header.remove_upload" }))),
                React.createElement(DropDown.Item, { onClick: onToggleReadClick }, threadReadStatusMap.get(threadId) ? (React.createElement(FormattedMessage, { id: "uploads.detail.header.mark_unread" })) : (React.createElement(FormattedMessage, { id: "uploads.detail.header.mark_read" }))),
                React.createElement(DropDown.Item, { onClick: onDisplayThreadDetail },
                    React.createElement(FormattedMessage, { id: "uploads.detail.header.view_details" })))),
        React.createElement("div", { className: "flex-row space-x-2 items-center hidden md:flex" },
            isModifiable && hasPermission(Permission.DocumentsDelete) && (React.createElement(WhiteButton, { size: "base", onClick: onRemoveUploadClick },
                React.createElement("div", { className: "flex flex-row items-center" },
                    React.createElement(XCircleIcon, { className: "w-4 h-4 mr-2 text-gray-500" }),
                    React.createElement(FormattedMessage, { id: "uploads.detail.header.remove_upload" })))),
            React.createElement(PrimaryButton, { size: "base", onClick: onToggleReadClick, disabled: isReadStatusLoading },
                React.createElement("div", { className: "flex flex-row items-center min-w-[9rem] justify-center" }, isReadStatusLoading ? (React.createElement(Spinner, { size: "small", className: "text-white" })) : threadReadStatusMap.get(threadId) ? (React.createElement(React.Fragment, null,
                    React.createElement(MailIcon, { className: "w-4 h-4 mr-2 text-white" }),
                    React.createElement(FormattedMessage, { id: "uploads.detail.header.mark_unread" }))) : (React.createElement(React.Fragment, null,
                    React.createElement(MailOpenIcon, { className: "w-4 h-4 mr-2 text-white" }),
                    React.createElement(FormattedMessage, { id: "uploads.detail.header.mark_read" }))))))));
});
export var ThreadDetailScreen = React.memo(function () {
    var location = useLocation();
    var navigation = useNavigation();
    var threadId_ = useParams().threadId;
    var showErrorToast = useAppToast().showErrorToast;
    var _a = useDialog(), deleteDialogProps = _a.dialogProps, openDeleteDialog = _a.openDialog;
    var intl = useIntl();
    var threadId = useMemo(function () { return parseInt(threadId_, 10); }, [threadId_]);
    var shouldShowDisplayDetail = useMemo(function () { return location.pathname.includes("/detail"); }, [location]);
    var threadAPI = useContext(ThreadAPIContext);
    var documentAPI = useContext(DocumentAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _b = __read(useState(false), 2), isNotExist = _b[0], setIsNotExist = _b[1];
    var _c = __read(useState(false), 2), isRemoved = _c[0], setIsRemoved = _c[1];
    var _d = useContext(ThreadReadStatusContext), setReadStatuses = _d.setReadStatuses, threadReadStatusMap = _d.threadReadStatusMap;
    var _e = __read(useState(false), 2), isDetailLoading = _e[0], setIsDetailLoading = _e[1];
    var _f = __read(useState(undefined), 2), threadDetail = _f[0], setThreadDetail = _f[1];
    var _g = __read(useState([]), 2), tagGroups = _g[0], setTagGroups = _g[1];
    var _h = __read(useState(false), 2), isReadStatusLoading = _h[0], setIsReadStatusLoading = _h[1];
    var toastError = useCallback(function (err) {
        if (err instanceof APIResponseError && err.response.status === 404) {
            return;
        }
        showErrorToast({
            titleId: "common.errors.data.fetch_failed.title",
            descriptionId: "common.errors.data.fetch_failed.description",
        }, {
            position: "bottom-center",
        });
    }, [showErrorToast]);
    var readThread = useCallback(function (threadId) {
        setIsReadStatusLoading(true);
        threadAPI
            .readThread(threadId)
            .then(function () {
            setReadStatuses([[threadId, true]]);
        })
            .catch(function () {
            showErrorToast({
                titleId: "common.errors.unknown.title",
                descriptionId: "common.errors.unknown.description",
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsReadStatusLoading(false);
        });
    }, [threadAPI, setReadStatuses, showErrorToast]);
    var unreadThread = useCallback(function (threadId) {
        setIsReadStatusLoading(true);
        threadAPI
            .unreadThread(threadId)
            .then(function () {
            setReadStatuses([[threadId, false]]);
        })
            .catch(function () {
            showErrorToast({
                titleId: "common.errors.unknown.title",
                descriptionId: "common.errors.unknown.description",
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsReadStatusLoading(false);
        });
    }, [setReadStatuses, showErrorToast, threadAPI]);
    var onToggleReadClick = useCallback(function () {
        if (threadDetail == null) {
            console.warn("onToggleReadClick called before threadDetail loaded");
            return;
        }
        var threadId = threadDetail.id;
        if (threadReadStatusMap.get(threadId)) {
            unreadThread(threadId);
        }
        else {
            readThread(threadId);
        }
    }, [readThread, threadDetail, threadReadStatusMap, unreadThread]);
    var fetchMessages = useCallback(function (request) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, threadAPI.listMessages(threadId, request)];
        });
    }); }, [threadAPI, threadId]);
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.uploads.root);
        }
    }, [navigation]);
    var onDisplayThreadDetail = useCallback(function () {
        navigation.replace(location.pathname + "/detail");
    }, [location.pathname, navigation]);
    var onDismissThreadDetail = useCallback(function () {
        navigation.replace(location.pathname.replace("/detail", ""));
    }, [location.pathname, navigation]);
    var onFetchListItemError = useCallback(function (err) {
        toastError(err);
    }, [toastError]);
    var _j = useSearchPagination(fetchMessages, {
        page: 1,
        perPage: 100,
    }, onFetchListItemError), data = _j.data, hasMore = _j.hasMore, isFetchingMore = _j.isFetchingMore, nextPage = _j.nextPage, isInitializing = _j.isInitializing, totalCount = _j.totalCount, refresh = _j.refresh;
    var subject = useMemo(function () {
        var _a, _b;
        if (data.length > 0) {
            return data[0].subject;
        }
        return (_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.subject) !== null && _b !== void 0 ? _b : "";
    }, [data, location.state]);
    var handleRefresh = useCallback(function () {
        refresh();
    }, [refresh]);
    var onRemove = useCallback(function (documentId, type) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            documentAPI
                .removeDocument(documentId, { type: type })
                .then(function () {
                handleRefresh();
                setIsRemoved(true);
            })
                .catch(function () {
                showErrorToast({
                    titleId: "common.errors.data.remove_failed.title",
                    descriptionId: "common.errors.data.remove_failed.description",
                }, {
                    position: "bottom-center",
                });
            });
            return [2 /*return*/];
        });
    }); }, [documentAPI, handleRefresh, showErrorToast]);
    var onRemoveUploadClick = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var confirm;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openDeleteDialog()];
                case 1:
                    confirm = _a.sent();
                    if (!confirm) {
                        return [2 /*return*/];
                    }
                    void onRemove(data[0].id, "all");
                    return [2 /*return*/];
            }
        });
    }); }, [data, onRemove, openDeleteDialog]);
    var fetchThreadDetailInfo = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, Promise.all([
                    tagGroupAPI.getAllTagGroups({
                        isVisible: true,
                    }),
                    threadAPI.getThreadDetail(threadId),
                ])
                    .then(function (_a) {
                    var _b = __read(_a, 2), tagGroupResp = _b[0], threadDetailResp = _b[1];
                    setTagGroups(tagGroupResp.data);
                    setThreadDetail(threadDetailResp);
                })
                    .catch(function (err) {
                    toastError(err);
                })];
        });
    }); }, [tagGroupAPI, threadAPI, threadId, toastError]);
    useEffect(function () {
        if (threadDetail == null)
            return;
        if (!threadDetail.isRead) {
            readThread(threadDetail.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadDetail === null || threadDetail === void 0 ? void 0 : threadDetail.id]);
    useEffect(function () {
        if (!isInitializing && data.length === 0) {
            setIsNotExist(true);
        }
    }, [data.length, isInitializing]);
    useEffectOnce(function () {
        setIsDetailLoading(true);
        fetchThreadDetailInfo().finally(function () {
            setIsDetailLoading(false);
        });
    }, function () { return true; });
    var contextValue = useMemo(function () {
        return {
            threadDetail: threadDetail,
        };
    }, [threadDetail]);
    if (isNotExist) {
        return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col bg-gray-100" },
            React.createElement("div", { className: "mt-[4.5rem] text-sm leading-5 font-normal text-center text-[#605E5C]" }, isRemoved ? (React.createElement(FormattedMessage, { id: "uploads.detail.thread_detail.thread_removed" })) : (React.createElement(FormattedMessage, { id: "uploads.detail.thread_detail.not_exist" })))));
    }
    return (React.createElement(ThreadDetailContext.Provider, { value: contextValue },
        React.createElement("div", { className: "flex-1 min-w-0 flex flex-col bg-gray-100" },
            React.createElement(ThreadDetailHeader, { className: cn(shouldShowDisplayDetail && "hidden md:flex"), threadId: threadId, subject: subject, itemCount: totalCount, isModifiable: !!(threadDetail === null || threadDetail === void 0 ? void 0 : threadDetail.isModifiable), isReadStatusLoading: isReadStatusLoading, onDisplayThreadDetail: onDisplayThreadDetail, onRemoveUploadClick: onRemoveUploadClick, onToggleReadClick: onToggleReadClick, onBack: handleGoBack }),
            shouldShowDisplayDetail && (React.createElement("div", { className: cn("flex px-1 py-2 bg-gray-50 md:hidden") },
                React.createElement("button", { type: "button", onClick: onDismissThreadDetail },
                    React.createElement(ChevronLeftIcon, { className: "h-9 w-9 text-gray-500" })))),
            React.createElement("div", { className: "flex-1 flex flex-row overflow-hidden" },
                React.createElement(ThreadMessageList, { className: cn("flex-1", shouldShowDisplayDetail && "hidden md:flex"), documents: data, isLoading: isInitializing, isFetchingMore: isFetchingMore, hasMore: hasMore, fetchMore: nextPage, onRemove: onRemove }),
                React.createElement("button", { type: "button", className: cn("bg-gray-50 w-8 h-full p-0.5 hidden md:block"), onClick: shouldShowDisplayDetail
                        ? onDismissThreadDetail
                        : onDisplayThreadDetail },
                    React.createElement("div", { className: "w-6 h-6 flex items-center justify-center bg-gray-200 rounded-full" }, shouldShowDisplayDetail ? (React.createElement(ChevronRightIcon, { className: "w-4 h-4 text-gray-400" })) : (React.createElement(ChevronLeftIcon, { className: "w-4 h-4 text-gray-400" })))),
                !!threadDetail && (React.createElement(ThreadDetailPanel, { className: cn(!shouldShowDisplayDetail && "hidden"), isLoading: isDetailLoading, threadId: threadId, threadDetail: threadDetail, tagGroups: tagGroups, refreshThreadDetailInfo: fetchThreadDetailInfo }))),
            React.createElement(Dialog, __assign({}, deleteDialogProps, { title: intl.formatMessage({
                    id: "uploads.delete_all.confirmation.title",
                }), description: intl.formatMessage({
                    id: "uploads.delete_all.confirmation.description",
                }), confirmLabelId: "common.remove", cancelLabelId: "common.cancel", icon: React.createElement(ExclamationIcon, { className: "h-6 w-6 text-red-600", "aria-hidden": "true" }) })))));
});

import { DirtyFormPrompt } from "@components/common/DirtyFormPrompt";
import { PrimaryButton } from "@components/common/PrimaryButton";
import { TextField } from "@components/form/TextField";
import { Formik, useFormikContext } from "formik";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { threadRemarksFormSchema } from "./models";
var ThreadRemarksFormView = React.memo(function (_a) {
    var isSubmitting = _a.isSubmitting;
    var formik = useFormikContext();
    var intl = useIntl();
    return (React.createElement("form", { className: "flex flex-col space-y-2 items-start", onSubmit: formik.handleSubmit },
        React.createElement(DirtyFormPrompt, null),
        React.createElement(TextField, { isTextArea: true, name: "remark", onChange: formik.handleChange, onBlur: formik.handleBlur, placeholder: intl.formatMessage({
                id: "uploads.detail.thread_detail.form.remarks.placeholder",
            }), value: formik.values.remark, rows: 4, containerClassName: "w-full" }),
        React.createElement(PrimaryButton, { size: "xs", type: "submit", disabled: !formik.values.remark || isSubmitting },
            React.createElement("span", { className: "font-normal text-xs leading-4" },
                React.createElement(FormattedMessage, { id: "uploads.detail.thread_detail.form.remarks.add" })))));
});
export var ThreadRemarksForm = React.forwardRef(function (props, refs) {
    var isSubmitting = props.isSubmitting, onSubmit = props.onSubmit;
    var initialValues = useMemo(function () { return ({
        remark: "",
    }); }, []);
    return (React.createElement(Formik, { innerRef: refs, initialValues: initialValues, validateOnChange: true, validationSchema: threadRemarksFormSchema, onSubmit: onSubmit },
        React.createElement("div", { className: "flex flex-col space-y-4" },
            React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900" },
                React.createElement(FormattedMessage, { id: "uploads.detail.thread_detail.form.section.remark.title" })),
            React.createElement(ThreadRemarksFormView, { isSubmitting: isSubmitting }))));
});

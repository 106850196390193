import { __rest, __values } from "tslib";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import cn from "classnames";
export var FilePickerButton = React.memo(function (props) {
    var acceptedMimeTypes = props.acceptedMimeTypes, uploadButtonLabelId = props.uploadButtonLabelId, callbackProps = __rest(props, ["acceptedMimeTypes", "uploadButtonLabelId"]);
    var onFileChange = useCallback(function (e) {
        var e_1, _a;
        var _b, _c;
        var files = Array.from((_b = e.target.files) !== null && _b !== void 0 ? _b : []);
        if (acceptedMimeTypes != null) {
            try {
                for (var files_1 = __values(files), files_1_1 = files_1.next(); !files_1_1.done; files_1_1 = files_1.next()) {
                    var file = files_1_1.value;
                    if (!acceptedMimeTypes.includes(file.type)) {
                        callbackProps.onFileBadMimeType(file);
                        console.error("Unsupported file type " + file.type + " for " + file.name);
                        return;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (files_1_1 && !files_1_1.done && (_a = files_1.return)) _a.call(files_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        if (callbackProps.multiple) {
            callbackProps.onFileSelect(files);
        }
        else {
            callbackProps.onFileSelect((_c = files[0]) !== null && _c !== void 0 ? _c : null);
        }
    }, [acceptedMimeTypes, callbackProps]);
    return (React.createElement("label", { htmlFor: "fileInput" },
        React.createElement("div", { className: cn("w-max", "px-3", "h-[2.125rem]", "rounded-md", "cursor-pointer", "bg-white", "hover:bg-gray-50", "shadow-sm", "flex", "items-center", "border", "border-gray-300", "focus:outline-none", "focus:ring-2", "focus:ring-offset-2", "focus:ring-orange-600") },
            React.createElement("span", { className: "text-xs leading-4 font-normal text-gray-700" },
                React.createElement(FormattedMessage, { id: uploadButtonLabelId }))),
        React.createElement("input", { id: "fileInput", className: "hidden", type: "file", onChange: onFileChange, multiple: false, accept: acceptedMimeTypes ? acceptedMimeTypes.join(",") : "*" })));
});

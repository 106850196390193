import { __assign, __values } from "tslib";
import React, { useMemo, useContext } from "react";
import { SearchContext, } from "@contexts/SearchContext";
import { FilterContext } from "@contexts/FilterContext";
import { TagIndexContext } from "@contexts/TagIndexContext";
import { isExternalTag, isInternalTag, } from "@contexts/TagIndexContext/models";
import { useRouteMatch } from "react-router-dom";
import { ROUTES } from "@router/routes";
export var SearchParamContext = React.createContext(undefined);
function composeThreadIdFilter(searchClauses) {
    var result = {};
    var threadIdClauses = searchClauses.filter(function (clause) { return clause.scope === "thread"; });
    var threadIds = threadIdClauses
        .map(function (clause) { return Number(clause.value); })
        .filter(function (id) { return Number.isFinite(id); });
    if (threadIds.length > 0) {
        result.thread_ids = threadIds;
    }
    return result;
}
function composeDateRangeFilters(filters) {
    var result = {};
    if (filters.dateRange.start) {
        result.date_range_start = filters.dateRange.start;
    }
    if (filters.dateRange.end) {
        result.date_range_end = filters.dateRange.end;
    }
    return result;
}
function composeAddressFilters(filters) {
    var result = {};
    if (filters.from.length > 0) {
        result.from_addresses = filters.from.flatMap(function (contact) { return contact.emails; });
    }
    if (filters.toOrCc.length > 0) {
        result.to_addresses = filters.toOrCc.flatMap(function (contact) { return contact.emails; });
    }
    return result;
}
function composeTagFilters(filters) {
    var result = {};
    if (filters.contacts.length > 0) {
        result.contact_ids = filters.contacts.map(function (contact) { return contact.id; });
    }
    if (filters.companies.length > 0) {
        result.company_ids = filters.companies.map(function (company) { return company.id; });
    }
    if (filters.deals.length > 0) {
        result.deal_ids = filters.deals.map(function (deal) { return deal.id; });
    }
    if (filters.priorities.length > 0) {
        result.priority_ids = filters.priorities.map(function (priority) { return priority.id; });
    }
    if (filters.otherTags.length > 0) {
        result.other_tag_ids = filters.otherTags.map(function (otherTag) { return otherTag.id; });
    }
    if (filters.customTags.length > 0) {
        result.tag_ids = filters.customTags.map(function (tag) { return tag.id; });
    }
    return result;
}
function composeFilters(searchClauses, filters) {
    var result = __assign(__assign(__assign(__assign({}, composeThreadIdFilter(searchClauses)), composeDateRangeFilters(filters)), composeAddressFilters(filters)), composeTagFilters(filters));
    if (filters.uploaders.length > 0) {
        result.uploader_ids = filters.uploaders.map(function (user) { return user.id; });
    }
    if (filters.industries.length > 0) {
        result.industry_ids = filters.industries.map(function (industry) { return industry.id; });
    }
    if (filters.countries.length > 0) {
        result.country_ids = filters.countries.map(function (country) { return country.id; });
    }
    if (filters.regions.length > 0) {
        result.region_ids = filters.regions.map(function (region) { return region.id; });
    }
    return result;
}
function composeSearchParam(searchClauses) {
    var e_1, _a;
    var _b, _c, _d, _e;
    var result = {};
    var valuesByScope = new Map();
    try {
        for (var searchClauses_1 = __values(searchClauses), searchClauses_1_1 = searchClauses_1.next(); !searchClauses_1_1.done; searchClauses_1_1 = searchClauses_1.next()) {
            var clause = searchClauses_1_1.value;
            var valueList = (_b = valuesByScope.get(clause.scope)) !== null && _b !== void 0 ? _b : [];
            valueList.push(clause.value);
            valuesByScope.set(clause.scope, valueList);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (searchClauses_1_1 && !searchClauses_1_1.done && (_a = searchClauses_1.return)) _a.call(searchClauses_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    result.from = valuesByScope.get("from");
    var toValues = ((_c = valuesByScope.get("to")) !== null && _c !== void 0 ? _c : [])
        .concat((_d = valuesByScope.get("cc")) !== null && _d !== void 0 ? _d : [])
        .concat((_e = valuesByScope.get("to,cc")) !== null && _e !== void 0 ? _e : []);
    if (toValues.length > 0) {
        result.to = toValues;
    }
    result.subject = valuesByScope.get("subject");
    result.body = valuesByScope.get("body");
    result.file = valuesByScope.get("file");
    result.remark = valuesByScope.get("remarks");
    // These are values without scope, put them to text
    result.text = valuesByScope.get(null);
    return result;
}
function composeSearchTagsParam(searchClauses) {
    var result = {};
    var tagsClauses = searchClauses.filter(function (clause) { return clause.scope === "tags"; });
    if (tagsClauses.length > 0) {
        result.exact_match = tagsClauses.map(function (c) { return c.value; });
    }
    return result;
}
function composeExternalTagIndexParam(tagIndexItem) {
    switch (tagIndexItem.type) {
        case "contact":
            return {
                industry_id: tagIndexItem.industryId,
                region_id: tagIndexItem.regionId,
                country_id: tagIndexItem.countryId,
                company_id: tagIndexItem.companyId,
                contact_id: tagIndexItem.contact.id,
            };
        case "company":
            return {
                industry_id: tagIndexItem.industryId,
                region_id: tagIndexItem.regionId,
                country_id: tagIndexItem.countryId,
                company_id: tagIndexItem.company.id,
            };
        case "country":
            return {
                industry_id: tagIndexItem.industryId,
                region_id: tagIndexItem.regionId,
                country_id: tagIndexItem.country.id,
            };
        case "region":
            return {
                industry_id: tagIndexItem.industryId,
                region_id: tagIndexItem.region.id,
            };
        case "industry":
            return {
                industry_id: tagIndexItem.industry.id,
            };
        default:
            throw new Error("Unexpected external tag type " + tagIndexItem.type);
    }
}
function composeInternalTagIndexParam(tagIndexItem) {
    switch (tagIndexItem.type) {
        case "department":
            return {
                department_id: tagIndexItem.department.id,
            };
        case "user":
            return {
                department_id: tagIndexItem.departmentId,
                user_id: tagIndexItem.user.id,
            };
        default:
            throw new Error("Unexpected internal tag type " + tagIndexItem.type);
    }
}
export function composeTagIndexParam(tagIndexItem) {
    if (isExternalTag(tagIndexItem)) {
        return {
            external: composeExternalTagIndexParam(tagIndexItem),
        };
    }
    if (isInternalTag(tagIndexItem)) {
        return {
            internal: composeInternalTagIndexParam(tagIndexItem),
        };
    }
    return {};
}
export var SearchParamContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var routeMatch = useRouteMatch({
        path: [ROUTES.uploads.allUploads, ROUTES.uploads.unreadUploads],
        exact: false,
    });
    var searchContext = useContext(SearchContext);
    var filterContext = useContext(FilterContext);
    var tagIndexContext = useContext(TagIndexContext);
    var filterParam = useMemo(function () {
        return composeFilters(searchContext.commitedSearchClauses, filterContext.appliedFilters);
    }, [searchContext.commitedSearchClauses, filterContext.appliedFilters]);
    var searchParam = useMemo(function () {
        return composeSearchParam(searchContext.commitedSearchClauses);
    }, [searchContext.commitedSearchClauses]);
    var searchTagsParam = useMemo(function () {
        return composeSearchTagsParam(searchContext.commitedSearchClauses);
    }, [searchContext.commitedSearchClauses]);
    var tagIndexParam = useMemo(function () {
        if (tagIndexContext.selectedItem == null) {
            return {};
        }
        var param = composeTagIndexParam(tagIndexContext.selectedItem);
        return param;
    }, [tagIndexContext.selectedItem]);
    var unreadOnly = useMemo(function () {
        return routeMatch
            ? routeMatch.path === ROUTES.uploads.unreadUploads
            : false;
    }, [routeMatch]);
    var value = useMemo(function () {
        return {
            filter: filterParam,
            search: searchParam,
            searchTags: searchTagsParam,
            tagIndex: tagIndexParam,
            unreadOnly: unreadOnly,
        };
    }, [filterParam, searchParam, searchTagsParam, tagIndexParam, unreadOnly]);
    return (React.createElement(SearchParamContext.Provider, { value: value }, children));
});

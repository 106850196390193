import React from "react";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
export var ScopeButton = React.memo(function (props) {
    var icon = props.icon, textId = props.textId, onMouseDown = props.onMouseDown, className = props.className;
    return (React.createElement("button", { type: "button", className: cn("pl-2 pr-3 py-2 rounded bg-indigo-50 flex items-center", className), onMouseDown: onMouseDown },
        React.createElement("img", { className: "w-4 h-4 object-contain mr-2", src: icon }),
        React.createElement("span", { className: "text-xs leading-4 font-medium text-indigo-700" },
            React.createElement(FormattedMessage, { id: textId }))));
});

import { __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useMemo, useContext, useState, useEffect, } from "react";
import { DocumentList } from "@components/common/DocumentList";
import { ThreadDetailScreen } from "@components/screens/ThreadDetailScreen";
import { useNavigation } from "@hooks/useNavigation";
import { usePagination } from "@hooks/usePagination";
import { DocumentAPIContext } from "@shared/contexts/api/DocumentAPIContext";
import { AllTagAPIContext } from "@shared/contexts/api/AllTagAPIContext";
import { SearchContext } from "@contexts/SearchContext";
import { TagIndexPanel } from "@components/tagIndex/TagIndexPanel";
import { FormattedMessage } from "react-intl";
import cn from "classnames";
import { Router, Switch, useHistory, useLocation, useRouteMatch, } from "react-router-dom";
import { ROUTES } from "./routes";
import { ThreadReadStatusContext } from "@contexts/ThreadReadStatusContext";
import ScreenRoute from "./ScreenRoute";
import { Permission } from "@shared/models/auth";
import { SearchParamContextProvider, SearchParamContext, } from "@contexts/SearchParamContext";
export var DocumentListRouterContext = React.createContext(undefined);
// TODO(tung): Move api calls / states of this component to a new screen component
// Should only left router logics
var InternalDocumentListRouter = function () {
    var documentAPI = useContext(DocumentAPIContext);
    var allTagsAPI = useContext(AllTagAPIContext);
    var searchContext = useContext(SearchContext);
    var _a = useContext(SearchParamContext), filter = _a.filter, search = _a.search, searchTags = _a.searchTags, tagIndex = _a.tagIndex, unreadOnly = _a.unreadOnly;
    var history = useHistory();
    var location = useLocation();
    var navigation = useNavigation();
    var rootMatch = useRouteMatch({
        path: [ROUTES.uploads.allUploads, ROUTES.uploads.unreadUploads],
        exact: true,
    });
    var _b = useContext(ThreadReadStatusContext), setReadStatuses = _b.setReadStatuses, refreshUnreadCount = _b.refreshUnreadCount;
    var routeMatch = useRouteMatch({
        path: [ROUTES.uploads.allUploads, ROUTES.uploads.unreadUploads],
        exact: false,
    });
    var _c = __read(useState({
        state: "idle",
    }), 2), relatedTagsState = _c[0], setRelatedTagsState = _c[1];
    var onFetchItemError = useCallback(function (e) {
        console.error("Failed to list threads", e);
    }, []);
    var searchDocuments = useCallback(function (page, perPage, options) { return __awaiter(void 0, void 0, void 0, function () {
        var filter, search, search_tags, tag_index, unread_only;
        return __generator(this, function (_a) {
            filter = options.filter, search = options.search, search_tags = options.search_tags, tag_index = options.tag_index, unread_only = options.unread_only;
            return [2 /*return*/, documentAPI
                    .searchDocuments({
                    page: page,
                    perPage: perPage,
                    filter: filter,
                    search: search,
                    search_tags: search_tags,
                    tag_index: tag_index,
                    unread_only: unread_only,
                })
                    .then(function (resp) {
                    setReadStatuses(resp.data.map(function (d) { return [d.threadId, d.isRead]; }));
                    refreshUnreadCount();
                    return resp;
                })];
        });
    }); }, [documentAPI, refreshUnreadCount, setReadStatuses]);
    var _d = usePagination({
        fetch: searchDocuments,
        initialPage: 1,
        perPage: 20,
        extraOptions: {
            filter: filter,
            search: search,
            search_tags: searchTags,
            tag_index: tagIndex,
            unread_only: unreadOnly,
        },
        onError: onFetchItemError,
    }), data = _d.data, isInitializing = _d.isInitializing, totalCount = _d.totalCount, refresh = _d.refresh, nextPage = _d.nextPage, hasMore = _d.hasMore, isFetchingMore = _d.isFetchingMore;
    var refreshList = useCallback(function () {
        refresh();
    }, [refresh]);
    var emailListRouterContextValue = useMemo(function () { return ({
        refreshList: refreshList,
    }); }, [refreshList]);
    var isUnreadList = useMemo(function () {
        if (routeMatch === null)
            return true;
        return routeMatch.path === ROUTES.uploads.unreadUploads;
    }, [routeMatch]);
    var onDocumentItemClick = useCallback(function (i) {
        if (routeMatch === null) {
            console.error("Unexpected routeMatch === null");
            return;
        }
        var showDetails = location.pathname.includes("/detail");
        var newRoute = isUnreadList
            ? ROUTES.uploads.unreadUploadsThread.format("" + i.threadId, showDetails)
            : ROUTES.uploads.allUploadsThread.format("" + i.threadId, showDetails);
        navigation.push(newRoute, {
            subject: i.subject,
        });
    }, [isUnreadList, location.pathname, navigation, routeMatch]);
    useEffect(function () {
        if (!searchContext.relatedTagsSearchText) {
            setRelatedTagsState({ state: "idle" });
            return;
        }
        allTagsAPI
            .listAllTags({
            search: searchContext.relatedTagsSearchText,
            limitPerGroup: 2,
            limit: 5,
        })
            .then(function (resp) {
            setRelatedTagsState({ state: "loaded", tags: resp });
        })
            .catch(function (e) {
            console.error("load related tags error:", e);
            setRelatedTagsState({ state: "error", error: e });
        });
    }, [allTagsAPI, searchContext.relatedTagsSearchText]);
    useEffect(function () {
        refreshUnreadCount();
    }, [refreshUnreadCount, rootMatch === null || rootMatch === void 0 ? void 0 : rootMatch.path]);
    var isListLoading = useMemo(function () {
        return relatedTagsState.state === "loading" || isInitializing;
    }, [isInitializing, relatedTagsState.state]);
    return (React.createElement(DocumentListRouterContext.Provider, { value: emailListRouterContextValue },
        React.createElement(Router, { history: history },
            React.createElement("div", { className: "flex flex-row w-full overflow-y-hidden md:min-w-[77.5rem]" },
                rootMatch != null && React.createElement(TagIndexPanel, null),
                React.createElement(DocumentList, { className: cn("min-w-[22.5rem]", !rootMatch ? "flex-none hidden md:flex md:w-0" : "flex-1"), title: isUnreadList ? (React.createElement(FormattedMessage, { id: "uploads.unread.title" })) : (React.createElement(FormattedMessage, { id: "uploads.all.title" })), count: totalCount, itemCountId: "uploads.document.count", documents: data, isLoading: isListLoading, onRefresh: refreshList, isCompact: !rootMatch, onItemClick: onDocumentItemClick, onFetchMore: nextPage, hasMore: hasMore, isFetchingMore: isFetchingMore, relatedTags: relatedTagsState.state === "loaded" ? relatedTagsState.tags : null }),
                React.createElement("div", { className: cn("bg-gray-50 w-4 h-full hidden", !rootMatch && "md:block") }),
                React.createElement(Switch, null,
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.uploads.allUploads, requiredPermission: Permission.DocumentsRead }),
                    React.createElement(ScreenRoute, { exact: true, path: ROUTES.uploads.unreadUploads, requiredPermission: Permission.DocumentsRead }),
                    React.createElement(ScreenRoute, { path: [
                            ROUTES.uploads.allUploadsThread.toString(),
                            ROUTES.uploads.unreadUploadsThread.toString(),
                        ], requiredPermission: Permission.ThreadsRead }, function (props) {
                        var _a;
                        return (React.createElement(ThreadDetailScreen, { key: (_a = props.match) === null || _a === void 0 ? void 0 : _a.params.threadId }));
                    }))))));
};
export var DocumentListRouter = React.memo(function () {
    return (React.createElement(SearchParamContextProvider, null,
        React.createElement(InternalDocumentListRouter, null)));
});

import { __awaiter, __generator } from "tslib";
import React, { useContext, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { FilterContext } from "@contexts/FilterContext";
import { UserAPIContext } from "@shared/contexts/api";
import { UserExtractors } from "@shared/models/user";
import { CollapsibleInlineFilter } from "./CollapsibleInlineFilter";
export var UploadedByFilter = React.memo(function (props) {
    var filterKey = props.filterKey, isCollapsed = props.isCollapsed, onToggle = props.onToggle;
    var intl = useIntl();
    var userAPI = useContext(UserAPIContext);
    var _a = useContext(FilterContext), appliedFilters = _a.appliedFilters, setAppliedFilter = _a.setAppliedFilter;
    var fetchOptions = useCallback(function (page, perPage, options) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, userAPI.listUsers({
                    page: page,
                    perPage: perPage,
                    search: options.search,
                    isEnabled: "true",
                    excludeSystem: true,
                })];
        });
    }); }, [userAPI]);
    var onSelect = useCallback(function (items) {
        setAppliedFilter({ uploaders: items });
    }, [setAppliedFilter]);
    var title = useMemo(function () {
        return intl.formatMessage({ id: "more_filter_panel.uploaded_by" });
    }, [intl]);
    var searchPlaceholder = useMemo(function () {
        return intl.formatMessage({ id: "common.search" });
    }, [intl]);
    var onToggleFilter = useCallback(function () {
        onToggle(filterKey);
    }, [filterKey, onToggle]);
    return (React.createElement(CollapsibleInlineFilter, { isCollapsed: isCollapsed, onToggle: onToggleFilter, selected: appliedFilters.uploaders, onSelect: onSelect, getOptions: fetchOptions, title: title, searchPlaceholder: searchPlaceholder, labelExtractor: UserExtractors.name, descriptionExtractor: UserExtractors.username, keyExtractor: UserExtractors.id }));
});

import * as yup from "yup";
import { colorSchema } from "./color_api";
import { userSchema } from "./user_api";
import { paginatedSearchRequest, generatePaginationSchema, } from "../pagination";
import { toggleableParamSchema } from "./params";
export var tagSchema = yup
    .object({
    id: yup.number().defined(),
    tagGroupId: yup.number().defined(),
    name: yup.string().defined(),
    isEnabled: yup.boolean().defined(),
    fullName: yup.string().defined().nullable(),
    color: colorSchema.defined().nullable(),
    createdBy: userSchema.defined(),
    additional: yup.object(),
})
    .camelCase()
    .defined();
export var listTagRequestSchema = yup
    .object()
    .concat(paginatedSearchRequest)
    .concat(toggleableParamSchema);
export var listTagResponseSchema = generatePaginationSchema(tagSchema);
export var createTagRequestSchema = yup
    .object()
    .shape({
    name: yup.string().required(),
    full_name: yup.string().defined().nullable(),
    color_id: yup.number().defined().nullable(),
    additional: yup.object().defined().nullable(),
})
    .snakeCase()
    .defined();
export var editTagRequestSchema = yup
    .object()
    .shape({
    name: yup.string().defined(),
    full_name: yup.string().defined().nullable(),
    color_id: yup.number().defined().nullable(),
    additional: yup.object().defined().nullable(),
})
    .snakeCase()
    .defined();

import React, { useMemo } from "react";
import { Persona, PersonaSize } from "@fluentui/react";
import { extractInitials } from "@utils/avatar";
import { FormattedMessage } from "react-intl";
import { formatDistance } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
var RemarkFeedItem = React.memo(function (props) {
    var _a, _b;
    var remark = props.remark, isLastItem = props.isLastItem;
    var creatorIdentifier = useMemo(function () {
        return (
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        remark.createdBy.fullName ||
            remark.createdBy.emails[0] ||
            remark.createdBy.username);
    }, [remark.createdBy]);
    var createdFromNow = useMemo(function () {
        return formatDistance(zonedTimeToUtc(remark.createdAt, "UTC"), Date.now(), {
            addSuffix: true,
        });
    }, [remark.createdAt]);
    return (React.createElement("li", null,
        React.createElement("div", { className: "relative pb-8" },
            !isLastItem ? (React.createElement("span", { className: "absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200", "aria-hidden": "true" })) : null,
            React.createElement("div", { className: "relative flex items-start space-x-3" },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "relative" },
                        React.createElement(Persona, { size: PersonaSize.size40, imageUrl: (_b = (_a = remark.createdBy.avatar) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : undefined, imageInitials: extractInitials(creatorIdentifier, 2), hidePersonaDetails: true })),
                    React.createElement("div", { className: "min-w-0 flex-1" },
                        React.createElement("div", null,
                            React.createElement("div", { className: "text-sm" },
                                React.createElement("h1", { className: "font-medium text-gray-900" }, creatorIdentifier)),
                            React.createElement("p", { className: "mt-0.5 text-sm text-gray-500" },
                                React.createElement(FormattedMessage, { id: "uploads.detail.thread_detail.remarks.timestamp", values: {
                                        relativeTimestamp: createdFromNow,
                                    } }))),
                        React.createElement("div", { className: "mt-2 text-sm text-gray-700 whitespace-pre-wrap" },
                            React.createElement("p", null, remark.content))))))));
});
export var RemarkFeed = React.memo(function (props) {
    var className = props.className, remarks = props.remarks;
    return (React.createElement("div", { className: className },
        React.createElement("ul", { role: "list", className: "-mb-8" }, remarks.map(function (item, index) { return (React.createElement(RemarkFeedItem, { remark: item, key: index, isLastItem: index === remarks.length - 1 })); }))));
});

import { __assign, __awaiter, __generator, __read, __values } from "tslib";
import React, { useContext, useCallback, useMemo, useState, useEffect, } from "react";
import cn from "classnames";
import { TagIndexContext, } from "@contexts/TagIndexContext";
import { CollapsiblePaginatedList } from "@components/common/CollapsibleList/CollapsiblePaginatedList";
import { DepartmentAPIContext, UserAPIContext, DocumentAPIContext, } from "@shared/contexts/api";
import { UserExtractors } from "@shared/models/user";
import { Spinner } from "@components/common/Spinner";
import { SearchParamContext, composeTagIndexParam, } from "@contexts/SearchParamContext";
import { AuthContext } from "@contexts/AuthContext";
import { Identity } from "@shared/models/auth";
import { useDepartmentLabel } from "@hooks/useDepartmentLabel";
var PAGE_SIZE = 20;
var CountState;
(function (CountState) {
    CountState[CountState["loading"] = 0] = "loading";
    CountState[CountState["loaded"] = 1] = "loaded";
})(CountState || (CountState = {}));
var InnerTagIndexInternalList = React.memo(function (_a) {
    var className = _a.className, departmentTrees = _a.departmentTrees;
    var userAPI = useContext(UserAPIContext);
    var documentAPI = useContext(DocumentAPIContext);
    var tagIndexContext = useContext(TagIndexContext);
    var searchParamContext = useContext(SearchParamContext);
    var selectItem = tagIndexContext.selectItem, selectedItem = tagIndexContext.selectedItem;
    var _b = __read(useState([]), 2), visibleItems = _b[0], setVisibleItems = _b[1];
    var _c = __read(useState(new Map()), 2), itemCountMap = _c[0], setItemCountMap = _c[1];
    var fetchCount = useCallback(function (tagIndex) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, documentAPI
                    .searchDocuments({
                    unread_only: searchParamContext.unreadOnly,
                    tag_index: composeTagIndexParam(tagIndex),
                    page: 1,
                    perPage: 0,
                })
                    .then(function (resp) { return resp.count; })];
        });
    }); }, [documentAPI, searchParamContext.unreadOnly]);
    var fetchCountsForItems = useCallback(function (items) {
        var e_1, _a;
        var _loop_1 = function (item) {
            var consoleError = console.error;
            setItemCountMap(function (countMap) {
                // Loaded already, no need to load again
                if (countMap.get(item) !== undefined)
                    return countMap;
                var newMap = new Map(countMap);
                var loadCountPromise = fetchCount(item);
                var loadingState = {
                    state: CountState.loading,
                    promise: loadCountPromise,
                };
                newMap.set(item, loadingState);
                loadCountPromise
                    .then(function (count) {
                    setItemCountMap(function (countMap) {
                        // Returned promise doesn't match loading state
                        if (countMap.get(item) !== loadingState)
                            return countMap;
                        var newMap = new Map(countMap);
                        newMap.set(item, { state: CountState.loaded, count: count });
                        return newMap;
                    });
                })
                    .catch(function (e) {
                    consoleError("Failed to fetch count", e);
                });
                return newMap;
            });
        };
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                _loop_1(item);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }, [fetchCount]);
    var departmentsByParentId = useMemo(function () {
        var e_2, _a;
        var _b;
        var map = new Map();
        var _loop_2 = function (department) {
            // Top level department
            var list = (_b = map.get(null)) !== null && _b !== void 0 ? _b : [];
            list.push(department);
            map.set(null, list);
            var setChildrenRecursively = function (node) {
                var e_3, _a;
                map.set(node.id, node.children);
                try {
                    for (var _b = (e_3 = void 0, __values(node.children)), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var child = _c.value;
                        setChildrenRecursively(child);
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
            };
            setChildrenRecursively(department);
        };
        try {
            for (var departmentTrees_1 = __values(departmentTrees), departmentTrees_1_1 = departmentTrees_1.next(); !departmentTrees_1_1.done; departmentTrees_1_1 = departmentTrees_1.next()) {
                var department = departmentTrees_1_1.value;
                _loop_2(department);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (departmentTrees_1_1 && !departmentTrees_1_1.done && (_a = departmentTrees_1.return)) _a.call(departmentTrees_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return map;
    }, [departmentTrees]);
    var fetchDepartmentUsers = useCallback(function (source, params) { return __awaiter(void 0, void 0, void 0, function () {
        var page, perPage, usersResp, userItems, departmentItems;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    page = (_a = params === null || params === void 0 ? void 0 : params.page) !== null && _a !== void 0 ? _a : 1;
                    perPage = (_b = params === null || params === void 0 ? void 0 : params.perPage) !== null && _b !== void 0 ? _b : PAGE_SIZE;
                    return [4 /*yield*/, userAPI.listUsers({
                            departmentIds: [source.department.id],
                            page: page,
                            perPage: perPage,
                        })];
                case 1:
                    usersResp = _d.sent();
                    userItems = usersResp.data.map(function (user) {
                        return {
                            item: {
                                type: "user",
                                departmentId: source.department.id,
                                user: user,
                            },
                            fetchNextParams: undefined,
                            isToggleable: false,
                        };
                    });
                    if (page > 1) {
                        // Not the first page, only return users
                        return [2 /*return*/, {
                                items: userItems,
                                hasMore: userItems.length >= perPage,
                                fetchNextParams: { page: page + 1, perPage: perPage },
                            }];
                    }
                    departmentItems = ((_c = departmentsByParentId.get(source.department.id)) !== null && _c !== void 0 ? _c : []).map(function (department) {
                        return {
                            item: {
                                type: "department",
                                department: department,
                            },
                            fetchNextParams: { page: 1, perPage: PAGE_SIZE },
                            isToggleable: true,
                        };
                    });
                    return [2 /*return*/, {
                            items: departmentItems.concat(userItems),
                            hasMore: userItems.length >= perPage,
                            fetchNextParams: { page: page + 1, perPage: perPage },
                        }];
            }
        });
    }); }, [departmentsByParentId, userAPI]);
    var getRootDepartments = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            return [2 /*return*/, {
                    items: ((_a = departmentsByParentId.get(null)) !== null && _a !== void 0 ? _a : []).map(function (department) {
                        return {
                            item: {
                                type: "department",
                                department: department,
                            },
                            fetchNextParams: { page: 1, perPage: PAGE_SIZE },
                            isToggleable: true,
                        };
                    }),
                    fetchNextParams: undefined,
                    hasMore: false,
                }];
        });
    }); }, [departmentsByParentId]);
    var onFetchMore = useCallback(function (source, params) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (source === null) {
                return [2 /*return*/, getRootDepartments()];
            }
            switch (source.type) {
                case "department":
                    return [2 /*return*/, fetchDepartmentUsers(source, params)];
                default:
                    throw new Error("Unexpected: User should have no childrens");
            }
            return [2 /*return*/];
        });
    }); }, [fetchDepartmentUsers, getRootDepartments]);
    var onVisibleItemsChanged = useCallback(function (items) {
        setVisibleItems(items);
    }, []);
    var departmentLabel = useDepartmentLabel();
    var internalLabelExtractor = useCallback(function (tag) {
        switch (tag.type) {
            case "department":
                return departmentLabel(tag.department);
            case "user":
                return UserExtractors.name(tag.user);
            default:
                throw new Error("Unexpected tag type " + tag.type);
        }
    }, [departmentLabel]);
    var internalKeyExtractor = useCallback(function (tag) {
        switch (tag.type) {
            case "department":
                return "department/" + tag.department.id;
            case "user":
                return "department/" + tag.departmentId + "/user/" + tag.user.id;
            default:
                throw new Error("Unexpected tag type " + tag.type);
        }
    }, []);
    var badgeTextExtractor = useCallback(function (item) {
        var count = itemCountMap.get(item);
        if (!count)
            return "";
        if (count.state === CountState.loading)
            return " ";
        return "" + count.count;
    }, [itemCountMap]);
    var onSelectExternalItem = useCallback(function (tag) {
        selectItem(tag);
    }, [selectItem]);
    var selectedExternalTagKey = useMemo(function () {
        if (selectedItem === null)
            return null;
        switch (selectedItem.type) {
            case "department":
            case "user":
                return internalKeyExtractor(selectedItem);
            default:
                return null;
        }
    }, [internalKeyExtractor, selectedItem]);
    useEffect(function () {
        // Clear loaded counts on fetchCount changed
        setItemCountMap(new Map());
        fetchCountsForItems(visibleItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchCount]);
    useEffect(function () {
        // Fetch counts on visible items changed
        fetchCountsForItems(visibleItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleItems]);
    return (React.createElement(CollapsiblePaginatedList, { className: cn(className), onFetchMore: onFetchMore, labelExtractor: internalLabelExtractor, keyExtractor: internalKeyExtractor, onItemSelected: onSelectExternalItem, selectedItemKey: selectedExternalTagKey, onVisibleItemsChange: onVisibleItemsChanged, badgeTextExtractor: badgeTextExtractor, expandFirstItemInitially: true }));
});
export var TagIndexInternalList = React.memo(function (props) {
    var className = props.className;
    var me = useContext(AuthContext).me;
    var departmentAPI = useContext(DepartmentAPIContext);
    var _a = __read(useState(null), 2), departmentTrees = _a[0], setDepartmentTrees = _a[1];
    useEffect(function () {
        departmentAPI
            .getDepartmentTree({
            isEnabled: (me === null || me === void 0 ? void 0 : me.identity) === Identity.SystemAdmin ? "any" : "true",
        })
            .then(function (resp) {
            setDepartmentTrees(resp.trees);
        })
            .catch(function (e) {
            console.error("Failed to fetch department tree in TagIndexExternalList", e);
        });
    }, [departmentAPI, me === null || me === void 0 ? void 0 : me.identity]);
    return (React.createElement(React.Fragment, null, departmentTrees !== null ? (React.createElement(InnerTagIndexInternalList, __assign({}, props, { departmentTrees: departmentTrees }))) : (React.createElement("div", { className: cn(className, "p-4") },
        React.createElement(Spinner, { size: "small" })))));
});

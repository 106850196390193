import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { getAllColorsResponseSchema, } from "@shared/models/api/color_api";
export var ColorAPIContext = React.createContext(undefined);
export var ColorAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var getAllColors = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var respRaw;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiContext.get("colors/all")];
                case 1:
                    respRaw = _a.sent();
                    return [2 /*return*/, getAllColorsResponseSchema.validateSync(respRaw)];
            }
        });
    }); }, [apiContext]);
    var value = useMemo(function () {
        return {
            getAllColors: getAllColors,
        };
    }, [getAllColors]);
    return (React.createElement(ColorAPIContext.Provider, { value: value }, children));
});

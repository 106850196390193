import * as yup from "yup";
import { contactSchema } from "./contact_api";
import { companySchema } from "./company_api";
import { dealSchema } from "./deal_api";
import { prioritySchema } from "./priority_api";
import { otherTagSchema } from "./other_tag_api";
import { tagSchema } from "./tag_api";
export var listAllTagParamSchema = yup
    .object({
    search: yup.string(),
    limit: yup.number(),
    limitPerGroup: yup.number(),
})
    .snakeCase()
    .defined();
export var listAllTagResponseSchema = yup
    .object({
    contacts: yup.array(contactSchema).defined(),
    companies: yup.array(companySchema).defined(),
    deals: yup.array(dealSchema).defined(),
    priorities: yup.array(prioritySchema).defined(),
    otherTags: yup.array(otherTagSchema).defined(),
    customTags: yup.array(tagSchema).defined(),
    total: yup.number().defined(),
})
    .camelCase()
    .defined();

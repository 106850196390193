import { __assign } from "tslib";
import React, { useCallback } from "react";
import { toast } from "react-hot-toast";
import { ExclamationCircleIcon, CheckCircleIcon, } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { FormattedMessage } from "react-intl";
import cn from "classnames";
var toastIconByType = {
    error: React.createElement(ExclamationCircleIcon, { className: "w-6 h-6 text-error" }),
    success: React.createElement(CheckCircleIcon, { className: "w-6 h-6 text-success" }),
};
var ToastTemplate = React.memo(function (_a) {
    var id = _a.id, type = _a.type, content = _a.content;
    var dismissToast = useCallback(function () {
        toast.dismiss(id);
    }, [id]);
    return (React.createElement("div", { className: cn("flex flex-row bg-white rounded-lg shadow-lg items-start p-4", { "items-center": !content.descriptionId }) },
        toastIconByType[type],
        React.createElement("div", { className: "ml-3 flex flex-col" },
            React.createElement("span", { className: "text-sm leading-5 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: content.titleId, values: content.titleValues })),
            content.descriptionId && (React.createElement("span", { className: "text-sm leading-5 font-normal text-gray-500 mt-1" },
                React.createElement(FormattedMessage, { id: content.descriptionId })))),
        React.createElement("button", { type: "button", className: "ml-4", onClick: dismissToast },
            React.createElement(XIcon, { className: "text-gray-400 w-5 h-5" }))));
});
export function useAppToast() {
    var showErrorToast = useCallback(function (content, opts) {
        toast.custom(function (t) { return React.createElement(ToastTemplate, { id: t.id, type: "error", content: content }); }, __assign({}, opts));
    }, []);
    var showSuccessToast = useCallback(function (content, opts) {
        toast.custom(function (t) { return React.createElement(ToastTemplate, { id: t.id, type: "success", content: content }); }, __assign({}, opts));
    }, []);
    return { showErrorToast: showErrorToast, showSuccessToast: showSuccessToast };
}

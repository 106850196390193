import { __awaiter, __generator } from "tslib";
import React, { useContext, useMemo, useCallback } from "react";
import { APIContext } from "./APIContext";
import { presignRequestSchema, presignResponseSchema, } from "@shared/models/api/file_api";
export var FileAPIContext = React.createContext(undefined);
export var FileAPIContextProvider = React.memo(function (_a) {
    var children = _a.children;
    var apiContext = useContext(APIContext);
    var uploadFile = useCallback(function (file, endpoint) {
        if (endpoint === void 0) { endpoint = "files/upload"; }
        return __awaiter(void 0, void 0, void 0, function () {
            var rawParams, params, respRaw, presignResponse, fileId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        rawParams = {
                            fileName: file.name,
                            fileType: file.type,
                            fileSize: file.size,
                        };
                        params = presignRequestSchema.validateSync(rawParams);
                        return [4 /*yield*/, apiContext.post(endpoint, params)];
                    case 1:
                        respRaw = _a.sent();
                        presignResponse = presignResponseSchema.validateSync(respRaw);
                        fileId = presignResponse.id;
                        return [4 /*yield*/, fetch(presignResponse.url, {
                                method: "PUT",
                                body: file,
                                headers: {
                                    "x-ms-blob-type": "BlockBlob",
                                },
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, fileId];
                }
            });
        });
    }, [apiContext]);
    var uploadImage = useCallback(function (file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, uploadFile(file, "files/upload_image")];
        });
    }); }, [uploadFile]);
    var value = useMemo(function () {
        return {
            uploadFile: uploadFile,
            uploadImage: uploadImage,
        };
    }, [uploadFile, uploadImage]);
    return (React.createElement(FileAPIContext.Provider, { value: value }, children));
});

import { __assign, __read, __rest } from "tslib";
import React, { Fragment, useState, useCallback, } from "react";
import cn from "classnames";
import { SearchContextProvider } from "@contexts/SearchContext";
import { FilterContextProvider } from "@contexts/FilterContext";
import { TagIndexContextProvider } from "@contexts/TagIndexContext";
import { MainRouteContextProvider } from "@contexts/MainRouteContext";
import cloverLogo from "@assets/ic-clover-logo.svg";
import { NavigationSideBar } from "@components/mainRoute/NavigationSideBar";
import { ROUTES } from "./routes";
import { AuthRoute, AuthState } from "./AuthRoute";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Header } from "@components/mainRoute/Header";
import { ThreadReadStatusContextProvider } from "@contexts/ThreadReadStatusContext";
import { NotFoundScreen } from "@components/screens/NotFoundScreen";
var SideMenuOverlay = React.memo(function (props) {
    var isActive = props.isActive, setActive = props.setActive, remountMainRouteScreen = props.remountMainRouteScreen;
    return (React.createElement(Transition.Root, { show: isActive, as: Fragment },
        React.createElement(Dialog, { as: "div", className: "fixed inset-0 flex z-40 md:hidden", onClose: setActive },
            React.createElement(Transition.Child, { as: Fragment, enter: "transition-opacity ease-linear duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition-opacity ease-linear duration-300", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                React.createElement(Dialog.Overlay, { className: "fixed inset-0 bg-gray-600 opacity-75" })),
            React.createElement(Transition.Child, { as: Fragment, enter: "transition ease-in-out duration-300 transform", enterFrom: "-translate-x-full", enterTo: "translate-x-0", leave: "transition ease-in-out duration-300 transform", leaveFrom: "translate-x-0", leaveTo: "-translate-x-full" },
                React.createElement("div", { className: "relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white" },
                    React.createElement(Transition.Child, { as: Fragment, enter: "ease-in-out duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in-out duration-300", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                        React.createElement("div", { className: "absolute top-0 right-0 -mr-12 pt-2" },
                            React.createElement("button", { type: "button", className: "ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white", onClick: function () { return setActive(false); } },
                                React.createElement(XIcon, { className: "h-6 w-6 text-white", "aria-hidden": "true" })))),
                    React.createElement("div", { className: "flex-shrink-0 flex items-center px-4" },
                        React.createElement("img", { className: "object-contain", src: cloverLogo })),
                    React.createElement(NavigationSideBar, { className: cn("pt-5"), setSideBarActive: setActive, remountMainRouteScreen: remountMainRouteScreen }))),
            React.createElement("div", { className: "flex-shrink-0 w-14 bg-transparent", "aria-hidden": "true" }))));
});
var MainRouteImpl = function (props) {
    var requiredPermission = props.requiredPermission, children = props.children, restProps = __rest(props, ["requiredPermission", "children"]);
    var _a = __read(useState(false), 2), sideBarOpen = _a[0], setSideBarOpen = _a[1];
    var _b = __read(useState(0), 2), screenKey = _b[0], setScreenKey = _b[1];
    var remountMainRouteScreen = useCallback(function () {
        setScreenKey(function (prev) { return prev + 1; });
    }, []);
    return (React.createElement(AuthRoute, __assign({ requiredAuthState: AuthState.authenticated, authFallbackPath: ROUTES.login, requiredPermission: requiredPermission, permissionFallbackScreen: NotFoundScreen }, restProps),
        React.createElement(ThreadReadStatusContextProvider, null,
            React.createElement(MainRouteContextProvider, null,
                React.createElement(TagIndexContextProvider, null,
                    React.createElement(SearchContextProvider, null,
                        React.createElement(FilterContextProvider, null,
                            React.createElement("div", { className: "relative flex-1 flex overflow-hidden" },
                                React.createElement(SideMenuOverlay, { isActive: sideBarOpen, setActive: setSideBarOpen, remountMainRouteScreen: remountMainRouteScreen }),
                                React.createElement("div", { className: cn("flex-1", "w-full", "flex", "flex-col") },
                                    React.createElement(Header, { setSideBarActive: setSideBarOpen }),
                                    React.createElement("div", { className: cn("flex-1", "flex", "flex-row", "bg-gray-100", "overflow-hidden") },
                                        React.createElement(NavigationSideBar, { className: cn("hidden", "pt-5", "md:flex", "md:max-w-[12.5rem]"), setSideBarActive: setSideBarOpen, remountMainRouteScreen: remountMainRouteScreen }),
                                        React.createElement("div", { className: cn("flex", "flex-1", "min-w-0", "overflow-x-auto"), key: screenKey }, children)))))))))));
};
var MainRoute = React.memo(MainRouteImpl);
export { MainRoute };

import { __assign, __read } from "tslib";
import React, { useContext, useMemo } from "react";
import { WhiteButton } from "../common/WhiteButton";
import { PlusCircleIcon } from "@heroicons/react/outline";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import RequirePermission from "../common/RequirePermission";
import { TagGroupStoreContext } from "@contexts/TagGroupStoreContext";
import { Permission } from "@shared/models/auth";
import { TagGroupSlug } from "@shared/models/tag";
function getGroupInfoFromSlug(slug) {
    switch (slug) {
        case TagGroupSlug.Company:
            return [
                "settings.tags.title.company",
                "settings.tags.tag_counts.company",
            ];
        case TagGroupSlug.Contact:
            return [
                "settings.tags.title.contact",
                "settings.tags.tag_counts.contact",
            ];
        case TagGroupSlug.Industry:
            return [
                "settings.tags.title.industry",
                "settings.tags.tag_counts.industry",
            ];
        case TagGroupSlug.Deal:
            return ["settings.tags.title.deal", "settings.tags.tag_counts.deal"];
        case TagGroupSlug.OtherTag:
            return ["settings.tags.title.others", "settings.tags.tag_counts.others"];
        case TagGroupSlug.Priority:
            return [
                "settings.tags.title.priority",
                "settings.tags.tag_counts.priority",
            ];
        case TagGroupSlug.Region:
            return ["settings.tags.title.region", "settings.tags.tag_counts.region"];
        default:
            throw new Error("Unexpected tag group slug");
    }
}
var TagGroupListItemView = React.memo(function (item) {
    var match = useRouteMatch(item.path);
    var tagGroupStore = useContext(TagGroupStoreContext);
    var tagGroup = tagGroupStore.tagGroupMap.get(item.tagGroupId);
    var tagGroupInfo = useMemo(function () {
        if (tagGroup.slug) {
            var _a = __read(getGroupInfoFromSlug(tagGroup.slug), 2), nameId = _a[0], tagCountMessageId = _a[1];
            return { nameId: nameId, tagCountMessageId: tagCountMessageId };
        }
        return {
            nameId: undefined,
            tagCountMessageId: "settings.tags.tag_counts.custom",
        };
    }, [tagGroup]);
    return (React.createElement("div", { className: cn("flex hover:bg-gray-50", match && "bg-gray-50", !tagGroup.enabled && "opacity-50") },
        React.createElement(Link, { to: item.path, className: "w-full p-4 items-start justify-center flex flex-col " },
            React.createElement("p", { className: "text-sm font-bold leading-5 text-gray-900" }, tagGroupInfo.nameId ? (React.createElement(FormattedMessage, { id: tagGroupInfo.nameId })) : (tagGroup.name)),
            React.createElement("span", { className: "text-sm font-normal leading-5 text-gray-500" },
                React.createElement(FormattedMessage, { id: tagGroupInfo.tagCountMessageId, values: { count: tagGroup.tagCount } })))));
});
var TagGroupSkeleton = React.memo(function () {
    return (React.createElement("div", { className: cn("flex-grow flex flex-col min-h-0 bg-white pt-3.5 px-4 border-b border-gray-200") },
        React.createElement("div", { className: "flex items-center mb-3.5 justify-between flex-wrap sm:flex-nowrap" },
            React.createElement("div", { className: " flex flex-col" },
                React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900" },
                    React.createElement(FormattedMessage, { id: "settings.tags.all_tags" })),
                React.createElement("div", { className: "h-4 w-16 bg-gray-200 rounded-full" }))),
        React.createElement("div", { className: "divide-y divide-gray-200 -mx-4 overflow-y-auto min-h-0 flex-grow" }, new Array(7).fill(null).map(function (_, index) { return (React.createElement("div", { key: index, className: "animate-pulse  w-full p-4 items-start justify-center flex flex-col space-y-1" },
            React.createElement("div", { className: "h-4 w-14 bg-gray-200 rounded-full" }),
            React.createElement("div", { className: "h-4 w-16 bg-gray-200 rounded-full" }))); }))));
});
export var TagGroupList = React.memo(function (props) {
    var className = props.className, onAddGroupClick = props.onAddGroupClick, items = props.items, isLoading = props.isLoading;
    return (React.createElement("div", { className: cn("flex", className) }, isLoading ? (React.createElement(TagGroupSkeleton, null)) : (React.createElement("div", { className: cn("flex-grow flex flex-col min-h-0 bg-white pt-3.5 px-4 border-b border-gray-200") },
        React.createElement("div", { className: "flex items-center mb-3.5 justify-between flex-wrap sm:flex-nowrap" },
            React.createElement("div", { className: " flex flex-col" },
                React.createElement("h1", { className: "font-bold text-base leading-6 text-gray-900" },
                    React.createElement(FormattedMessage, { id: "settings.tags.all_tags" })),
                React.createElement("span", { className: "font-normal text-sm leading-5 text-gray-600" },
                    React.createElement(FormattedMessage, { id: "settings.tags.group_count", values: {
                            count: items.length,
                        } }))),
            React.createElement(RequirePermission, { permission: Permission.TagGroupsCreate },
                React.createElement("div", { className: "flex-shrink-0" },
                    React.createElement(WhiteButton, { size: "base", className: "hidden md:flex", onClick: onAddGroupClick },
                        React.createElement(FormattedMessage, { id: "settings.tags.add_new_tag_group" })),
                    React.createElement(WhiteButton, { size: "sm", className: "md:hidden", onClick: onAddGroupClick },
                        React.createElement(PlusCircleIcon, { className: "h-4 w-4 text-gray-500" }))))),
        React.createElement("div", { className: "divide-y divide-gray-200 -mx-4 overflow-y-auto min-h-0 flex-grow" }, items.map(function (item) { return (React.createElement(TagGroupListItemView, __assign({ key: item.tagGroupId }, item))); }))))));
});

import * as yup from "yup";
import { DocumentGroupType, DocumentType, } from "../document";
import { generatePaginationSchema, paginatedRequest, } from "../pagination";
import { contactSchema } from "./contact_api";
import { companySchema } from "./company_api";
import { prioritySchema } from "./priority_api";
import { tagSchema } from "./tag_api";
import { dealSchema } from "./deal_api";
import { fileSchema } from "./file_api";
import { userSchema } from "./user_api";
import { departmentSchema } from "./department_api";
import { otherTagSchema } from "./other_tag_api";
import { RemoveTypeValues } from "../remove_type";
export var contactEmailAddressSchema = yup
    .object({
    name: yup.string().defined(),
    address: yup.string().defined(),
    contact: contactSchema.defined().nullable(),
})
    .defined();
export var documentGroupSchema = yup
    .object({
    type: yup
        .mixed()
        .oneOf(Object.values(DocumentGroupType))
        .defined(),
    threadId: yup.number().defined(),
    documentId: yup.string().defined().nullable(),
    subject: yup.string().defined(),
    bodyPreview: yup.string().defined(),
    itemCount: yup.number().defined(),
    deals: yup.array(dealSchema.defined()).defined(),
    firstSenderContact: contactEmailAddressSchema.defined().nullable(),
    lastCreatedAt: yup.date().defined(),
    hasAttachments: yup.boolean().defined(),
    uploadedBy: userSchema.defined(),
    isRead: yup.boolean().defined(),
})
    .camelCase()
    .defined();
export var documentSchema = yup
    .object({
    id: yup.string().defined(),
    type: yup
        .mixed()
        .oneOf(Object.values(DocumentType))
        .defined(),
    from: contactEmailAddressSchema.defined().nullable(),
    to: yup.array(contactEmailAddressSchema.defined()).defined(),
    cc: yup.array(contactEmailAddressSchema.defined()).defined(),
    attachments: yup.array(fileSchema.defined()).defined(),
    creator: userSchema.defined().nullable(),
    subject: yup.string().defined(),
    body: yup.string().defined(),
    fullBody: yup.string().defined(),
    createdAt: yup.date().defined(),
    isModifiable: yup.bool().defined(),
})
    .camelCase()
    .defined();
export var searchDocumentRequestSearchParam = yup
    .object()
    .shape({
    text: yup.array(yup.string().defined()),
    from: yup.array(yup.string().defined()),
    to: yup.array(yup.string().defined()),
    subject: yup.array(yup.string().defined()),
    body: yup.array(yup.string().defined()),
    remark: yup.array(yup.string().defined()),
})
    .snakeCase();
export var searchDocumentRequestFilterParam = yup
    .object()
    .shape({
    thread_ids: yup.array(yup.number().defined()),
    date_range_start: yup.date(),
    date_range_end: yup.date(),
    from_addresses: yup.array(yup.string().defined()),
    to_addresses: yup.array(yup.string().defined()),
    contact_ids: yup.array(yup.number().defined()),
    company_ids: yup.array(yup.number().defined()),
    deal_ids: yup.array(yup.number().defined()),
    priority_ids: yup.array(yup.number().defined()),
    other_tag_ids: yup.array(yup.number().defined()),
    tag_ids: yup.array(yup.number().defined()),
    uploader_ids: yup.array(yup.number().defined()),
    industry_ids: yup.array(yup.number().defined()),
    country_ids: yup.array(yup.number().defined()),
    region_ids: yup.array(yup.number().defined()),
})
    .snakeCase();
export var searchDocumentRequestSearchTagsParam = yup
    .object()
    .shape({
    exact_match: yup.array(yup.string().defined()),
})
    .snakeCase();
var searchDocumentExternalTagIndexParam = yup.object({
    industry_id: yup.number().defined(),
    region_id: yup.number().optional(),
    country_id: yup.number().optional(),
    company_id: yup.number().optional(),
    contact_id: yup.number().optional(),
});
var searchDocumentInternalTagIndexParam = yup.object({
    department_id: yup.number().defined(),
    user_id: yup.number().optional(),
});
export var searchDocumentRequestTagIndexParam = yup.object({
    external: searchDocumentExternalTagIndexParam.optional().default(undefined),
    internal: searchDocumentInternalTagIndexParam.optional().default(undefined),
});
export var searchDocumentRequestSchema = yup
    .object({
    search: searchDocumentRequestSearchParam.notRequired().default(undefined),
    filter: searchDocumentRequestFilterParam.notRequired().default(undefined),
    search_tags: searchDocumentRequestSearchTagsParam
        .notRequired()
        .default(undefined),
    tag_index: searchDocumentRequestTagIndexParam
        .optional()
        .default(undefined),
    unread_only: yup.boolean().notRequired(),
})
    .concat(paginatedRequest)
    .defined();
export var searchDocumentResponseSchema = generatePaginationSchema(documentGroupSchema);
export var documentTagsRequestSchema = yup
    .object({
    contact_ids: yup.array(yup.number().defined()).defined(),
    company_ids: yup.array(yup.number().defined()).defined(),
    deal_ids: yup.array(yup.number().defined()).defined(),
    priority_ids: yup.array(yup.number().defined()).defined(),
    other_tag_ids: yup.array(yup.number().defined()).defined(),
    tag_ids: yup.array(yup.number().defined()).defined(),
    share_with_ids: yup.array(yup.number().defined()).defined(),
    department_ids: yup.array(yup.number().defined()).defined(),
})
    .snakeCase()
    .defined();
export var uploadDocumentFilesRequestSchema = yup
    .object({
    files: yup.array(yup.string().defined()).defined(),
    subject: yup.string().defined(),
    body: yup.string().defined(),
    remark: yup.string().defined().nullable(),
    tags: documentTagsRequestSchema,
})
    .snakeCase()
    .defined();
export var uploadDocumentFilesResponseSchema = yup
    .object({
    id: yup.string().defined(),
    subject: yup.string().defined(),
    body: yup.string().defined(),
    remark: yup.string().defined().nullable(),
    contacts: yup.array(contactSchema).defined(),
    companies: yup.array(companySchema).defined(),
    deals: yup.array(dealSchema).defined(),
    priorities: yup.array(prioritySchema).defined(),
    otherTags: yup.array(otherTagSchema).defined(),
    tags: yup.array(tagSchema).defined(),
    shareWiths: yup.array(userSchema).defined(),
    departments: yup.array(departmentSchema).defined(),
})
    .camelCase()
    .defined();
export var updateDocumentFilesRequestSchema = yup
    .object({
    files: yup.array(yup.string().defined()).defined(),
    subject: yup.string().defined(),
    body: yup.string().defined(),
    tags: documentTagsRequestSchema,
})
    .snakeCase()
    .defined();
export var updateDocumentFilesResponseSchema = yup
    .object({
    id: yup.string().defined(),
    subject: yup.string().defined(),
    body: yup.string().defined(),
    contacts: yup.array(contactSchema).defined(),
    companies: yup.array(companySchema).defined(),
    deals: yup.array(dealSchema).defined(),
    priorities: yup.array(prioritySchema).defined(),
    otherTags: yup.array(otherTagSchema).defined(),
    tags: yup.array(tagSchema).defined(),
    shareWiths: yup.array(userSchema).defined(),
    departments: yup.array(departmentSchema).defined(),
})
    .camelCase()
    .defined();
export var getDefaultDocumentFilesResponseSchema = yup
    .object({
    subject: yup.string().defined(),
    body: yup.string().defined(),
    contacts: yup.array(contactSchema).defined(),
    companies: yup.array(companySchema).defined(),
    deals: yup.array(dealSchema).defined(),
    priorities: yup.array(prioritySchema).defined(),
    otherTags: yup.array(otherTagSchema).defined(),
    tags: yup.array(tagSchema).defined(),
    shareWiths: yup.array(userSchema).defined(),
    departments: yup.array(departmentSchema).defined(),
})
    .camelCase()
    .defined();
export var removeDocumentRequestSchema = yup
    .object({
    type: yup.string().defined().oneOf(RemoveTypeValues),
})
    .snakeCase()
    .defined();
export var exportDocumentResponseSchema = yup
    .object({
    url: yup.string().defined(),
})
    .camelCase()
    .defined();

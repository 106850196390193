import React, { useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { FilterContext } from "@contexts/FilterContext";
import { WhiteButton } from "@components/common/WhiteButton";
import { PrimaryButton } from "@components/common/PrimaryButton";
import { CompanyFilter } from "@components/filter/CompanyFilter";
import { DealFilter } from "@components/filter/DealFilter";
import { DateRangeFilter } from "@components/filter/DateRangeFilter";
export var FilterPanel = React.memo(function () {
    var _a = useContext(FilterContext), clearAllFilters = _a.clearAllFilters, isMoreFilterPanelOpened = _a.isMoreFilterPanelOpened, openMoreFilterPanel = _a.openMoreFilterPanel, closeMoreFilterPanel = _a.closeMoreFilterPanel, appliedFilters = _a.appliedFilters;
    var isMoreFilterApplied = useMemo(function () {
        return [
            appliedFilters.from,
            appliedFilters.toOrCc,
            appliedFilters.uploaders,
            appliedFilters.contacts,
            appliedFilters.priorities,
            appliedFilters.otherTags,
            appliedFilters.customTags,
            appliedFilters.industries,
            appliedFilters.countries,
            appliedFilters.regions,
        ].some(function (items) { return items.length > 0; });
    }, [appliedFilters]);
    var MoreButton = useMemo(function () {
        if (isMoreFilterApplied) {
            return PrimaryButton;
        }
        return WhiteButton;
    }, [isMoreFilterApplied]);
    return (React.createElement("section", { className: "block" },
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { className: "flex-1 overflow-hidden" },
                React.createElement(CompanyFilter, null)),
            React.createElement("div", { className: "flex-1 overflow-hidden ml-2" },
                React.createElement(DealFilter, null))),
        React.createElement("div", { className: "flex items-center mt-2 mb-4" },
            React.createElement("div", { className: "flex-1 overflow-hidden" },
                React.createElement(DateRangeFilter, null)),
            isMoreFilterPanelOpened ? (React.createElement(MoreButton, { size: "base", className: "ml-2", onClick: closeMoreFilterPanel },
                React.createElement(FormattedMessage, { id: "filter_panel.less_filters" }))) : (React.createElement(MoreButton, { size: "base", className: "ml-2", onClick: openMoreFilterPanel },
                React.createElement(FormattedMessage, { id: "filter_panel.more_filters" })))),
        !isMoreFilterPanelOpened && (React.createElement("div", null,
            React.createElement("button", { type: "button", className: "text-xs leading-4 text-indigo-700", onClick: clearAllFilters },
                React.createElement(FormattedMessage, { id: "filter_panel.clear_filter" }))))));
});

import { __read } from "tslib";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { TagGroupAPIContext } from "@shared/contexts/api/TagGroupAPIContext";
import { ColorAPIContext } from "@shared/contexts/api/ColorAPIContext";
import { CompanyAPIContext } from "@shared/contexts/api/CompanyAPIContext";
import { CountryAPIContext } from "@shared/contexts/api/CountryAPIContext";
import { IndustryAPIContext } from "@shared/contexts/api/IndustryAPIContext";
import { ROUTES } from "@router/routes";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import { useAppToast } from "../common/Toast";
import { SkeletonForm } from "../settings/forms/SkeletonForm";
import { CompanyForm } from "../settings/forms/CompanyForm";
import { parseTagUpdateErrorMessage } from "@shared/models/error";
import { useNavigation } from "@hooks/useNavigation";
export var CompanyTagEditScreen = React.memo(function () {
    var _a, _b;
    var tagId = useParams().tagId;
    var showErrorToast = useAppToast().showErrorToast;
    var companyId = useMemo(function () { return parseInt(tagId, 10); }, [tagId]);
    var navigation = useNavigation();
    var location = useLocation();
    var companyAPI = useContext(CompanyAPIContext);
    var colorAPI = useContext(ColorAPIContext);
    var countryAPI = useContext(CountryAPIContext);
    var industryAPI = useContext(IndustryAPIContext);
    var tagGroupAPI = useContext(TagGroupAPIContext);
    var _c = __read(useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tagName) !== null && _b !== void 0 ? _b : ""), 2), tagName = _c[0], setTagName = _c[1];
    var _d = __read(useState(undefined), 2), initialFormValues = _d[0], setInitialFormValues = _d[1];
    var _e = __read(useState([]), 2), colors = _e[0], setColors = _e[1];
    var _f = __read(useState([]), 2), countries = _f[0], setCountries = _f[1];
    var _g = __read(useState([]), 2), industries = _g[0], setIndustries = _g[1];
    var _h = __read(useState({}), 2), additionalFields = _h[0], setAdditionalFields = _h[1];
    var _j = __read(useState(true), 2), isLoading = _j[0], setIsLoading = _j[1];
    var _k = __read(useState(false), 2), isSubmitting = _k[0], setIsSubmitting = _k[1];
    var handleGoBack = useCallback(function () {
        if (!navigation.goBack()) {
            navigation.push(ROUTES.settings.tags.company.group_detail);
        }
    }, [navigation]);
    var handleSubmit = useCallback(function (formValues) {
        var _a, _b;
        setIsSubmitting(true);
        companyAPI
            .updateCompany(companyId, {
            name: formValues.tagName.trim(),
            fullName: !!formValues.fullName ? formValues.fullName.trim() : null,
            domains: !!formValues.domain
                ? formValues.domain.split(",").map(function (d) { return d.trim(); })
                : [],
            address: !!formValues.address ? formValues.address.trim() : null,
            countryIds: formValues.countries.data.map(function (c) { return c.id; }),
            industryId: formValues.industry.id,
            colorId: (_b = (_a = formValues.color) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
            additional: formValues.additional,
        })
            .then(function () {
            handleGoBack();
        })
            .catch(function (err) {
            var _a = __read(parseTagUpdateErrorMessage(err), 2), titleId = _a[0], descriptionId = _a[1];
            showErrorToast({
                titleId: titleId,
                descriptionId: descriptionId,
            }, {
                position: "bottom-center",
            });
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    }, [companyId, companyAPI, setIsSubmitting, showErrorToast, handleGoBack]);
    useEffect(function () {
        setIsLoading(true);
        Promise.all([
            companyAPI.getCompany(companyId),
            colorAPI.getAllColors(),
            countryAPI.getAllCountries(),
            industryAPI.getAllIndustries(),
            tagGroupAPI.getTagGroupBySlug("company"),
        ])
            .then(function (_a) {
            var _b, _c, _d;
            var _e = __read(_a, 5), company = _e[0], colorResp = _e[1], countryResp = _e[2], industryResp = _e[3], tagGroupResp = _e[4];
            // Handles displaying tag name when we enter the page by typing in the url
            setTagName(company.name);
            setColors(colorResp.data);
            setCountries(countryResp.data);
            setIndustries(industryResp.data);
            setAdditionalFields(tagGroupResp.definition);
            setInitialFormValues({
                fullName: (_b = company.fullName) !== null && _b !== void 0 ? _b : "",
                tagName: company.name,
                countries: {
                    data: company.countries,
                },
                industry: company.industry,
                domain: company.domains.join(","),
                color: (_c = company.color) !== null && _c !== void 0 ? _c : colorResp.data[0],
                address: (_d = company.address) !== null && _d !== void 0 ? _d : "",
                additional: company.additional,
            });
        })
            .catch(function (err) {
            console.error("Failed to fetch data = ", err);
            showErrorToast({
                titleId: "common.errors.data.fetch_failed.title",
                descriptionId: "common.errors.data.fetch_failed.description",
            }, {
                position: "bottom-center",
            });
            handleGoBack();
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [
        companyId,
        colorAPI,
        countryAPI,
        industryAPI,
        tagGroupAPI,
        companyAPI,
        handleGoBack,
        setTagName,
        showErrorToast,
        setColors,
        setCountries,
        setIndustries,
        setAdditionalFields,
        setInitialFormValues,
    ]);
    return (React.createElement("div", { className: "flex-1 min-w-0 flex flex-col" },
        React.createElement("div", { className: "px-4 py-6 bg-white" },
            React.createElement("span", { className: "text-base leading-6 font-bold text-gray-900" },
                React.createElement(FormattedMessage, { id: "settings.tags.form.edit.title", values: {
                        tagName: tagName,
                    } }))),
        React.createElement("div", { className: "flex-1" }, isLoading ? (React.createElement(SkeletonForm, null)) : (React.createElement(CompanyForm, { initialFormValues: initialFormValues, colors: colors, industries: industries, countries: countries, additionalFields: additionalFields, isSubmitting: isSubmitting, onSubmit: handleSubmit, onCancel: handleGoBack })))));
});

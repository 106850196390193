import { __assign, __read, __values } from "tslib";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import cn from "classnames";
import { CollapsibleListContext, useCollapsibleListContext, } from "./CollapsibleListContext";
import { CollapsibleListItem } from "./CollapsibleListItem";
import { Waypoint } from "react-waypoint";
import { Spinner } from "@components/common/Spinner";
var InnerCollapsibleList = React.memo(function (_a) {
    var className = _a.className, listState = _a.listState, expandFirstItemInitially = _a.expandFirstItemInitially;
    var _b = useCollapsibleListContext(), onItemSelected = _b.onItemSelected, onFetchData = _b.onFetchData, childrenMap = _b.childrenMap;
    var _c = __read(useState(null), 2), expandedItemIdx = _c[0], setExpandedItemIdx = _c[1];
    var children = useMemo(function () { return childrenMap.get(listState.item); }, [childrenMap, listState.item]);
    var onClickChild = useCallback(function (item, idx) {
        if (item.isToggleable) {
            setExpandedItemIdx(function (prevIdx) {
                if (prevIdx === idx)
                    return null;
                return idx;
            });
        }
        if (item.item !== null) {
            onItemSelected === null || onItemSelected === void 0 ? void 0 : onItemSelected(item.item);
        }
    }, [onItemSelected]);
    var onLoadMore = useCallback(function () {
        onFetchData(listState);
    }, [listState, onFetchData]);
    var _d = __read(useState(0), 2), waypointKey = _d[0], setWaypointKey = _d[1];
    useEffect(function () {
        setWaypointKey(function (prev) { return prev + 1; });
    }, [listState]);
    return (React.createElement("ul", { className: cn("block overflow-y-auto", className) }, children === null || children === void 0 ? void 0 :
        children.map(function (item, idx) {
            return (React.createElement(CollapsibleListItem, { key: idx, isExpanded: expandedItemIdx === idx ||
                    (expandedItemIdx == null &&
                        idx === 0 &&
                        !!expandFirstItemInitially), listState: item, itemIndex: idx, depth: 0, onClick: onClickChild }));
        }),
        listState.isFetchingMore && (React.createElement("li", { className: "block p-2" },
            React.createElement(Spinner, { size: "small" }))),
        listState.hasMore && (React.createElement(Waypoint, { key: waypointKey, onEnter: onLoadMore },
            React.createElement("li", null)))));
});
var CollapsiblePaginatedListImpl = function (_a) {
    var className = _a.className, labelExtractor = _a.labelExtractor, badgeTextExtractor = _a.badgeTextExtractor, keyExtractor = _a.keyExtractor, selectedItemKey = _a.selectedItemKey, onItemSelected = _a.onItemSelected, onFetchMore = _a.onFetchMore, onVisibleItemsChange = _a.onVisibleItemsChange, expandFirstItemInitially = _a.expandFirstItemInitially;
    var _b = __read(useState(function () {
        return new Map([
            [
                null,
                {
                    parent: undefined,
                    item: null,
                    fetchNextParams: undefined,
                    isToggleable: false,
                    isFetchingMore: false,
                    hasMore: true,
                },
            ],
        ]);
    }), 2), listStateMap = _b[0], setListStateMap = _b[1];
    var _c = __read(useState(new Set()), 2), visibleItems = _c[0], setVisibleItems = _c[1];
    var onItemShow = useCallback(function (item) {
        setVisibleItems(function (prev) {
            if (prev.has(item))
                return prev;
            var newSet = new Set(prev);
            newSet.add(item);
            return newSet;
        });
    }, []);
    var onItemHide = useCallback(function (item) {
        setVisibleItems(function (prev) {
            if (!prev.has(item))
                return prev;
            var newSet = new Set(prev);
            newSet.delete(item);
            return newSet;
        });
    }, []);
    var childrenMap = useMemo(function () {
        var e_1, _a;
        var _b;
        var result = new Map();
        try {
            for (var _c = __values(listStateMap.entries()), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), item = _e[1];
                if (item.parent === undefined) {
                    continue;
                }
                var list = (_b = result.get(item.parent)) !== null && _b !== void 0 ? _b : [];
                list.push(item);
                result.set(item.parent, list);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return result;
    }, [listStateMap]);
    var onFetchData = useCallback(function (listState) {
        var _a;
        if (listState.isFetchingMore || !listState.hasMore) {
            return;
        }
        setListStateMap(function (prevMap) {
            var newMap = new Map(prevMap);
            newMap.set(listState.item, __assign(__assign({}, listState), { isFetchingMore: true }));
            return newMap;
        });
        onFetchMore((_a = listState.item) !== null && _a !== void 0 ? _a : null, listState.fetchNextParams)
            .then(function (newDataList) {
            var newListStates = newDataList.items.map(function (newData) {
                var newListState = {
                    parent: listState.item,
                    item: newData.item,
                    fetchNextParams: newData.fetchNextParams,
                    isFetchingMore: false,
                    isToggleable: newData.isToggleable,
                    hasMore: newData.isToggleable,
                };
                return newListState;
            });
            setListStateMap(function (prevMap) {
                var e_2, _a;
                var newMap = new Map(prevMap);
                newMap.set(listState.item, __assign(__assign({}, listState), { fetchNextParams: newDataList.fetchNextParams, isFetchingMore: false, hasMore: newDataList.hasMore }));
                try {
                    for (var newListStates_1 = __values(newListStates), newListStates_1_1 = newListStates_1.next(); !newListStates_1_1.done; newListStates_1_1 = newListStates_1.next()) {
                        var listState_1 = newListStates_1_1.value;
                        newMap.set(listState_1.item, listState_1);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (newListStates_1_1 && !newListStates_1_1.done && (_a = newListStates_1.return)) _a.call(newListStates_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                return newMap;
            });
        })
            .catch(function (e) {
            console.error(e);
        });
    }, [onFetchMore]);
    useEffect(function () {
        onVisibleItemsChange === null || onVisibleItemsChange === void 0 ? void 0 : onVisibleItemsChange(Array.from(visibleItems));
    }, [onVisibleItemsChange, visibleItems]);
    var contextValue = useMemo(function () {
        return {
            labelExtractor: labelExtractor,
            badgeTextExtractor: badgeTextExtractor,
            onItemSelected: onItemSelected,
            onFetchData: onFetchData,
            childrenMap: childrenMap,
            keyExtractor: keyExtractor,
            selectedItemKey: selectedItemKey,
            onItemHide: onItemHide,
            onItemShow: onItemShow,
        };
    }, [
        labelExtractor,
        badgeTextExtractor,
        onItemSelected,
        onFetchData,
        childrenMap,
        keyExtractor,
        selectedItemKey,
        onItemHide,
        onItemShow,
    ]);
    var rootListState = useMemo(function () {
        return listStateMap.get(null);
    }, [listStateMap]);
    return (React.createElement(CollapsibleListContext.Provider, { value: contextValue },
        React.createElement(InnerCollapsibleList, { className: className, listState: rootListState, expandFirstItemInitially: expandFirstItemInitially })));
};
export var CollapsiblePaginatedList = React.memo(CollapsiblePaginatedListImpl);
